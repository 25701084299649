import { useEffect, useMemo, useState } from 'react';
import { DAYS_IN_YEAR, REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS } from '../../shared';

import { MultiFeeDistributionService } from '../../libs/aave-protocol-js/MulteFeeDistributionContract';
import { useProviderContext } from '../../libs/provider/WalletProvider';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import useRewardTokenPrices from '../tokens-price/tokens-price.hooks';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { Address } from 'viem';
import { useLockDataStore } from './lock-data.store';
import { useFeeDistribution } from '../fee-distribution';

export function useLockData() {
  const [lockApr, setLockApr] = useState<BigNumber>(valueToBigNumber(-1));
  const { dailyPlatformFees } = useFeeDistribution();
  const { user } = useDynamicPoolDataContext();
  const {
    prices: { totalLockedSupplyWithMultiplier, lpTokenPriceUsd },
  } = useRewardTokenPrices();
  const [lockData, setLockData] = [useLockDataStore.use.data(), useLockDataStore.use.setLockData()];
  const setLockIndex = useLockDataStore.use.setLockIndex();
  const setAutoRelockDisabled = useLockDataStore.use.setAutoRelockDisabled();
  const isLockDataLoaded = useLockDataStore.use.isLockDataLoaded();

  const { provider } = useProviderContext();

  const multiFeeDistributionService = useMemo(
    () => new MultiFeeDistributionService(provider, REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS),
    [provider]
  );

  useEffect(() => {
    if (!isLockDataLoaded) {
      (async () => {
        const lockInfo = await multiFeeDistributionService.getLockInfo(user?.id as Address);

        setLockData(...lockInfo);
      })();
    }
  }, [isLockDataLoaded, user]);

  useEffect(() => {
    const annualPlatformRevenue = dailyPlatformFees.times(DAYS_IN_YEAR);

    const totalLPValue = totalLockedSupplyWithMultiplier
      ? totalLockedSupplyWithMultiplier.times(lpTokenPriceUsd)
      : valueToBigNumber(0);
    const lockApr =
      annualPlatformRevenue.eq(0) && totalLPValue.eq(0)
        ? valueToBigNumber(0)
        : annualPlatformRevenue.div(totalLPValue).times(100);

    if (!lockApr.isNaN()) {
      setLockApr(lockApr);
    }
  }, [dailyPlatformFees, totalLockedSupplyWithMultiplier, lpTokenPriceUsd]);

  return {
    ...lockData,
    lockApr,
    setLockIndex,
    setAutoRelockDisabled,
  };
}
