import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { MathUtils } from '../../../../utils/math.utils';
import APYLine from '../APYLine';
import { ReserveCollateralData } from '../ReserveCollateralData/ReserveCollateralData';
import { ComputedReserveData } from '../../../../libs/pool-data-provider';
import { TokensSymbolEnum } from '../../../../shared';
import { PoolUtils } from '../../../../utils';
import { BN_ONE } from '../../../../helpers/leverage';
import { useMemo } from 'react';
import { usePoolsInfoData } from '../../../../store';

export function ReserveBorrow({
  reserveOverviewData,
  poolReserve,
}: {
  reserveOverviewData: any;
  poolReserve: ComputedReserveData;
}) {
  const { poolsInfoData } = usePoolsInfoData();

  const loopingData = useMemo(() => {
    const maxLeverage = BN_ONE.div(
      BN_ONE.minus(valueToBigNumber(poolReserve.baseLTVasCollateral))
    ).decimalPlaces(2, BigNumber.ROUND_FLOOR);

    if (poolsInfoData.length) {
      return PoolUtils.getPoolLooping({
        reserve: poolReserve,
        poolsInfoData,
        amount: valueToBigNumber(1),
        maxLeverage,
      });
    }
  }, [poolsInfoData, poolReserve]);

  return (
    <BasicBox className="ReserveOverview__section-list ReserveOverview__supply" variant="secondary">
      <div className="basic-shadow ReserveOverview__shadow--lb"></div>
      <BasicBox.Title>Borrow info</BasicBox.Title>

      <ul className="ReserveOverview__badges ReserveOverview__badges--last-stick">
        <li className="ReserveOverview__badge">
          <p className="ReserveOverview__badge-title">Total borrowed</p>
          <div className="ReserveOverview__badge-txt ff-lg">
            {MathUtils.formatNumber(reserveOverviewData.totalBorrows, 2)}
          </div>
          <span className="ReserveOverview__badge-value">
            ${' '}
            {MathUtils.formatNumber(
              valueToBigNumber(poolReserve.priceInMarketReferenceCurrency).times(
                reserveOverviewData.totalBorrows
              ),
              2
            )}
          </span>
        </li>
        <li className="ReserveOverview__badge">
          <p className="ReserveOverview__badge-title">Interest</p>
          <div className="ReserveOverview__badge-txt ff-lg">
            <APYLine
              value={reserveOverviewData.variableAPY}
              condition={reserveOverviewData.borrowingEnabled}
            />
          </div>
        </li>
        <li className="ReserveOverview__badge">
          <p className="ReserveOverview__badge-title">{TokensSymbolEnum.REWARD} APR</p>
          <div className="ReserveOverview__badge-txt ff-lg">
            {MathUtils.toLocaleNumber(loopingData?.rewardsBorrowApr || 0, 2)}%
          </div>
        </li>
        <li className="ReserveOverview__badge">
          <p className="ReserveOverview__badge-title">{TokensSymbolEnum.REWARD} APR</p>
          <div className="ReserveOverview__badge-txt ff-lg">
            {MathUtils.toLocaleNumber(loopingData?.netAPY, 2)}%
          </div>
        </li>
      </ul>
      <ReserveCollateralData {...{ reserveOverviewData }} />
    </BasicBox>
  );
}
