import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .DashboardHealth {
    display: flex;

    @include respond-to(xs) {
      flex-direction: column-reverse;
    }

    &__left {
      margin-right: 38px;

      @include respond-to(sm) {
        margin-right: 24px;
      }

      @include respond-to(xs) {
        margin: 12px auto 0;
        width: 200px;
      }
    }

    &__image {
      position: relative;
    }

    &__bar {
      width: 100%;
      height: auto;
    }

    &__arrow {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      transform-origin: 50% 100%;
      transform: rotate(-90deg);
      width: 4%;
      height: 72.5%;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      padding: 5px 0.5%;

      span {
        text-align: center;
        width: 16px;
      }
    }

    &__box {
      margin-bottom: 24px;

      @include respond-to(xs) {
        margin-bottom: 12px;
      }
    }

    &__box-text {
      font-size: 16px;
      font-weight: 600;

      @include respond-to(xs) {
        font-size: 14px;
      }
    }

    &__box-value {
      font-size: 27px;
      font-weight: 700;
      margin-top: 5px;
      text-align: left;

      @include respond-to(xs) {
        font-size: 22px;
      }
    }

    &__item {
      margin: 5px 0 0;
      white-space: nowrap;

      @include respond-to(xs) {
        font-size: 12px;
      }
    }

    &__item-label {
      color: rgba(0, 0, 0, 0.6);
    }

    &__item-value {
      display: inline-block;
      margin-left: 4px;
    }
  }
`;

export default staticStyles;
