import { valueToBigNumber } from '@aave/math-utils';
import { ComputedUserReserve } from '@aave/math-utils';
import BigNumber from 'bignumber.js';
import { useDynamicPoolDataContext, ComputedReserveData } from '../../../libs/pool-data-provider';
import { useEffect, useState } from 'react';

export default function useMaxToWithdraw({
  userReserve,
  poolReserve,
}: {
  poolReserve?: ComputedReserveData;
  userReserve?: ComputedUserReserve;
}) {
  const { user } = useDynamicPoolDataContext();
  const [maxAmountToWithdraw, setMaxAmountToWithdraw] = useState<BigNumber>(valueToBigNumber(-1));

  useEffect(() => {
    if (user && userReserve && poolReserve) {
      const underlyingBalance = valueToBigNumber(userReserve.underlyingBalance);
      const availableLiquidity = valueToBigNumber(poolReserve.availableLiquidity);
      let maxAmountToWithdraw = BigNumber.min(underlyingBalance, availableLiquidity);
      let maxCollateralToWithdrawInETH = valueToBigNumber('0');

      if (
        userReserve.usageAsCollateralEnabledOnUser &&
        poolReserve.usageAsCollateralEnabled &&
        Number(user.totalBorrowsMarketReferenceCurrency) !== 0
      ) {
        const excessHF = valueToBigNumber(user.healthFactor).minus('1');
        if (excessHF.gt('0')) {
          maxCollateralToWithdrawInETH = excessHF
            .multipliedBy(user.totalBorrowsMarketReferenceCurrency)
            // because of the rounding issue on the contracts side this value still can be incorrect
            .div(Number(poolReserve.reserveLiquidationThreshold) + 0.01)
            .multipliedBy('0.99');
        }
        maxAmountToWithdraw = BigNumber.min(
          maxAmountToWithdraw,
          maxCollateralToWithdrawInETH.dividedBy(poolReserve.priceInMarketReferenceCurrency)
        );
      }

      setMaxAmountToWithdraw(maxAmountToWithdraw);
    }
  }, [user, userReserve, poolReserve]);

  return { maxAmountToWithdraw };
}
