import { Address } from 'viem';
import { iota } from '../../ui-config/markets/tokensConfig';

export const UI_POOL_DATA_ADDRESS: Address = iota.UiPoolDataProvider as Address;
export const LENDING_POOL_ADDRESSES_PROVIDER: Address =
  iota.LendingPoolAddressesProvider as Address;
export const AAVE_PROTOCOL_DATA_PROVIDER: Address = iota.AaveProtocolDataProvider as Address;

export const WALLET_BALANCE_PROVIDER: Address = iota.WalletBalanceProvider as Address;

export const LOOPING_CONTRACT: Address = iota.Looping as Address;

export const CHEF_INCENTIVES_CONTROLLER: Address = iota.ChefIncentivesController as Address;

export const MULTI_FEE_DISTRIBUTION_ADDRESS: Address = iota.MultiFeeDistribution as Address;
