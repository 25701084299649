export const PoolInfoQuery = `{
  poolInfos {
    allocPoint
    rewardsPerSecond
    tokenAddress
    totalAlloc
    totalSupply
    symbol
    price
    decimals
  }
}`;
