import { Web3Provider, Provider } from '@ethersproject/providers';
import { Address } from 'viem';
import { Contract, Signer, ethers } from 'ethers';

export class StaticApi {
  private static contract: Contract | null = null;
  private static provider: Provider | Web3Provider | Signer | null = null;

  public static getContract(): Contract {
    if (StaticApi.contract === null) {
      throw new Error('Contract not connected');
    } else {
      return StaticApi.contract;
    }
  }

  public static getProvider(): Provider | Web3Provider | Signer {
    if (StaticApi.provider === null) {
      throw new Error('Provider not connected');
    } else {
      return StaticApi.provider;
    }
  }

  public static setProvider(provider: Provider | Web3Provider | Signer) {
    StaticApi.provider = provider;
  }

  public static connect(
    contractAddress: Address,
    abi: any,
    provider: Provider | Web3Provider | Signer
  ) {
    StaticApi.contract = new ethers.Contract(contractAddress, abi, provider);
  }
}
