import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Menu {
    display: flex;
    align-items: center;
    padding: 0 32px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    height: 76px;
    background-color: rgba(254, 248, 232, 0.05);
    backdrop-filter: blur(20px);

    @include respond-to(sm) {
      padding: 0 16px;
      backdrop-filter: none;
      background: rgba(255, 255, 255, 0.95);
    }

    @include respond-to(sm) {
      padding: 0;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      border: none;
      height: auto;
      min-height: 50px;
    }

    &__logo-mobile {
      width: 97px;
    }

    &__logo-inner {
      img {
        width: 97px;
      }
      @include respond-to(sm) {
        display: none;
      }
    }

    &__back-button {
      position: absolute;
      padding: 15px;
      left: 0;
      img {
        width: 20px;
        height: 20px;
      }
    }

    &__title-inner {
      display: none;
      @include respond-to(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding: 0 50px;
      }
      p {
        font-size: $regular;
        text-transform: uppercase;
      }
    }

    &__right-inner {
      display: flex;
      align-items: center;
      margin-left: 120px;
      @include respond-to(md) {
        margin: 0 auto;
      }
      @include respond-to(sm) {
        display: none;
      }
    }

    &__connect {
      margin-left: auto;
      display: flex;
      gap: 12px;
      @include respond-to(md) {
        margin-left: 0;
      }
    }

    &__navigation-inner {
      ul {
        display: flex;
        align-items: center;
        .Menu__link-inner {
          margin-left: 40px;
          @include respond-to(xl) {
            margin-left: 30px;
          }
          @include respond-to(lg) {
            margin-left: 20px;
          }
        }
      }
    }

    &__linkHidden {
      display: none;
    }

    &__burger-inner {
      display: none;
      @include respond-to(md) {
        display: block;
        margin-right: 5px;
      }
      @include respond-to(sm) {
        margin-right: 0;
        position: absolute;
        left: 10px;
      }
    }

    &__buttons-inner {
      display: flex;
      align-items: center;
      @include respond-to(sm) {
        display: none;
      }
    }

    &__link-inner {
      &:hover .MenuLink__title {
        i {
          width: 30%;
          opacity: 1;
        }
      }

      &--dropdown {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .MenuLink {
          cursor: pointer;

          &.Link__disabled {
            filter: blur(5px);
            cursor: default;
          }
        }

        &:hover {
          .Menu__item-dropdown {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    &__item-dropdown {
      position: absolute;
      top: 100%;
      left: -16px;
      display: flex;
      flex-direction: column;
      opacity: 0;
      visibility: hidden;
      padding: 12px 0;
      min-width: 160px;
      background: rgba(255, 255, 255, 0.9);
    }

    &__item-dropdown-link {
      text-align: left;
      padding: 12px 16px;

      &:hover {
        background-color: rgba(221, 221, 221, 0.5);
      }
    }

    &__link-inner-arrow {
      width: 12px;
      transition: color 0.2s;
    }

    &__navigation-soon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      font-size: 14px;
      color: #1763fd;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
`;

export default staticStyles;
