import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .BackTo {
    position: relative;
    z-index: 10;

    button {
      color: #000;
      font-weight: 600;
      font-size: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        svg {
          transform: translateX(-50%);
        }
      }

      svg {
        margin-right: 20px;
        transition: transform 0.2s;
      }
    }
  }

  .ReserveOverview {
    color: #000;

    &__content {
      position: relative;
      z-index: 1;
    }

    &__section {
      margin: 24px 0;
    }

    &__group {
      display: flex;
      gap: 32px;

      @include respond-to(md) {
        flex-wrap: wrap;
      }

      @include respond-to(sm) {
        flex-direction: column;
      }

      &--md-block {
        @include respond-to(md) {
          display: block;
        }
      }
    }

    &__section-list {
      position: relative;
      overflow: hidden;
    }

    &__list {
      margin-top: 12px;
    }

    &__list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px 0 10px;

      span {
        font-weight: 600;
      }
    }

    &__list-value {
      &--switcher {
        margin: -2px 0;
      }
    }

    &__title {
      margin: 0 0 24px;
    }

    &__badges {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      padding-bottom: 20px;
      justify-content: space-between;
      margin-top: 12px;
      gap: 20px;

      &--last-stick {
        .ReserveOverview__badge {
          margin-right: 0;
        }
      }

      @include respond-to(sm) {
        flex-wrap: wrap;

        .ReserveOverview__badge {
          flex-basis: calc(50% - 20px);
        }
      }
    }

    &__badge {
      &:first-of-type {
        margin-left: 0;
      }
    }

    &__badge-title {
      font-size: 16px;

      @include respond-to(lg) {
        font-size: 14px;
      }
    }

    &__badge-txt {
      margin-top: 10px;
      font-size: 27px;

      @include respond-to(lg) {
        font-size: 22px;
      }

      &--no-offset {
        margin-top: 0;
      }

      .Value {
        display: inline-block;
        .Value__value {
          font-size: 27px;
          font-weight: 700;
        }
      }
    }

    &__badge-value {
      margin-top: 10px;
      display: block;
      font-size: 14px;
      opacity: 0.8;
    }

    &__borrow {
      flex-basis: 8%;
    }

    &__supply {
      margin-top: 33px;

      @include respond-to(sm) {
        margin-top: 24px;
      }
    }

    &__supply-usage {
      display: flex;
      font-weight: 600;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0 12px;

      @include respond-to(xs) {
        flex-direction: column;
      }

      p {
        font-size: 20px;
      }

      span {
        font-size: 16px;
        color: #ffb21e;
      }
    }

    &__shadow {
      &--lt {
        left: 0;
        top: 0;
        transform: translateY(-60%);
      }

      &--rb {
        right: 0;
        bottom: 0;
        transform: translate(33%, 33%);
      }

      &--lb {
        left: 0;
        bottom: 0;
        transform: translate(-40%, 40%);
      }
    }

    &__badge-box-wrapper {
      display: flex;
      gap: 12px;

      @include respond-to(xs) {
        flex-direction: column;
      }
    }

    &__badge-box {
      border-image-source: linear-gradient(
        119.09deg,
        rgba(58, 205, 237, 0.02) 1.2%,
        rgba(255, 255, 255, 0) 100%
      );
      box-shadow: -2px -2px 16.8px 0px rgba(255, 255, 255, 0.04),
        2px 2px 26.8px 0px rgba(0, 0, 0, 0.17);

      border: none;
      text-align: center;
      padding: 20px 26px 14px;
      border-radius: 20px;
    }

    &__link {
      display: inline-flex;
      color: inherit;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      margin-top: 12px;

      svg {
        width: 15px;
        margin-top: -2px;
        margin-left: 10px;
      }
    }

    &__deposits {
      @include respond-to(md) {
        flex-basis: 100%;
      }
    }

    &__graphic {
      width: 750px;

      @include respond-to(md) {
        width: 100%;
      }
    }
  }
`;

export default staticStyles;
