import { useEffect, useState } from 'react';

import {
  ReservesDataHumanized,
  UiPoolDataProvider,
  UserReserveDataHumanized,
} from '@aave/contract-helpers';
import { usePoolDataStore } from '../../../store';

import { usePolling } from '../../hooks/use-polling';
import { useProviderContext } from '../../provider/WalletProvider';
import { useWeb3DataContext } from '../../web3-data-provider';
import { LENDING_POOL_ADDRESSES_PROVIDER, UI_POOL_DATA_ADDRESS, iota } from '../../../shared';

// interval in which the rpc data is refreshed
const POLLING_INTERVAL = 30 * 60 * 1000;

export interface PoolDataResponse {
  loading: boolean;
  error: boolean;
  data: {
    reserves?: ReservesDataHumanized;
    userReserves?: UserReserveDataHumanized[];
  };
  refresh: () => Promise<any>;
}

// Fetch reserve and user incentive data from UiIncentiveDataProvider
export function usePoolData(skip: boolean): PoolDataResponse {
  const { provider } = useProviderContext();
  const { currentAccount: address } = useWeb3DataContext();

  const [errorReserves, setErrorReserves] = useState<boolean>(false);
  const [loadingUserReserves, setLoadingUserReserves] = useState<boolean>(false);
  const [errorUserReserves, setErrorUserReserves] = useState<boolean>(false);
  const [reserves, setReserves] = useState<ReservesDataHumanized | undefined>(undefined);
  const [userReserves, setUserReserves] = useState<UserReserveDataHumanized[] | undefined>(
    undefined
  );

  const [fetchPoolData, poolData, currencyData, isPoolDataLoading] = [
    usePoolDataStore.use.fetchPoolData(),
    usePoolDataStore.use.poolData(),
    usePoolDataStore.use.currencyData(),
    usePoolDataStore.use.isPoolDataLoading(),
  ];

  const fetchReserves = async () => {
    try {
      await fetchPoolData(provider);

      setErrorReserves(false);
    } catch (e) {
      console.log('e', e);
      setErrorReserves(e.message);
    }
  };

  // Fetch and format user incentive data from UiIncentiveDataProvider
  const fetchUserReserves = async () => {
    if (!address) return;

    const poolDataProviderContract = new UiPoolDataProvider({
      uiPoolDataProviderAddress: UI_POOL_DATA_ADDRESS,
      provider,
    });

    try {
      setLoadingUserReserves(true);
      const userReservesResponse: UserReserveDataHumanized[] =
        await poolDataProviderContract.getUserReservesHumanized(
          LENDING_POOL_ADDRESSES_PROVIDER,
          address
        );

      setUserReserves(userReservesResponse);
      setErrorUserReserves(false);
    } catch (e) {
      console.log('e', e);
      setErrorUserReserves(e.message);
    }
    setLoadingUserReserves(false);
  };

  usePolling(fetchReserves, POLLING_INTERVAL, skip, [skip, UI_POOL_DATA_ADDRESS, iota.id]);
  usePolling(fetchUserReserves, POLLING_INTERVAL, skip, [
    skip,
    UI_POOL_DATA_ADDRESS,
    iota.id,
    address,
  ]);

  const loading = isPoolDataLoading || loadingUserReserves;
  const error = errorReserves || errorUserReserves;

  useEffect(() => {
    if (currencyData !== null) {
      setReserves({
        reservesData: poolData,
        baseCurrencyData: currencyData,
      });
    }
  }, [poolData, currencyData]);

  return {
    loading,
    error,
    data: { reserves, userReserves },
    refresh: () => {
      return Promise.all([fetchUserReserves(), fetchReserves()]);
    },
  };
}
