import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { useLockData } from '../../../../hooks';
import { MathUtils } from '../../../../utils/math.utils';
import { ReactComponent as CoinsImage } from '../../../../images/coins-image.svg';
import MetLockAndEarn from '../MetLockAndEarn';
import { useRewardsApr } from '../../../../store';
import PreloaderOver from '../../../../components/basic/PreloaderOver';

export function MarketsAprs() {
  const { lockApr, lockMultipliers } = useLockData();
  const aprByTokens = useRewardsApr();

  const maxLockApr = lockApr.multipliedBy(lockMultipliers[lockMultipliers.length - 1]);

  const maxLockLoaded = !maxLockApr.isNaN() && maxLockApr.isPositive();

  return (
    <BasicBox className="markets-top markets-basic-box">
      <div className="markets-top-info">
        <div className="markets-top-info__image">
          <CoinsImage />
        </div>
        <p className="markets-top-info__text ff-lg">
          Lock wLP and earn {MathUtils.formatNumber(maxLockApr, 2)}% APR paid in
        </p>
      </div>

      <MetLockAndEarn {...{ aprByTokens }} />

      <PreloaderOver isShown={!aprByTokens.isLoaded || !maxLockLoaded} />
    </BasicBox>
  );
}
