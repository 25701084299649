import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .eligibility-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-width: 330px;

    @include respond-to(sm) {
      display: block;
      margin-top: 24px;
      min-width: auto;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @include respond-to(sm) {
        flex-direction: row;
        justify-content: space-around;
        gap: 20px;
        margin-top: -10px;
      }
    }
  }

  .eligibility {
    &__footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 8px;
      color: #000;
    }

    &__footer-label {
      color: rgba(0, 0, 0, 0.4);
    }

    &__footer-amount {
      margin-left: 8px;
    }
  }

  .eligibility-progress {
    display: flex;
    gap: 10px;

    &__line {
      cursor: default;

      &--small {
        width: 40px;
      }

      &--big {
        flex: 1 1 auto;
      }

      .input-bar__thumb {
        display: none;
      }

      .input-bar__range {
        overflow: hidden;
      }
    }
  }
`;

export default staticStyles;
