import ValuePercent from '../../../../components/basic/ValuePercent';
import Value from '../../../../components/basic/Value';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import HealthFactor from '../../../../components/HealthFactor';
import { valueToBigNumber } from '@aave/math-utils';
import BigNumber from 'bignumber.js';

export function ReserveOverviewStats({
  walletBalance,
  currencySymbol,
  userReserve,
  poolReserve,
}: any) {
  const { user } = useDynamicPoolDataContext();

  const availableBorrowsMarketReferenceCurrency = valueToBigNumber(
    user?.availableBorrowsMarketReferenceCurrency || 0
  );
  const availableBorrows = availableBorrowsMarketReferenceCurrency.gt(0)
    ? BigNumber.min(
        availableBorrowsMarketReferenceCurrency
          .div(poolReserve.priceInMarketReferenceCurrency)
          .multipliedBy(user && user.totalBorrowsMarketReferenceCurrency !== '0' ? '0.99' : '1'),
        poolReserve.availableLiquidity
      ).toNumber()
    : 0;

  return (
    <ul className="ReserveOverview__list">
      <li className="ReserveOverview__list-item">
        <p>Your wallet balance</p>
        <span>
          <Value
            value={walletBalance.toString()}
            symbol={currencySymbol}
            minimumValueDecimals={2}
            maximumValueDecimals={2}
          />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Amount deposited</p>
        <span>
          <Value
            value={userReserve?.underlyingBalance || 0}
            symbol={currencySymbol}
            minimumValueDecimals={2}
            maximumValueDecimals={2}
          />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Amount borrowed</p>
        <span>
          <Value
            value={userReserve?.totalBorrows || 0}
            symbol={currencySymbol}
            minimumValueDecimals={2}
            maximumValueDecimals={2}
          />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Maximum borrow amount</p>
        <span>
          {poolReserve.borrowingEnabled ? (
            <Value
              value={availableBorrows}
              symbol={currencySymbol}
              minimumValueDecimals={2}
              maximumValueDecimals={2}
            />
          ) : (
            '—'
          )}
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Health factor</p>{' '}
        <span className="ReserveOverview__list-value">
          <HealthFactor className="HealthFactor__percent" value={user?.healthFactor || '-1'} />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Loan to value</p>{' '}
        <span>
          <ValuePercent
            value={user?.currentLoanToValue ? Number(user?.currentLoanToValue) * 100 : 0}
            minimumDecimals={2}
            maximumDecimals={2}
          />
        </span>
      </li>
    </ul>
  );
}
