import { MessageDescriptor } from 'react-intl';
import { MarketDataType } from '../../../helpers/config/types';

import messages from './messages';

export interface Navigation {
  title: MessageDescriptor;
  hiddenWithoutWallet?: boolean;
  link?: string;
  dropdown?: { link: string; title: MessageDescriptor }[];
  absolute?: boolean;
  onClick?: () => void;
  isVisible?: (data: MarketDataType) => boolean | undefined;
}

export const navigation: Navigation[] = [
  {
    link: '/markets',
    title: messages.markets,
  },
  {
    link: '/dashboard',
    title: messages.dashboard,
  },
  {
    link: '/manage',
    title: messages.stake,
  },
  {
    title: messages.tools,
    dropdown: [
      { link: '/leverage', title: messages.leverage },
      { link: '/deleverage', title: messages.deleverage },
    ],
  },
];

export const mobileNavigation: Navigation[] = [...navigation];
