import staticStyles from './style';
import { useBorrowRates } from './useBorrowRates';
import { ComputedReserveData } from '../../../../libs/pool-data-provider';
import { useEffect, useRef } from 'react';
declare global {
  interface Window {
    ApexCharts: any;
  }
}
export const BorrowRateGraph = ({ poolReserve }: { poolReserve: ComputedReserveData }) => {
  const borrowRates = useBorrowRates(poolReserve);
  const chartRef = useRef(null);

  const formatValue = (value: string) => {
    return `${value}%`;
  };

  useEffect(() => {
    if (window.ApexCharts && chartRef.current) {
      const graphicData = {
        series: [
          {
            name: 'Borrow Interest',
            data: borrowRates,
          },
        ],
        chart: {
          width: '100%',
          height: '100%',
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          redrawOnWindowResize: true,
        },
        stroke: {
          curve: 'smooth',
          width: 1,
        },
        xaxis: {
          categories: borrowRates.map((rate, index) => 10 * index),
          title: {
            text: 'Utilization rate',
            offsetY: -5,
          },
          labels: {
            formatter: formatValue,
          },
        },
        yaxis: {
          title: {
            text: 'Borrow Interest',
            offsetX: 5,
          },
          labels: {
            formatter: formatValue,
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              xaxis: { labels: { style: { fontSize: '10' } } },
              yaxis: {
                labels: {
                  style: { fontSize: '10' },

                  formatter: formatValue,
                },
                title: {
                  text: 'Borrow Interest',
                  offsetX: 5,
                },
              },
            },
          },
        ],
      };

      const chart = new window.ApexCharts(chartRef.current, graphicData);

      chart.render();

      return () => {
        chart.destroy();
      };
    } else {
      console.error('ApexCharts is not loaded');
    }
  }, [borrowRates]);

  return (
    <div className="reserve-graphic">
      {borrowRates.length > 0 ? <div id="chart" ref={chartRef}></div> : null}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
};
