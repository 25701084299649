import { BigNumber as EtherBigNumber } from 'ethers';
import { StaticApi } from '../static-api';
import BigNumber from 'bignumber.js';
import { Web3Utils } from '../../utils';
import { valueToBigNumber } from '@aave/protocol-js';
import { valueToWei } from '@aave/contract-helpers/dist/esm/commons/utils';
import { Address } from 'viem';
import { ERC20Service } from '@aave/contract-helpers/dist/cjs/erc20-contract';
import { Provider } from '@ethersproject/providers';
export interface FactoryEstimateDepositModel {
  amountA: BigNumber;
  amountB: BigNumber;
  lpAmount: BigNumber;
}

export class FactoryApi extends StaticApi {
  public static async getEstimatedAmounts({
    tokenA,
    tokenB,
    amountADesired,
    amountBDesired,
  }: {
    tokenA: Address;
    tokenB: Address;
    amountADesired: string;
    amountBDesired: string;
  }): Promise<FactoryEstimateDepositModel> {
    const contract = FactoryApi.getContract();
    const provider = FactoryApi.getProvider();
    const { decimalsOf } = new ERC20Service(provider as Provider);

    const [tokanADecimals, tokenBDecimals] = await Promise.all([
      decimalsOf(tokenA),
      decimalsOf(tokenB),
    ]);

    try {
      const [amountA, amountB, lpAmount]: EtherBigNumber[] = await contract.estimateDepositAmounts(
        tokenA,
        tokenB,
        valueToWei(amountADesired, tokanADecimals),
        valueToWei(amountBDesired, tokenBDecimals)
      );

      return {
        amountA: valueToBigNumber(Web3Utils.formatValue(amountA)),
        amountB: valueToBigNumber(Web3Utils.formatValue(amountB)),
        lpAmount: valueToBigNumber(Web3Utils.formatValue(lpAmount)),
      };
    } catch (error) {
      console.log(error);
      throw Error(`estimate deposit failed: ${error}`);
    }
  }
}
