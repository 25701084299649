import { ReactNode, useCallback } from 'react';

import staticStyles from './style';
import DefaultButton from '../DefaultButton';
import classNames from 'classnames';

interface ButtonTabsProps {
  setSelectedTab: (tab: any, index: number) => void;
  tabs: string[];
  selectedTab: string | number;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}

export default function ButtonTabs({
  tabs,
  selectedTab,
  setSelectedTab,
  disabled,
  className,
  children,
}: ButtonTabsProps) {
  const handleChange = useCallback(
    (value: string, index: number) => () => {
      setSelectedTab(value, index);
    },
    [setSelectedTab]
  );

  return (
    <>
      <div className={classNames('ButtonTabs', className)}>
        {children
          ? children
          : tabs.map((tab: string, index: number) => (
              <DefaultButton
                onClick={handleChange(tab, index)}
                disabled={disabled}
                color={selectedTab === tab ? 'neutral' : 'tab'}
                key={`button-tab-${index}`}
                title={tab}
                size="small"
              />
            ))}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
