import classNames from 'classnames';
import { useThemeContext } from '../../../libs/aave-ui-kit';

import LiquidityMiningAPYLine from '../LiquidityMiningAPYLine';

import staticStyles from './style';
import { MathUtils } from '../../../utils/math.utils';

interface LiquidityMiningCardProps {
  symbol?: string;
  type?: string;
  value?: string | number;
  thirtyDaysValue?: string | number;
  liquidityMiningValue?: string | number;
  className?: string;
  noBorder?: boolean;
  showTokenIcon?: boolean;
}

export default function LiquidityMiningCard({
  symbol,
  type,
  value = '-1',
  thirtyDaysValue,
  liquidityMiningValue,
  className,
  noBorder,
  showTokenIcon = false,
}: LiquidityMiningCardProps) {
  const { sm } = useThemeContext();

  const helpLiquidityAPYTooltipId =
    symbol && type ? `help-liquidity-apy-${type}-${symbol}` : undefined;
  const thirtyDaysAverageTooltipId =
    symbol && type ? `show-30days-average-${type}-${symbol}` : undefined;

  return (
    <div className={classNames('LiquidityMiningCard', className)}>
      <div
        className={
          !sm && !!symbol && thirtyDaysValue && +thirtyDaysValue > 0
            ? 'LiquidityMiningCard__valueWithTooltip'
            : ''
        }
        data-tip={!!symbol}
        data-for={thirtyDaysAverageTooltipId}
      >
        {value.toString() !== '-1' ? <>{MathUtils.formatNumber(value, 2)}%</> : null}
      </div>

      {((liquidityMiningValue && liquidityMiningValue !== '0') ||
        (type !== 'borrow-stable' && type !== 'deposit')) && (
        <LiquidityMiningAPYLine
          symbol={symbol}
          value={liquidityMiningValue || 0}
          tooltipId={helpLiquidityAPYTooltipId}
          noBorder={noBorder}
          showTokenIcon={showTokenIcon}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .LiquidityMiningAPYLine__withTooltip {
          .ValuePercent {
            p {
              font-size: 14px !important;
            }
          }
        }
      `}</style>
    </div>
  );
}
