import React from 'react';
import classNames from 'classnames';
import { useThemeContext } from '../../../libs/aave-ui-kit';

import staticStyles from './style';

interface PreloaderProps {
  withText?: boolean;
  caption?: string;
  subCaption?: string;
  subDescription?: string;
  smallSize?: boolean;
  forwardRef?: React.Ref<any>;
  withBackground?: boolean;
  className?: string;
}

export default function Preloader({ forwardRef, className }: PreloaderProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div className={classNames('Preloader', className)} ref={forwardRef}>
      <div className="Preloader__dots">
        <div className="Preloader__dot" />
        <div className="Preloader__dot" />
        <div className="Preloader__dot" />
        <div className="Preloader__dot" />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .Preloader {
          &__dot {
            background: rgba(136, 136, 136, 0.3);

            &:nth-child(1) {
              animation: animation 3s linear infinite 0s;
            }
            &:nth-child(2) {
              animation: animation 3s linear infinite 0.15s;
            }
            &:nth-child(3) {
              animation: animation 3s linear infinite 0.3s;
            }
            &:nth-child(4) {
              animation: animation 3s linear infinite 0.45s;
            }
          }
        }

        @keyframes animation {
          0% {
            opacity: 0;
            transform: scale(0.5);
            background: rgba(136, 136, 136, 0.3);
          }
          25% {
            opacity: 1;
            transform: scale(1.25);
            background: rgba(136, 136, 136, 0.5);
          }
          50% {
            opacity: 0;
            transform: scale(0.5);
            background: rgba(136, 136, 136, 0.3);
          }
          75% {
            opacity: 1;
            transform: scale(1.25);
            background: rgba(136, 136, 136, 0.5);
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
}
