import React, { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { SelectDropdown, SelectHeader, SelectOption } from './components';
import { staticStyles } from './styles';
import classNames from 'classnames';

export interface SelectProps {
  onOptionChange: (selected: number) => void;
  children: [ReactElement, ReactElement];
  selectedId: number;
  disabled?: boolean;
  className?: string;
}

export function Select({ onOptionChange, children, selectedId, disabled, className }: SelectProps) {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const selectWrapper = useRef<HTMLDivElement | null>(null);

  const handleChangeOption = (selectedId: number) => {
    onOptionChange(selectedId);
    setIsOpened(false);
  };

  const handleHeaderClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (!disabled) {
      event.stopPropagation();
      setIsOpened(!isOpened);
    }
  };

  useEffect(() => {
    const selectOpenListener = (event: Event) => {
      if (selectWrapper.current !== null && !selectWrapper.current.contains(event.target as Node)) {
        setIsOpened(false);
      }
    };
    document.addEventListener('mousedown', selectOpenListener);

    return () => document.body.removeEventListener('mousedown', selectOpenListener);
  }, []);

  return (
    <>
      <div
        className={classNames(
          'basic-select',
          className,
          disabled ? 'disabled' : 'enabled',
          isOpened && 'is-opened'
        )}
        ref={selectWrapper}
      >
        {React.cloneElement(children[0], {
          onHeaderClick: handleHeaderClick,
          disabled: disabled,
        })}

        {React.cloneElement(children[1], {
          onOptionClick: handleChangeOption,
          selectedId: selectedId,
          isOpened: isOpened,
        })}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

Select.Item = SelectOption;
Select.Header = SelectHeader;
Select.Dropdown = SelectDropdown;
