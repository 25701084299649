import { useIntl } from 'react-intl';
import classNames from 'classnames';
import NoDataPanel from '../../../../components/NoDataPanel';
import messages from './messages';
import staticStyles from './style';

import BasicTable from '../../../../components/BasicTable';
import DepositItem from '../DashboardTable/DepositItem';
import BorrowItem from '../DashboardTable/BorrowItem';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { DepositTableItem } from '../../../../shared';

interface MainDashboardTableProps {
  depositedPositions: DepositTableItem[];
  borrowedPositions: any[];
  isBorrow: boolean;
}

const depositTableHeaders = ['Assets', 'Deposits', 'APY', 'Collateral'];
const borrowTableHeaders = ['Assets', 'Borrows', 'APY'];

export default function MainDashboardTable({
  depositedPositions,
  borrowedPositions,
  isBorrow,
}: MainDashboardTableProps) {
  const intl = useIntl();

  return (
    <BasicBox
      className={classNames('MainDashboardTable', {
        MainDashboardTable__onlyOne: isBorrow,
        MainDashboardTable__noBorrows: !borrowedPositions.length,
      })}
    >
      <div className="MainDashboardTable__inner">
        {depositedPositions.length > 0 && (
          <>
            <div className="MainDashboardTable__title ff-lg">Your Deposit</div>
            <BasicTable className="dashboard-table">
              <BasicTable.Header className="dashboard-table__header">
                {depositTableHeaders?.map((item, key) => (
                  <BasicTable.Item key={key}>{item}</BasicTable.Item>
                ))}
              </BasicTable.Header>

              {depositedPositions.map((item: DepositTableItem, index) => (
                <DepositItem {...item} key={index} />
              ))}
            </BasicTable>
          </>
        )}
      </div>
      {borrowedPositions.length > 0 ? (
        <div className="MainDashboardTable__inner">
          <div className="MainDashboardTable__title ff-lg">Your Borrow</div>

          <>
            <BasicTable className="dashboard-table">
              <BasicTable.Header className="dashboard-table__header ">
                {borrowTableHeaders?.map((item, key) => (
                  <BasicTable.Item key={key}>{item}</BasicTable.Item>
                ))}
                <BasicTable.Item>{''}</BasicTable.Item>
              </BasicTable.Header>

              {borrowedPositions.map((item: any, index) => (
                <BorrowItem {...item} key={index} />
              ))}
            </BasicTable>
          </>
        </div>
      ) : (
        <div className="MainDashboardTable__inner--no-data">
          <NoDataPanel
            title={intl.formatMessage(messages.nothingBorrowed)}
            description={intl.formatMessage(messages.nothingBorrowedDescription)}
          />
        </div>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicBox>
  );
}
