import { API_ETH_MOCK_ADDRESS } from '@aave/protocol-js';
import { BaseNetworkConfig } from '../helpers/config/types';
import { ChainId } from '@aave/contract-helpers';
import { metis, polygon, bsc } from 'wagmi/dist/chains';
import { iota, kava } from '../shared';

//, 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58', 'https://rpc.ankr.com/eth'

// https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58
// 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58'

export const networkConfigs: Record<string, BaseNetworkConfig> = {
  [ChainId.mainnet]: {
    name: 'Ethereum mainnet',
    publicJsonRPCUrl: [
      'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58',
      'https://eth-mainnet.g.alchemy.com/v2/dLAKFWe8EgTeNB8T6kBGs5JD7AncorRQ',
      'https://rpc.ankr.com/eth',
    ], // PRIVATE from POKT 'https://polygon-rpc.com'
    // publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
    addresses: {
      walletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
      uiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://etherscan.io',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [metis.id]: {
    name: 'Andromeda',
    publicJsonRPCUrl: [
      'https://andromeda.metis.io/?owner=1088',
      'https://metis-mainnet.public.blastapi.io',
      // 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58',
      // 'https://eth-mainnet.g.alchemy.com/v2/dLAKFWe8EgTeNB8T6kBGs5JD7AncorRQ',
    ], // PRIVATE from POKT 'https://polygon-rpc.com'
    // publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
    addresses: {
      walletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
      uiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'METIS',
    baseAssetWrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [polygon.id]: {
    name: 'Matic',
    publicJsonRPCUrl: [
      'https://metis-mainnet.public.blastapi.io',
      'https://metis-pokt.nodies.app',
      // 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58',
      // 'https://eth-mainnet.g.alchemy.com/v2/dLAKFWe8EgTeNB8T6kBGs5JD7AncorRQ',
      'https://andromeda.metis.io/?owner=1088',
    ], // PRIVATE from POKT 'https://polygon-rpc.com'
    // publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
    addresses: {
      walletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
      uiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'MATIC',
    baseAssetWrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [ChainId.arbitrum_one]: {
    name: 'Arbitrum One',
    publicJsonRPCUrl: [
      'https://metis-mainnet.public.blastapi.io',
      'https://metis-pokt.nodies.app',
      // 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58',
      // 'https://eth-mainnet.g.alchemy.com/v2/dLAKFWe8EgTeNB8T6kBGs5JD7AncorRQ',
      'https://andromeda.metis.io/?owner=1088',
    ], // PRIVATE from POKT 'https://polygon-rpc.com'
    // publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
    addresses: {
      walletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
      uiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'ARB',
    baseAssetWrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [ChainId.xdai]: {
    name: 'Dai',
    publicJsonRPCUrl: [
      'https://metis-mainnet.public.blastapi.io',
      'https://metis-pokt.nodies.app',
      // 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58',
      // 'https://eth-mainnet.g.alchemy.com/v2/dLAKFWe8EgTeNB8T6kBGs5JD7AncorRQ',
      'https://andromeda.metis.io/?owner=1088',
    ], // PRIVATE from POKT 'https://polygon-rpc.com'
    // publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
    addresses: {
      walletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
      uiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'DAI',
    baseAssetWrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [bsc.id]: {
    name: 'Binance',
    publicJsonRPCUrl: [
      'https://metis-mainnet.public.blastapi.io',
      'https://metis-pokt.nodies.app',
      // 'https://mainnet.infura.io/v3/132a2b613dcb4e15bc90cc9cb04ecd58',
      // 'https://eth-mainnet.g.alchemy.com/v2/dLAKFWe8EgTeNB8T6kBGs5JD7AncorRQ',
      'https://andromeda.metis.io/?owner=1088',
    ], // PRIVATE from POKT 'https://polygon-rpc.com'
    // publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
    addresses: {
      walletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
      uiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'BSC',
    baseAssetWrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [iota.id]: {
    name: 'iota',
    publicJsonRPCUrl: [
      'https://json-rpc.evm.iotaledger.net',
      'https://iota-mainnet-evm.public.blastapi.io',
    ],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'IOTA',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.evm.iota.org/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  [kava.id]: {
    name: 'Kava',
    publicJsonRPCUrl: [
      'https://rpc.ankr.com/kava_evm',
      'https://kava-pokt.nodies.app',
      'https://kava-evm-rpc.publicnode.com',
    ],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
      // uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
    },
    // cachingServerUrl: 'http://localhost:3001/graphql',
    // cachingWSServerUrl: 'ws://localhost:3001/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseAsset: 'KAVA',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.kava.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  3: {
    name: 'Ropsten Testnet',
    publicJsonRPCUrl: ['https://ropsten.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://ropsten.etherscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  4: {
    name: 'Rinkeby Testnet',
    publicJsonRPCUrl: ['https://rinkeby.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://rinkeby.etherscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  5: {
    name: 'Goerli Testnet',
    publicJsonRPCUrl: ['https://goerli.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://goerli.etherscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  10: {
    name: 'Optimism',
    publicJsonRPCUrl: [
      'https://mainnet.optimism.io',
      'https://optimism.infura.io/v3/YOUR_INFURA_PROJECT_ID',
    ],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x4200000000000000000000000000000000000006',
    rewardTokenSymbol: 'OP',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://optimistic.etherscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  42: {
    name: 'Kovan Testnet',
    publicJsonRPCUrl: ['https://kovan.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://kovan.etherscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  97: {
    name: 'Binance Smart Chain Testnet',
    publicJsonRPCUrl: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'BNB',
    baseAssetWrappedAddress: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    rewardTokenSymbol: 'WBNB',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://testnet.bscscan.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  250: {
    name: 'Fantom Opera',
    publicJsonRPCUrl: ['https://rpc.ftm.tools/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'FTM',
    baseAssetWrappedAddress: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    rewardTokenSymbol: 'WFTM',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://ftmscan.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  288: {
    name: 'Boba Network Mainnet',
    publicJsonRPCUrl: ['https://mainnet.boba.network/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://blockexplorer.boba.network/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  420: {
    name: 'Optimism Goerli Testnet',
    publicJsonRPCUrl: ['https://goerli.optimism.io/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://blockscout.com/optimism/goerli/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  43114: {
    name: 'Avalanche Mainnet',
    publicJsonRPCUrl: [
      'https://api.avax.network/ext/bc/C/rpc',
      'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
    ],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'AVAX',
    baseAssetWrappedAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    rewardTokenSymbol: 'WAVAX',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://snowtrace.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  80001: {
    name: 'Mumbai Testnet',
    publicJsonRPCUrl: [
      'https://rpc-mumbai.maticvigil.com/',
      'https://matic-mumbai.chainstacklabs.com',
    ],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'MATIC',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000001010',
    rewardTokenSymbol: 'WMATIC',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://mumbai.polygonscan.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  11155111: {
    name: 'Sepolia Testnet',
    publicJsonRPCUrl: ['https://rpc.sepolia.org/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://sepolia.etherscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  25: {
    name: 'Cronos Mainnet',
    publicJsonRPCUrl: ['https://evm-cronos.crypto.org', 'https://cronos-rpc.heavenswail.one'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'CRO',
    baseAssetWrappedAddress: '0xA0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    rewardTokenSymbol: 'WCRO',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://cronoscan.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  8217: {
    name: 'Klaytn Mainnet Cypress',
    publicJsonRPCUrl: ['https://public-node-api.klaytnapi.com/v1/cypress'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'KLAY',
    baseAssetWrappedAddress: '0x798ad232c5bf0e6bb80df2b739f3887af5d96f72',
    rewardTokenSymbol: 'WKLAY',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://scope.klaytn.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  66: {
    name: 'OKExChain Mainnet',
    publicJsonRPCUrl: [
      'https://exchainrpc.okex.org',
      'https://okc-mainnet.gateway.pokt.network/v1/lb/6136201a7bad1500343e248d',
    ],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'OKT',
    baseAssetWrappedAddress: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    rewardTokenSymbol: 'WOKT',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://www.oklink.com/okexchain',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  122: {
    name: 'Fuse Mainnet',
    publicJsonRPCUrl: ['https://rpc.fuse.io/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'FUSE',
    baseAssetWrappedAddress: '0x0be9e53fd7edac9f859882afdda116645287c629',
    rewardTokenSymbol: 'WFUSE',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.fuse.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  128: {
    name: 'Heco Mainnet',
    publicJsonRPCUrl: ['https://http-mainnet.hecochain.com'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'HT',
    baseAssetWrappedAddress: '0x64ff637fb478863b7468bc97d30a5bf3a428a1fd',
    rewardTokenSymbol: 'WHT',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://hecoinfo.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  321: {
    name: 'KCC Mainnet',
    publicJsonRPCUrl: ['https://rpc-mainnet.kcc.network', 'https://kcc-rpc.com'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'KCS',
    baseAssetWrappedAddress: '0x4f28cfa1b212d7b8a2d144e636c00e6d91b6ce1a',
    rewardTokenSymbol: 'WKCS',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.kcc.io/en',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  2500: {
    name: 'Redlight Chain Mainnet',
    publicJsonRPCUrl: ['https://dataseed2.redlightscan.finance/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'REDLC',
    baseAssetWrappedAddress: '0xC86401865c5e68Bbbf33aaDd21208a3E3d9FdB6A',
    rewardTokenSymbol: 'WREDLC',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://redlightscan.finance/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  3331: {
    name: 'ZCore Mainnet',
    publicJsonRPCUrl: ['https://rpc.zcore.cash/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ZCR',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WZCR',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.zcore.cash/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  4689: {
    name: 'IoTeX Mainnet',
    publicJsonRPCUrl: ['https://babel-api.mainnet.iotex.io'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'IOTX',
    baseAssetWrappedAddress: '0xa00744882684c3e4747faefd68d283ea44099d03',
    rewardTokenSymbol: 'WIOTX',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://iotexscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  7777: {
    name: 'ZetaChain Mainnet',
    publicJsonRPCUrl: ['https://rpc.mainnet.zetachain.com/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ZETA',
    baseAssetWrappedAddress: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    rewardTokenSymbol: 'WZETA',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://zetachain.zetaexplorer.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  1285: {
    name: 'Moonriver',
    publicJsonRPCUrl: ['https://rpc.moonriver.moonbeam.network'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'MOVR',
    baseAssetWrappedAddress: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
    rewardTokenSymbol: 'WMOVR',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://moonriver.moonscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  42170: {
    name: 'Arbitrum Nova',
    publicJsonRPCUrl: ['https://nova.arbitrum.io/rpc'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x722e8bdd2ce80a4422e880164f2079488e115365',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://nova-explorer.arbitrum.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  42220: {
    name: 'Celo Mainnet',
    publicJsonRPCUrl: ['https://forno.celo.org'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'CELO',
    baseAssetWrappedAddress: '0x471ece3750da237f93b8e339c536989b8978a438',
    rewardTokenSymbol: 'WCELO',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.celo.org/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  1313161554: {
    name: 'Aurora Mainnet',
    publicJsonRPCUrl: ['https://mainnet.aurora.dev'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.mainnet.aurora.dev',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  1313161555: {
    name: 'Aurora Testnet',
    publicJsonRPCUrl: ['https://testnet.aurora.dev'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.testnet.aurora.dev',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  8453: {
    name: 'Base Mainnet',
    publicJsonRPCUrl: ['https://mainnet.base.org'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x4200000000000000000000000000000000000006',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://basescan.org/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  81457: {
    name: 'ZKSync Era Mainnet',
    publicJsonRPCUrl: ['https://zksync2-mainnet.zksync.io/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x4200000000000000000000000000000000000006',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://zksync.io/explorer',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  59144: {
    name: 'Scroll Mainnet',
    publicJsonRPCUrl: ['https://rpc.scroll.io/mainnet'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x4200000000000000000000000000000000000006',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.scroll.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  5000: {
    name: 'Gnosis Chain',
    publicJsonRPCUrl: ['https://rpc.gnosischain.com/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'xDai',
    baseAssetWrappedAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    rewardTokenSymbol: 'WXDAI',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://gnosisscan.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  7700: {
    name: 'Heco Testnet',
    publicJsonRPCUrl: ['https://http-testnet.hecochain.com'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'HT',
    baseAssetWrappedAddress: '0x64ff637fb478863b7468bc97d30a5bf3a428a1fd',
    rewardTokenSymbol: 'WHT',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://hecotestnetscan.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  369: {
    name: 'Zilliqa',
    publicJsonRPCUrl: ['https://api.zilliqa.com'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ZIL',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WZIL',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 12,
    explorerLink: 'https://viewblock.io/zilliqa',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  32659: {
    name: 'Evmos',
    publicJsonRPCUrl: ['https://evmos-mainnet.public.evm.rivet.cloud/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'EVMOS',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WEVMOS',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://evmos.org/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  324: {
    name: 'Velas',
    publicJsonRPCUrl: ['https://evm.velas.com/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'VLX',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WVLX',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.velas.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  1284: {
    name: 'IoTeX Testnet',
    publicJsonRPCUrl: ['https://babel-api.testnet.iotex.io'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'IOTX',
    baseAssetWrappedAddress: '0xa00744882684c3e4747faefd68d283ea44099d03',
    rewardTokenSymbol: 'WIOTX',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://iotexscan.io/testnet',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  13371: {
    name: 'Metis Andromeda',
    publicJsonRPCUrl: ['https://andromeda.metis.io/?owner=13371'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'METIS',
    baseAssetWrappedAddress: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    rewardTokenSymbol: 'WMETIS',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://andromeda-explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  4337: {
    name: 'Linea',
    publicJsonRPCUrl: ['https://rpc.linea.build'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x4200000000000000000000000000000000000006',
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.linea.build/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  2000: {
    name: 'Forta',
    publicJsonRPCUrl: ['https://rpc.forta.network/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'FORTA',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WFORTA',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.forta.network/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  361: {
    name: 'Fuse',
    publicJsonRPCUrl: ['https://rpc.fuse.io/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'FUSE',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WFUSE',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.fuse.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  17777: {
    name: 'Velas',
    publicJsonRPCUrl: ['https://evm.velas.com/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'VLX',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WVLX',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.velas.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  248: {
    name: 'Harmony Mainnet',
    publicJsonRPCUrl: ['https://api.harmony.one'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ONE',
    baseAssetWrappedAddress: '0x0d2761D0B93071E828D39A1CAf27e2274e9c7374',
    rewardTokenSymbol: 'WONE',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.harmony.one/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  57: {
    name: 'Metis Andromeda',
    publicJsonRPCUrl: ['https://andromeda.metis.io/?owner=57'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'METIS',
    baseAssetWrappedAddress: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    rewardTokenSymbol: 'WMETIS',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://andromeda-explorer.metis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  7000: {
    name: 'Oasis Network',
    publicJsonRPCUrl: ['https://rpc.oasis.io/'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'ROSE',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WROSE',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.oasis.io/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
  5611: {
    name: 'opBNB',
    publicJsonRPCUrl: ['https://rpc.opbnb.org'],
    addresses: {
      walletBalanceProvider: '0xB1592eD475435E99109Adeacd2960d036DA6C135',
      uiPoolDataProvider: '0xA3cC942B7Ef21B44A975Caddd3c48BE52Ee357b5',
    },
    baseAsset: 'BNB',
    baseAssetWrappedAddress: '0x0000000000000000000000000000000000000000',
    rewardTokenSymbol: 'WBNB',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    explorerLink: 'https://opbnbscan.com/',
    rpcOnly: false,
    nftApiUrl: 'https://api-nft.ceant.net/',
  },
};

export const networkIds = Object.keys(networkConfigs).map((id) => Number(id)) as ChainId[];
