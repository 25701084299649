import { BigNumber } from '@aave/protocol-js';

import staticStyles from './style';
import InputBar from '../InputBar';
import classNames from 'classnames';
import HealthFactor from '../../HealthFactor';

interface RiskBarProps {
  onChange: (amount: string) => void;
  maxAmount: string;
  newHealthFactor: BigNumber;
  amountToBorrowInUsd: BigNumber;
  className?: string;
  disabled?: boolean;
}

export default function RiskBar({
  onChange,
  maxAmount,
  className,
  newHealthFactor,
  disabled,
}: RiskBarProps) {
  if (Number(maxAmount) / 100 <= 0) {
    return null;
  }

  const handleChange = (value: number) => {
    onChange(value.toString());
  };

  return (
    <>
      <InputBar
        type="secondary"
        minAmount={1}
        maxAmount={Number(maxAmount)}
        value={newHealthFactor.toNumber()}
        className={classNames('risk-range', className)}
      >
        {({ rate, min, max }) => (
          <>
            <InputBar.Header>
              <span>Risker</span>
              <span>
                New Health Factor:{' '}
                <span className="risk-range__value">
                  {<HealthFactor value={newHealthFactor.toString()} />}
                </span>
              </span>

              <span>Safer</span>
            </InputBar.Header>
            <InputBar.Range
              disabled={Boolean(disabled)}
              onChange={handleChange}
              minAmount={min}
              maxAmount={max}
              value={newHealthFactor.toNumber() > max ? max : newHealthFactor.toNumber()}
            >
              <InputBar.Progress rate={rate > 1 ? 1 : rate} />
            </InputBar.Range>
          </>
        )}
      </InputBar>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
