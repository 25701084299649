import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .PageHeader {
    font-weight: 600;
    font-size: 38px;
    color: #000;
    text-transform: uppercase;

    @include respond-to(sm) {
      font-size: 28px;
    }
  }
`;

export default staticStyles;
