import { createSelectors } from '../createSelectors';
import { create } from '../../libs/zustand';
import { StoreToolsModel } from './tools.model';
import { Address } from 'viem';

export const useToolsStore = createSelectors(
  create<StoreToolsModel>((set, get) => ({
    selectedA: null,
    selectedB: null,
    amountA: '',
    amountB: '',
    borrowReceiver: '' as Address,
    repayReceiver: '' as Address,
    leverage: 1.1,
    setSelectedA: (selectedA) => {
      set((state) => ({
        ...state,
        selectedA,
      }));
    },
    setSelectedB: (selectedB) => {
      set((state) => ({
        ...state,
        selectedB,
      }));
    },
    setLeverage: (leverage) => {
      set((state) => ({
        ...state,
        leverage,
      }));
    },
    setAmountA: (amount) => {
      set((state) => ({
        ...state,
        amountA: amount,
      }));
    },
    setAmountB: (amount) => {
      set((state) => ({
        ...state,
        amountB: amount,
      }));
    },
    setBorrowReceiver: (address: Address) => {
      set((state) => ({
        ...state,
        borrowReceiver: address,
      }));
    },
    setRepayReceiver: (address: Address) => {
      set((state) => ({
        ...state,
        repayReceiver: address,
      }));
    },
  }))
);
