import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { BasicModal, getAssetInfo } from '../../libs/aave-ui-kit';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { UserSummary, ComputedReserveData } from '../../libs/pool-data-provider';
import { ComputedUserReserve } from '@aave/math-utils';
import { useTxBuilderContext } from '../../libs/tx-provider';
import messages from './messages';
import PoolTxConfirmationView from '../PoolTxConfirmationView';
import Row from '../basic/Row';
import Value from '../basic/Value';
import HealthFactor from '../HealthFactor';
import { getReferralCode } from '../../libs/referral-handler';
import { getAtokenInfo } from '../../helpers/get-atoken-info';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import { useDepositData } from './useDepositData';
import DefaultButton from '../basic/DefaultButton';
import { MathUtils } from '../../utils/math.utils';
import RiskBar from '../basic/RiskBar';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

interface BorrowAmountProps {
  poolReserve: ComputedReserveData;
  currencySymbol: string;
  user: UserSummary;
  userReserve: ComputedUserReserve;
}

export function DepositWidget({
  userReserve,
  poolReserve,
  user,
  currencySymbol,
}: BorrowAmountProps) {
  let blockingError = '';
  const intl = useIntl();
  const [amountToDeposit, setAmountToDeposit] = useState<BigNumber>(BigNumber(0));
  const [step, setStep] = useState<string>(Steps.amount);
  const [fieldValue, setFieldValue] = useState<string>('');

  const { lendingPool } = useTxBuilderContext();
  const {
    notShowHealthFactor,
    walletBalance,
    maxAmountToDeposit,
    usageAsCollateralEnabledOnDeposit,
    amountInUsd,
    healthFactorAfterDeposit,
  } = useDepositData({ user, poolReserve, amountToDeposit, userReserve });

  const aTokenData = getAtokenInfo({
    address: poolReserve.aTokenAddress,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
  });
  const assetDetails = getAssetInfo(currencySymbol);

  const handleGetTransactions = useCallback(async () => {
    return lendingPool.deposit({
      user: user.id,
      reserve: poolReserve.underlyingAsset,
      amount: amountToDeposit.toString(),
      referralCode: getReferralCode(),
    });
  }, [amountToDeposit, user, poolReserve]);

  const handleAmountChange = (value: string) => {
    setFieldValue(value);
    setAmountToDeposit(BigNumber(value));
  };

  const handleSetAmountSubmit = (amount: string) => {
    setStep(Steps.confirmation);
  };

  if (BigNumber(walletBalance).lt(amountToDeposit)) {
    blockingError = intl.formatMessage(messages.errorWalletBalanceNotEnough, {
      poolReserveSymbol: assetDetails.formattedSymbol || assetDetails.symbol,
    });
  }

  return (
    <>
      <BasicAmountField
        className="reserve-widget__field vest-widget__field"
        maxAmount={maxAmountToDeposit}
      >
        {(maxAmount) => (
          <>
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                handleChange={handleAmountChange}
                value={fieldValue}
                max={maxAmount}
                type="number"
              />
              <BasicAmountField.USDValue>
                {MathUtils.truncateNumber(
                  valueToBigNumber(fieldValue).times(poolReserve.priceInMarketReferenceCurrency),
                  10
                )}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              <BasicAmountField.Asset symbol={currencySymbol} />
              <BasicAmountField.Balance onClick={() => handleAmountChange(maxAmount.toString())}>
                {maxAmount.toString()}
              </BasicAmountField.Balance>
            </BasicAmountField.TokenBox>
          </>
        )}
      </BasicAmountField>

      <RiskBar
        onChange={() => {}}
        disabled={true}
        maxAmount={'10'}
        amountToBorrowInUsd={amountInUsd}
        newHealthFactor={
          healthFactorAfterDeposit.lt(1) ? valueToBigNumber(1) : healthFactorAfterDeposit
        }
        className="reserve-widget__range"
      />

      <DefaultButton
        fill
        className="reserve-widget__button"
        onClick={handleSetAmountSubmit}
        disabled={amountToDeposit.toNumber() <= 0}
      >
        Continue
      </DefaultButton>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        <PoolTxConfirmationView
          mainTxName={'Deposit'}
          caption={'Deposit overview'}
          boxTitle={'Deposit'}
          boxDescription={'Please submit to deposit'}
          approveDescription={'Please approve before depositing'}
          getTransactionsData={handleGetTransactions}
          blockingError={blockingError}
          aTokenData={aTokenData}
          goToAfterSuccess="/dashboard"
        >
          <Row title={'Amount'} withMargin={notShowHealthFactor}>
            <Value
              symbol={currencySymbol}
              value={amountToDeposit.toString()}
              tokenIcon={true}
              subValue={amountInUsd.toString()}
              subSymbol="USD"
              tooltipId={currencySymbol}
            />
          </Row>

          <Row title={'Collateral Usage'} withMargin={notShowHealthFactor}>
            <strong
              style={{
                color: usageAsCollateralEnabledOnDeposit ? '#50f4cd' : '#ff5a47',
              }}
              className="Collateral__text"
            >
              {usageAsCollateralEnabledOnDeposit ? 'Yes' : 'No'}
            </strong>
          </Row>

          {notShowHealthFactor && (
            <Row title={'New health factor'} withMargin={notShowHealthFactor}>
              <HealthFactor withoutModal={true} value={healthFactorAfterDeposit.toString()} />
            </Row>
          )}
        </PoolTxConfirmationView>
      </BasicModal>
    </>
  );
}
