import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .tools {
    color: #000;
    &__subtitle {
      font-size: 18px;
      margin-top: 10px;

      @include respond-to(md) {
        font-size: 16px;
      }
    }

    &__connect {
      box-shadow: none;
      border: none;
      padding: 24px;
      margin: 32px auto 0;
      max-width: 720px;
    }

    &__section {
      display: flex;
      gap: 16px;

      @include respond-to(md) {
        display: block;
      }
    }

    &__section-inner {
      margin-top: 24px;
      position: relative;
    }

    &__section-header {
      @include respond-to(xs) {
        display: block;
      }
    }

    &__table-tabs {
      margin: -5px 0 -6px;

      .DefaultButton {
        background: none;
        cursor: pointer;
      }

      @include respond-to(xs) {
        margin: 24px auto 0;
        max-width: 300px;

        .DefaultButton {
          width: 50%;
        }
      }
    }

    &__table {
      display: none;

      &--active {
        display: block;
      }

      @include respond-to(xs) {
        font-size: 14px;
      }
    }

    &__table-header {
      align-items: flex-end;

      @include respond-to(xs) {
        padding: 8px 8px 8px 20px;
      }
    }

    &__table-row {
      padding: 5px 16px;
      margin: 4px 0;
      position: relative;
      cursor: pointer;
      box-shadow: none;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 8px;
      @include respond-to(md) {
        padding: 10px 16px;
      }

      @include respond-to(xs) {
        padding: 5px 8px 5px 16px;

        .basic-token {
          font-size: 14px;
          gap: 6px;

          &__img {
            width: 24px;
            height: 24px;
          }
        }
      }

      &--active,
      &:hover {
        background: rgba(255, 255, 255, 0.24);
      }

      &--disabled {
        cursor: default;
        opacity: 0.5;
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
        &:active {
          transform: scale(0.995);
        }
      }
    }

    &__table-arrow {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 8px;
      opacity: 0;

      .tools__table-row:hover & {
        opacity: 0.2;
      }

      .tools__table-row.tools__table-row--active & {
        opacity: 1;
      }
    }

    &__table-item {
      &--available {
        flex-basis: 15%;
      }
    }

    &__table-td {
      padding: 0 10px;
      text-align: center;

      .basic-token {
        margin-left: 10px;
      }
    }

    &__table-td-sub {
      font-size: 12px;
      opacity: 0.7;
      display: block;
    }

    &__inner-box {
      padding: 16px;
    }

    &__summery {
      margin: 24px 0;
    }

    &__summery-body {
      display: flex;
      gap: 32px;

      @include respond-to(md) {
        gap: 16px;
      }

      @include respond-to(sm) {
        display: block;
      }
    }
  }
`;

export default staticStyles;
