import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: '{currencySymbol} Reserve overview',

  userCaption: 'Your information',
  noConnectWalletCaption: 'Please connect a wallet',
  noConnectWalletDescription:
    'We couldn’t detect a wallet. Please connect a wallet to view your personal information here.',

  provideLiquidity: 'Provide liquidity {here}',
  here: 'here',
  caption: 'Reserve Status & Configuration',
  totalBorrowed: 'Total Borrowed',
  availableLiquidity: 'Available Liquidity',
  reserveSize: 'Reserve size',
  utilisationRate: 'Utilisation rate',

  depositAPY: 'Deposit APY',
  depositAPR: 'Deposit APR',
  variableBorrowing: 'Variable borrowing',
  borrowAPY: 'Borrow APY',
  borrowAPR: 'Borrow APR',
  overTotal: '% over total',

  maximumLTV: 'Maximum LTV',
  liquidationThreshold: 'Liquidation threshold',
  liquidationPenalty: 'Liquidation penalty',
  usedAsCollateral: 'Used as collateral',
  stableBorrowing: 'Stable borrowing',
});
