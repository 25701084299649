import { PoolBaseCurrencyHumanized, ReserveDataHumanized } from '@aave/contract-helpers';

import { TheGraphResponse } from '../../apis/the-graph';
import { TheGraphApi, GRAPH_CLIENT } from '../../apis/the-graph/the-graph.api';

import { GraphPoolCurrencyModel } from './pools-graph.model';
import { poolCurrencyQuery, poolDataQuery } from './pools-graph.queries';
import { LENDING_POOL_ADDRESSES_PROVIDER } from '../../shared';

export class PoolsGraphApi {
  public static getPoolData(): Promise<ReserveDataHumanized[]> {
    return TheGraphApi.getGraphData(GRAPH_CLIENT, poolDataQuery).then(
      (
        response: TheGraphResponse<{
          lendingPools: Omit<ReserveDataHumanized, 'underlyingAsset'>[];
        }>
      ) => {
        return response.data.lendingPools.map(
          (reserve: Omit<ReserveDataHumanized, 'underlyingAsset'>) => ({
            ...reserve,
            underlyingAsset: reserve.id,
            id: (reserve.id + LENDING_POOL_ADDRESSES_PROVIDER).toLowerCase(),
          })
        );
      }
    );
  }

  public static getCurrencyData(): Promise<PoolBaseCurrencyHumanized> {
    return TheGraphApi.getGraphData(GRAPH_CLIENT, poolCurrencyQuery).then(
      (response: TheGraphResponse<{ poolCurrency: GraphPoolCurrencyModel }>) => {
        const poolCurrency = response.data.poolCurrency;

        if (poolCurrency === null) {
          return {
            marketReferenceCurrencyPriceInUsd: '0',
            networkBaseTokenPriceInUsd: '0',
            networkBaseTokenPriceDecimals: 18,
            marketReferenceCurrencyDecimals: 18,
          };
        }

        return {
          marketReferenceCurrencyPriceInUsd: poolCurrency.marketReferenceCurrencyPriceInUsd,
          networkBaseTokenPriceInUsd: poolCurrency.networkBaseTokenPriceInUsd,
          networkBaseTokenPriceDecimals: Number(poolCurrency.networkBaseTokenPriceDecimals),
          marketReferenceCurrencyDecimals: poolCurrency.marketReferenceCurrencyDecimals.length - 1,
        };
      }
    );
  }
}
