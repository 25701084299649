import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .emissions-wrapper {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    color: #000;

    @include respond-to(lg) {
      flex-direction: column;
    }
  }

  .emissions {
    flex: 1 1 auto;
    position: relative;
    display: flex;

    &__buy-btn {
      margin: auto 0 0 auto;

      @include respond-to(sm) {
        display: none;
      }
    }

    @include respond-to(sm) {
      flex-direction: column;
    }

    &__header {
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 4px;
      align-items: flex-start;
    }

    &__text {
      font-size: 16px;
      opacity: 0.8;
      max-width: 300px;

      @include respond-to(lg) {
        font-size: 14px;
      }
    }

    &__group {
      margin-top: 12px;
      display: flex;
      gap: 10%;

      @include respond-to(md) {
        gap: 12px;
      }

      @include respond-to(sm) {
        gap: 12px;
      }

      @include respond-to(sm) {
        flex-wrap: wrap;
        justify-content: space-between;

        & .emissions__badge-value {
          font-size: 20px;
        }
      }

      & .emissions__badge {
        margin-top: 24px;

        @include respond-to(sm) {
          margin-top: 18px;
        }

        @include respond-to(xs) {
          margin-top: 12px;
        }
      }
    }

    &__btns-group {
      gap: 12px;
      display: flex;

      &--sm-hidden {
        @include respond-to(sm) {
          display: none;
        }
      }

      &--sm-show {
        display: none;
        margin-top: 24px;
        justify-content: center;

        @include respond-to(sm) {
          display: flex;
        }
      }
    }

    &__badge {
      color: #000;
      position: relative;

      &-name {
        font-size: 16px;

        @include respond-to(md) {
          font-size: 14px;
        }

        @include respond-to(sm) {
          font-size: 12px;
        }
      }

      &-value {
        margin-top: 3px;
        font-size: 27px;

        @include respond-to(md) {
          font-size: 22px;
        }

        @include respond-to(sm) {
          font-size: 14px;
        }
      }
    }

    &__badge-deposits {
      min-width: 100px;
      white-space: nowrap;

      @include respond-to(sm) {
        min-width: auto;
      }
    }

    &__badge-locked {
      white-space: nowrap;
      min-width: 100px;
      .emissions__badge-value {
        color: #000;
      }

      @include respond-to(sm) {
        min-width: auto;
      }
    }

    &__body {
      margin-top: 0;
      flex: 1 1 auto;
      margin-right: 26px;
      position: relative;

      @include respond-to(sm) {
        margin-right: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -12px;
        bottom: 0;
        width: 1px;
        height: 175px;

        background: linear-gradient(
          0,
          rgba(221, 221, 221, 0) 0%,
          rgba(221, 221, 221, 0.6) 46%,
          rgba(221, 221, 221, 0) 100%
        );

        @include respond-to(sm) {
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) 46%,
            rgba(255, 255, 255, 0) 100%
          );
          width: 264px;
          height: 1px;
          top: 100%;
          left: 50%;
          right: auto;
          transform: translateX(-50%);
          margin-top: 16px;
        }
      }
    }

    &__eligibility {
      width: 500px;
    }
  }
`;

export default staticStyles;
