import { MouseEvent, ReactNode } from 'react';
import { ReactComponent as SelectArrowIcon } from '../../../../images/icons/arrow-down.svg';
import classNames from 'classnames';
import { staticStyles } from './styles';

export interface SelectHeaderProps {
  children: ReactNode;
  onHeaderClick?: (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  className?: string;
}

export function SelectHeader({ children, onHeaderClick, className }: SelectHeaderProps) {
  return (
    <>
      <div
        onClick={(e) => onHeaderClick!(e)}
        className={classNames('basic-select__header', className)}
      >
        {children}

        <div className="basic-select__header-arrow">
          <SelectArrowIcon color={'inherit'} width="100%" height="100%" />
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
