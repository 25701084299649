import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import ValuePercent from '../../../../components/basic/ValuePercent';
import { MathUtils } from '../../../../utils/math.utils';

export function ReserveCollateralData({ reserveOverviewData }: { reserveOverviewData: any }) {
  return (
    <>
      <div className="ReserveOverview__supply-usage">
        <p>Collateral Usage</p>
        {reserveOverviewData.usageAsCollateralEnabled ? (
          <span>Can be used as collateral</span>
        ) : (
          <span>Can't be used as collateral</span>
        )}
      </div>

      <div className="ReserveOverview__badge-box-wrapper">
        <BasicBox className="ReserveOverview__badge-box">
          <div className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Max LTV</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {MathUtils.formatNumber(reserveOverviewData.baseLTVasCollateral * 100, 2)}%
            </div>
          </div>
        </BasicBox>
        <BasicBox className="ReserveOverview__badge-box">
          <div className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Liquidation threshold</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {MathUtils.formatNumber(
                reserveOverviewData.liquidationBonus <= 0
                  ? 0
                  : reserveOverviewData.liquidationThreshold * 100,
                2
              )}
              %
            </div>
          </div>
        </BasicBox>
        <BasicBox className="ReserveOverview__badge-box">
          <div className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Liquidation penalty</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {MathUtils.formatNumber(reserveOverviewData.liquidationBonus * 100, 2)}%
            </div>
          </div>
        </BasicBox>
      </div>
    </>
  );
}
