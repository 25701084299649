import { useCallback, useEffect, useMemo, useState } from 'react';
import InputBar from '../../../../components/basic/InputBar';
import { MathUtils } from '../../../../utils/math.utils';
import { ToolsForm } from '../ToolsForm/ToolsForm';
import { useToolsStore } from '../../../../store';
import { BasicAmountField } from '../../../../components/BasicAmountField/BasicAmountField';
import { valueToBigNumber } from '@aave/math-utils';
import BigNumber from 'bignumber.js';
import { ToolsHelper } from '../../tools.helper';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import { LEVEERAGE_CONTRACT_ADDRESS } from '../../../../libs/ToolsContract/typechain/factories/Tools__factory';
import { ToolsContract } from '../../../../libs/ToolsContract';
import { BasicModal, getAssetInfo } from '../../../../libs/aave-ui-kit';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import { ComputedReserveData, UserSummary } from '../../../../libs/pool-data-provider';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import { Address } from 'viem';
import { ReserveWithBalance } from '../../hooks';
import { SwapDataType } from '../../tools.types';
import { LeverageTabs } from '../../Leverage';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

export function LeverageForm({
  selectedA,
  selectedB,
  user,
  leverageFrom,
}: {
  selectedA: ReserveWithBalance | null;
  selectedB: ComputedReserveData | null;
  user: UserSummary;
  leverageFrom: LeverageTabs;
}) {
  const borrowReceiverAddress = useToolsStore.use.borrowReceiver();

  const { provider } = useProviderContext();

  const [amountA, setAmountA] = [useToolsStore.use.amountA(), useToolsStore.use.setAmountA()];
  const [amountB, setAmountB] = [useToolsStore.use.amountB(), useToolsStore.use.setAmountB()];
  const [leverage, setLeverage] = [useToolsStore.use.leverage(), useToolsStore.use.setLeverage()];
  const [maxLeverage, setMaxLeverage] = useState<BigNumber>(valueToBigNumber(10));
  const [step, setStep] = useState<Steps>(Steps.amount);
  const [swapData, setSwapData] = useState<SwapDataType>({
    outAmountWithFee: '',
    data: { to: '', data: '' },
  });

  const isASelected = selectedA !== null;
  const isBSelected = selectedB !== null;
  const isSelected = isASelected && isBSelected;

  const isDisabled = useMemo(() => {
    const bigIntA = valueToBigNumber(amountA);
    const bigIntB = valueToBigNumber(amountB);

    if (isSelected) {
      return (
        !bigIntA.gt(0) ||
        !bigIntB.gt(0) ||
        bigIntA.gt(selectedA.balance) ||
        bigIntB.gt(selectedB.availableLiquidity)
      );
    } else {
      return true;
    }
  }, [isSelected, selectedA, selectedB, amountA, amountB]);

  const toolsContract = new ToolsContract(provider, LEVEERAGE_CONTRACT_ADDRESS);

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      if (isSelected) {
        const repaymentAmount = ToolsHelper.getFinalDepositAmount(amountA, leverage);

        console.log('Repayment Amount: ', repaymentAmount.toString());

        const swapResponse = await ToolsHelper.getSwapData({
          account: borrowReceiverAddress,
          tokenA: selectedA,
          tokenB: selectedB,
          repaymentAmount,
        });

        setSwapData(swapResponse);
        setStep(Steps.confirmation);
      }
    },
    [provider, selectedA, amountA, amountB, leverage, selectedB]
  );

  const handleGetTransactions = useCallback(() => {
    return toolsContract.flashBorrow({
      user: user.id as Address,
      borrowReceiverAddress,
      collateral: selectedA as ReserveWithBalance,
      collateralAmount: amountA,
      borrow: selectedB as ComputedReserveData,
      swapData: swapData,
      leverage,
      leverageFrom,
    });
  }, [amountA, amountB, maxLeverage, swapData, provider]);

  useEffect(() => {
    if (selectedA) {
      const maxLeverage = ToolsHelper.calculateMaxLeverage(selectedA.baseLTVasCollateral);

      setMaxLeverage(maxLeverage);
    }
  }, [selectedA]);

  useEffect(() => {
    if (selectedA && selectedB) {
      const borrowAmount = ToolsHelper.calculateBorrowAmount({
        selectedA: selectedA,
        selectedB: selectedB,
        value: amountA,
        maxLeverage: maxLeverage.toNumber(),
        rate: leverage / maxLeverage.toNumber(),
      });

      setAmountB(!borrowAmount.isNaN() ? borrowAmount.toString() : '');
    }
  }, [selectedB, selectedA, amountA, leverage, maxLeverage]);

  return (
    <>
      <ToolsForm {...{ handleSubmit }}>
        <ToolsForm.Field title="Deposit">
          <BasicAmountField className="tools-form__field" size="small">
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                className="tools-form__input"
                handleChange={setAmountA}
                value={amountA}
              />
              <BasicAmountField.USDValue>
                {isSelected
                  ? MathUtils.toLocaleNumber(
                      valueToBigNumber(amountA).times(selectedA?.priceInMarketReferenceCurrency),
                      2
                    )
                  : 0}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              {isASelected && (
                <>
                  <BasicAmountField.Asset symbol={selectedA?.symbol} />
                  <BasicAmountField.Balance
                    className="tools-form__balance"
                    onClick={() => setAmountA(selectedA.balance)}
                  >
                    {selectedA?.balance}
                  </BasicAmountField.Balance>
                </>
              )}
            </BasicAmountField.TokenBox>
          </BasicAmountField>
        </ToolsForm.Field>
        <ToolsForm.Field title="Borrow">
          <BasicAmountField className="tools-form__field" size="small">
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                className="tools-form__input"
                handleChange={() => {}}
                value={amountB}
              />
              <BasicAmountField.USDValue>
                {MathUtils.toLocaleNumber(
                  valueToBigNumber(amountB).times(selectedB?.priceInMarketReferenceCurrency || 0),
                  2
                )}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              {isBSelected && (
                <>
                  <BasicAmountField.Asset symbol={selectedB?.symbol} />
                  <BasicAmountField.Balance className="tools-form__balance" onClick={() => {}}>
                    {selectedB.availableLiquidity}
                  </BasicAmountField.Balance>
                </>
              )}
            </BasicAmountField.TokenBox>
          </BasicAmountField>
        </ToolsForm.Field>

        <ToolsForm.Range value={leverage} min={1.1} max={Number(maxLeverage)}>
          {({ rate, min, max }) => (
            <>
              <InputBar.Header>
                <span>{min}</span>
                <span>Leverage x{MathUtils.truncateNumber(leverage, 2)}</span>
                <span>{max}</span>
              </InputBar.Header>
              <InputBar.Range
                onChange={(leverage) => {
                  if (selectedA && selectedB) {
                    setLeverage(Number(leverage.toFixed(1)));
                  }
                }}
                minAmount={min}
                maxAmount={max}
                value={leverage}
              >
                <InputBar.Progress rate={rate} />
              </InputBar.Range>
            </>
          )}
        </ToolsForm.Range>

        <ToolsForm.Button disabled={isDisabled}>Leverage</ToolsForm.Button>
      </ToolsForm>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        {isSelected ? (
          <PoolTxConfirmationView
            mainTxName={'Leverage'}
            caption={'Leverage overview'}
            boxTitle={'Leverage'}
            boxDescription={'Please submit to leverage'}
            approveDescription={'Please approve before leverage'}
            getTransactionsData={handleGetTransactions}
            blockingError=""
            aTokenData={getAtokenInfo({
              address: selectedB.underlyingAsset,
              symbol: getAssetInfo(selectedB.symbol).symbol,
              decimals: selectedB.decimals,
            })}
          />
        ) : null}
      </BasicModal>
    </>
  );
}
