import { ReactNode, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';

import './style.scss';
import { useHistory, useLocation } from 'react-router-dom';

interface ScrollBarProps {
  children: ReactNode;
  className?: string;
  onUpdate?: (value: any) => void;
}

export default function ScrollBar({ children, className, onUpdate }: ScrollBarProps) {
  const containerRef = useRef<Scrollbars>(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      const current = location.pathname.split('/')[1];
      const next = newLocation.pathname.split('/')[1];

      if (containerRef.current && current !== next) {
        containerRef.current.scrollTop(0);
      }
    });

    return () => {
      unlisten();
    };
  }, [history, location]);

  return (
    <Scrollbars
      ref={containerRef}
      className={classNames('ScrollBar', className)}
      onUpdate={onUpdate}
    >
      {children}
    </Scrollbars>
  );
}
