import { useState } from 'react';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import ButtonTabs from '../../../../components/basic/ButtonTabs';
import staticStyles from './styles';
import { BigNumber } from '@aave/protocol-js';
import { ComputedReserveData, UserSummary } from '../../../../libs/pool-data-provider';
import { BorrowWidget, DepositWidget } from '../../../../components';
import { ComputedUserReserve } from '@aave/math-utils';
import { ReserveSupply } from '../ReserveSupply/ReserveSupply';
import { ReserveBorrow } from '../ReserveBorrow/ReserveBorrow';

enum WidgetsNames {
  Deposit = 'Deposit',
  Borrow = 'Borrow',
}

const VEST_TABS: WidgetsNames[] = [WidgetsNames.Deposit, WidgetsNames.Borrow];

export function VestWidgets({
  currencySymbol,
  poolReserve,
  reserveOverviewData,
  userReserve,
  user,
}: {
  currencySymbol: string;
  walletBalance: BigNumber;
  poolReserve: ComputedReserveData;
  user: UserSummary;
  userReserve: ComputedUserReserve;
  reserveOverviewData: any;
}) {
  const [currentTab, setCurrentTab] = useState<WidgetsNames>(WidgetsNames.Deposit);
  const isDeposit = currentTab === WidgetsNames.Deposit;

  return (
    <>
      <div className="reserve-widget">
        <>
          <BasicBox variant="secondary" className="reserve-widget__box">
            <BasicBox.Header className="reserve-widget__box-header">
              <BasicBox.Title className="reserve-widget__title">
                {currentTab} {currencySymbol}
              </BasicBox.Title>
              <ButtonTabs
                tabs={VEST_TABS}
                selectedTab={currentTab}
                setSelectedTab={setCurrentTab}
                className="reserve-widget__tabs"
              />
            </BasicBox.Header>

            <BasicBox.Body className="reserve-widget__body">
              {isDeposit ? (
                <DepositWidget {...{ user, currencySymbol, poolReserve, userReserve }} />
              ) : (
                <BorrowWidget {...{ user, currencySymbol, poolReserve, userReserve }} />
              )}
            </BasicBox.Body>
          </BasicBox>
          {isDeposit ? (
            <ReserveSupply {...{ poolReserve, currencySymbol, reserveOverviewData }} />
          ) : (
            <ReserveBorrow {...{ reserveOverviewData, poolReserve }} />
          )}
        </>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
