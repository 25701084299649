import { useState } from 'react';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import ButtonTabs from '../../../../components/basic/ButtonTabs';
import staticStyles from './style';
import { BigNumber } from '@aave/protocol-js';
import { ComputedReserveData, UserSummary } from '../../../../libs/pool-data-provider';
import { LoopWidget } from '../../../../components';
import { ComputedUserReserve } from '@aave/math-utils';

enum LoopOptionsModel {
  Deposit = 'Deposit',
  Borrow = 'Borrow',
}
const LOOP_TABS: LoopOptionsModel[] = [LoopOptionsModel.Deposit, LoopOptionsModel.Borrow];

export function ReserveLoop({
  symbol,
  poolReserve,
  user,
  userReserve,
}: {
  symbol: string;
  walletBalance: BigNumber;
  poolReserve: ComputedReserveData;
  user: UserSummary;
  userReserve: ComputedUserReserve;
}) {
  const [currentTab, setCurrentTab] = useState<string>(LoopOptionsModel.Deposit);

  return (
    <>
      <BasicBox className="reserve-loop" variant="secondary">
        <BasicBox.Header className="reserve-widget__box-header">
          <ButtonTabs
            tabs={LOOP_TABS}
            selectedTab={currentTab}
            setSelectedTab={setCurrentTab}
            className="reserve-loop__tabs"
          />
        </BasicBox.Header>

        <BasicBox.Body>
          <BasicBox.Title className="reserve-widget__title">1-Click Loop & Lock</BasicBox.Title>
          <p className="reserve-widget__text">
            Increase your overall yield with up to 4x leverage. This will automatically repeat the
            deposit and borrow process until your desired leverage is achieved.
          </p>

          <LoopWidget
            {...{
              user,
              poolReserve,
              symbol,
              userReserve,
              isBorrow: currentTab === LoopOptionsModel.Borrow,
            }}
          />
        </BasicBox.Body>
      </BasicBox>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
