import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useWeb3DataContext } from '../../libs/web3-data-provider';
import { useMenuContext } from '../../libs/menu';
import messages from './messages';
import staticStyles from './style';
import DefaultButton from '../basic/DefaultButton';

interface ConnectButtonProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

export default function ConnectButton({ className, size = 'medium' }: ConnectButtonProps) {
  const intl = useIntl();
  const { showSelectWalletModal } = useWeb3DataContext();
  const { closeMobileMenu } = useMenuContext();

  return (
    <>
      <DefaultButton
        title={intl.formatMessage(messages.connectWallet)}
        size={size}
        className={classNames('ConnectButton', `ConnectButton__${size}`, className)}
        onClick={() => {
          showSelectWalletModal();
          closeMobileMenu();
        }}
      />

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .ConnectButton {
          min-width: 140px;
        }
      `}</style>
    </>
  );
}
