import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/screen-size';

  .basic-table {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #000;

    @include respond-to(sm) {
      font-size: 14px;
    }

    &__Header {
      display: flex;
      padding: 20px 16px;
      text-align: center;
    }

    &__row {
      display: flex;
      align-items: center;
      transition: background 0.2s;
      border-radius: 4px;
    }

    &__item {
      flex: 1;
      padding: 0 10px;
    }

    &__sort-button {
      color: inherit;
      display: flex;
      align-items: center;
      font-size: inherit;
      font-weight: inherit;
    }

    &__arrow {
      width: 12px;
      height: 7px;
      color: #000;
      margin-left: 8px;

      .basic-table__sort-button--desc & {
        transform: rotate(180deg);
      }
    }
  }
`;

export default staticStyles;
