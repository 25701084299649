import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .m-revenue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    margin: 24px 0 0;
    padding: 24px;
    position: relative;
    flex-basis: 53%;

    @include respond-to(xs) {
      padding: 24px 12px;
    }

    &__title {
      p {
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;

        @include respond-to(xs) {
          font-size: 16px;
        }
      }

      &-value {
        display: block;
        font-size: 28px;
        font-weight: 500;
        margin: 4px 0;

        @include respond-to(xs) {
          font-size: 24px;
        }
      }
    }

    &__btns {
      margin-top: 12px;
      display: flex;
      gap: 12px;
    }

    &__tokens {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 8px 12px;
      align-items: center;
      justify-content: left;

      @include respond-to(md) {
        margin: 16px 0;
        padding: 0;
      }
    }

    &__token {
      display: flex;
      align-items: center;
      line-height: 1.1;
      padding: 6px 12px;
      border-radius: 50px;
      flex: none;
      gap: 20px;
      background: #fffdf9;
      border: 1px solid #d9d9d9;
      color: #000;
      font-weight: 500;
    }

    &__token-img {
      width: 28px;
      height: 28px;

      @include respond-to(md) {
        width: 26px;
        height: 26px;
      }
    }

    &__token-left {
      &.basic-token {
        gap: 8px;
      }
    }

    &__token-value {
      display: inline;
      white-space: nowrap;
    }

    &__token-right {
      margin-left: auto;
      text-align: right;
    }

    &__token-balance {
      font-size: 14px;
    }

    &__token-usd {
      opacity: 0.6;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include respond-to(md) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__auto {
      display: flex;
      align-items: center;
      font-weight: 700;

      @include respond-to(md) {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    &__auto-text {
      margin-left: 12px;
      white-space: nowrap;

      @include respond-to(md) {
        span {
          display: none;
        }
      }
    }

    &__slipage {
      font-size: 16px;
      font-weight: 600;
      margin-left: auto;
    }
  }
`;

export default staticStyles;
