import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .EligibilityChart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -30px -10px;

    @include respond-to(sm) {
      margin: 0;
    }

    &__svgs {
      transform: rotate(25deg);
    }

    &__svg {
      width: 100%;
      height: 100%;

      &--absolute {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &__inner {
      position: absolute;
      padding: 24px 20px 20px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #000;
      text-align: center;
      font-size: 18px;
      display: flex;
      align-items: center;

      span {
        color: #1763fd;
      }

      b {
        font-size: 14px;
        margin: 0 2px;
      }
    }
  }
`;

export default staticStyles;
