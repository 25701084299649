import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';

import './style';

import classNames from 'classnames';
import staticStyles from './style';
import { ReactComponent as CloseIcon } from '../../../../images/icons/close-icon.svg';

interface BasicModalDefaultProps {
  children: ReactNode;
  className?: string;
}

interface BasicModalProps extends BasicModalDefaultProps {
  isVisible: boolean;
  onBackdropPress: () => void;
}

interface BasicModalCloseProps extends Omit<BasicModalDefaultProps, 'children'> {
  onClose: () => void;
}

export default function BasicModal({
  isVisible,
  onBackdropPress,
  children,
  className,
}: BasicModalProps) {
  useEffect(() => {
    if (isVisible) {
      window.onpopstate = () => {
        onBackdropPress();
      };
    } else {
      window.onpopstate = () => {};
    }

    return () => {
      window.onpopstate = () => {};
    };
  }, [isVisible]);

  return (
    <>
      <Modal
        className={classNames(
          'basic-modal',
          { 'basic-modal--enter': isVisible, 'basic-modal--exit': !isVisible },
          className
        )}
        isOpen={isVisible}
        onRequestClose={(e: React.MouseEvent) => {
          e.stopPropagation();
          onBackdropPress();
        }}
        ariaHideApp={false}
        closeTimeoutMS={300}
      >
        {children}
      </Modal>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

BasicModal.Close = ({ className, onClose }: BasicModalCloseProps) => {
  return (
    <div className={classNames('basic-modal__close', className)}>
      <button className="basic-modal__close-btn" type="button" onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

BasicModal.Header = ({ className, children }: BasicModalDefaultProps) => {
  return <div className={classNames('basic-modal__header', className)}>{children}</div>;
};

BasicModal.Content = ({ className, children }: BasicModalDefaultProps) => {
  return <div className={classNames('basic-modal__content', className)}>{children}</div>;
};

BasicModal.Footer = ({ className, children }: BasicModalDefaultProps) => {
  return <div className={classNames('basic-modal__footer', className)}>{children}</div>;
};
