import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import BigNumber from 'bignumber.js';

import staticStyles from './style';
import { MathUtils } from '../../../utils/math.utils';
import { EligibilityChartSvg } from './EligibilityChartSvg';
import { MINIMUM_ELIGIBILITY_PERCENT } from '../../EmissionsRow/components/EligibilityProgress/EligibilityProgress';

export interface CircleCompositionBarItem {
  color: string;
  value: number;
  label: string | number;
}

interface CircleCompositionBarProps {
  eligibilityPercent: BigNumber;
}

const SMALL_LINE_VALUE = 20;
const BIG_LINE_VALUE = 80;

export function EligibilityChart({ eligibilityPercent }: CircleCompositionBarProps) {
  const SMALL_RATE = eligibilityPercent.lte(MINIMUM_ELIGIBILITY_PERCENT)
    ? eligibilityPercent.div(MINIMUM_ELIGIBILITY_PERCENT).toNumber()
    : 1;

  const BIG_RATE = eligibilityPercent.lt(100)
    ? eligibilityPercent
        .minus(MINIMUM_ELIGIBILITY_PERCENT)
        .div(100 - MINIMUM_ELIGIBILITY_PERCENT)
        .plus(0.02)
        .toNumber()
    : 1;

  const defaultData = [
    { color: '#020180', value: SMALL_LINE_VALUE, label: `` },
    { color: '#020180', value: BIG_LINE_VALUE, label: `` },
  ];

  const data = [
    {
      color: '#1763FD',
      value: SMALL_RATE < 0.2 && SMALL_RATE !== 0 ? 5 : SMALL_RATE * SMALL_LINE_VALUE,
      label: ``,
    },
  ];

  if (SMALL_RATE < 1) {
    data.push({ color: 'transparent', value: (1 - SMALL_RATE) * SMALL_LINE_VALUE, label: `` });
  }

  data.push({ color: '#1763FD', value: BIG_RATE * BIG_LINE_VALUE, label: `` });

  if (BIG_RATE < 1) {
    data.push({ color: 'transparent', value: (1 - BIG_RATE) * BIG_LINE_VALUE, label: `` });
  }

  return (
    <div className="EligibilityChart">
      <div className="EligibilityChart__svgs">
        <ParentSize>
          {() => (
            <EligibilityChartSvg width={184} height={184} slices={defaultData} disableBackground />
          )}
        </ParentSize>

        {eligibilityPercent.gt(0) && (
          <ParentSize className="EligibilityChart__svg--absolute">
            {() => <EligibilityChartSvg width={184} height={184} slices={data} disableBackground />}
          </ParentSize>
        )}
      </div>

      <div className="EligibilityChart__inner">
        <span>{MathUtils.truncateNumber(eligibilityPercent, 2)}%</span>
        <b>/</b>5%
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
