import { useThemeContext } from '../../../../libs/aave-ui-kit';

import Caption from '../../../../components/basic/Caption';

import staticStyles from './style';

import { LOGO_2 } from '../../../../ui-config';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';

export default function DashboardNoData() {
  const { currentTheme, isCurrentThemeDark, sm } = useThemeContext();

  return (
    <BasicBox className="DashboardNoData">
      <div className="DashboardNoData__logo-wrap">
        <LOGO_2 />
      </div>
      <Caption
        title="No deposits yet"
        description="You need to deposit some collateral first to unlock your borrowing power"
      />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DashboardNoData {
          &__markets {
            &:after {
              background: ${isCurrentThemeDark
                ? currentTheme.lightBlue.hex
                : sm
                ? currentTheme.disabledGray.hex
                : currentTheme.mainBg.hex};
            }
          }
          &__bottom--text {
            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </BasicBox>
  );
}
