import CircleCompositionBar, {
  CircleCompositionBarItem,
} from '../../../../components/compositionBars/CircleCompositionBar';
import { getAssetColor, getAssetInfo } from '../../../../helpers/config/assets-config';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { valueToBigNumber } from '@aave/protocol-js';
import staticStyles from './style';
import { useIntl } from 'react-intl';
import { useUserDepositedComp } from '../../../../hooks/useUserDepositedComp';
import PreloaderOver from '../../../../components/basic/PreloaderOver';

export function DashboardDeposit() {
  const { user, reserves } = useDynamicPoolDataContext();
  const intl = useIntl();
  const depositCompositionData: CircleCompositionBarItem[] = [];

  const totalValue = Number(user?.totalLiquidityMarketReferenceCurrency || 0);

  const depositList = useUserDepositedComp();

  user?.userReservesData.forEach((userReserve) => {
    const poolReserve = reserves.find((res) => {
      return (
        res.symbol === (userReserve.reserve.symbol === 'ETH' ? 'WETH' : userReserve.reserve.symbol)
      );
    });

    if (!poolReserve) {
      throw new Error('data is inconsistent pool reserve is not available');
    }
    if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
      if (userReserve.underlyingBalance !== '0') {
        depositCompositionData.push({
          label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
            valueToBigNumber(userReserve.underlyingBalanceMarketReferenceCurrency)
              .dividedBy(user?.totalLiquidityMarketReferenceCurrency)
              .multipliedBy(100)
              .toNumber(),
            { maximumFractionDigits: 2 }
          )}%`,
          value: Number(userReserve.underlyingBalanceMarketReferenceCurrency),
          color: getAssetColor(userReserve.reserve.symbol),
        });
      }
    }
  });

  return (
    <>
      <div className="DashboardDeposit">
        <div className="DashboardDeposit__left">
          <CircleCompositionBar
            title="Deposits"
            totalValue={totalValue}
            data={
              totalValue !== 0
                ? depositCompositionData
                : [{ value: 100, label: '', color: '#C0C0C0' }]
            }
          ></CircleCompositionBar>
        </div>
        <div className="DashboardDeposit__body">
          {totalValue !== 0 ? (
            <>
              <p className="DashboardDeposit__title">Deposit composition</p>

              <ul className="DashboardDeposit__tokens">
                {depositList?.map((item: any, index: number) => (
                  <li className="DashboardDeposit__token" key={index}>
                    <p>{item.symbol}</p>
                    <span>{item?.label}</span>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="DashboardDeposit__title DashboardDeposit__title--center">
              No deposits detected
            </p>
          )}
        </div>
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>

      <PreloaderOver isShown={!user?.userReservesData} />
    </>
  );
}
