import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import React, { useContext } from 'react';
import { getProvider } from '../../helpers/config/markets-and-network-config';
import { ChainId } from '@aave/contract-helpers';
import { iota } from '../../shared';

type WalletProviderContext = {
  provider: Web3Provider;
  Web3Provider: Web3Provider;
  account: string | null | undefined;
  chainId?: number;
};

const Context = React.createContext<WalletProviderContext>({} as WalletProviderContext);

export const WalletProvider: React.FC = ({ children }) => {
  const { library, account, chainId } = useWeb3React();

  const provider = (chainId === iota.id && library) || getProvider(iota.id as ChainId);

  // @ts-ignore
  return (
    <Context.Provider value={{ provider, account, chainId, Web3Provider: library }}>
      {children}
    </Context.Provider>
  );
};

export const useProviderContext = () => useContext(Context);
