import React, { ReactElement, ReactNode, useContext } from 'react';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useWeb3DataContext } from '../../web3-data-provider';
import { NetworkConfig } from '../../../helpers/config/types';
import { useConnectionStatusContext } from '../../connection-status-provider';
import { assetsOrder } from '../../../ui-config/assets';
import { ChainId } from '@aave/contract-helpers';
import { ReserveDataHumanized, UserReserveDataHumanized } from '@aave/contract-helpers';
import { normalize } from '@aave/math-utils';

import { usePoolData } from '../hooks/use-pool-data';

/**
 * removes the marketPrefix from a symbol
 * @param symbol
 * @param prefix
 */
export const unPrefixSymbol = (symbol: string, prefix: string) => {
  return symbol.toUpperCase().replace(RegExp(`^(${prefix[0]}?${prefix.slice(1)})`), '');
};

export interface UserReserveDataExtended extends UserReserveDataHumanized {
  reserve: ReserveDataHumanized;
}

export interface StaticPoolDataContextData {
  userId?: string;
  chainId: ChainId;
  networkConfig: NetworkConfig;
  isUserHasDeposits: boolean;
  rawReserves: ReserveDataHumanized[];
  rawUserReserves?: UserReserveDataExtended[];
  rawReservesWithBase: ReserveDataHumanized[];
  rawUserReservesWithBase?: UserReserveDataExtended[];
  marketRefCurrencyDecimals: number;
  marketRefPriceInUsd: string;
  WrappedBaseNetworkAssetAddress: string;
  refresh: () => Promise<void>;
}

const StaticPoolDataContext = React.createContext({} as StaticPoolDataContextData);

interface MetisStaticPoolDataProviderProps {
  children: ReactNode;
  loader: ReactElement;
  errorPage: ReactElement;
}

export function StaticPoolDataProvider({
  children,
  loader,
  errorPage,
}: MetisStaticPoolDataProviderProps) {
  const { currentAccount } = useWeb3DataContext();
  const { chainId, networkConfig } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();

  const {
    error: rpcDataError,
    loading: rpcDataLoading,
    data: rpcData,
    refresh,
  } = usePoolData(!isRPCActive);

  const activeData = rpcData;
  if ((isRPCActive && rpcDataLoading && !rpcData) || !isRPCActive) {
    return loader;
  }

  if (!activeData || (isRPCActive && rpcDataError) || !isRPCActive) {
    return errorPage;
  }

  const reserves: ReserveDataHumanized[] | undefined = activeData.reserves?.reservesData.map(
    (reserve) => ({
      ...reserve,
    })
  );

  const reservesWithFixedUnderlying: ReserveDataHumanized[] | undefined = reserves
    ?.map((reserve) => {
      return { ...reserve };
    })
    .sort(
      ({ symbol: a }, { symbol: b }) =>
        assetsOrder.indexOf(a.toUpperCase()) - assetsOrder.indexOf(b.toUpperCase())
    );

  const userReserves: UserReserveDataExtended[] = [];
  const userReservesWithFixedUnderlying: UserReserveDataExtended[] = [];
  activeData.userReserves?.forEach((userReserve) => {
    const reserve = reserves?.find(
      (reserve) =>
        reserve.underlyingAsset.toLowerCase() === userReserve.underlyingAsset.toLowerCase()
    );
    if (reserve) {
      const reserveWithBase: UserReserveDataExtended = {
        ...userReserve,
        reserve,
      };
      userReserves.push(reserveWithBase);
      userReservesWithFixedUnderlying.push(reserveWithBase);
    }
  });

  const isUserHasDeposits = userReserves.some(
    (userReserve) => userReserve.scaledATokenBalance !== '0'
  );

  const marketRefPriceInUsd = activeData?.reserves?.baseCurrencyData
    ?.marketReferenceCurrencyPriceInUsd
    ? activeData.reserves.baseCurrencyData?.marketReferenceCurrencyPriceInUsd
    : '0';

  const marketRefCurrencyDecimals = activeData?.reserves?.baseCurrencyData
    ?.marketReferenceCurrencyDecimals
    ? activeData.reserves.baseCurrencyData?.marketReferenceCurrencyDecimals
    : 18;

  return (
    <StaticPoolDataContext.Provider
      value={{
        userId: currentAccount,
        chainId,
        networkConfig,
        refresh: isRPCActive ? refresh : async () => {},
        WrappedBaseNetworkAssetAddress: networkConfig.baseAssetWrappedAddress
          ? networkConfig.baseAssetWrappedAddress
          : '', // TO-DO: Replace all instances of this with the value from protocol-data-provider instead
        rawReserves: reservesWithFixedUnderlying ? reservesWithFixedUnderlying : [],
        rawUserReserves: userReservesWithFixedUnderlying,
        rawReservesWithBase: reserves ? reserves : [],
        rawUserReservesWithBase: userReserves,
        marketRefPriceInUsd: normalize(marketRefPriceInUsd, 8),
        marketRefCurrencyDecimals,
        isUserHasDeposits,
      }}
    >
      {children}
    </StaticPoolDataContext.Provider>
  );
}

export const useStaticPoolDataContext = () => useContext(StaticPoolDataContext);
