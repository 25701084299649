import { ethers } from 'ethers';

import { REWARD_RATE_DECIMALS } from '../../modules/manage/constants';

import { Web3Utils } from '../../utils';

import {
  DailyDataModel,
  DailyTokenDataModel,
  RewardDataModel,
} from './fee-distribution-graph.model';
import { valueToBigNumber } from '@aave/protocol-js';

export class FeeDistributionGraphHelper {
  public static convertRewardData(rewardsData: RewardDataModel[]) {
    return rewardsData.map((reward: RewardDataModel) => ({
      ...reward,
      rewardRate: FeeDistributionGraphHelper.getRewardsRatesPerSec(reward),
      price: Web3Utils.formatValue(reward.price, 8),
    }));
  }

  public static getRewardsRatesPerSec(data: RewardDataModel): string {
    const rewardRatePerSec: string = valueToBigNumber(
      Web3Utils.formatValue(data.rewardRate, REWARD_RATE_DECIMALS)
    )
      .div(valueToBigNumber(10).pow(data.decimals))
      .toString();

    return rewardRatePerSec;
  }

  public static convertDailyData(dailyData: DailyDataModel[]) {
    return dailyData.map((data: DailyDataModel) => ({
      id: data.id,
      dataPerToken: data.dataPerToken.map((token: DailyTokenDataModel) => {
        return {
          ...token,
          price: ethers.utils.formatUnits(token.price, 8),
          rate: FeeDistributionGraphHelper.convertDailyRate(token.rate, token.decimals),
        };
      }),
    }));
  }

  private static convertDailyRate(rate: string, decimals: number): string {
    return valueToBigNumber(Web3Utils.formatValue(rate, REWARD_RATE_DECIMALS))
      .div(Math.pow(10, decimals))
      .toString();
  }
}
