import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import messages from './messages';
import { ReactComponent as BackArrowPurp } from '../../../../images/icons/back-arrow.svg';

import staticStyles from './style';

import { valueToBigNumber } from '@aave/protocol-js';
import { useHistory } from 'react-router-dom';
import {
  BorrowRateGraph,
  ReserveDepositsList,
  ReserveLoop,
  ReserveMainInfo,
  ReserveOtherStats,
  ReserveOverviewStats,
  VestWidgets,
} from '../../components';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import NoDataPanel from '../../../../components/NoDataPanel';
import { useIntl } from 'react-intl';
import { EmissionsRow } from '../../../../components';
import { getAssetInfo } from '../../../../libs/aave-ui-kit';

function ReserveOverview({
  poolReserve,
  userReserve,
  currencySymbol,
  walletBalance,
  user,
}: ValidationWrapperComponentProps) {
  const history = useHistory();

  const intl = useIntl();

  const symbol = getAssetInfo(currencySymbol).symbol;

  const totalLiquidityInUsd = valueToBigNumber(poolReserve.totalLiquidity)
    .times(poolReserve.priceInMarketReferenceCurrency)
    .toString();

  const totalBorrowsInUsd = valueToBigNumber(poolReserve.totalDebt)
    .times(poolReserve.priceInMarketReferenceCurrency)
    .toString();
  const availableLiquidityInUsd = valueToBigNumber(poolReserve.availableLiquidity)
    .times(poolReserve.priceInMarketReferenceCurrency)
    .toString();

  const reserveOverviewData = {
    totalLiquidityInUsd,
    totalBorrowsInUsd,
    availableLiquidityInUsd,
    totalLiquidity: poolReserve.totalLiquidity,
    totalBorrows: poolReserve.totalDebt,
    availableLiquidity: poolReserve.availableLiquidity,
    supplyAPY: Number(poolReserve.supplyAPY),
    supplyAPR: Number(poolReserve.supplyAPR),
    avg30DaysLiquidityRate: Number(poolReserve.avg30DaysLiquidityRate),
    stableAPY: Number(poolReserve.stableBorrowAPY),
    stableAPR: Number(poolReserve.stableBorrowAPR),
    variableAPY: Number(poolReserve.variableBorrowAPY),
    variableAPR: Number(poolReserve.variableBorrowAPR),
    stableOverTotal: valueToBigNumber(poolReserve.totalStableDebt)
      .dividedBy(poolReserve.totalDebt)
      .toNumber(),
    variableOverTotal: valueToBigNumber(poolReserve.totalVariableDebt)
      .dividedBy(poolReserve.totalDebt)
      .toNumber(),
    avg30DaysVariableRate: Number(poolReserve.avg30DaysVariableBorrowRate),
    utilizationRate: Number(poolReserve.utilizationRate),
    baseLTVasCollateral: Number(poolReserve.baseLTVasCollateral),
    liquidationThreshold: Number(poolReserve.reserveLiquidationThreshold),
    liquidationBonus: Number(poolReserve.reserveLiquidationBonus),
    usageAsCollateralEnabled: poolReserve.usageAsCollateralEnabled,
    stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
    borrowingEnabled: poolReserve.borrowingEnabled,
    priceInMarketReferenceCurrency: poolReserve.priceInMarketReferenceCurrency,
  };

  return (
    <ScreenWrapper className="ReserveOverview">
      <div className="ReserveOverview__content">
        <div className="ReserveOverview__content-wrapper__data">
          <div className="BackTo">
            <button onClick={history.goBack}>
              <BackArrowPurp />
              <span>Back</span>
            </button>
          </div>

          <EmissionsRow />

          <ReserveMainInfo
            {...{ reserveOverviewData, poolReserve }}
            symbol={currencySymbol}
            dollarPrefix
          />

          {user && userReserve ? (
            <>
              <div className="ReserveOverview__group ReserveOverview__group--md-block ReserveOverview__section ">
                <VestWidgets
                  {...{
                    currencySymbol,
                    walletBalance,
                    poolReserve,
                    user,
                    reserveOverviewData,
                    userReserve,
                  }}
                />
                <ReserveLoop
                  {...{
                    poolReserve,
                    user,
                    userReserve,
                    symbol,
                    walletBalance,
                  }}
                />
              </div>

              <BasicBox variant="secondary" className="ReserveOverview__section">
                <BasicBox.Title className="ReserveOverview__title">
                  Reserve Status & Configuration
                </BasicBox.Title>

                <div className="ReserveOverview__group">
                  <BasicBox
                    className="ReserveOverview__section-list ReserveOverview__deposits"
                    variant="secondary"
                  >
                    <BasicBox.Title>Your deposited assets</BasicBox.Title>
                    <ReserveDepositsList />
                  </BasicBox>
                  <BasicBox className="ReserveOverview__section-list" variant="secondary">
                    <BasicBox.Title>Overview</BasicBox.Title>

                    <ReserveOverviewStats
                      {...{ walletBalance, userReserve, currencySymbol, poolReserve }}
                    />
                  </BasicBox>
                  <BasicBox className="ReserveOverview__section-list" variant="secondary">
                    <BasicBox.Title>Other stats</BasicBox.Title>

                    <ReserveOtherStats {...{ reserveOverviewData, poolReserve, userReserve }} />
                  </BasicBox>
                </div>
              </BasicBox>

              <BasicBox variant="secondary" className="ReserveOverview__graphic">
                <BasicBox.Title className="ReserveOverview__title">
                  Borrow Interest Rate Strategy
                </BasicBox.Title>

                <BorrowRateGraph {...{ poolReserve }} />
              </BasicBox>
            </>
          ) : (
            <NoDataPanel
              title={intl.formatMessage(messages.noConnectWalletCaption)}
              description={intl.formatMessage(messages.noConnectWalletDescription)}
              withConnectButton={true}
              className="ReserveOverview__noDataPanel"
            />
          )}
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </ScreenWrapper>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(ReserveOverview);
