import { useIntl } from 'react-intl';

import defaultMessages from '../../../../defaultMessages';

import TableButtonColumn from '../../../dashboard/components/DashboardTable/TableButtonColumn';
import { ReactElement, useState } from 'react';
import classNames from 'classnames';
import BasicTable from '../../../../components/BasicTable';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { Asset } from '../../../../libs/aave-ui-kit/helpers/assets-list';
import TableValueCol from './TableValueCol';
import TableAprCol from './TableAprCol';
import { ReactComponent as ArrowDown } from '../../../../images/icons/arrow-down.svg';
import staticStyles from './style';

function BorrowItem(props: any): ReactElement {
  const {
    reserve: { symbol },
    currentBorrows,
    currentBorrowsUSD,
    isActive,
    isFrozen,
    borrowingEnabled,
    onBorrowClick,
    onRepayClick,
    rewardsBorrowApr,
    apy,
  } = props;
  const intl = useIntl();
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <BasicTable.Row
        className={classNames('dashboard-table__row', isShown && 'active')}
        variant="secondary"
      >
        <div className="dashboard-table__top">
          <BasicTable.Item>
            <BasicToken tokenSymbol={symbol}>
              {(asset: Asset) => {
                return (
                  <>
                    <BasicToken.Image icon={asset.icon} size="medium" />
                    <BasicToken.Name name={asset.symbol} />
                  </>
                );
              }}
            </BasicToken>
          </BasicTable.Item>
          <BasicTable.Item>
            <TableValueCol value={Number(currentBorrows)} subValue={Number(currentBorrowsUSD)} />
          </BasicTable.Item>
          <BasicTable.Item>
            <TableAprCol
              type="borrow"
              value={apy ?? 0}
              liquidityMiningValue={rewardsBorrowApr ?? 0}
              symbol={symbol}
            />
          </BasicTable.Item>
          <BasicTable.Item>{''}</BasicTable.Item>
          <button
            className="dashboard-table__row-button  dashboard-table__row-button--borrow"
            onClick={() => setIsShown((isShown) => !isShown)}
          >
            <ArrowDown className="dashboard-table__row-arrow" />
          </button>
        </div>
        <div className="dashboard-table__row-footer">
          <TableButtonColumn
            color="secondary"
            disabled={!isActive || !borrowingEnabled || isFrozen}
            title={intl.formatMessage(defaultMessages.borrow)}
            onClick={onBorrowClick}
          />
          <TableButtonColumn
            color="primary"
            disabled={!isActive}
            title={intl.formatMessage(defaultMessages.repay)}
            onClick={onRepayClick}
          />
        </div>
      </BasicTable.Row>
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}

export default BorrowItem;
