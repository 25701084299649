import { useIntl } from 'react-intl';
import { BasicModal } from '../../../libs/aave-ui-kit';
import { BigNumber } from '@aave/protocol-js';
import { ComputedReserveData, UserSummary } from '../../../libs/pool-data-provider';
import { useTxBuilderContext } from '../../../libs/tx-provider';
import defaultMessages from '../../../defaultMessages';
import messages from './messages';

import BasicForm from '../../forms/BasicForm';
import NoDataPanel from '../../NoDataPanel';
import { ComputedUserReserve } from '@aave/math-utils';
import WithdrawConfirmationModal from './confirmation';
import { useState } from 'react';
import useMaxToWithdraw from './useMaxToWithdraw';

interface WithdrawModalProps {
  showModal: boolean;
  onBackdropPress: () => void;
  currencySymbol: string;
  poolReserve: ComputedReserveData;
  history: any;
  userReserve?: ComputedUserReserve;
  user?: UserSummary;
}

export default function WithdrawModal({
  showModal,
  onBackdropPress,
  currencySymbol,
  poolReserve,
  userReserve,
  user,
}: WithdrawModalProps) {
  const [amount, setAmount] = useState<BigNumber>(new BigNumber('0'));
  const [showConfirmationModal, setConfirmationModal] = useState<boolean>(false);
  const intl = useIntl();
  const { lendingPool } = useTxBuilderContext();
  const { maxAmountToWithdraw } = useMaxToWithdraw({ poolReserve, userReserve });

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  if (!userReserve) {
    return null;
  }

  const handleWithdrawSubmit = (amount: string, max?: boolean) => {
    const _amount = new BigNumber(max ? '-1' : amount);
    setAmount(_amount);
    setConfirmationModal(true);
  };

  // const handleTransactionData = (userId: string) => async () => {
  //   return await lendingPool.withdraw({
  //     user: userId,
  //     reserve: poolReserve.underlyingAsset,
  //     amount: '-1',
  //     aTokenAddress: poolReserve.aTokenAddress,
  //   });
  // };

  return (
    <>
      <BasicModal className="AddressModal" isVisible={showModal} onBackdropPress={onBackdropPress}>
        <BasicModal.Close onClose={onBackdropPress} />
        <div style={{ width: '100%' }}>
          <BasicForm
            balanceTitle="Available"
            title={intl.formatMessage(defaultMessages.withdraw)}
            description={intl.formatMessage(messages.formDescription)}
            maxAmount={maxAmountToWithdraw.toString()}
            currencySymbol={currencySymbol}
            onSubmit={handleWithdrawSubmit}
            amountFieldTitle={intl.formatMessage(messages.amountTitle)}
            maxDecimals={poolReserve.decimals}
            poolReserve={poolReserve}
            absoluteMaximum
          />
        </div>
      </BasicModal>

      <WithdrawConfirmationModal
        currencySymbol={currencySymbol}
        userReserve={userReserve}
        poolReserve={poolReserve}
        amount={amount}
        user={user}
        showModal={showConfirmationModal}
        onBackdropPress={() => {
          onBackdropPress();
          setConfirmationModal(false);
        }}
      />
    </>
  );
}
