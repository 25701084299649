import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .tools-info {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__group {
      display: flex;
      gap: 16px;

      @include respond-to(md) {
        margin-top: 16px;
      }

      @include respond-to(xs) {
        display: block;
        margin: 0;
      }
    }

    &__position {
      flex: 1;

      @include respond-to(xs) {
        padding: 8px 16px;
        margin-top: 16px;
      }
    }

    &__token {
      margin: 24px 0 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to(md) {
        .basic-token {
          gap: 8px;
          margin-right: 8px;
        }
      }

      @include respond-to(xs) {
        margin: 12px 0 4px;
      }
    }

    &__text {
      font-size: 18px;
      font-weight: 600;
      margin: 2px 0;
    }

    &__token-left {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__token-right {
      text-align: right;
    }

    &__token-balance {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.1;
    }

    &__token-usd {
      display: block;
      margin-top: 5px;
    }

    &__list {
      margin: 16px 0 0;
      flex: 1 1 auto;
    }

    &__list-item {
      display: flex;
      justify-content: space-between;
      gap: 16;
      padding: 11px 0 10px;

      span {
        font-weight: 600;
      }
    }
  }
`;

export default staticStyles;
