import { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from '.././../images/icons/arrow-down.svg';
import staticStyles from './style';
import { BasicBox } from '../BasicBox/BasicBox';

interface BasicTableProps {
  children: ReactNode;
  className?: string;
}

interface TableHeaderButtonProps extends BasicTableProps {
  handleSorting: (value: string) => void;
  sortDesc: boolean;
  sortKey: string;
  sortName?: string;
}

interface TableRowProps extends BasicTableProps {
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
}

export default function BasicTable({ children, className }: BasicTableProps) {
  return (
    <div className={classNames('basic-table', className)}>
      {children}

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
}

BasicTable.Header = ({ children, className }: BasicTableProps) => {
  return <div className={classNames('basic-table__header', className)}>{children}</div>;
};

BasicTable.Row = ({ children, className, variant = 'primary', onClick }: TableRowProps) => {
  return (
    <BasicBox
      className={classNames('basic-table__row', className)}
      variant={variant}
      onClick={() => onClick && onClick()}
    >
      {children}
    </BasicBox>
  );
};

BasicTable.Item = ({ children, className }: BasicTableProps) => {
  return <div className={classNames('basic-table__item', className)}>{children}</div>;
};

BasicTable.SortButton = ({
  handleSorting,
  sortName,
  sortDesc,
  sortKey,
  className,
  children,
}: TableHeaderButtonProps) => {
  return (
    <button
      onClick={() => handleSorting(sortKey)}
      className={classNames(
        'basic-table__sort-button',
        {
          'basic-table__sort-button--desc': sortName === sortKey && sortDesc,
          'basic-table__sort-button--active': sortName === sortKey,
        },
        className
      )}
      type="button"
    >
      {children}

      <ArrowDown className="basic-table__arrow" />
    </button>
  );
};
