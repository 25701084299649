import { useDynamicPoolDataContext } from '../libs/pool-data-provider';

import { BigNumber, InterestRate, valueToBigNumber } from '@aave/protocol-js';
import { getAssetColor } from '../libs/aave-ui-kit';
import { loanActionLinkComposer } from '../helpers/loan-action-link-composer';
import { PoolUtils } from '../utils';
import { usePoolsInfoData } from '../store';
import { BN_ONE } from '../helpers/leverage';
import { useEffect, useState } from 'react';

export function useUserPositions() {
  const { user, reserves } = useDynamicPoolDataContext();

  const { poolsInfoData } = usePoolsInfoData();

  const [depositedPositions, setDepositedPositions] = useState<any[]>([]);
  const [borrowedPositions, setBorrowedPositions] = useState<any[]>([]);
  const [totalRewardsInUsd, setTotalRewardsInUsd] = useState<BigNumber>(valueToBigNumber(-1));
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const borrowedPositions: any[] = [];
    const depositedPositions: any[] = [];

    if (user && poolsInfoData.length) {
      let totalRewardsInUsd: BigNumber = valueToBigNumber(0);
      user.userReservesData.forEach((userReserve: any) => {
        const maxLeverage = BN_ONE.div(
          BN_ONE.minus(valueToBigNumber(userReserve.reserve.baseLTVasCollateral))
        ).decimalPlaces(2, BigNumber.ROUND_FLOOR);

        const { rewardsDepositApr, rewardsBorrowApr } = PoolUtils.getPoolLooping({
          reserve: userReserve.reserve,
          poolsInfoData,
          amount: valueToBigNumber(1),
          maxLeverage,
        });

        const poolReserve = reserves.find((res: any) => res.symbol === userReserve.reserve.symbol);

        if (!poolReserve) {
          throw new Error('data is inconsistent pool reserve is not available');
        }

        if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
          const baseListData = {
            uiColor: getAssetColor(userReserve.reserve.symbol),
            isActive: poolReserve.isActive,
            isFrozen: poolReserve.isFrozen,
            stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
            reserve: {
              ...userReserve.reserve,
              liquidityRate: poolReserve.supplyAPY,
            },
          };

          if (userReserve.underlyingBalance !== '0') {
            depositedPositions.push({
              ...baseListData,
              borrowingEnabled: poolReserve.borrowingEnabled,
              avg30DaysLiquidityRate: poolReserve.avg30DaysLiquidityRate,
              usageAsCollateralEnabledOnThePool: poolReserve.usageAsCollateralEnabled,
              usageAsCollateralEnabledOnUser: userReserve.usageAsCollateralEnabledOnUser,
              underlyingBalance: userReserve.underlyingBalance,
              underlyingBalanceUSD: userReserve.underlyingBalanceUSD,
              aincentivesAPR: rewardsDepositApr.toNumber() > 0 ? rewardsDepositApr.toString() : '0',
              depositAPY: poolReserve.borrowingEnabled ? Number(poolReserve.supplyAPY) : -1,
            });

            totalRewardsInUsd = totalRewardsInUsd.plus(
              rewardsDepositApr.times(userReserve.underlyingBalanceUSD).div(100)
            );
          }

          if (Number(userReserve.variableBorrows)) {
            borrowedPositions.push({
              ...baseListData,
              borrowingEnabled: poolReserve.borrowingEnabled,
              currentBorrows: userReserve.variableBorrows,
              currentBorrowsUSD: userReserve.variableBorrowsUSD,
              borrowRateMode: InterestRate.Variable,
              borrowRate: poolReserve.variableBorrowAPY,
              vincentivesAPR: rewardsBorrowApr.toNumber() > 0 ? rewardsBorrowApr.toString() : '0',
              avg30DaysVariableRate: poolReserve.avg30DaysVariableBorrowRate,
              repayLink: loanActionLinkComposer(
                'repay',
                poolReserve.id,
                InterestRate.Variable,
                poolReserve.underlyingAsset
              ),
              borrowLink: loanActionLinkComposer(
                'borrow',
                poolReserve.id,
                InterestRate.Variable,
                poolReserve.underlyingAsset
              ),
              apy: poolReserve.borrowingEnabled ? Number(poolReserve.variableBorrowAPY) : -1,
              rewardsBorrowApr: rewardsBorrowApr,
            });

            totalRewardsInUsd = totalRewardsInUsd.plus(
              rewardsBorrowApr.times(userReserve.variableBorrowsUSD).div(100)
            );
          }

          if (userReserve.stableBorrows !== '0') {
            borrowedPositions.push({
              ...baseListData,
              borrowingEnabled: poolReserve.borrowingEnabled && poolReserve.stableBorrowRateEnabled,
              currentBorrows: userReserve.stableBorrows,
              currentBorrowsUSD: userReserve.stableBorrowsUSD,
              borrowRateMode: InterestRate.Stable,
              borrowRate: userReserve.stableBorrowAPY,
              vincentivesAPR: rewardsBorrowApr.toNumber() > 0 ? rewardsBorrowApr.toString() : '0',
              repayLink: loanActionLinkComposer(
                'repay',
                poolReserve.id,
                InterestRate.Stable,
                poolReserve.underlyingAsset
              ),
              borrowLink: loanActionLinkComposer(
                'borrow',
                poolReserve.id,
                InterestRate.Stable,
                poolReserve.underlyingAsset
              ),
            });

            totalRewardsInUsd = totalRewardsInUsd.plus(
              rewardsBorrowApr.times(userReserve.stableBorrowsUSD).div(100)
            );
          }
        }
      });

      setTotalRewardsInUsd(totalRewardsInUsd);
      setDepositedPositions(depositedPositions);
      setBorrowedPositions(borrowedPositions);
      setIsLoaded(true);
    }
  }, [poolsInfoData, user]);

  return { depositedPositions, borrowedPositions, isLoaded, totalRewardsInUsd };
}
