import { ReactNode } from 'react';
import classNames from 'classnames';

import { ScrollBar } from '../../../libs/aave-ui-kit';

interface CustomScrollProps {
  children: ReactNode;
  className?: string;
  onUpdate?: (value: any) => void;
}

export default function CustomScroll({ children, className, onUpdate }: CustomScrollProps) {
  return (
    <>
      <ScrollBar className={classNames('CustomScroll', className)} onUpdate={onUpdate}>
        {children}
      </ScrollBar>

      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';
        .CustomScroll > div {
          &:last-of-type {
            div {
              background-color: #52679d !important;
              z-index: 6;
            }
          }
        }

        .CustomScroll .ScreenWrapper {
          padding-top: 56px;
          padding-bottom: 76px;
          @include respond-to(sm) {
            padding-top: 48px;
            padding-bottom: 0;
          }
        }
      `}</style>
    </>
  );
}
