import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .HealthFactor {
    &__danger {
      color: #fe5b46;
    }

    &__normal {
      color: #f8d359;
    }

    &__safe {
      color: #50f4cd;
    }
  }
`;

export default staticStyles;
