import { BigNumber, providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { Tools } from './typechain/contracts';
import { Tools__factory } from './typechain/factories';
import { Address } from 'viem';
import { Web3Utils } from '../../utils';
import {
  BaseDebtToken,
  BaseDebtTokenInterface,
} from '@aave/contract-helpers/dist/esm/baseDebtToken-contract';
import { LendingPoolService } from '../aave-protocol-js/LendingPool';
import { LP_CONTRACT_ADDRESS } from '../aave-protocol-js/LendingPool/typechain/factories/LendingPool__factory';
import { ReserveWithBalance } from '../../modules/tools/hooks';
import { ComputedReserveData } from '../pool-data-provider';
import { SwapDataType } from '../../modules/tools/tools.types';
import { valueToBigNumber } from '@aave/math-utils';
import { _abi } from './typechain/factories/Tools__factory';
import { LeverageTabs } from '../../modules/tools/Leverage';

export class ToolsContract extends BaseService<Tools> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;
  readonly baseDebtToken: BaseDebtTokenInterface;

  constructor(provider: providers.Provider, multiFeeDistribution: string) {
    super(provider, Tools__factory);

    this.contractAddress = multiFeeDistribution;
    this.erc20Service = new ERC20Service(provider);
    this.baseDebtToken = new BaseDebtToken(provider, this.erc20Service);
  }

  public async flashBorrow({
    collateral,
    collateralAmount,
    borrow,
    leverage,
    borrowReceiverAddress,
    user,
    swapData,
    leverageFrom,
  }: {
    user: Address;
    collateral: ReserveWithBalance;
    collateralAmount: string;
    borrow: ComputedReserveData;
    leverage: number;
    borrowReceiverAddress: Address;
    swapData: SwapDataType;
    leverageFrom: LeverageTabs;
  }): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve } = this.erc20Service;

    const txs: EthereumTransactionTypeExtended[] = [];
    const toolsContract: Tools = this.getContractInstance(this.contractAddress);

    const collateralAmountWei = Web3Utils.toWei(collateralAmount, collateral.decimals);
    const borrowAmount = valueToBigNumber(swapData.outAmountWithFee)
      .decimalPlaces(borrow.decimals)
      .toString();
    const borrowAmountWei = Web3Utils.toWei(borrowAmount, borrow.decimals);
    const formatedLeverage = Number(leverage) * 10;

    const approved = await isApproved({
      token: collateral.underlyingAsset,
      user: user as string,
      spender: this.contractAddress,
      amount: collateralAmount,
    });

    if (!approved) {
      const approveTx = approve({
        user: user as string,
        token: collateral.underlyingAsset,
        spender: this.contractAddress,
        amount: collateralAmountWei.toString(),
      });
      txs.push(approveTx);
    }

    console.log(collateral.variableDebtTokenAddress);

    const delegationApproved = await this.baseDebtToken.isDelegationApproved({
      debtTokenAddress: borrow.variableDebtTokenAddress,
      allowanceGiver: user as string,
      allowanceReceiver: borrowReceiverAddress,
      amount: borrowAmount,
    });

    if (!delegationApproved) {
      const approveTx = this.baseDebtToken.approveDelegation({
        user: user as string,
        delegatee: borrowReceiverAddress,
        debtTokenAddress: borrow.variableDebtTokenAddress,
        amount: borrowAmountWei.toString(),
      });
      txs.push(approveTx);
    }

    console.log('call flashBorrow: ', [
      collateral.underlyingAsset,
      collateralAmountWei.toString(),
      borrow.underlyingAsset,
      borrowAmountWei.toString(),
      formatedLeverage.toString(),
      swapData.data,
    ]);

    const props: [string, BigNumber, string, BigNumber, string, { to: string; data: string }] = [
      collateral.underlyingAsset,
      collateralAmountWei,
      borrow.underlyingAsset,
      borrowAmountWei,
      formatedLeverage.toString(),
      swapData.data,
    ];

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        leverageFrom === LeverageTabs.Wallet
          ? toolsContract.populateTransaction.flashBorrowFromWallet(...props)
          : toolsContract.populateTransaction.flashBorrowFromDeposit(...props),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation([], txCallback),
    });

    return txs;
  }

  public async flashRepay({
    borrow,
    borrowAmount,
    repayWith,
    repayReceiverAddress,
    user,
    swapData,
  }: {
    borrow: ReserveWithBalance;
    borrowAmount: string;
    repayWith: ReserveWithBalance;
    repayReceiverAddress: Address;
    user: Address;
    swapData: SwapDataType;
  }): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const toolsContract: Tools = this.getContractInstance(this.contractAddress);
    const borrowAmountWei = Web3Utils.toWei(borrowAmount, borrow.decimals);
    const repayWithAmount = valueToBigNumber(swapData.outAmountWithFee)
      .decimalPlaces(repayWith.decimals)
      .toString();
    const repayWithAmountWei = Web3Utils.toWei(repayWithAmount, repayWith.decimals);

    const LPContract = new LendingPoolService(this.provider, LP_CONTRACT_ADDRESS);

    const isApproved = await LPContract.isDelegateApproved({ user, address: repayReceiverAddress });

    if (!isApproved) {
      const delegationTx = await LPContract.delegateWithdraw({
        user,
        address: repayReceiverAddress,
      });

      txs.push(delegationTx);
    }

    console.log(
      repayWith.underlyingAsset,
      borrowAmountWei.toString(),
      borrow.underlyingAsset,
      repayWithAmountWei.toString(),
      swapData.data
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        toolsContract.populateTransaction.flashRepay(
          borrow.underlyingAsset,
          borrowAmountWei,
          repayWith.underlyingAsset,
          repayWithAmountWei,
          swapData.data
        ),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  }

  public async getBorrowReceiver() {
    const toolsContract: Tools = this.getContractInstance(this.contractAddress);

    return await toolsContract.borrowReceiver();
  }

  public async getRepayReceiver() {
    const toolsContract: Tools = this.getContractInstance(this.contractAddress);

    return await toolsContract.repayReceiver();
  }
}
