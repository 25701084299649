import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .loop-widget {
    display: flex;
    flex-direction: column;

    @include respond-to(md) {
      display: block;
      margin: 24px 0;
    }

    &__box-header {
      @include respond-to(md) {
        overflow: auto;
      }
    }

    &__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      @include respond-to(md) {
        height: auto;
        display: block;
      }
    }

    &__tabs {
      margin: auto;
    }

    &__title {
      margin-top: 6px;
    }

    &__text {
      color: rgba(0, 0, 0, 0.4);
      margin: 12px 0 0;
      font-size: 16px;
    }

    &__field {
      margin-top: 12px;
    }

    &__range {
      margin: 32px 0;
    }

    &__info {
      margin: 16px 0;
      padding: 8px 12px;
      border-radius: 14px;
    }

    &__list-item {
      display: flex;
      justify-content: space-between;
      gap: 16;
      padding: 5px 0 4px;
      font-size: 13px;

      span {
        font-weight: 600;
      }
    }

    &__button {
      margin-top: 32px;
      display: block;
    }
  }
`;

export default staticStyles;
