import { Provider } from '@ethersproject/providers';
import { StaticApi } from '../static-api';
import { PRICE_PROVIDER_ABI, PRICE_PROVIDER_CONTRACT } from './price-provider.constants';
import { MULTI_FEE_DISTRIBUTION_ADDRESS } from '../../shared';

import { valueToBigNumber } from '@aave/protocol-js';
import { MulticallUtils, Web3Utils } from '../../utils';
import { _abi } from '../../libs/aave-protocol-js/MulteFeeDistributionContract/typechain/factories/MultiFeeDistribution__factory';
import { ethers } from 'ethers';

export class PriceProviderApi extends StaticApi {
  public static async getTokenPrices(): Promise<any> {
    const provider = PriceProviderApi.getProvider();

    const multicall = MulticallUtils.createMulltical(provider as Provider);

    const {
      results: { totalLockedSupply, prices },
    } = await multicall.call([
      {
        reference: 'prices',
        contractAddress: PRICE_PROVIDER_CONTRACT,
        abi: PRICE_PROVIDER_ABI,
        calls: [
          { reference: 'lpTokenPrice', methodName: 'getLpTokenPrice', methodParameters: [] },
          { reference: 'lpTokenPriceUsd', methodName: 'getLpTokenPriceUsd', methodParameters: [] },
          { reference: 'tokenPrice', methodName: 'getTokenPrice', methodParameters: [] },
          { reference: 'tokenPriceUsd', methodName: 'getTokenPriceUsd', methodParameters: [] },
        ],
      },
      {
        reference: 'totalLockedSupply',
        contractAddress: MULTI_FEE_DISTRIBUTION_ADDRESS,
        abi: _abi,
        calls: [
          { reference: 'lockedSupply', methodName: 'lockedSupply', methodParameters: [] },
          {
            reference: 'lockedSupplyWithMultiplier',
            methodName: 'lockedSupplyWithMultiplier',
            methodParameters: [],
          },
        ],
      },
    ]);

    // let result: any = {};

    // for (let item of prices.callsReturnContext) {
    //   result[item.reference] = item.success
    //     ? ethers.utils.formatUnits(item.returnValues[0], 8)
    //     : '0';
    // }

    const parsedLockedData = MulticallUtils.convertData(totalLockedSupply);
    const parsedPricesData: { [key: string]: string } = MulticallUtils.convertData(prices);

    Object.entries(parsedPricesData).forEach(([key, value]: [string, string]) => {
      parsedPricesData[key] = value ? Web3Utils.formatValue(value, 8) : '0';
    });

    return {
      totalLockedSupply: valueToBigNumber(Web3Utils.formatValue(parsedLockedData.lockedSupply)),
      totalLockedSupplyWithMultiplier: valueToBigNumber(
        Web3Utils.formatValue(parsedLockedData.lockedSupplyWithMultiplier)
      ),
      ...parsedPricesData,
    };
  }
}
