import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { useCurrentTimestamp } from '../hooks/use-current-timestamp';
import { useStaticPoolDataContext } from './static-pool-data-provider';
import {
  formatReserve,
  FormatReserveResponse,
  formatUserSummary,
  FormatUserSummaryResponse,
  normalize,
} from '@aave/math-utils';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import useTotalData from '../../aave-protocol-js/hooks/use-total-data';

export interface ComputedReserveData extends FormatReserveResponse {
  id: string;
  underlyingAsset: string;
  name: string;
  symbol: string;
  decimals: number;
  usageAsCollateralEnabled: boolean;
  borrowingEnabled: boolean;
  stableBorrowRateEnabled: boolean;
  isActive: boolean;
  isFrozen: boolean;
  aTokenAddress: string;
  stableDebtTokenAddress: string;
  interestRateStrategyAddress: string;
  variableDebtTokenAddress: string;
  priceInMarketReferenceCurrency: string;
  avg30DaysLiquidityRate?: string;
  avg30DaysVariableBorrowRate?: string;
  variableRateSlope1: string;
  variableRateSlope2: string;
}

export interface UserSummary extends FormatUserSummaryResponse {
  id: string;
}

export interface DynamicPoolDataContextData {
  reserves: ComputedReserveData[];
  user?: UserSummary;
  totalLockedSupplyWithMultiplier: BigNumber | null;
  totalLockedSupply: BigNumber;
  // readyToVest: BigNumber;
  rewardTokenPrice: BigNumber;
  marketCap: BigNumber;
  totalPlatformFees: BigNumber;
}

const DynamicPoolDataContext = React.createContext({} as DynamicPoolDataContextData);

export function DynamicPoolDataProvider({ children }: PropsWithChildren<{}>) {
  const { rawReserves, rawUserReserves, userId, marketRefCurrencyDecimals, marketRefPriceInUsd } =
    useStaticPoolDataContext();

  const {
    totalLockedSupply,
    rewardTokenPrice,
    marketCap,
    totalPlatformFees,
    totalLockedSupplyWithMultiplier,
  } = useTotalData();
  const currentTimestamp = useCurrentTimestamp(60);
  const [lastAvgRatesUpdateTimestamp, setLastAvgRatesUpdateTimestamp] = useState(currentTimestamp);

  useEffect(() => {
    if (currentTimestamp > lastAvgRatesUpdateTimestamp + 1000 * 60 * 5) {
      setLastAvgRatesUpdateTimestamp(currentTimestamp);
      console.log('LAST AVG RATE UPDATE');
    }
  }, [currentTimestamp, lastAvgRatesUpdateTimestamp]);

  const computedUserData =
    userId && rawUserReserves
      ? formatUserSummary({
          currentTimestamp,
          marketRefPriceInUsd,
          marketRefCurrencyDecimals,
          rawUserReserves: rawUserReserves,
        })
      : undefined;

  const formattedPoolReserves: ComputedReserveData[] = rawReserves?.map((reserve) => {
    const formattedReserve = formatReserve({
      reserve,
      currentTimestamp,
    });

    const fullReserve: ComputedReserveData = {
      ...reserve,
      ...formattedReserve,
      priceInMarketReferenceCurrency: normalize(
        reserve.priceInMarketReferenceCurrency,
        marketRefCurrencyDecimals
      ),
    };

    return {
      ...fullReserve,
      supplyAPY: valueToBigNumber(fullReserve.supplyAPY).times(100).toString(),
      variableBorrowAPY: valueToBigNumber(fullReserve.variableBorrowAPY).times(100).toString(),
    };
  });

  let userSummary: UserSummary | undefined = undefined;

  if (computedUserData && userId) {
    const _userReservesData = computedUserData.userReservesData.map((item) => {
      return {
        ...item,
        reserve: item.reserve,
      };
    });

    computedUserData.userReservesData = [..._userReservesData];

    userSummary = {
      id: userId,
      ...computedUserData,
    };
  }

  return (
    <DynamicPoolDataContext.Provider
      value={{
        user: userSummary,
        reserves: formattedPoolReserves || [],
        totalLockedSupply: totalLockedSupply,
        totalLockedSupplyWithMultiplier: totalLockedSupplyWithMultiplier,
        // readyToVest: valueToBigNumber(readyToVest ? readyToVest : 0),
        rewardTokenPrice: valueToBigNumber(rewardTokenPrice ? rewardTokenPrice : 0),
        marketCap: valueToBigNumber(marketCap ? marketCap : 0),
        totalPlatformFees: valueToBigNumber(totalPlatformFees ? totalPlatformFees : 0),
      }}
    >
      {children}
    </DynamicPoolDataContext.Provider>
  );
}

export const useDynamicPoolDataContext = () => useContext(DynamicPoolDataContext);
