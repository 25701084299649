import { ReactNode, ReactNodeArray } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { BasicModal } from '../../../../libs/aave-ui-kit';

import messages from './messages';
import staticStyles from './style';

interface ConnectWalletWrapperProps {
  children: ReactNode | ReactNodeArray;
  className?: string;
  isVisible: boolean;
  onBackdropPress: () => void;
}

export default function ConnectWalletWrapper({
  children,
  className,
  isVisible,
  onBackdropPress,
}: ConnectWalletWrapperProps) {
  const intl = useIntl();

  return (
    <BasicModal
      onBackdropPress={onBackdropPress}
      isVisible={isVisible}
      className={classNames('ConnectWalletWrapper', className)}
    >
      <div className="ConnectWalletWrapper__inner">
        <BasicModal.Header className="ConnectWalletWrapper__caption-inner">
          {intl.formatMessage(messages.caption)}
        </BasicModal.Header>

        <BasicModal.Close onClose={onBackdropPress} />

        {children}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
