import BigNumber from 'bignumber.js';
import { UserFeeBalances } from '../types';

export class ManageHelper {
  public static isRewardFinished(periodFinished: string): boolean {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    return Number(periodFinished) < currentTimestamp;
  }

  public static getUserRevenuePerDay({
    userShare,
    dailyPlatformFees,
  }: {
    userShare: BigNumber;
    dailyPlatformFees: BigNumber;
  }): BigNumber {
    const dailyRevenue: BigNumber = dailyPlatformFees.times(userShare.div(100));

    return dailyRevenue;
  }

  public static getIsAvailableToUnlock(date: Date): boolean {
    const targetDate = new Date(date);

    const currentDate = new Date();

    return currentDate > targetDate;
  }

  public static parseUserFeeBalances(balances: BigNumber[]): UserFeeBalances {
    const [lockedBal, unlockableBal, stakedBal, earnedBal, withdrawableBal, penaltyBal, totalBal] =
      balances;

    return {
      lockedBal,
      unlockableBal,
      stakedBal,
      earnedBal,
      withdrawableBal,
      penaltyBal,
      totalBal,
    };
  }
}
