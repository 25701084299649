import css from 'styled-jsx/css';
import bg from '../../../../images/markets/blik.png';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Markets {
    display: block;
    color: #000;
  }

  .markets-inner {
    display: flex;
    gap: 24px;
    @include respond-to(md) {
      display: block;
    }
  }

  .TotalFeesLock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 24px;
    background: linear-gradient(180deg, rgba(28, 22, 91, 0.3) 0%, rgba(28, 22, 91, 0.1) 103.62%);
    z-index: 1000;
    padding-bootm: 32px;
    backdrop-filter: blur(15px);
    background-repeat: no-repeat;
    background-size: cover;

    @include respond-to(xs) {
      flex-direction: column;
    }
    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .markets-basic-box {
    position: relative;
    overflow: hidden;
  }

  .markets-top {
    padding: 24px 30px;
    margin: 24px 0;

    @include respond-to(lg) {
      padding: 16px 20px;
    }

    @include respond-to(md) {
      display: block;
    }

    @include respond-to(xs) {
      padding: 16px;
    }
  }

  .markets-top-info {
    display: flex;
    align-items: center;
    min-width: 500px;
    position: relative;

    @include respond-to(lg) {
      min-width: 400px;
    }

    @include respond-to(sm) {
      min-width: auto;
    }

    &__image {
      margin-right: 20px;
      width: 100px;

      svg {
        width: 100%;
      }

      @include respond-to(sm) {
        width: 66px;
      }

      @include respond-to(xs) {
        display: none;
      }
    }

    &__text {
      font-size: 24px;
      flex: 1;
      text-transform: uppercase;

      @include respond-to(lg) {
        font-size: 18px;

        br {
          display: none;
        }
      }

      @include respond-to(sm) {
        font-size: 16px;
      }
    }

    &__btn {
      width: auto;
      margin-left: auto;
      @include respond-to(md) {
        margin-left: auto;
      }
    }
  }

  .markets-totals {
    margin: 24px 0;

    &__inner {
      max-width: 1200px;
      margin: auto;
      display: flex;
      // justify-content: space-around;
      padding: 0 0 0 88px;
      flex-wrap: wrap;

      @include respond-to(md) {
        padding: 9px 20px;
      }

      @include respond-to(sm) {
        flex-wrap: wrap;
        padding: 0;
        text-align: center;
      }
    }
  }

  .markets-total {
    color: #000;
    font-weight: 600;

    &__item {
      position: relative;
      flex-basis: 50%;
      margin: 18px 0;
    }

    &__item-name {
      font-size: 16px;

      @include respond-to(sm) {
        font-size: 14px;
      }
    }

    &__item-value {
      margin: 9px 0;
      font-size: 28px;

      @include respond-to(sm) {
        font-size: 24px;
      }
    }

    &__item-sub {
      display: block;
      color: rgba(0, 0, 0, 0.4);
    }

    &__item-link {
      color: #020180;
      font-size: inherit;
      font-weight: 600;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default staticStyles;
