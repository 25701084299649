import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useWeb3DataContext } from '../../../libs/web3-data-provider';
import goToTop from '../../../helpers/goToTop';
import Link from '../../basic/Link';
import MenuLink from '../MenuLink';
import AddressInfo from '../AddressInfo';
import MobileContent from '../MobileContent';
import { LOGO } from '../../../ui-config';
import mobileLogo from '../../../images/logo-mobile.svg';

import staticStyles from './style';

import { navigation } from '../navigation';
import { BasicBox } from '../../BasicBox/BasicBox';
import { ReactComponent as ArrowDown } from '../../../images/icons/arrow-down.svg';

export default function Menu() {
  const location = useLocation();
  const intl = useIntl();
  const { currentAccount } = useWeb3DataContext();

  const isActive = (url: string) => {
    return `/${url.split('/')[1]}` === `/${location.pathname.split('/')[1]}`;
  };

  return (
    <header className="Menu">
      <div className="Menu__logo-inner">
        <Link className="Menu__logo-link" to="/markets" onClick={() => goToTop()}>
          <img src={LOGO} alt="iolend" />
        </Link>
      </div>

      <div className="Menu__title-inner">
        <div className="Menu__burger-inner">
          <MobileContent isActive={isActive} currentAccount={currentAccount} />
        </div>

        <img src={mobileLogo} alt="" className="Menu__logo-mobile" />
      </div>

      <div className="Menu__right-inner">
        <nav className="Menu__navigation-inner">
          <ul>
            {navigation.map((link, index) =>
              link.link !== undefined ? (
                <li className="Menu__link-inner" key={index}>
                  <MenuLink
                    to={link.link}
                    title={intl.formatMessage(link.title)}
                    isActive={isActive(link.link)}
                  />
                </li>
              ) : (
                <li className="Menu__link-inner Menu__link-inner--dropdown" key={index}>
                  <MenuLink
                    title={intl.formatMessage(link.title)}
                    isActive={link.dropdown!.some((item) => isActive(item.link))}
                  >
                    <BasicBox className="Menu__item-dropdown">
                      {link.dropdown?.map((item, key) => (
                        <MenuLink
                          className="Menu__item-dropdown-link"
                          to={item.link}
                          title={intl.formatMessage(item.title)}
                          isActive={isActive(item.link)}
                          key={`${index}-${key}`}
                          disabled
                          noBorder
                        />
                      ))}
                      <p className="Menu__navigation-soon">coming soon</p>
                    </BasicBox>
                  </MenuLink>
                  <ArrowDown className="Menu__link-inner-arrow" />
                </li>
              )
            )}
          </ul>
        </nav>
      </div>

      <div className="Menu__connect">
        <div className="Menu__buttons-inner">
          <AddressInfo />
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </header>
  );
}
