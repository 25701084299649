export var Networks;
(function (Networks) {
    Networks[Networks["mainnet"] = 1] = "mainnet";
    Networks[Networks["ropsten"] = 3] = "ropsten";
    Networks[Networks["rinkeby"] = 4] = "rinkeby";
    Networks[Networks["goerli"] = 5] = "goerli";
    Networks[Networks["optimism"] = 10] = "optimism";
    Networks[Networks["kovan"] = 42] = "kovan";
    Networks[Networks["matic"] = 137] = "matic";
    Networks[Networks["kovanOptimism"] = 69] = "kovanOptimism";
    Networks[Networks["xdai"] = 100] = "xdai";
    Networks[Networks["xDaiTestnet"] = 10200] = "xDaiTestnet";
    Networks[Networks["goerliOptimism"] = 420] = "goerliOptimism";
    Networks[Networks["arbitrum"] = 42161] = "arbitrum";
    Networks[Networks["rinkebyArbitrum"] = 421611] = "rinkebyArbitrum";
    Networks[Networks["goerliArbitrum"] = 421613] = "goerliArbitrum";
    Networks[Networks["mumbai"] = 80001] = "mumbai";
    Networks[Networks["sepolia"] = 11155111] = "sepolia";
    Networks[Networks["avalancheMainnet"] = 43114] = "avalancheMainnet";
    Networks[Networks["avalancheFuji"] = 43113] = "avalancheFuji";
    Networks[Networks["fantomTestnet"] = 4002] = "fantomTestnet";
    Networks[Networks["fantom"] = 250] = "fantom";
    Networks[Networks["bsc"] = 56] = "bsc";
    Networks[Networks["bsc_testnet"] = 97] = "bsc_testnet";
    Networks[Networks["moonbeam"] = 1284] = "moonbeam";
    Networks[Networks["moonriver"] = 1285] = "moonriver";
    Networks[Networks["moonbaseAlphaTestnet"] = 1287] = "moonbaseAlphaTestnet";
    Networks[Networks["harmony"] = 1666600000] = "harmony";
    Networks[Networks["cronos"] = 25] = "cronos";
    Networks[Networks["fuse"] = 122] = "fuse";
    Networks[Networks["songbirdCanaryNetwork"] = 19] = "songbirdCanaryNetwork";
    Networks[Networks["costonTestnet"] = 16] = "costonTestnet";
    Networks[Networks["boba"] = 288] = "boba";
    Networks[Networks["aurora"] = 1313161554] = "aurora";
    Networks[Networks["astar"] = 592] = "astar";
    Networks[Networks["okc"] = 66] = "okc";
    Networks[Networks["heco"] = 128] = "heco";
    Networks[Networks["metis"] = 1088] = "metis";
    Networks[Networks["rsk"] = 30] = "rsk";
    Networks[Networks["rskTestnet"] = 31] = "rskTestnet";
    Networks[Networks["evmos"] = 9001] = "evmos";
    Networks[Networks["evmosTestnet"] = 9000] = "evmosTestnet";
    Networks[Networks["thundercore"] = 108] = "thundercore";
    Networks[Networks["thundercoreTestnet"] = 18] = "thundercoreTestnet";
    Networks[Networks["oasis"] = 26863] = "oasis";
    Networks[Networks["celo"] = 42220] = "celo";
    Networks[Networks["godwoken"] = 71402] = "godwoken";
    Networks[Networks["godwokentestnet"] = 71401] = "godwokentestnet";
    Networks[Networks["klatyn"] = 8217] = "klatyn";
    Networks[Networks["milkomeda"] = 2001] = "milkomeda";
    Networks[Networks["kcc"] = 321] = "kcc";
    Networks[Networks["etherlite"] = 111] = "etherlite";
    Networks[Networks["lineaTestnet"] = 59140] = "lineaTestnet";
    Networks[Networks["linea"] = 59144] = "linea";
    Networks[Networks["scrollAlpha"] = 534352] = "scrollAlpha";
    Networks[Networks["zkSyncEra"] = 324] = "zkSyncEra";
    Networks[Networks["zkSyncEraTestnet"] = 280] = "zkSyncEraTestnet";
    Networks[Networks["shibarium"] = 109] = "shibarium";
    Networks[Networks["mantle"] = 5000] = "mantle";
    Networks[Networks["mantleTestnet"] = 5001] = "mantleTestnet";
    Networks[Networks["base"] = 8453] = "base";
    Networks[Networks["baseTestnet"] = 84531] = "baseTestnet";
    Networks[Networks["polygonZkEvm"] = 1101] = "polygonZkEvm";
    Networks[Networks["polygonZkEvmTestnet"] = 1442] = "polygonZkEvmTestnet";
    Networks[Networks["zora"] = 7777777] = "zora";
    Networks[Networks["zoraTestnet"] = 999] = "zoraTestnet";
})(Networks || (Networks = {}));
