import { ReactNode } from 'react';
import classNames from 'classnames';
import { staticStyles } from './styles';

interface SelectOptionProps {
  children: ReactNode;
  onOptionClick?: (selectedId: number) => void;
  disabled?: boolean;
  selectedId?: number;
  className?: string;
}

export function SelectOption({
  onOptionClick,
  selectedId,
  children,
  className,
  disabled,
}: SelectOptionProps) {
  return (
    <>
      <span
        className={classNames('basic-select__option', className, disabled && 'disabled')}
        onClick={() => onOptionClick!(selectedId!)}
      >
        {children}
      </span>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
