import { useCallback, useEffect, useMemo, useState } from 'react';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import { BasicModal } from '../../libs/aave-ui-kit';
import staticStyles from './style';
import DefaultButton from '../basic/DefaultButton';

import PoolTxConfirmationView from '../PoolTxConfirmationView';
import { useTxBuilderContext } from '../../libs/tx-provider';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { REWARD_ADDRESS, TokensSymbolEnum } from '../../shared';
import { SELECT_TOKENS } from './zap.constants';
import { Select } from '../Select/Select';
import { useTokensBalanceData } from '../../store';
import { ZapHelper } from './zap.helper';
import { MathUtils } from '../../utils/math.utils';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { getAtokenInfo } from '../../helpers/get-atoken-info';
import { EligibilityProgress } from '../EmissionsRow/components/EligibilityProgress/EligibilityProgress';
import ButtonTabs from '../basic/ButtonTabs';
import { useBalanceContext } from '../../libs/wallet-balance-provider/BalanceProvider';
import useRewardTokenPrices from '../../store/tokens-price/tokens-price.hooks';
import { Address } from 'viem';
import { useProviderContext } from '../../libs/provider/WalletProvider';
import { useWeb3DataContext } from '../../libs/web3-data-provider';
import { ZapLockAprs } from './components/ZapLockAprs/ZapLockAprs';
import { useInterval, useLockData } from '../../hooks';
import { ReactComponent as BackArrow } from '../../images/icons/back-arrow.svg';
import { BasicBox } from '../BasicBox/BasicBox';
import { FACTORY_ADDRESS, FactoryApi } from '../../apis';
import { FACTORY_ABI } from '../../apis/factory/factory.abi';
import { TokenUtils } from '../../utils';
import { useEligibility } from '../EmissionsRow/components/EligibilityProgress/useEligibility';
import { ethers } from 'ethers';
export interface FieldDataProps {
  value: string;
  symbol: TokensSymbolEnum;
}

enum Steps {
  amount = 'amount',
  lockApr = 'lockApr',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

interface ZapModalProps {
  onHandleClose: () => void;
  isOpen: boolean;
  onlyVestingMode?: boolean;
}

let blockingError = '';

export enum ZapFromEnum {
  Wallet = 'Wallet',
  Vesting = 'Vesting',
}

export enum FilledByEnum {
  Pay = 'Pay',
  Receive = 'Receive',
}

const TABS_OPTIONS: ZapFromEnum[] = [ZapFromEnum.Wallet, ZapFromEnum.Vesting];

export function ZapModal({ isOpen, onHandleClose, onlyVestingMode = false }: ZapModalProps) {
  const { Zap } = useTxBuilderContext();
  const { user } = useDynamicPoolDataContext();
  const { lockIndex } = useLockData();

  const { totalVesting, userTotalLockedBalance, RTTokenInfo } = useBalanceContext();
  const { userBalances, refetchTokensBalanceData } = useTokensBalanceData();
  const { prices, refetchPrices } = useRewardTokenPrices();
  const { provider } = useProviderContext();
  const { currentAccount: address } = useWeb3DataContext();
  const { isEligible } = useEligibility();
  const [step, setStep] = useState<string>(Steps.amount);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [selectedTabFrom, setSelectedTabFrom] = useState<ZapFromEnum>(
    onlyVestingMode ? ZapFromEnum.Vesting : ZapFromEnum.Wallet
  );
  const [exectFrom, setExectFrom] = useState<FilledByEnum>(FilledByEnum.Pay);
  const [exectPrices, setExectPrices] = useState<{
    exectPay: BigNumber;
    exectReceive: BigNumber;
    isLoading: boolean;
  }>({
    exectPay: valueToBigNumber(0),
    exectReceive: valueToBigNumber(0),
    isLoading: false,
  });

  const [dataPay, setDataPay] = useState<FieldDataProps>({
    value: '',
    symbol: TokensSymbolEnum.GAS,
  });
  const [dataReceive, setDataReceive] = useState<FieldDataProps>({
    value: '',
    symbol: TokensSymbolEnum.REWARD,
  });

  const aTokenData = getAtokenInfo({
    address: REWARD_ADDRESS,
    symbol: TokensSymbolEnum.REWARD,
    decimals: 18,
  });

  const requiredLockedLP = useMemo(() => {
    if (user) {
      const requiredInUsd = valueToBigNumber(user.totalLiquidityUSD).times(0.05);

      return requiredInUsd.div(prices.lpTokenPriceUsd);
    } else {
      return valueToBigNumber(0);
    }
  }, [user]);

  const maxToPay = useMemo(
    () => valueToBigNumber(ZapHelper.getBalance(userBalances, dataPay)),
    [userBalances, dataPay]
  );

  const maxToReceive = useMemo(
    () =>
      selectedTabFrom === ZapFromEnum.Wallet
        ? valueToBigNumber(RTTokenInfo.walletBalance)
        : totalVesting,
    [selectedTabFrom, totalVesting, RTTokenInfo]
  );

  const isButtonDisabled = useMemo(() => {
    const isDisabled =
      !Number(dataPay.value) || maxToPay.lt(dataPay.value) || maxToReceive.lt(dataReceive.value);

    return isDisabled && step === Steps.amount;
  }, [dataPay, step, maxToReceive, dataReceive]);

  const isOneMonthDisabled = useMemo(
    () => lockIndex === 0 && selectedTabFrom === ZapFromEnum.Vesting && step === Steps.lockApr,
    [lockIndex, selectedTabFrom, step]
  );

  const updatePayData = useCallback(
    (value: string) => {
      setDataPay((data: FieldDataProps) => ({
        ...data,
        value: value,
      }));
      setDataReceive((data: FieldDataProps) => ({
        ...data,
        value: value.length
          ? exectPrices.exectPay
              .times(value)
              .toFixed(18)
              .replace(/\.?0+$/, '')
          : '',
      }));
    },
    [dataReceive, maxToReceive, exectPrices, selectedTabFrom]
  );

  const handleToPayValue = useCallback(
    (value: string) => {
      if (selectedTabFrom === ZapFromEnum.Vesting) {
        return;
      }

      updatePayData(value);
      setExectFrom(FilledByEnum.Pay);
      fetchData();
    },
    [selectedTabFrom, dataPay]
  );

  const updateReceiveData = useCallback(
    (value: string) => {
      setDataPay((data: FieldDataProps) => ({
        ...data,
        value: value.length
          ? exectPrices.exectReceive
              .times(value)
              .toFixed(18)
              .replace(/\.?0+$/, '')
          : '',
      }));
      setDataReceive((data: FieldDataProps) => ({
        ...data,
        value: value,
      }));
    },
    [dataPay, maxToPay, exectPrices, provider]
  );

  const handleToReceive = useCallback(
    (value: string) => {
      if (selectedTabFrom === ZapFromEnum.Vesting) {
        return;
      }

      updateReceiveData(value);
      setExectFrom(FilledByEnum.Receive);
      fetchData();
    },
    [selectedTabFrom, dataReceive]
  );

  const fetchData = useCallback(async () => {
    if (provider && isOpen) {
      refetchTokensBalanceData();
      refetchPrices();
    }
  }, [provider, address, isOpen]);

  const handleGetTransactions = useCallback(async () => {
    const wethAmount =
      exectFrom === FilledByEnum.Pay
        ? dataPay.value
        : exectPrices.exectReceive.times(dataReceive.value);
    const rewardAmount =
      exectFrom === FilledByEnum.Pay
        ? exectPrices.exectPay.times(dataPay.value)
        : dataReceive.value;

    const basicProps = {
      user: user!.id,
      wethAmount: wethAmount.toString(),
      rewardAmount: rewardAmount.toString(),
      lockDurationIndex: lockIndex,
      from: selectedTabFrom,
      rewardAddress: REWARD_ADDRESS,
    } as any;

    let dataByToken: {
      isWETH: boolean;
      assetAddress: Address;
    } = {} as any;

    const isWETH = dataPay.symbol === TokensSymbolEnum.GAS;

    dataByToken = {
      isWETH,
      assetAddress: isWETH
        ? ethers.constants.AddressZero
        : TokenUtils.getAddressBySymbol(dataPay.symbol),
    };

    return Zap.zap({
      ...basicProps,
      ...dataByToken,
    });
  }, [dataPay, dataReceive, user, selectedTabFrom, lockIndex, provider, exectFrom]);

  const handleSubmit = useCallback(() => {
    setStep(step === Steps.amount ? Steps.lockApr : Steps.confirmation);
  }, [step]);

  const fecthExectPrices = useCallback(async () => {
    if (exectPrices.isLoading) return;

    setExectPrices((data) => ({ ...data, isLoading: true }));

    FactoryApi.connect(FACTORY_ADDRESS, FACTORY_ABI, provider);

    const [estimatedAmountsPay, estimatedAmountsReceive] = await Promise.all([
      FactoryApi.getEstimatedAmounts({
        tokenA: TokenUtils.getAddressBySymbol(TokensSymbolEnum.wIOTA),
        tokenB: TokenUtils.getAddressBySymbol(dataReceive.symbol),
        amountADesired: '1',
        amountBDesired: '0',
      }),
      FactoryApi.getEstimatedAmounts({
        tokenA: TokenUtils.getAddressBySymbol(TokensSymbolEnum.wIOTA),
        tokenB: TokenUtils.getAddressBySymbol(dataReceive.symbol),
        amountADesired: '0',
        amountBDesired: '1',
      }),
    ]);

    setExectPrices({
      exectPay: estimatedAmountsPay.amountB,
      exectReceive: estimatedAmountsReceive.amountA,
      isLoading: false,
    });
  }, [exectPrices, provider]);

  useEffect(() => {
    fecthExectPrices();
  }, []);

  useEffect(() => {
    if (exectFrom === FilledByEnum.Pay) {
      updatePayData(dataPay.value);
    } else {
      updateReceiveData(dataReceive.value);
    }
  }, [exectFrom, exectPrices]);

  useEffect(() => {
    if (selectedTabFrom === ZapFromEnum.Vesting) {
      setExectFrom(FilledByEnum.Receive);
      updateReceiveData(maxToReceive.toString());
    }
  }, [selectedTabFrom, onlyVestingMode, maxToReceive]);

  const handleSelectChange = (selectedId: number) => {
    setSelectedItem(selectedId);
    setDataPay((state) => ({
      ...state,
      symbol: SELECT_TOKENS[selectedId],
    }));
  };

  const handleTabChange = (tab: ZapFromEnum, index: number) => {
    setSelectedTabFrom(tab);
  };

  useInterval(() => {
    if (isOpen) fecthExectPrices();
  }, 3000);

  return (
    <>
      <BasicModal isVisible={isOpen} onBackdropPress={onHandleClose} className="zap-modal">
        <BasicModal.Header className="zap-modal__header">
          {step === Steps.lockApr && (
            <span className="zap-modal__back" onClick={() => setStep(Steps.amount)}>
              <BackArrow /> Back
            </span>
          )}
          Zap into
        </BasicModal.Header>
        <BasicModal.Close onClose={onHandleClose} />

        <BasicBox className="zap-modal__emission">
          <p className="zap-modal__emission-title">
            {isEligible ? (
              <>You’re emissions eligible! 🔥</>
            ) : (
              <>You are not eligible for emissions! &#x1F61E;</>
            )}
          </p>
          <p className="zap-modal__emission-text">
            When the value of your locked wLP is equal to 5% of your total deposit value you will
            become eligible for emissions.
          </p>

          <div className="zap-modal__range">
            <EligibilityProgress className="zap-modal__eligibility" />
          </div>
        </BasicBox>

        {step === Steps.amount ? (
          <div className="zap-modal__fields">
            <BasicAmountField className="zap-modal__field" maxAmount={maxToPay}>
              {(maxAmount) => (
                <>
                  <BasicAmountField.InputBox>
                    <BasicAmountField.Input
                      className="zap-modal__field-input"
                      handleChange={handleToPayValue}
                      value={dataPay.value}
                      max={maxAmount}
                      type="number"
                    />
                    <BasicAmountField.USDValue>
                      {MathUtils.truncateNumber(
                        Number(dataPay.value)
                          ? valueToBigNumber(dataPay.value).times(prices.wethPriceUsd)
                          : valueToBigNumber(0),
                        10
                      )}
                    </BasicAmountField.USDValue>
                  </BasicAmountField.InputBox>
                  <BasicAmountField.TokenBox>
                    <Select
                      selectedId={selectedItem}
                      onOptionChange={handleSelectChange}
                      className="zap-modal__field-select"
                    >
                      <Select.Header>
                        <BasicAmountField.Asset symbol={dataPay.symbol} />
                      </Select.Header>
                      <Select.Dropdown>
                        {SELECT_TOKENS.map((token: string) => (
                          <Select.Item key={token}>
                            <BasicAmountField.Asset symbol={token} />
                          </Select.Item>
                        ))}
                      </Select.Dropdown>
                    </Select>
                    <BasicAmountField.Balance
                      onClick={() => handleToPayValue(maxAmount.toString())}
                    >
                      {maxAmount.toString()}
                    </BasicAmountField.Balance>
                  </BasicAmountField.TokenBox>
                </>
              )}
            </BasicAmountField>

            <BasicAmountField className="zap-modal__field" maxAmount={maxToReceive}>
              {(maxAmount) => (
                <>
                  <BasicAmountField.InputBox>
                    <BasicAmountField.Input
                      className="zap-modal__field-input"
                      handleChange={handleToReceive}
                      value={dataReceive.value}
                      max={maxAmount}
                      type="number"
                    />
                    <BasicAmountField.USDValue className="za-modal__field-usd">
                      {MathUtils.truncateNumber(
                        Number(dataReceive.value)
                          ? valueToBigNumber(dataReceive.value).times(prices.tokenPriceUsd)
                          : valueToBigNumber(0),
                        10
                      )}
                    </BasicAmountField.USDValue>

                    <ButtonTabs
                      className="zap-modal__tabs"
                      tabs={TABS_OPTIONS}
                      setSelectedTab={handleTabChange}
                      selectedTab={selectedTabFrom}
                    />
                  </BasicAmountField.InputBox>
                  <BasicAmountField.TokenBox>
                    <BasicAmountField.Asset symbol={dataReceive.symbol} />
                    <BasicAmountField.Balance onClick={() => handleToReceive(maxAmount.toString())}>
                      {maxAmount.toString()}
                    </BasicAmountField.Balance>
                  </BasicAmountField.TokenBox>
                </>
              )}
            </BasicAmountField>
          </div>
        ) : (
          <ZapLockAprs />
        )}

        <DefaultButton
          size="medium"
          fill
          className="zap-modal__btn-continue"
          onClick={handleSubmit}
          disabled={isButtonDisabled || isOneMonthDisabled}
        >
          Continue
        </DefaultButton>

        <BasicBox className="zap-modal__list">
          <div className="zap-modal__list-item">
            <p>Total value of deposited assets</p>{' '}
            <span>$ {MathUtils.formatNumber(user?.totalLiquidityUSD, 2) || ''}</span>
          </div>
          <div className="zap-modal__list-item">
            <p>Required locked LP value for emissions</p>{' '}
            <span>
              $ {MathUtils.formatNumber(requiredLockedLP.times(prices.lpTokenPriceUsd), 2)}
            </span>
          </div>
          <div className="zap-modal__list-item">
            <p>Currently locked</p> <span>{MathUtils.formatNumber(userTotalLockedBalance, 2)}</span>
          </div>
        </BasicBox>
      </BasicModal>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        {user && (
          <PoolTxConfirmationView
            mainTxName={'Zap'}
            caption={'Zap overview'}
            boxTitle={'Zap'}
            boxDescription={'Please submit to zap'}
            approveDescription={'Please approve before zaping'}
            getTransactionsData={handleGetTransactions}
            blockingError={blockingError}
            aTokenData={aTokenData}
          ></PoolTxConfirmationView>
        )}
      </BasicModal>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
