import { ChainId } from '@aave/contract-helpers';
import { MarketDataType } from '../../helpers/config/types';
import { mainet, iota as iotaConfig } from './tokensConfig';
import { iota } from '../../shared';

export enum CustomMarket {
  mainnet = 'mainnet',
  iota = 'iota',
}

export const marketsData: { mainnet: MarketDataType; iota: any } = {
  iota: {
    chainId: iota.id,
    aTokenPrefix: '',
    enabledFeatures: {
      governance: true,
      staking: true,
      liquiditySwap: true,
      collateralRepay: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESSES_PROVIDER: iotaConfig.LendingPoolAddressesProvider.toLowerCase(),
      LENDING_POOL: iotaConfig.LendingPool,
      REPAY_WITH_COLLATERAL_ADAPTER: iotaConfig.LendingPoolCollateralManager,
      // LEVERAGER: iotaConfig.Leverager,
      STAKING_TOKEN: iotaConfig._OLDETHLP,
      MASTER_CHEF: '-',
      _OLD_ETH_LP_TOKEN: iotaConfig._OLDETHLP,
      _OLD_TOKEN: iotaConfig._OLDToken,
      MULTI_FEE_DISTRIBUTION: iotaConfig.MultiFeeDistribution,
      MULTI_FEE_DISTRIBUTION_V1: iotaConfig.MultiFeeDistribution,
      AAVE_PROTOCOL_DATA_PROVIDER: iotaConfig.AaveProtocolDataProvider,
      CHEF_INCENTIVES_CONTROLLER_V1: iotaConfig.ChefIncentivesController,
      ALL_TOKENS: [...iotaConfig.assets],
      ALL_TOKENS_1: [...iotaConfig.tokens],
    },
  },
  mainnet: {
    chainId: ChainId.mainnet,
    aTokenPrefix: 'U',
    enabledFeatures: {
      governance: true,
      staking: true,
      liquiditySwap: true,
      collateralRepay: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESSES_PROVIDER: mainet.LendingPoolAddressesProvider.toLowerCase(),
      LENDING_POOL: mainet.LendingPool,
      WETH_GATEWAY: mainet.WETHGateway,
      REPAY_WITH_COLLATERAL_ADAPTER: mainet.LendingPoolCollateralManager,
      LEVERAGER: mainet.Leverager,
      STAKING_TOKEN: mainet._OLDETHLP,
      MASTER_CHEF: '-',
      _OLD_ETH_LP_TOKEN: mainet._OLDETHLP,
      _OLD_TOKEN: mainet._OLDToken,
      MULTI_FEE_DISTRIBUTION: mainet.V2.MultiFeeDistribution,
      MULTI_FEE_DISTRIBUTION_V1: mainet.MultiFeeDistribution,
      MULTI_FEE_DISTRIBUTION_V3: mainet.V3.MultiFeeDistribution,
      AAVE_PROTOCOL_DATA_PROVIDER: mainet.AaveProtocolDataProvider,
      CHEF_INCENTIVES_CONTROLLER: mainet.V2.ChefIncentivesController,
      CHEF_INCENTIVES_CONTROLLER_V1: mainet.ChefIncentivesController,
      CHEF_INCENTIVES_CONTROLLER_V3: mainet.V3.ChefIncentivesController,
      ALL_TOKENS: [...mainet.assets],
      ALL_TOKENS_1: [...mainet.tokens],
      NONFUNGIBLE_POSITION_MANAGER: mainet.V3.NonfungiblePositionManager,
    },
  },
};
