import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .Preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: #fff;
    &__dots {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 8px;
    }
  }
`;

export default staticStyles;
