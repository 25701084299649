import { providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { LendingPool } from './typechain/contracts';
import { LendingPool__factory } from './typechain/factories';

export class LendingPoolService extends BaseService<LendingPool> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;

  constructor(provider: providers.Provider, LPContractAddress: string) {
    super(provider, LendingPool__factory);

    this.contractAddress = LPContractAddress;
    this.erc20Service = new ERC20Service(provider);
  }

  public async isDelegateApproved({
    user,
    address,
  }: {
    user: tEthereumAddress;
    address: tEthereumAddress;
  }): Promise<boolean> {
    const LendingPoolContract: LendingPool = this.getContractInstance(this.contractAddress);

    const delegatedAddresses = await LendingPoolContract.withdrawDelegatesOf(user);

    return delegatedAddresses.includes(address);
  }

  public async delegateWithdraw({
    user,
    address,
  }: {
    user: tEthereumAddress;
    address: tEthereumAddress;
  }): Promise<EthereumTransactionTypeExtended> {
    const LendingPoolContract: LendingPool = this.getContractInstance(this.contractAddress);

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: async () => LendingPoolContract.populateTransaction.delegateWithdraw(address),
      from: user,
    });

    return {
      tx: txCallback,
      txType: eEthereumTxType.ERC20_APPROVAL,
      gas: this.generateTxPriceEstimation([], txCallback),
    };
  }
}
