import { ReactNode } from 'react';
import { Range, relativeValue } from 'react-range';

import staticStyles from './style';
import classNames from 'classnames';

type type = 'primary' | 'secondary' | 'neutral';

export type InputBarCallback = { rate: number; min: number; max: number };

interface InputBarBasicProps {
  children?: ReactNode;
  className?: string;
}

interface InputBarDataProps {
  value: number;
  minAmount: number;
  maxAmount: number;
}

interface InputBarProps extends InputBarBasicProps, InputBarDataProps {
  onChange: (amount: number) => void;
  children: ({ rate, min, max }: InputBarCallback) => ReactNode;
  type?: type;
}

interface InputBarRangeProps extends InputBarBasicProps, InputBarDataProps {
  onChange: (amount: number) => void;
  disabled?: boolean;
  type?: type;
}

interface InputBarProgressProps extends InputBarBasicProps {
  rate: number;
}

export default function InputBar({
  children,
  className,
  minAmount,
  maxAmount,
  value,
  type = 'primary',
}: Omit<InputBarProps, 'onChange'>) {
  return (
    <div className={classNames('input-bar', className, `input-bar--${type}`)}>
      {children({
        rate: relativeValue(value, minAmount, maxAmount),
        min: minAmount,
        max: maxAmount,
      })}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}

InputBar.Header = ({ className, children }: InputBarBasicProps) => (
  <div className={classNames('input-bar__header', className)}>{children}</div>
);

InputBar.Footer = ({ className, children }: InputBarBasicProps) => (
  <div className={classNames('input-bar__footer', className)}>{children}</div>
);

InputBar.DraggedContent = ({ className, children, rate }: InputBarProgressProps) => (
  <div className={classNames('input-bar__dragged', className)} style={{ left: `${rate * 100}%` }}>
    {children}
  </div>
);

InputBar.Range = ({
  disabled = false,
  value,
  minAmount,
  maxAmount,
  className,
  children,
  onChange,
}: InputBarRangeProps) => {
  const handleChange = (value: number[]) => {
    onChange(value[0]);
  };

  return (
    <Range
      step={maxAmount / 100}
      min={minAmount}
      disabled={disabled}
      max={maxAmount}
      values={[value]}
      onChange={(values) => handleChange(values)}
      renderTrack={({ props, children: thumb }) => (
        <div className={classNames('input-bar__range', className)} {...props} style={props.style}>
          {children}
          {thumb}
        </div>
      )}
      renderThumb={({ props }) => <div className="input-bar__thumb" {...props} />}
    />
  );
};

InputBar.Progress = ({ className, rate }: InputBarProgressProps) => (
  <div
    className={classNames('input-bar__progress', className)}
    style={{ width: `${rate * 100}%` }}
  />
);
