import { Group } from '@visx/group';
import { Arc, Pie } from '@visx/shape';
import { useTooltipInPortal } from '@visx/tooltip';

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

export type PieProps = {
  width: number;
  height: number;
  slices: Slice[];
  id?: string;
  margin?: typeof defaultMargin;
  centerText?: string;
  shadow?: any;
  disableBackground?: boolean;
  className?: string;
};

export type Slice = { value: number; label: string | number; color: string };

const frequency = (d: Slice) => d.value;

export function EligibilityChartSvg({
  width,
  height,
  margin = defaultMargin,
  slices,
  disableBackground,
  className,
  shadow,
  id,
}: PieProps) {
  const { containerRef } = useTooltipInPortal({
    scroll: true,
  });
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top + 20;
  const left = centerX + margin.left + 20;
  const pieSortValues = (a: number, b: number) => 1;

  return (
    <>
      <svg width={width + 40} height={height + 40} ref={containerRef} className={className}>
        <defs>
          <filter id={id} x="-20%" y="-20%" width="140%" height="140%">
            {shadow}
          </filter>
        </defs>
        <Group top={top} left={left} filter={shadow ? `url(#${id})` : ''}>
          <g key={`arc-bg`}>
            {!disableBackground && (
              <Arc
                outerRadius={radius + radius * 0.04}
                innerRadius={radius * 0.85 - radius * 0.04}
                startAngle={0}
                endAngle={360}
              >
                {({ path }) => <path d={path(null) as any} fill={'#fff'} />}
              </Arc>
            )}
          </g>
          <Pie
            data={slices}
            pieValue={frequency}
            pieSortValues={pieSortValues}
            outerRadius={radius}
            innerRadius={radius * 0.7}
            cornerRadius={20}
            padAngle={0.06}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { label, color: arcFill } = arc.data;
                const arcPath = pie.path(arc);
                return (
                  <g key={`arc-${label}-${index}`}>
                    <path d={arcPath ? arcPath : undefined} fill={arcFill} />
                  </g>
                );
              });
            }}
          </Pie>
        </Group>
      </svg>
    </>
  );
}
