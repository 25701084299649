import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .preloader.preloader-over {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: none;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.6);
    border-radius: 3px;
  }
`;

export default staticStyles;
