import { PropsWithChildren, ReactComponentElement, ReactNode } from 'react';
import classNames from 'classnames';

import { getAssetInfo } from '../../libs/aave-ui-kit';

import { Asset } from '../../libs/aave-ui-kit/helpers/assets-list';
import staticStyles from './style';

export interface TokenBasicProps {
  children: ReactNode | ((asset: Asset, asset2?: Asset) => ReactNode);
  tokenSymbol: string;
  className?: string;
}

export interface TokenImageProps {
  size: 'small' | 'medium' | 'large' | 'extra-large';
  className?: string;
  icon?: string;
  icon2?: string;
  alt?: string;
}
export interface TokenNameProps {
  children?: ReactNode;
  className?: string;
  name?: string;
}

export default function BasicToken({ className, children, tokenSymbol }: TokenBasicProps) {
  const [tokenSymbol1, tokenSymbol2] = tokenSymbol.split('/');
  const asset = getAssetInfo(tokenSymbol1);
  const asset2 = tokenSymbol2 ? getAssetInfo(tokenSymbol2.split(' ')[0]) : undefined;

  return (
    <>
      <div className={classNames('basic-token', className)}>
        {typeof children === 'function' ? children(asset, asset2) : children}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

BasicToken.ImageGroup = ({ className, icon, icon2, size, alt }: TokenImageProps) => {
  return (
    <div className={classNames('basic-token__image-group', size, className)}>
      <BasicToken.Image size={size} icon={icon} alt={alt?.split('/')[0]} />
      <BasicToken.Image size={size} icon={icon2} alt={alt?.split('/')[1]} />
    </div>
  );
};

BasicToken.Image = ({ icon, icon2, className, size, alt }: TokenImageProps) => {
  const isTwoIcons = icon2 !== undefined;

  return isTwoIcons ? (
    <BasicToken.ImageGroup icon={icon} icon2={icon2} size={size} alt={alt} className={className} />
  ) : (
    <img
      className={classNames('basic-token__img', `basic-token__img--${size}`, className)}
      src={icon}
      alt={alt}
    />
  );
};

BasicToken.Name = ({ className, name, children }: TokenNameProps) => {
  return <p className={classNames('basic-token__name', className)}>{children ? children : name}</p>;
};
