import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { useThemeContext, DropdownWrapper } from '../../../libs/aave-ui-kit';

import { useMenuContext } from '../../../libs/menu';
import goToTop from '../../../helpers/goToTop';
import Link from '../../basic/Link';
import AddressInfo from '../AddressInfo';
import { mobileNavigation } from '../navigation';
import { ReactComponent as Twitter } from '../../../images/icons/twitter-icon.svg';
import { ReactComponent as Discord } from '../../../images/icons/discord-icon.svg';
import { ReactComponent as Docs } from '../../../images/icons/docs-icon.svg';
import { ReactComponent as PoweredBy } from '../../../images/powered-by.svg';

import staticStyles from './style';
import { DISCORD_LINK } from '../../../shared';

interface MobileContentProps {
  isActive: (url: string) => boolean;
  currentAccount: string;
}

export default function MobileContent({ isActive }: MobileContentProps) {
  const intl = useIntl();
  const { md } = useThemeContext();
  const { openMobileMenu, closeMobileMenu, mobileMenuVisible, setMobileMenuVisible } =
    useMenuContext();

  useEffect(() => {
    if (mobileMenuVisible && !md) {
      closeMobileMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md]);

  const handleLinkClick = () => {
    goToTop();
    closeMobileMenu();
  };

  const handlers = useSwipeable({
    onSwipedRight: () => closeMobileMenu(),
  });

  return (
    <div {...handlers}>
      <div
        className={classNames('MobileContent__overlay', {
          MobileContent__overlayActive: mobileMenuVisible,
        })}
      />

      <DropdownWrapper
        visible={mobileMenuVisible}
        setVisible={setMobileMenuVisible}
        className="MobileContent"
        contentClassName="MobileContent__content-wrapper"
        contentId="MobileMenuContent"
        buttonComponent={
          <button
            className={classNames('MobileContent__button', {
              MobileContent__buttonActive: mobileMenuVisible,
            })}
            onClick={openMobileMenu}
            type="button"
          >
            <span className="MobileContent__button-box">
              <span className="MobileContent__button-inner" />
            </span>
          </button>
        }
      >
        <div className="MobileContent__content">
          <div className="MobileContent__top">
            <AddressInfo />
          </div>

          <div className="MobileContent__navigation">
            <ul>
              {mobileNavigation.map((link, index) =>
                !link.onClick && link.link !== undefined ? (
                  <li className={classNames('MobileContent__link-wrapper')} key={index}>
                    <Link
                      className={classNames('MobileContent__link', {
                        MobileContent__linkActive: isActive(link.link),
                      })}
                      to={link.link}
                      absolute={link.absolute}
                      inNewWindow={link.absolute}
                      onClick={() => !link.absolute && handleLinkClick()}
                      color="white"
                    >
                      <span>{intl.formatMessage(link.title)}</span>
                    </Link>
                  </li>
                ) : (
                  link.dropdown?.map((item, key) => (
                    <li className="MobileContent__link-wrapper" key={`${index}-${key}`}>
                      <Link
                        className={classNames('MobileContent__link', {
                          MobileContent__linkActive: isActive(item.link),
                        })}
                        to={item.link}
                        onClick={() => !link.absolute && handleLinkClick()}
                        color="white"
                      >
                        <span>{intl.formatMessage(item.title)}</span>
                      </Link>
                    </li>
                  ))
                )
              )}
            </ul>
          </div>
        </div>
        <div className="MobileContent__footer">
          <PoweredBy className="MobileContent__powered-by" />
          <div className="MobileContent__socials">
            <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
              <Discord />
            </a>
            <a href="https://X.com/iolendfi" target="_blank" rel="noreferrer">
              <Twitter />
            </a>
            <a href="https://iolend.gitbook.io/" target="_blank" rel="noreferrer">
              <Docs />
            </a>
          </div>
        </div>
      </DropdownWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
