import { useEffect } from 'react';
import { useChefIncentives } from './chef-incentives.store';

import useRewardTokenPrices from '../tokens-price/tokens-price.hooks';
import { useProviderContext } from '../../libs/provider/WalletProvider';

export function usePoolsInfoData() {
  const { provider } = useProviderContext();
  const [{ data, isLoaded: isPoolsInfoLoaded, isLoading }, fetchPoolsInfoData] = [
    useChefIncentives.use.poolsInfoData(),
    useChefIncentives.use.fetchPoolsInfoData(),
  ];

  const {
    prices: { tokenPriceUsd },
    isLoaded: isPricesLoaded,
    isLoading: isPricesLoading,
  } = useRewardTokenPrices();

  useEffect(() => {
    if (Number(tokenPriceUsd) > -1 && !isPoolsInfoLoaded && provider) {
      fetchPoolsInfoData(tokenPriceUsd, provider);
    }
  }, [tokenPriceUsd, isPoolsInfoLoaded]);

  return {
    poolsInfoData: data,
    isLoaded: isPoolsInfoLoaded && isPricesLoaded,
    isLoading: isPricesLoading && isLoading,
  };
}
