import { Address } from 'viem';

import {
  MARKET_TOKENS,
  NEW_TOKENS,
  REWARD_TOKENS,
  VARIABLE_TOKENS,
} from '../shared/constants/tokens.constants';

export class TokenUtils {
  public static getTokenSymbolByAddress(address: Address): string {
    const tokenData = [...MARKET_TOKENS, ...NEW_TOKENS, ...REWARD_TOKENS, ...VARIABLE_TOKENS].find(
      (token) => token.token.toLowerCase() === address.toLowerCase()
    );

    if (tokenData) {
      return tokenData.symbol;
    } else {
      throw new Error(`token undefined: ${address}`);
    }
  }

  public static getAddressBySymbol(symbol: string): Address {
    const tokenData = [...MARKET_TOKENS, ...NEW_TOKENS, ...REWARD_TOKENS, ...VARIABLE_TOKENS].find(
      (token) => token.symbol.toLowerCase() === symbol.toLowerCase()
    );

    if (tokenData) {
      return tokenData.token;
    } else {
      throw new Error(`token undefined: ${symbol}`);
    }
  }
}
