export const interestRateStrategyAbi = [
  {
    inputs: [
      {
        internalType: 'contract ILendingPoolAddressesProvider',
        name: 'provider',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'optimalUtilizationRate_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'baseVariableBorrowRate_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'variableRateSlope1_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'variableRateSlope2_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'stableRateSlope1_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'stableRateSlope2_',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'EXCESS_UTILIZATION_RATE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'OPTIMAL_UTILIZATION_RATE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addressesProvider',
    outputs: [
      {
        internalType: 'contract ILendingPoolAddressesProvider',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'baseVariableBorrowRate',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'reserve',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'aToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'liquidityAdded',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'liquidityTaken',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalStableDebt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalVariableDebt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'averageStableBorrowRate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'reserveFactor',
        type: 'uint256',
      },
    ],
    name: 'calculateInterestRates',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'reserve',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'availableLiquidity',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalStableDebt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalVariableDebt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'averageStableBorrowRate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'reserveFactor',
        type: 'uint256',
      },
    ],
    name: 'calculateInterestRates',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMaxVariableBorrowRate',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'stableRateSlope1',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'stableRateSlope2',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'variableRateSlope1',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'variableRateSlope2',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
