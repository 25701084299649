export enum TokensSymbolEnum {
  REWARD = 'IOL',
  ETH = 'ETH',
  WETH = 'WETH',
  DAI = 'DAI',
  IOTA = 'IOTA',
  GAS = 'IOTA',
  wIOTA = 'wIOTA',
  USDT = 'USDT',
}
