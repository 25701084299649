import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MarketTable {
    padding: 10px 12px 6px;
    margin-top: 24px;
    background: #fff;
    border: 1px solid #d9d9d9;

    @include respond-to(sm) {
      padding: 0;
    }

    &__sort-button {
      margin: auto;
    }

    &__Header {
      padding: 20px 46px 20px 16px;

      @include respond-to(sm) {
        display: none;
      }
    }

    &__header-button {
      margin: auto;
    }

    &__header-column {
      &:first-of-type {
        .MarketTable__header-button {
          margin-left: 0;
        }
      }
    }

    &__row {
      margin: 8px 0;
      text-align: left;
      cursor: pointer;
      position: relative;
      padding: 12px 46px 12px 16px;

      .Value {
        text-align: left;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      @include respond-to(sm) {
        display: block;
        padding: 6px 12px 16px;
        font-size: 14px;
      }
    }

    &__column {
      &--center {
        text-align: center;
      }

      @include respond-to(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 8px;

        &:nth-of-type(2n) {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }

    & &__card {
      @include respond-to(sm) {
        flex-direction: row;
        align-items: center;

        .LiquidityMiningAPYLine {
          margin: -4px 0 -4px 8px;
        }
      }
    }

    &__column-label {
      display: none;

      @include respond-to(sm) {
        display: block;
      }
    }

    &__amount {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.3);
    }

    &__row-arrow {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      width: 11px;
      height: 18px;

      @include respond-to(sm) {
        transform: none;
        top: 36px;
        right: 30px;
      }
    }
  }
`;

export default staticStyles;
