import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ButtonTabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 3px;

    .DefaultButton {
      border-radius: 0;
      font-size: 16px;
      padding: 4px 16px;
      min-width: 110px;
      font-weight: 400;

      @include respond-to(sm) {
        padding: 4px 8px;
        min-width: auto;
        font-size: 14px;
      }
    }
  }
`;

export default staticStyles;
