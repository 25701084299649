import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .NoDataPanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;

    &__wrapper {
      width: 100%;
      padding: 80px 24px;
      margin: 24px auto;
    }

    &__logo-wrap {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #aaaaaa;
    }

    &__logo {
      max-width: 60%;
      max-height: 60%;
    }

    &__button-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .NoDataPanel {
        display: block;
        min-height: auto;
      }
    }
  }
`;

export default staticStyles;
