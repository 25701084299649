import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import css from 'styled-jsx/css';
import { useThemeContext } from './libs/aave-ui-kit';

import { useStaticPoolDataContext } from './libs/pool-data-provider';
import { useMenuContext } from './libs/menu';
import { CURRENCY_ROUTE_PARAMS } from './helpers/router-types';
import ScreensWrapper from './components/wrappers/ScreensWrapper';

import { Markets, ReserveOverview, Dashboard, Leverage, Deleverage } from './modules';
import IncorrectNetworkAlert from './components/IncorrectNetworkAlert';
import ManageRadiantMain from './modules/manage/screens/ManageRadiantMain';
import bg from './images/bg.png';

const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .App {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
    height: 1px;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      position: relative;
      background: url(${bg}) no-repeat right top;
      background-size: auto 140%;
    }
  }
`;

function ModulesWithMenu() {
  const { isUserHasDeposits } = useStaticPoolDataContext();

  return (
    <ScreensWrapper>
      <Switch>
        <Route path="/markets" component={Markets} />
        <Route path="/dashboard" component={Dashboard} />

        <Route
          exact={true}
          path={`/reserve-overview/${CURRENCY_ROUTE_PARAMS}`}
          component={ReserveOverview}
        />

        <Route path="/manage" component={ManageRadiantMain} key="Manage" />
        {/* <Route path="/leverage" component={Leverage} key="Tools" /> */}
        {/* <Route path="/deleverage" component={Deleverage} key="Tools" /> */}

        <Redirect to={isUserHasDeposits ? '/dashboard' : '/markets'} />
      </Switch>
    </ScreensWrapper>
  );
}

const App: React.FC = () => {
  const { md } = useThemeContext();
  const { openMobileMenu } = useMenuContext();

  const handlers = useSwipeable({
    onSwipedLeft: () => (md ? openMobileMenu() : null),
  });

  return (
    <div className="App">
      <IncorrectNetworkAlert />
      <div {...handlers} className="App__content">
        <Switch>
          <Route component={ModulesWithMenu} />
        </Switch>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
};

export default App;
