import { defineMessages } from 'react-intl';

export default defineMessages({
  markets: 'Markets',
  dashboard: 'My dashboard',
  stake: 'Manage',
  tools: 'Tools',
  leverage: 'Leverage',
  deleverage: 'Deleverage',
  tokenSale: 'Token Sale',
});
