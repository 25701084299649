import css from 'styled-jsx/css';

/*language=SCSS*/
export const staticStyles = css.global`
  .basic-select__option {
    width: 100%;
    cursor: pointer;
    transition: 0.2s;
    padding: 8px;
    width: 140px;
    margin: 1px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &.active {
      background-color: rgba(221, 221, 221, 0.4);
    }

    &.disabled {
      display: none;
    }
  }
`;
