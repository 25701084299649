import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .ReserveInformation-top {
    margin: 24px 0;
    color: #000;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    @include respond-to(md) {
      display: block;
    }

    &__token {
      display: flex;
      align-items: center;
    }

    &__body {
      margin-left: 13px;
    }

    &__token-icon {
      img {
        margin-right: 0;
      }
    }

    &__token-symbol {
      font-size: 17px;
      // text-transform: uppercase;
    }

    &__select-header,
    &__select-header {
      .enabled &:hover,
      .is-opened & {
        background: none;
      }

      .basic-select__header-arrow {
        margin: 0 24 0 44px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__select-dropdown {
      width: 240px;
      left: 0;
    }

    &__select-item {
      width: 100%;
    }

    &__list {
      display: flex;
      margin: 0 0 0 auto;
      gap: 70px;

      @include respond-to(lg) {
        gap: 38px;
        margin: 0 auto;
      }

      @include respond-to(md) {
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 12px;
      }

      .ReserveOverview__badge {
        @include respond-to(sm) {
          flex-basis: calc(50% - 20px);
        }
      }
    }
  }
`;

export default staticStyles;
