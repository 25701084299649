import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { BigNumber } from '@aave/protocol-js';

import { MultiFeeDistributionService } from '../../../../libs/aave-protocol-js/MulteFeeDistributionContract';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import StakeTxConfirmationView from '../StakeTxConfirmationView';
import stakeMessages from '../StakeTxConfirmationView/messages';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import {
  STAKE_ADDRESS,
  TokensSymbolEnum,
  MULTI_FEE_DISTRIBUTION_ADDRESS,
} from '../../../../shared';
import { Address } from 'viem';
import { LockDurationIndex } from '../ManageLock/ManageLock';

interface LockConfirmationProps {
  amount: BigNumber;
  maxAmount: BigNumber;
  userId: Address;
  lockDuration: LockDurationIndex;
  onMainTxConfirmed?: () => void;
  onAfterSuccessClick?: (e: ChangeEvent) => void;
}

function LockConfirmation({
  amount,
  maxAmount,
  userId,
  lockDuration,
  onMainTxConfirmed = () => {},
  onAfterSuccessClick = () => {},
}: LockConfirmationProps) {
  const intl = useIntl();
  const { provider } = useProviderContext();
  const selectedStake = TokensSymbolEnum.REWARD;

  const multiFeeDistributionService = new MultiFeeDistributionService(
    provider,
    STAKE_ADDRESS,
    MULTI_FEE_DISTRIBUTION_ADDRESS
  );
  const handleGetTransactions = () =>
    multiFeeDistributionService.stake(userId, amount.toString(), lockDuration);

  let blockingError = '';
  if (amount.gt(maxAmount)) {
    blockingError = intl.formatMessage(stakeMessages.notEnoughBalance, {
      asset: selectedStake.toUpperCase(),
    });
  }

  return (
    <StakeTxConfirmationView
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(defaultMessages.lock, { asset: selectedStake.toUpperCase() })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(defaultMessages.lock, { asset: selectedStake.toUpperCase() })}
      mainTxType="LOCK_ACTION"
      blockingError={blockingError}
      onMainTxConfirmed={onMainTxConfirmed}
      onAfterSuccessClick={onAfterSuccessClick}
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      buttonTitle={intl.formatMessage(messages.buttonTitle)}
    >
      <Row title={`Amount`}>
        <Value
          symbol={`${TokensSymbolEnum.REWARD}-${TokensSymbolEnum.wIOTA} LP`}
          value={amount.isNaN() ? '0' : amount.toString()}
          tokenIcon={true}
          tooltipId={selectedStake.toUpperCase()}
        />
      </Row>
    </StakeTxConfirmationView>
  );
}

export default LockConfirmation;
