import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
  gql,
} from '@apollo/client';
import { GRAPH_API } from '../../shared';

import { TheGraphResponse } from './the-graph.model';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const GRAPH_CLIENT: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: GRAPH_API,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export class TheGraphApi {
  public static async getGraphData(
    client: ApolloClient<NormalizedCacheObject>,
    query: string
  ): Promise<TheGraphResponse<any>> {
    try {
      return await client.query({
        query: gql(query),
      });
    } catch (error) {
      console.error(error);

      throw new Error('graph error', error);
    }
  }
}
