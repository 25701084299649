import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .reserve-graphic {
    width: 100%;
    height: 400px;

    @include respond-to(sm) {
      width: 100%;
      height: 300;
    }

    @include respond-to(xs) {
      width: 100%;
      height: 240;
    }
  }

  #react-charts-portal table {
    color: #fff;
    font-size: 12px;
  }
`;

export default staticStyles;
