import { TheGraphResponse } from '../../apis/the-graph';
import { TheGraphApi, GRAPH_CLIENT } from '../../apis/the-graph/the-graph.api';

import { GraphPoolInfoDataModel } from './chief-incentives.model';
import { PoolInfoQuery } from './chief-incentives.queries';

export class ChiefIncentivesGraphApi {
  public static getPoolsInfoData(): Promise<GraphPoolInfoDataModel[]> {
    return TheGraphApi.getGraphData(GRAPH_CLIENT, PoolInfoQuery)
      .then(
        (
          response: TheGraphResponse<{
            poolInfos: GraphPoolInfoDataModel[];
          }>
        ) => response.data.poolInfos
      )
      .catch(() => {
        throw new Error('Graph Projective Fees load failed');
      });
  }
}
