import { ContractCallReturnContext, Multicall } from '../libs/ethereum-multicall';
import { Provider } from '@ethersproject/providers';

export class MulticallUtils {
  public static convertData(data: ContractCallReturnContext) {
    let result: any = {};

    for (let item of data.callsReturnContext) {
      result[item.reference] =
        item.returnValues.length > 1 ? item.returnValues : item.returnValues[0];
    }

    return result;
  }

  public static createMulltical(provider: Provider) {
    return new Multicall({
      ethersProvider: provider,
      tryAggregate: true,
    });
  }
}
