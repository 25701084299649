const dataPerToken = `
  id
  rate
  price
  aTokenAddress
  underlyingAsset
  decimals
`;

export const rewardsDataQuery = `{
  rewardDatas {
    balance
    id
    lastUpdateTime
    periodFinish
    rewardPerTokenStored
    rewardRate
    underlyingAsset
    price
    decimals
  }
}`;

export const dayDataQuery = `{
  dailyDatas(first: 1000) {
    id
    dataPerToken {
      ${dataPerToken}
    }
  }
}`;

export const totalFeeDataQuery = `{
  totalFeeData(id: "total-fee-data") {
    id
    timestamp
    totalFee
    currentDay
  }
}`;
