import staticStyles from './style';
import Preloader from '../Preloader';
import classNames from 'classnames';

interface PreloaderProps {
  isShown: boolean;
  className?: string;
}

export default function PreloaderOver({ isShown, className }: PreloaderProps) {
  return (
    <>
      {isShown ? <Preloader className={classNames('preloader-over', className)} /> : null}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
