import { UiPoolDataProvider } from '@aave/contract-helpers';
import { PoolsGraphApi } from '../../graphs';
import { createSelectors } from '../../store/createSelectors';
import { create } from '../../libs/zustand';
import { providers } from 'ethers';
import { PoolsDataType, StorePoolDataModel } from './pool-data.model';
import { LENDING_POOL_ADDRESSES_PROVIDER, UI_POOL_DATA_ADDRESS } from '../../shared';

export const usePoolDataStore = createSelectors(
  create<StorePoolDataModel>((set, get) => ({
    poolData: [],
    currencyData: null,
    isPoolDataLoading: false,
    fetchPoolData: async (provider) => {
      if (get().isPoolDataLoading) {
        return;
      }

      set((state) => ({ ...state, isPoolDataLoading: true }));

      let poolsData: PoolsDataType = {} as PoolsDataType;

      try {
        const poolDataProviderContract = new UiPoolDataProvider({
          uiPoolDataProviderAddress: UI_POOL_DATA_ADDRESS,
          provider: provider as providers.Provider,
        });

        // const user = await poolDataProviderContract.getUserReservesData(
        //   LENDING_POOL_ADDRESSES_PROVIDER,
        //   '0x837d047a564862C0235F7178fB51406916A03915'
        // );

        // console.log(user);

        // console.log('/// reservesResponse ///');
        // console.log(reservesResponse);

        const reservesResponse = await poolDataProviderContract.getReservesHumanized(
          LENDING_POOL_ADDRESSES_PROVIDER
        );

        poolsData = {
          pools: reservesResponse.reservesData,
          currencies: reservesResponse.baseCurrencyData,
        };
      } catch (error) {
        const [pools, currencies] = await Promise.all([
          PoolsGraphApi.getPoolData(),
          PoolsGraphApi.getCurrencyData(),
        ]);

        console.log(error);

        poolsData = { pools, currencies };
      }

      set((state) => ({
        ...state,
        poolData: poolsData.pools,
        currencyData: poolsData.currencies,
        isPoolDataLoading: false,
      }));
    },
  }))
);
