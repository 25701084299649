import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ConnectWalletModal {
    &__content {
      width: 100%;
      display: flex;
      position: relative;
      justify-content: center;
      z-index: 3;
      @include respond-to(xl) {
      }
      @include respond-to(sm) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__privacy-inner {
      text-align: center;
      max-width: 800px;
      position: relative;
      z-index: 3;

      p {
        margin-top: 16px;
      }

      a {
        text-decoration: underline;
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__disclaimer {
      font-weight: 400;
      font-size: 12px;
    }

    .ConnectWalletModal__warningArea {
      max-width: 800px;
      margin-top: 20px;
      position: relative;
      z-index: 3;
      @include respond-to(xl) {
      }
      @include respond-to(md) {
      }
      @include respond-to(sm) {
        display: none;
      }
      .WarningAreaTopLine {
        justify-content: center;
      }
    }

    .ConnectWalletModal__LedgerChecklist-mobile {
      display: none;
      @include respond-to(sm) {
        display: block;
        max-width: 330px;
      }
    }
  }
`;

export default staticStyles;
