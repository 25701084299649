import { useState } from 'react';
import { useIntl } from 'react-intl';
import { BasicModal, getAssetInfo, isAssetStable } from '../../libs/aave-ui-kit';
import { InterestRate, valueToBigNumber } from '@aave/protocol-js';
import { UserSummary, ComputedReserveData } from '../../libs/pool-data-provider';
import { ComputedUserReserve } from '@aave/math-utils';
import { useTxBuilderContext } from '../../libs/tx-provider';
import messages from './messages';
import PoolTxConfirmationView from '../PoolTxConfirmationView';
import Row from '../basic/Row';
import Value from '../basic/Value';
import HealthFactor from '../HealthFactor';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import { useRepayData } from './useRepayData';
import DefaultButton from '../basic/DefaultButton';
import NotHaveEnoughFundsToRepayHelpModal from '../HelpModal/NotHaveEnoughFundsToRepayHelpModal';
import { MathUtils } from '../../utils/math.utils';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

interface BorrowAmountProps {
  poolReserve: ComputedReserveData;
  symbol: string;
  user: UserSummary;
  userReserve: ComputedUserReserve;
}

export function RepayWidget({ userReserve, poolReserve, user, symbol }: BorrowAmountProps) {
  const intl = useIntl();
  const [isTxExecuted, setIsTxExecuted] = useState(false);
  const [step, setStep] = useState<string>(Steps.amount);
  const [fieldValue, setFieldValue] = useState<string>('');
  const { lendingPool } = useTxBuilderContext();

  const assetDetails = getAssetInfo(poolReserve.symbol);

  const {
    amountToRepay,
    maxAmountToRepay,
    walletBalance,
    amountToRepayUI,
    healthFactorAfterRepay,
    displayAmountAfterRepayInUsd,
    safeAmountToRepayAll,
    displayAmountToRepay,
    displayAmountToRepayInUsd,
    displayAmountAfterRepay,
  } = useRepayData({
    user,
    poolReserve,
    userReserve,
    fieldValue: Number(fieldValue) ? fieldValue : '0',
  });

  const handleConfirmGetTransactions = async () =>
    await lendingPool.repay({
      user: user.id,
      reserve: poolReserve.underlyingAsset,
      amount: amountToRepay,
      interestRateMode: InterestRate.Variable,
    });

  const handleMainTxExecuted = () => setIsTxExecuted(true);

  const handleAmountChange = (value: string) => {
    setFieldValue(value);
  };

  const handleSetAmountSubmit = (amount: string) => {
    setStep(Steps.confirmation);
  };

  const blockingError =
    walletBalance.eq('0') || walletBalance.lt(amountToRepay)
      ? intl.formatMessage(messages.error, {
          userReserveSymbol: assetDetails.formattedSymbol || assetDetails.symbol,
        })
      : '';

  const warningMessage =
    valueToBigNumber(fieldValue).eq('-1') &&
    amountToRepayUI.gte(maxAmountToRepay) &&
    !amountToRepayUI.gte(safeAmountToRepayAll)
      ? 'Mind that due to the continuous accumulation of interest, a small amount could be remaining, as your wallet balance is just above the current amount pending to repay'
      : '';

  const isNotHaveEnoughFunds = amountToRepay === '-1' && walletBalance.lt(maxAmountToRepay);

  return (
    <>
      <BasicAmountField
        className="reserve-widget__field vest-widget__field"
        maxAmount={maxAmountToRepay}
      >
        {(maxAmount) => (
          <>
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                handleChange={handleAmountChange}
                value={fieldValue}
                max={maxAmount}
              />
              <BasicAmountField.USDValue>
                {MathUtils.truncateNumber(
                  valueToBigNumber(fieldValue).times(poolReserve.priceInMarketReferenceCurrency),
                  10
                )}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              <BasicAmountField.Asset symbol={symbol} />
              <BasicAmountField.Balance
                onClick={() => handleAmountChange(walletBalance.toString())}
              >
                {walletBalance.toString()}
              </BasicAmountField.Balance>
            </BasicAmountField.TokenBox>
          </>
        )}
      </BasicAmountField>

      <DefaultButton
        fill
        className="reserve-widget__button"
        onClick={handleSetAmountSubmit}
        disabled={Number(amountToRepay) <= 0}
      >
        Continue
      </DefaultButton>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        <PoolTxConfirmationView
          mainTxName={'Repay'}
          caption={'Repay overview'}
          boxTitle={'Repay'}
          boxDescription={'Please submit to repay'}
          approveDescription={'Please approve before repaying'}
          getTransactionsData={handleConfirmGetTransactions}
          onMainTxExecuted={handleMainTxExecuted}
          blockingError={blockingError}
          goToAfterSuccess="/dashboard/borrowings"
          warningMessage={warningMessage}
        >
          <Row title={'Amount to repay'} withMargin={true}>
            <Value
              symbol={symbol}
              value={displayAmountToRepay.toString()}
              tokenIcon={true}
              subValue={displayAmountToRepayInUsd.toString()}
              subSymbol="USD"
              maximumValueDecimals={isAssetStable(symbol) ? 4 : 12}
              maximumSubValueDecimals={4}
              updateCondition={isTxExecuted}
              tooltipId={poolReserve.underlyingAsset}
            />
          </Row>

          <Row
            title={'Remaining to repay'}
            subTitle={
              isNotHaveEnoughFunds && (
                <NotHaveEnoughFundsToRepayHelpModal
                  text={'You don’t have enough funds to repay the full amount'}
                />
              )
            }
            withMargin={true}
          >
            <Value
              symbol={symbol}
              value={Number(displayAmountAfterRepay) > 0 ? Number(displayAmountAfterRepay) : 0}
              subValue={
                Number(displayAmountAfterRepayInUsd) > 0 ? Number(displayAmountAfterRepayInUsd) : 0
              }
              subSymbol="USD"
              maximumSubValueDecimals={4}
              tokenIcon={true}
              maximumValueDecimals={isNotHaveEnoughFunds ? 14 : isAssetStable(symbol) ? 4 : 12}
              updateCondition={isTxExecuted}
              tooltipId={poolReserve.id}
              withSmallDecimals={isNotHaveEnoughFunds}
              isSmallValueCenterEllipsis={isNotHaveEnoughFunds}
            />
          </Row>

          <Row title={'Current Health Factor'}>
            <HealthFactor
              title={'Current Health Factor'}
              value={user.healthFactor}
              updateCondition={isTxExecuted}
              titleColor="dark"
            />
          </Row>

          <Row title={'Next Health Factor'}>
            <HealthFactor
              value={healthFactorAfterRepay.toString()}
              withoutModal={true}
              updateCondition={isTxExecuted}
              titleColor="dark"
            />
          </Row>
        </PoolTxConfirmationView>
      </BasicModal>
    </>
  );
}
