import { DepositTableItem } from '../../../../shared';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import staticStyles from './style';
import { MathUtils } from '../../../../utils/math.utils';
import { useUserPositions } from '../../../../hooks';

export function ReserveDepositsList() {
  const { depositedPositions } = useUserPositions();

  return (
    <>
      <ul className="reserve-deposits">
        {depositedPositions.map((item: DepositTableItem, index) => (
          <li key={index} className="reserve-deposits__item">
            <BasicToken tokenSymbol={item.reserve.symbol}>
              {(asset) => (
                <>
                  <BasicToken.Image icon={asset.icon} size="medium" />
                  <BasicToken.Name name={asset.symbol} />
                </>
              )}
            </BasicToken>

            <div className="reserve-deposits__balance">
              <p>{MathUtils.truncateNumber(item.underlyingBalance, 2)}</p>
              <span>
                ${' '}
                {Number(item.underlyingBalanceUSD) >= 0.01
                  ? MathUtils.truncateNumber(item.underlyingBalanceUSD, 2)
                  : '< 0.01'}
              </span>
            </div>
          </li>
        ))}
      </ul>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
