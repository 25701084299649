import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .m-lock {
    width: 100%;
    color: #000;
    position: relative;
    margin: auto;

    &__title {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    &__get-lp {
      display: flex;
      justify-content: flex-end;
      margin-top: -8px;
    }

    &__field {
      margin-top: 8px;
    }

    &__lock-btn {
      margin-top: 12px;
      width: 100%;
    }

    &__default {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__default-tabs {
      margin-top: 14px;

      @include respond-to(sm) {
        margin-top: 8px;
      }

      @include respond-to(xs) {
        margin-top: 4px;
      }
    }

    &__default-tabs-list {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      @include respond-to(xs) {
        justify-content: space-around;
      }
    }

    &__default-tabs-item {
      margin-top: 10px;
    }

    &__default-tabs-btn {
      font-size: 16px;
      padding: 4px 16px;
      min-width: 110px;
      font-weight: 400;

      @include respond-to(xs) {
        font-size: 12px;
      }
    }

    &__apr {
      text-align: center;
      margin-top: 12px;

      @include respond-to(xs) {
        margin-top: 6px;
      }
    }

    &__apr-label {
      font-size: 12px;
      padding: 5px 8px 4px;
      text-transform: uppercase;
      border-radius: 15px;
      min-width: 85px;
      text-align: center;
      display: inline-block;
      background: rgba(0, 0, 0, 0.08);
      color: #000000;
      font-weight: 400;

      &--highlight {
        color: #52679d;
      }
    }

    &__relock-switcher {
      margin: 0 0 auto auto;
    }

    &__relock-btns {
      display: flex;
      gap: 12px;

      @include respond-to(xs) {
        flex-direction: column;
      }
    }

    &__relock-btn {
      min-width: 105px;
    }

    &__unlock {
      font-size: 16px;
      color: #000;
      padding: 4px 16px;

      @include respond-to(xs) {
        padding: 4px 12px;
      }
    }

    &__unlock-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;

      @include respond-to(xs) {
        padding: 8px 0;
      }
    }

    &__unlock-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    &__unlock-token {
      display: flex;
      align-items: center;
    }

    &__unlock-sub {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      margin-right: 4px;

      @include respond-to(xs) {
        font-size: 12px;
        margin-right: 0;
      }
    }

    &__unlock-value {
      font-size: 16px;
      font-weight: 600;
      margin-right: 8px;

      @include respond-to(xs) {
        font-size: 14px;
      }
    }
  }
`;

export default staticStyles;
