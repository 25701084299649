import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
  .ReactModal__Overlay {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0) !important;
    position: relative;
    z-index: 99;
    backdrop-filter: blur(1.4rem);
    padding: 16px;
    overflow: auto;

    &--after-open {
      animation: fadeIn 0.3s forwards;
    }

    &--before-close {
      animation: fadeOut 0.3s forwards;
    }
  }
  .basic-modal {
    color: #000;
    margin: auto;
    position: relative;
    border-radius: 12px;
    padding: 24px 26px;
    outline: none;
    min-width: 320px;
    max-width: 767px;
    border: 1px solid;

    @include respond-to(sm) {
      min-width: auto;
      padding: 18px 20px;
    }

    @include respond-to(xs) {
      width: 100%;
      padding: 18px 12px;
    }

    border-image-source: linear-gradient(
      119.09deg,
      rgba(58, 205, 237, 0.02) 1.2%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: -2px -2px 16.8px 0px rgba(255, 255, 255, 0), 8px 8px 34.3px 0px rgba(0, 0, 0, 0.58);

    &--enter {
      animation: fadeIn 0.3s forwards, slideIn 0.3s forwards;
    }
    &--exit {
      animation: fadeOut 0.3s forwards, slideOut 0.3s forwards;
    }
    &__header {
      font-size: 23px;
      line-height: 1.3;
      font-weight: 600;
      text-align: center;
      margin: 0 0 24px;

      @include respond-to(sm) {
        font-size: 20px;
        margin-bottom: 18px;
      }

      @include respond-to(sm) {
        font-size: 18px;
      }
    }
    &__close-btn {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      position: absolute;
      right: 20px;
      top: 22px;
      color: #000;
      @include respond-to(sm) {
        right: 5px;
        top: 5px;
      }
      &:hover {
        background-color: rgba(221, 221, 221, 0.3);
      }
      svg {
        width: 14px;
        height: 14px;
        margin: auto;
      }
    }

    &__content {
      margin-top: 24px;
    }

    &__footer {
      margin-top: 24px;
      display: flex;
      gap: 12px;
      justify-content: center;
    }
  }
`;

export default staticStyles;
