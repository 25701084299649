import staticStyles from './style';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { useMemo } from 'react';
import { MathUtils } from '../../../../utils/math.utils';
import classNames from 'classnames';
import { EligibilityChart } from '../../../compositionBars/EligibilityChart/EligibilityChart';
import { useEligibility } from './useEligibility';

export function EligibilityProgressChart({ className }: { className?: string }) {
  const { userTotalLockedBalance } = useBalanceContext();
  const {
    prices: { lpTokenPriceUsd },
  } = useRewardTokenPrices();
  const { user } = useDynamicPoolDataContext();
  const { eligibilityPercent } = useEligibility();

  const lockedInUsd = useMemo(
    () => userTotalLockedBalance.times(lpTokenPriceUsd),
    [userTotalLockedBalance, lpTokenPriceUsd]
  );

  return (
    <>
      <div className={classNames('eligibility-chart', className)}>
        <EligibilityChart {...{ eligibilityPercent }} />
        <div className="eligibility-chart__body">
          <div className="emissions__badge emissions__badge-deposits">
            <p className="emissions__badge-name">Deposits</p>
            <p className="emissions__badge-value ff-lg">
              $ {MathUtils.formatNumber(user?.totalLiquidityUSD, 2)}
            </p>
          </div>

          <div className="emissions__badge  emissions__badge-locked">
            <p className="emissions__badge-name">Locked</p>
            <p className="emissions__badge-value ff-lg">
              $ {!lockedInUsd.isNegative() ? MathUtils.formatNumber(lockedInUsd, 2) : 0}
            </p>
          </div>
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
