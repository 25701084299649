import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AddressInfo {
    margin-left: 10px;
    color: #000;
    @include respond-to(sm) {
      margin-left: auto;
    }

    &__content {
      width: 200px;
      padding: 4px 12px 0;
      background: #fff;
      border-radius: 4px;
      @include respond-to(sm) {
        width: 260px;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 4px 0;
    }

    &__title {
      font-size: 11px;
      margin-right: auto;
      opacity: 0.6;
    }

    &__group-btns {
      display: flex;
      gap: 10px;
    }

    &__icon-btn {
      border-radius: 20px;
      transition: 0.3s;
      color: #000;
      background-color: rgba(221, 221, 221, 0.3);
      width: 20px;
      height: 20px;

      &:hover {
        background-color: rgba(221, 221, 221, 0.5);
      }

      &:active {
        background-color: rgba(221, 221, 221, 0.2);
        opacity: 0.8;
      }
    }

    &__icon {
      width: 100%;

      &--checked {
        width: 14px;
      }
    }

    &__select {
      display: inline-flex;
    }

    &__select-head {
      padding: 0 8px 0 0;
      border-radius: 0;
      display: inline-flex;

      .enabled &:hover,
      .is-opened & {
        background: none;
      }
    }

    &__select-option {
      padding: 0;
      width: auto;
    }

    &__selec-dropdown {
      border-radius: 4px;
      box-shadow: 8px 8px 13px rgba(0, 0, 0, 0.3), -2px -2px 16px rgba(255, 255, 255, 0.06);
      background: #16151b;
      padding: 2px;
    }

    &__row {
      margin: 12px -12px 0;
      display: flex;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding: 10px 12px;
      font-size: 11px;
    }

    &__row-label {
      margin-right: auto;
      opacity: 0.6;
    }

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: right;
      min-width: 110px;
      padding: 5px;
      height: 32px;
      font-size: $extraSmall;
      transition: $transition;

      @include respond-to(sm) {
        width: 110px;
        height: 54px;
        font-size: $small;
        align-items: center;
      }

      p {
        margin-bottom: 2px;
        text-transform: capitalize;
        white-space: nowrap;
        @include respond-to(sm) {
          margin-bottom: 5px;
        }
      }

      span {
        opacity: 0.5;
      }
    }

    &__address {
      font-size: 13px;
      padding: 4px 8px;
      border-radius: 3px;
      background-color: rgba(221, 221, 221, 0.3);

      &--single {
        padding: 4px 8px;
        display: inline-flex;
        margin: 0 0 4px;
      }
    }

    &__ens {
      margin: 0 0;
      opacity: 0.6;
      font-size: 12px;
    }

    &__content-network {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: inline-flex;
        text-transform: capitalize;
      }
      i {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 6px;
        position: relative;
        bottom: 1px;
        @include respond-to(sm) {
          margin-right: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }

    &__contentButton {
      width: 100%;
      margin-top: 24px;
    }
  }
`;

export default staticStyles;
