import classNames from 'classnames';
import { ReactNode } from 'react';

interface BoxProps {
  children: ReactNode;
  className?: string;
  isHidden?: boolean;
}
interface BoxWrapperProps extends BoxProps {
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
}

export function BasicBox({ children, className, variant = 'primary', onClick }: BoxWrapperProps) {
  return (
    <div
      className={classNames('basic-box', `basic-box--${variant}`, className)}
      onClick={() => onClick && onClick()}
    >
      {children}
      <style jsx={true} global={true}>
        {`
          @import 'src/_mixins/screen-size';
          .basic-box {
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            padding: 24px;
            flex: 1;
            background: #fff;

            @include respond-to(sm) {
              padding: 18px 14px;
            }

            &--primary {
            }

            &--secondary {
            }
          }
        `}
      </style>
    </div>
  );
}

BasicBox.Header = ({ children, className }: BoxProps) => {
  return (
    <div className={classNames('basic-box__header', className)}>
      {children}
      <style jsx={true} global={true}>
        {`
          .basic-box__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        `}
      </style>
    </div>
  );
};

BasicBox.Title = ({ children, className }: BoxProps) => {
  return (
    <div className={classNames('basic-box__title ff-lg', className)}>
      {children}
      <style jsx={true} global={true}>
        {`
          @import 'src/_mixins/screen-size';

          .basic-box__title {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;

            @include respond-to(sm) {
              font-size: 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

BasicBox.Body = ({ children, className, isHidden }: BoxProps) => {
  return !isHidden ? (
    <div className={classNames('basic-box__body', className)}>
      {children}
      <style jsx={true} global={true}>
        {`
          @import 'src/_mixins/vars';
          @import 'src/_mixins/screen-size';

          .basic-box__body {
            margin-top: 32px;

            @include respond-to(sm) {
              margin-top: 24px;
            }

            @include respond-to(xs) {
              margin-top: 16px;
            }
          }
        `}
      </style>
    </div>
  ) : null;
};
