import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .WalletCard {
    position: relative;
    margin: 5px;
    color: inherit;

    &__error {
      font-size: $extraSmall;
      font-weight: 400;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 0;
    }

    &__button {
      width: 150px;
      min-height: 108px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      transition-property: border, background;
      transition-duration: 0.3s;
      border: 1px solid rgba(221, 221, 221, 0.5);
      padding: 16px;
      border-radius: 12px;
      color: inherit;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
        background-color: rgba(221, 221, 221, 0.2);
      }
    }

    &__image-inner {
      width: 40px;
      height: 40px;
      @include respond-to(lg) {
      }
      @include respond-to(md) {
      }
      img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: $transition;
        @include respond-to(md) {
        }
      }
    }

    &__text-inner {
      text-align: center;
      p {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        margin-top: 6px;
        @include respond-to(lg) {
        }
        @include respond-to(md) {
        }
      }
      @include respond-to(md) {
        width: 100%;
      }
      span {
        display: inline-block;
        font-weight: 300;
        font-size: 8px;
        @include respond-to(md) {
        }
      }
    }
  }
`;

// @ts-ignore
export default staticStyles;
