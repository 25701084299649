import { FormEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, EthereumTransactionTypeExtended } from '@aave/protocol-js';

import { useWeb3DataContext } from '../../../libs/web3-data-provider';
import Caption from '../../basic/Caption';
import DefaultButton from '../../basic/DefaultButton';
import ConnectButton from '../../ConnectButton';
import messages from './messages';
import staticStyles from './style';
import { ValidationWrapperComponentProps } from '../../RouteParamsValidationWrapper';
import { BigNumber } from '@aave/protocol-js';
import { BasicAmountField } from '../../BasicAmountField/BasicAmountField';

interface BasicFormProps extends Pick<ValidationWrapperComponentProps, 'poolReserve'> {
  title?: string;
  description?: string | ReactNode;
  maxAmount: string;
  amountFieldTitle?: string;
  currencySymbol: string;
  onSubmit: (amount: string, max?: boolean) => void;
  withRiskBar?: boolean;
  submitButtonTitle?: string;
  absoluteMaximum?: boolean;
  className?: string;
  maxDecimals?: number;
  warning?: ReactNode;
  children?: ReactNode;
  isDeposit?: boolean;
  isRepay?: boolean;
  userTokensBalance?: BigNumber;
  getTransactionData?: (
    user: string
  ) => () => Promise<EthereumTransactionTypeExtended[]> | EthereumTransactionTypeExtended[];
  balanceTitle?: string;
  maxButtonValue?: BigNumber;
}

export default function BasicForm({
  poolReserve,
  title,
  description,
  maxAmount,
  currencySymbol,
  onSubmit,
  submitButtonTitle,
  absoluteMaximum,
  className,
  warning,
  children,
  isRepay,
  userTokensBalance,
  maxButtonValue = valueToBigNumber(maxAmount),
}: BasicFormProps) {
  const intl = useIntl();
  const { currentAccount } = useWeb3DataContext();

  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const [amount, setAmount] = useState('');

  const handleAmountChange = (newAmount: string) => {
    const newAmountValue = valueToBigNumber(newAmount);
    if (newAmountValue.gte(maxButtonValue)) {
      setAmount(newAmount);
      return setIsMaxSelected(true);
    } else if (newAmountValue.isNegative()) {
      setAmount('0');
    } else {
      setAmount(newAmount);
    }
    setIsMaxSelected(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!valueToBigNumber(amount).isNaN() && +amount !== 0) {
      return onSubmit(amount, absoluteMaximum && isMaxSelected);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classNames('BasicForm', className)}>
      {!!title && <Caption title={title} description={description} />}

      <div className="BasicForm__inner">
        {children}
        <BasicAmountField maxAmount={maxButtonValue}>
          {(max) => (
            <>
              <BasicAmountField.InputBox>
                <BasicAmountField.Input
                  handleChange={handleAmountChange}
                  value={amount}
                  type="number"
                />
                <BasicAmountField.USDValue>
                  {Number(amount) * Number(poolReserve.priceInMarketReferenceCurrency)}
                </BasicAmountField.USDValue>
              </BasicAmountField.InputBox>
              <BasicAmountField.TokenBox>
                <BasicAmountField.Asset symbol={currencySymbol} />
                <BasicAmountField.Balance
                  label={isRepay ? 'To repay:' : undefined}
                  onClick={() => handleAmountChange(max.toFixed())}
                >
                  {max.toFixed()}
                </BasicAmountField.Balance>
              </BasicAmountField.TokenBox>
            </>
          )}
        </BasicAmountField>

        {isRepay && (
          <BasicAmountField.Balance className="BasicForm__wallet-balance">
            {userTokensBalance?.toString()}
          </BasicAmountField.Balance>
        )}

        {/* {isRepay && (
          <div className="BasicForm__deposit-block" style={{ color: 'white' }}>
            <div className="BasicForm__deposit-block__column">
              <div className="BasicForm__deposit-block__column-title">Amount</div>
              <div className="BasicForm__deposit-block__column-value">
                <img
                  className="TokenIcon__image"
                  src={asset.icon}
                  alt={currencySymbol}
                  height={24}
                  width={24}
                />
                {amount} {currencySymbol.toUpperCase()}
              </div>
            </div>
            <div className="BasicForm__deposit-block__column">
              <div className="BasicForm__deposit-block__column-title">Collateral Usage</div>
              <div className="BasicForm__deposit-block__column-value BasicForm__deposit-block__column-collateral">
                Yes
              </div>
            </div>
          </div>
        )} */}

        {/* {[ChainId.mainnet].includes(chainId) && getTransactionData && (
         <TxEstimation getTransactionsData={getTransactionData} amount={amount} />
         )} */}

        {!!warning && <div className="BasicForm__warning">{warning}</div>}
      </div>

      <div className="BasicForm__buttons">
        {!currentAccount ? (
          <ConnectButton />
        ) : (
          <DefaultButton
            title={submitButtonTitle || intl.formatMessage(messages.continue)}
            type="submit"
            className="BasicForm__button"
            disabled={maxButtonValue?.lt(amount) || Number(amount) === 0}
          />
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </form>
  );
}
