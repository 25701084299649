import { DAYS_IN_YEAR, HOURS_IN_DAY, MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from '../shared';

export class DateUtils {
  public static getYearSeconds(): number {
    const secondsInYear = DAYS_IN_YEAR * DateUtils.getDaySeconds();

    return secondsInYear;
  }
  public static getDaySeconds(): number {
    const secondsInDay = HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;

    return secondsInDay;
  }

  public static getDaysBySeconds(seconds: number): number {
    return seconds / DateUtils.getDaySeconds();
  }

  public static calculateTimeLeft(endTime: string): string {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = Number(endTime) - currentTime;

    if (timeLeft <= 0) {
      return 'ended';
    }

    const days = Math.floor(timeLeft / (3600 * 24));
    const hours = Math.floor((timeLeft % (3600 * 24)) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);

    let timeLeftString = '';
    if (days > 0) {
      timeLeftString += `${days} days `;
    }
    if (hours > 0) {
      timeLeftString += `${hours} hours `;
    }
    if (hours === 0) {
      timeLeftString += `${minutes} minutes`;
    }

    return timeLeftString.trim();
  }

  public static humanizedTime(lockTimeInSeconds: number): string {
    const months = Math.floor(lockTimeInSeconds / (3600 * 24 * 30));
    lockTimeInSeconds -= months * (3600 * 24 * 30);
    const days = Math.floor(lockTimeInSeconds / (3600 * 24));
    lockTimeInSeconds -= days * (3600 * 24);
    const hours = Math.floor(lockTimeInSeconds / 3600);
    lockTimeInSeconds -= hours * 3600;
    const minutes = Math.floor(lockTimeInSeconds / 60);

    if (months > 0) {
      return `${months} month${months !== 1 ? 's' : ''} `;
    }
    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} `;
    }
    if (hours > 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''} `;
    } else {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} `;
    }
  }
}
