import { createSelectors } from '../createSelectors';
import { create } from '../../libs/zustand';

import { StoreProjectedInterface } from './chef-incentives.model';
import { ChiefIncentivesGraphApi } from '../../graphs';
import { ChefIncentivesHelper } from './chef-incentives.helper';
import { ChefIncentivesService } from '../../libs/aave-protocol-js/ChefIncentivesContract';
import { CHEF_INCENTIVES_CONTROLLER } from '../../shared';

export const useChefIncentives = createSelectors(
  create<StoreProjectedInterface>((set, get) => ({
    poolsInfoData: {
      isLoading: false,
      isLoaded: false,
      data: [],
    },
    fetchPoolsInfoData: async (tokenPriceUsd: string, provider) => {
      const storedData = get().poolsInfoData;
      if (storedData.isLoading || storedData.isLoaded) {
        return;
      }

      set((state) => ({
        ...state,
        isLoading: true,
      }));

      try {
        const result = await ChiefIncentivesGraphApi.getPoolsInfoData();

        const chefChefIncentivesService = new ChefIncentivesService(
          provider,
          CHEF_INCENTIVES_CONTROLLER
        );

        const totalSupplies = await chefChefIncentivesService.getTotalSupplies(
          result.map((pool) => pool.tokenAddress)
        );

        const poolsInfoData = await ChefIncentivesHelper.comparePoolInfoData(
          result,
          totalSupplies,
          tokenPriceUsd
        );

        set((state) => ({
          ...state,
          poolsInfoData: {
            ...storedData,
            data: poolsInfoData,
            isLoading: false,
            isLoaded: true,
          },
        }));
      } catch (error) {
        console.log(error);

        set((state) => ({
          ...state,
          poolInfoData: {
            ...storedData,
            isLoading: false,
            isLoaded: false,
          },
        }));
      }
    },
  }))
);
