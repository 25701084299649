import DefaultButton from '../../../../components/basic/DefaultButton';
import staticStyles from './style';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Asset } from '../../../../libs/aave-ui-kit/helpers/assets-list';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';

import { sendEthTransaction } from '../../../../helpers/send-ethereum-tx';
import { UserSummary } from '../../../../libs/pool-data-provider';
import { MultiFeeDistributionService } from '../../../../libs/aave-protocol-js/MulteFeeDistributionContract';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import BigNumber from 'bignumber.js';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import Value from '../../../../components/basic/Value';
import { REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS } from '../../../../shared';
import { MathUtils } from '../../../../utils/math.utils';

interface ClaimableRewardsTableProps {
  user: UserSummary;
  setStatsRerender: Dispatch<SetStateAction<number>>;
}

export function ManageRevenueSectoin({ user, setStatsRerender }: ClaimableRewardsTableProps) {
  const { claimableRewards, totalClaimableRewardsInUsd } = useBalanceContext();
  // const { prices } = useRewardTokenPrices();
  const { provider } = useProviderContext();
  // const [autoCompoundAnabled, setAutoCompoundAnabled] = useState(false);
  const [totalFees, setTotalFees] = useState<BigNumber>(BigNumber(0));
  const [isClaimDisabled, setIsClaimDisabled] = useState<boolean>(false);
  // const [userSlippage, setUserSlippage] = useState<BigNumber>(BigNumber(0));

  const multiFeeDistributionService = useMemo(
    () => new MultiFeeDistributionService(provider, REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS),
    [provider]
  );

  const isClaimable: boolean = claimableRewards.some((reward) => reward.amount.gt(0));

  // BigNumber.config({ EXPONENTIAL_AT: 40 });

  // const handleAutoCompound = useCallback(async () => {
  //   const actionTx = await multiFeeDistributionService.toggleAutocompound(user.id);

  //   return sendEthTransaction(
  //     actionTx,
  //     provider,
  //     () => {
  //       console.log('sendEthTransaction');
  //     },
  //     null,
  //     {
  //       onConfirmation: () => {
  //         setAutoCompoundAnabled(!autoCompoundAnabled);
  //       },
  //     }
  //   );
  // }, [autoCompoundAnabled, user, provider]);

  const handleClaimRewards = useCallback(async () => {
    setIsClaimDisabled(true);

    if (user) {
      const txGetter = await multiFeeDistributionService.getAllRewards(user.id);

      return sendEthTransaction(txGetter, provider, () => {}, null, {
        onConfirmation: () => {
          console.log('Claim confirmed');
          setIsClaimDisabled(false);
        },
        onError: () => {
          console.log('Error');

          setIsClaimDisabled(false);
        },
      });
    }
  }, [user, provider]);

  // useEffect(() => {
  //   (async () => {
  //     const userSlippage = await multiFeeDistributionService.getUserSlippage(user.id);
  //     const autoCompoundAnabled = await multiFeeDistributionService.getAutocompoundEnabled(user.id);

  //     setAutoCompoundAnabled(autoCompoundAnabled);

  //     setUserSlippage(userSlippage);
  //   })();
  // }, [user]);

  return (
    <>
      <BasicBox className="m-revenue manage__basic-box" variant="secondary">
        <div className="m-revenue__title">
          <p>Your platform Revenue</p>
          <span className="m-revenue__title-value ff-lg">
            {totalClaimableRewardsInUsd.isPositive() ? (
              <>$ {MathUtils.toLocaleNumber(totalClaimableRewardsInUsd, 2)}</>
            ) : (
              <>Fetching...</>
            )}
          </span>
        </div>

        <div className="m-revenue__tokens">
          {claimableRewards.map((reward, index) => (
            <BasicBox variant="secondary" className="m-revenue__token" key={index}>
              <BasicToken
                tokenSymbol={reward.tokSymbol as string}
                className="m-revenue__token-left"
              >
                {(asset, asset2) => (
                  <>
                    <BasicToken.Image
                      icon={asset.icon}
                      icon2={asset2?.icon}
                      alt={reward.tokSymbol}
                      size="medium"
                      className="m-revenue__token-img"
                    />
                    <BasicToken.Name name={asset.symbol} className="m-revenue__token-symbol" />
                  </>
                )}
              </BasicToken>

              <div className="m-revenue__token-right">
                <p className="m-revenue__token-balance">
                  {
                    <Value
                      value={Number(reward.amount)}
                      className="m-revenue__token-value"
                      maximumValueDecimals={2}
                      minimumValueDecimals={0}
                    />
                  }
                </p>
                <div className="m-revenue__token-usd">
                  ${' '}
                  {
                    <Value
                      className="m-revenue__token-value"
                      value={Number(reward.usdVal)}
                      maximumValueDecimals={2}
                      minimumValueDecimals={0}
                    />
                  }
                </div>
              </div>
            </BasicBox>
          ))}
        </div>

        <div className="m-revenue__btns">
          {/* <DefaultButton
    title={'Compound'}
    color="primary"
    size="small"
    disabled={!isClaimable}
  /> */}
          <DefaultButton
            title="Claim all"
            color="secondary"
            size="small"
            // disabled={!isClaimable || isClaimDisabled}
            loading={isClaimDisabled}
            onClick={handleClaimRewards}
          />
        </div>
        {/* <div className="m-revenue__right">
          <label className="m-revenue__auto">
            <BasicSwitcher isOn={autoCompoundAnabled} handleToggle={handleAutoCompound} />
            <span className="m-revenue__auto-text">Auto compound</span>
          </label>

          <p className="m-revenue__slipage">Slippage: {userSlippage.toString()} %</p>
        </div> */}
      </BasicBox>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
