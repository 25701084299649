import {
  DailyDataModel,
  FeeDistributionGraphApi,
  RewardDataModel,
  TotalFeeDataModel,
} from '../../graphs';
import { createSelectors } from '../createSelectors';
import { create } from '../../libs/zustand';

import { StoreFeeDistributionModel } from './fee-distribution.model';
import { FeeDistributionHelper } from './fee-distribution.helper';

export const useFeeDistributionStore = createSelectors(
  create<StoreFeeDistributionModel>((set, get) => ({
    rewardsData: [],
    dailyData: [],
    isFeeLoading: false,
    isFeeLoaded: false,
    isDailyLoading: false,
    isDailyLoaded: false,
    totalPlatformFees: -1,
    fetchRewardsData: async () => {
      if (get().isFeeLoading || get().rewardsData.length > 0) {
        return;
      }

      set((state) => ({ ...state, isFeeLoading: true }));

      const rewardsData: RewardDataModel[] = await FeeDistributionGraphApi.getRewardsData();

      set((state) => ({
        ...state,
        rewardsData: rewardsData,
        isFeeLoading: false,
        isFeeLoaded: true,
      }));
    },
    fetchDailyData: async () => {
      if (get().isDailyLoading) {
        return;
      }

      set((state) => ({ ...state, isDailyLoading: true }));

      const [dailyData, totalFeeData]: [DailyDataModel[], TotalFeeDataModel] = await Promise.all([
        FeeDistributionGraphApi.getDailyRewardsPerDay(),
        FeeDistributionGraphApi.getTotalFeeData(),
      ]);

      const totalPlatformFees =
        FeeDistributionHelper.getTotalByDailyRewards(dailyData) + Number(totalFeeData?.totalFee);

      set((state) => ({
        ...state,
        totalPlatformFees,
        dailyData,
        isDailyLoading: false,
        isDailyLoaded: true,
      }));
    },
  }))
);
