import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import BasicTable from '../../../../components/BasicTable';

import messages from './messages';
import staticStyles from './style';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';

import { ReactComponent as ArrowRight } from '../../../../images/icons/arrow-right.svg';
import { MathUtils } from '../../../../utils/math.utils';

type sortOptionsType = {
  desc: boolean;
  name?: string;
};

const columns = [
  {
    title: messages.assets,
    sortKey: 'currencySymbol',
  },
  {
    title: messages.depositAPY,
    sortKey: 'depositAPY',
  },
  {
    title: messages.borrowAPY,
    sortKey: 'borrowAPY',
  },
  {
    title: messages.loopAPR,
    sortKey: 'loopAPY',
  },
];

interface MarketTableProps {
  data: any;
  setSortOptions: Dispatch<SetStateAction<sortOptionsType>>;
  sortOptions: sortOptionsType;
}

export default function MarketTable({ data, sortOptions, setSortOptions }: MarketTableProps) {
  const intl = useIntl();
  const [sortedData, setSortedData] = useState<any[]>([]);
  const history = useHistory();

  const handleSorting = useCallback(
    (name) => {
      const isSortByName = name !== sortOptions.name;
      if (isSortByName) {
        setSortOptions((prev) => ({ ...prev, name }));
      } else {
        setSortOptions((prev) => ({ ...prev, desc: !sortOptions.desc }));
      }
    },
    [sortOptions, data]
  );

  useEffect(() => {
    const newData = [...data];

    if (sortOptions.name) {
      const name: string = sortOptions.name;
      const isNumber = !isNaN(Number(newData?.[0]?.[sortOptions.name]));

      newData.sort((a: any, b: any) => {
        const aValue = a[name];
        const bValue = b[name];

        if (isNumber) {
          return sortOptions.desc ? bValue - aValue : aValue - bValue;
        } else {
          return sortOptions.desc
            ? bValue.toString().localeCompare(aValue.toString())
            : aValue.toString().localeCompare(bValue.toString());
        }
      });
    }
    setSortedData(newData);
  }, [data, sortOptions]);

  const handleClick = useCallback(
    ({ id, underlyingAsset }: any) => {
      history.push(`/reserve-overview/${underlyingAsset}-${id}`);
    },
    [history]
  );

  return (
    <>
      <BasicTable className="MarketTable">
        <BasicTable.Header className="MarketTable__header">
          {columns.map((column, index) => (
            <BasicTable.Item className="MarketTable__header-column" key={index}>
              <BasicTable.SortButton
                sortName={sortOptions.name}
                sortDesc={sortOptions.desc}
                handleSorting={handleSorting}
                sortKey={column.sortKey}
                className="MarketTable__header-button"
              >
                {intl.formatMessage(column.title)}
              </BasicTable.SortButton>
            </BasicTable.Item>
          ))}
        </BasicTable.Header>

        {sortedData.map((item, index) => (
          <BasicTable.Row
            key={index}
            variant="secondary"
            className="MarketTable__row"
            onClick={() => handleClick({ underlyingAsset: item.underlyingAsset, id: item.id })}
          >
            <BasicTable.Item className="MarketTable__column">
              <BasicToken tokenSymbol={item.currencySymbol}>
                {(asset, asset2) => (
                  <>
                    <BasicToken.Image
                      icon={asset.icon}
                      icon2={asset2?.icon}
                      alt={item.currencySymbol}
                      size="large"
                    />
                    <div>
                      <BasicToken.Name
                        name={!asset2 ? asset.symbol : `${asset.symbol} / ${asset2.symbol}`}
                      />
                      <p className="MarketTable__amount">
                        Deposited: $
                        {item.totalLiquidityInUSD < 0.01
                          ? ' <0.01'
                          : MathUtils.formatNumber(item.totalLiquidityInUSD, 2)}
                      </p>
                      <p className="MarketTable__amount">
                        Borrowed: $
                        {item.totalBorrowsInUSD < 0.01
                          ? ' <0.01'
                          : MathUtils.formatNumber(item.totalBorrowsInUSD, 2)}
                      </p>
                    </div>
                  </>
                )}
              </BasicToken>
            </BasicTable.Item>

            <BasicTable.Item className="MarketTable__column MarketTable__column--center">
              <span className="MarketTable__column-label">Deposit APY</span>
              <LiquidityMiningCard
                className="MarketTable__card"
                value={item.depositAPY}
                thirtyDaysValue={item.avg30DaysLiquidityRate}
                liquidityMiningValue={item.rewardsDepositApr}
                symbol={item.currencySymbol}
                type="deposit"
              />
            </BasicTable.Item>

            <BasicTable.Item className="MarketTable__column MarketTable__column--center">
              <span className="MarketTable__column-label">Borrow APY</span>
              {item.borrowingEnabled && Number(item.variableBorrowRate) >= 0 ? (
                <LiquidityMiningCard
                  className="MarketTable__card"
                  value={item.variableBorrowRate}
                  thirtyDaysValue={item.avg30DaysVariableRate}
                  liquidityMiningValue={item.rewardsBorrowApr}
                  symbol={item.currencySymbol}
                  type="borrow-variable"
                />
              ) : (
                '—'
              )}
            </BasicTable.Item>

            <BasicTable.Item className="MarketTable__column MarketTable__column--center">
              <span className="MarketTable__column-label">Max loop</span>
              {MathUtils.truncateNumber(item.loopAPY, 2)}%
            </BasicTable.Item>
            <ArrowRight className="MarketTable__row-arrow" />
          </BasicTable.Row>
        ))}
      </BasicTable>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
