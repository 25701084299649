import { Wallet } from '../../index';
import { AvailableWeb3Connectors } from '../../../../libs/web3-data-provider';

import staticStyles from './style';

interface WalletCardProps extends Wallet {
  handleUnlockExternalWallet: (providerName: AvailableWeb3Connectors) => void;
}

export default function WalletCard({
  title,
  description,
  icon,
  disabled,
  providerName,
  handleUnlockExternalWallet,
  errorMessage,
}: WalletCardProps) {
  return (
    <div className="WalletCard">
      {disabled && errorMessage && <strong className="WalletCard__error">{errorMessage}</strong>}

      <button
        className="WalletCard__button"
        onClick={() => handleUnlockExternalWallet(providerName)}
        disabled={disabled}
        type="button"
      >
        <div className="WalletCard__image-inner">
          <img src={icon} alt={title} />
        </div>

        <div className="WalletCard__text-inner">
          <p>{title}</p>
          {!!description && <span>{description}</span>}
        </div>
      </button>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
