import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .Caption {
    width: 100%;
    text-align: center;
    &__primary {
    }

    h2 {
      margin: 16px 0 20px;
      font-weight: 600;
      font-size: 23px;

      @include respond-to(sm) {
        margin: 12px;
      }
    }

    &__description {
      margin: 20px 0;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }
`;

export default staticStyles;
