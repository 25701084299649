import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { UserSummary, ComputedReserveData } from '../../libs/pool-data-provider';
import staticStyles from './styles';
import { LoopWidgetHelper } from './loop-widget.helper';
import { useWalletBalanceProviderContext } from '../../libs/wallet-balance-provider/WalletBalanceProvider';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import DefaultButton from '../basic/DefaultButton';
import { useProviderContext } from '../../libs/provider/WalletProvider';
import { usePoolsInfoData } from '../../store';
import { PoolUtils } from '../../utils/pool.utils';
import { BN_ONE, loopingLeverageToLtv, significantLoopingCount } from '../../helpers/leverage';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { LeveragerContract } from '../../libs/aave-protocol-js/LeveragerContract';
import InputBar from '../basic/InputBar';
import { MathUtils } from '../../utils/math.utils';
import HealthFactor from '../HealthFactor';
import { BasicModal } from '../../libs/aave-ui-kit';
import PoolTxConfirmationView from '../PoolTxConfirmationView';
import messages from './messages';
import { LOOPING_CONTRACT, TokensSymbolEnum } from '../../shared';
import { ComputedUserReserve } from '@aave/math-utils';
import { BasicBox } from '../BasicBox/BasicBox';

interface LoopWidgetProps {
  poolReserve: ComputedReserveData;
  symbol: string;
  user: UserSummary;
  userReserve: ComputedUserReserve;
  isBorrow: boolean;
}

const INTEREST_RATE_MODE = '2';
const AMOUNT_MIN = '1';
const LEVERAGE_MIN = '1.1';

export function LoopWidget({ poolReserve, user, symbol, isBorrow }: LoopWidgetProps) {
  const intl = useIntl();
  const { walletData } = useWalletBalanceProviderContext();
  const { provider } = useProviderContext();
  const { poolsInfoData } = usePoolsInfoData();

  // const [loopingData, setLoopingData] = useState<EstimateLoopingAndAprs>(
  //   {} as EstimateLoopingAndAprs
  // );

  const maxLeverage = BN_ONE.div(BN_ONE.minus(valueToBigNumber(poolReserve.baseLTVasCollateral)))
    .decimalPlaces(2, BigNumber.ROUND_FLOOR)
    .toString();

  const [formData, setFormData] = useState({
    amount: '',
    leverage: maxLeverage,
  });

  const walletBalance = LoopWidgetHelper.getWalletBalance(walletData, poolReserve);

  let blockingError = '';

  const [isConfirm, setIsConfirm] = useState(false);
  const [errors, setErrors] = useState({
    amount: '',
    leverage: '',
  });

  const loopingData = PoolUtils.getPoolLooping({
    reserve: poolReserve,
    poolsInfoData,
    amount: valueToBigNumber(formData.amount),
    maxLeverage: valueToBigNumber(formData.leverage),
    userSummary: user,
  });

  // useEffect(() => {
  //   if (poolsInfoData.length > 0 && poolReserve.supplyAPY && !isNaN(Number(formData.leverage))) {

  //     setLoopingData(loopingData);
  //   }
  // }, [poolReserve, poolsInfoData, user, formData, maxLeverage]);

  const inputHandler = useCallback(
    (maxValue: string, minValue: string) => (value: string) => {
      // if (maxValue && parseFloat(value) > parseFloat(maxValue)) {
      //   value = maxValue;
      // }

      if (minValue && parseFloat(value) < parseFloat(minValue)) {
        setErrors((prev) => ({
          ...prev,
          amount: `This field should be more than ${minValue}`,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          amount: '',
        }));
      }

      setFormData((prev) => ({
        ...prev,
        amount: value,
      }));
    },
    [setErrors]
  );

  const handleBarChange = useCallback(
    (amount: number) => {
      setFormData((prev) => ({
        ...prev,
        leverage: amount.toString(),
      }));
    },
    [formData]
  );

  const handleSubmit = useCallback(() => {
    if (parseFloat(formData.amount) <= 0) {
      setErrors((prev) => ({
        ...prev,
        amount: 'Please input the correct amount',
      }));
      return;
    }

    if (!errors.leverage) {
      setIsConfirm(true);
    }
  }, [errors, formData, setIsConfirm, setErrors]);

  const handleGetTransactions = useCallback(async () => {
    const leveragerContract = new LeveragerContract(provider, LOOPING_CONTRACT);

    const userId = user.id;
    const assetAddress = poolReserve.underlyingAsset;
    const debtTokenAddress = poolReserve.variableDebtTokenAddress;
    const amount = formData.amount;
    const borrowRatio = loopingLeverageToLtv(valueToBigNumber(formData.leverage));
    const loopCount = significantLoopingCount(valueToBigNumber(formData.leverage));

    return await leveragerContract.loop(
      userId,
      assetAddress,
      debtTokenAddress,
      amount,
      INTEREST_RATE_MODE,
      borrowRatio.toString(),
      loopCount.toString(),
      isBorrow
    );
  }, [user, poolReserve, formData, provider, isBorrow]);

  const handleMainTxExecuted = () => {};

  return (
    <>
      <BasicAmountField className="loop-widget__field" maxAmount={walletBalance}>
        {(maxAmount) => (
          <>
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                type="number"
                handleChange={inputHandler(walletBalance.toString(), AMOUNT_MIN)}
                value={formData.amount}
                max={maxAmount}
              />
              <BasicAmountField.USDValue>
                {Number(formData.amount)
                  ? valueToBigNumber(formData.amount)
                      .times(poolReserve.priceInMarketReferenceCurrency)
                      .toString()
                  : 0}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              <BasicAmountField.Asset symbol={symbol} />
              <BasicAmountField.Balance
                onClick={() =>
                  inputHandler(walletBalance.toString(), AMOUNT_MIN)(maxAmount.toString())
                }
              >
                {maxAmount.toString()}
              </BasicAmountField.Balance>
            </BasicAmountField.TokenBox>
          </>
        )}
      </BasicAmountField>

      <InputBar
        minAmount={Number(LEVERAGE_MIN)}
        maxAmount={Number(maxLeverage)}
        value={Number(formData.leverage)}
        className="loop-widget__range"
      >
        {({ rate, min, max }) => (
          <>
            <InputBar.Header>
              <span>{LEVERAGE_MIN}</span>
              <span>Leverage: {formData.leverage}x</span>
              <span>{maxLeverage}</span>
            </InputBar.Header>
            <InputBar.Range
              onChange={handleBarChange}
              minAmount={min}
              maxAmount={max}
              value={Number(formData.leverage)}
            >
              <InputBar.Progress rate={rate} />
            </InputBar.Range>
          </>
        )}
      </InputBar>

      {loopingData ? (
        <>
          {' '}
          <BasicBox className="loop-widget__info">
            <ul className="loop-widget__list">
              <li className="loop-widget__list-item">
                <p>{TokensSymbolEnum.REWARD} APR</p>{' '}
                <span>{MathUtils.toLocaleNumber(loopingData.rewardAPR || 0, 2)}%</span>
              </li>
              <li className="loop-widget__list-item">
                <p>{symbol} APY</p>{' '}
                <span>
                  {MathUtils.toLocaleNumber(
                    valueToBigNumber(loopingData.depositAPY.minus(loopingData.borrowAPY) ?? 0),
                    2
                  )}
                  %
                </span>
              </li>
              <li className="loop-widget__list-item">
                <p>Net APY</p> <span>{MathUtils.toLocaleNumber(loopingData?.netAPY, 2)}%</span>
              </li>
            </ul>
          </BasicBox>
          <BasicBox className="loop-widget__info">
            <ul className="loop-widget__list">
              <li className="loop-widget__list-item">
                <p>Deposit APY</p>{' '}
                <span>{MathUtils.toLocaleNumber(loopingData?.depositAPY, 2)}%</span>
              </li>
              <li className="loop-widget__list-item">
                <p>Borrow APY</p>{' '}
                <span>-{MathUtils.toLocaleNumber(loopingData?.borrowAPY, 2)}%</span>
              </li>
            </ul>
          </BasicBox>
          <BasicBox className="loop-widget__info">
            <ul className="loop-widget__list">
              <li className="loop-widget__list-item">
                <p>Health Factor:</p>{' '}
                <span>
                  <HealthFactor
                    value={loopingData?.healthFactor?.toString() || '-1'}
                    withoutTitle={false}
                    title=" "
                    withoutModal={true}
                  />
                </span>
              </li>
            </ul>
          </BasicBox>
        </>
      ) : null}

      <DefaultButton
        fill
        className="loop-widget__button"
        onClick={handleSubmit}
        disabled={!Number(formData.amount)}
      >
        Start looping
      </DefaultButton>

      <BasicModal isVisible={isConfirm} onBackdropPress={() => setIsConfirm(false)}>
        <BasicModal.Close onClose={() => setIsConfirm(false)} />

        <BasicModal.Header>1-Click Loop & Lock</BasicModal.Header>

        <BasicModal.Content>
          <PoolTxConfirmationView
            mainTxName={intl.formatMessage(messages.title)}
            boxTitle={intl.formatMessage(messages.title)}
            boxDescription={intl.formatMessage(messages.boxDescription)}
            getTransactionsData={handleGetTransactions}
            onMainTxExecuted={handleMainTxExecuted}
            blockingError={blockingError}
          />
        </BasicModal.Content>
        <BasicModal.Footer>
          <DefaultButton
            title={intl.formatMessage(messages.goBack)}
            size="medium"
            onClick={() => setIsConfirm(false)}
          />
        </BasicModal.Footer>
      </BasicModal>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
