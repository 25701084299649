import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import { MathUtils } from '../../../../utils/math.utils';
import PreloaderOver from '../../../../components/basic/PreloaderOver';
import { DAYS_IN_YEAR } from '../../../../shared';
import { useUserPositions } from '../../../../hooks';

export function DashboarRewards() {
  const { prices } = useRewardTokenPrices();
  const { allPendingRewards, userRevenuePerDay } = useBalanceContext();
  const { totalRewardsInUsd } = useUserPositions();

  const isLoaded =
    !allPendingRewards.isNegative() &&
    totalRewardsInUsd.isPositive() &&
    !userRevenuePerDay.isNegative();

  return (
    <ul className="Dashboard__rewards">
      <li className="Dashboard__reward">
        <p className="Dashboard__reward-txt">Your daily rewards</p>
        <p className="Dashboard__reward-value ff-lg">
          $ {MathUtils.toLocaleNumber(totalRewardsInUsd.div(DAYS_IN_YEAR), 2)}
        </p>
      </li>
      <li className="Dashboard__reward">
        <p className="Dashboard__reward-txt">Your protocol fees</p>
        <p className="Dashboard__reward-value ff-lg">
          $ {MathUtils.toLocaleNumber(userRevenuePerDay, 2)}
        </p>
        <p className="Dashboard__reward-subtext">Streamed over a 7-day period</p>
      </li>
      <li className="Dashboard__reward">
        <p className="Dashboard__reward-txt">Ready to vest</p>
        <p className="Dashboard__reward-value ff-lg">
          {MathUtils.toLocaleNumber(allPendingRewards, 2)}
        </p>
        <p className="Dashboard__reward-subtext">
          $ {MathUtils.toLocaleNumber(allPendingRewards.times(prices.tokenPriceUsd), 2)}
        </p>
      </li>
      <li className="Dashboard__reward">
        <p className="Dashboard__reward-txt">Total annual rewards</p>
        <p className="Dashboard__reward-value ff-lg">
          $ {MathUtils.toLocaleNumber(totalRewardsInUsd, 2)}
        </p>
        <p className="Dashboard__reward-subtext">
          Projected over 365-day average based on current market rates
        </p>
      </li>

      <PreloaderOver isShown={!isLoaded} />
    </ul>
  );
}
