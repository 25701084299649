import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .manage {
    color: #000;

    &__txt {
      font-size: 18px;
      margin-top: 10px;

      @include respond-to(sm) {
        font-size: 16px;
      }
    }

    &__top {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;

      @include respond-to(md) {
        display: block;
      }
    }

    &__contant {
      display: flex;
      gap: 17px;

      @include respond-to(md) {
        display: block;
      }

      .manage__basic-box {
        flex: 1 1 auto;
        flex-basis: 50%;
        margin-bottom: 24px;
      }
    }

    &__content-box {
      margin-top: 38px;

      @include respond-to(sm) {
        margin-top: 32px;
      }

      @include respond-to(xs) {
        margin-top: 26px;
      }
    }

    &__content-title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__content-text {
      margin: 12px 0;
      font-size: 16px;

      @include respond-to(sm) {
        font-size: 14px;
      }
    }

    &__group {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__token {
      display: flex;
      align-items: flex-start;
    }

    &__token-img {
      margin-right: 16px;
      margin-top: 2px;

      @include respond-to(xs) {
        margin-right: 12px;
      }
    }

    &__token-balance {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
      padding: 2px 0 3px;

      @include respond-to(xs) {
        font-size: 18px;
      }
    }

    &__token-usd {
      display: block;
      opacity: 0.4;
      font-size: 14px;

      @include respond-to(xs) {
        font-size: 12px;
      }
    }

    &__connect {
      margin: 50px auto 80px;
    }
  }
`;

export default staticStyles;
