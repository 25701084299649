import { BigNumber, BigNumberish, ethers } from 'ethers';

export class Web3Utils {
  public static toWei(value: BigNumberish, decimals = 18): BigNumber {
    return ethers.utils.parseUnits(value.toString(), decimals);
  }

  public static formatValue(number: BigNumberish, decimals?: number): string {
    return ethers.utils.formatUnits(number, !isNaN(Number(decimals)) ? decimals : 18);
  }
}
