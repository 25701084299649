import { BasicSwitcher } from '../../../../components/BasicSwitcher/BasicSwitcher';
import ValuePercent from '../../../../components/basic/ValuePercent';
import { useHistory } from 'react-router-dom';

export function ReserveOtherStats({ reserveOverviewData, userReserve, poolReserve }: any) {
  const history = useHistory();

  const toggleSwitch = () => {
    // toggleUseAsCollateral(
    //   history,
    //   poolReserve.id,
    //   !userReserve?.usageAsCollateralEnabledOnUser,
    //   poolReserve.underlyingAsset
    // );
  };

  return (
    <ul className="ReserveOverview__list">
      <li className="ReserveOverview__list-item">
        <p>Available as collateral</p>
        <span className="ReserveOverview__list-value ReserveOverview__list-value--switcher">
          <BasicSwitcher
            isOn={reserveOverviewData.usageAsCollateralEnabled}
            handleToggle={toggleSwitch}
          />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Max LTV</p>
        <span>
          <ValuePercent
            value={reserveOverviewData.baseLTVasCollateral * 100}
            minimumDecimals={2}
            maximumDecimals={2}
          />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Liquidation threshold</p>
        <span>
          <ValuePercent
            value={
              reserveOverviewData.liquidationBonus <= 0
                ? 0
                : reserveOverviewData.liquidationThreshold * 100
            }
            minimumDecimals={2}
            maximumDecimals={2}
          />
        </span>
      </li>
      <li className="ReserveOverview__list-item">
        <p>Liquidation penalty</p>
        <span>
          <ValuePercent
            value={reserveOverviewData.liquidationBonus * 100}
            minimumDecimals={2}
            maximumDecimals={2}
          />
        </span>
      </li>
    </ul>
  );
}
