import { Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { Address } from 'viem';
import { ERC20_TOKEN_ABI } from './erc20-token.abi';
import { TokenData } from '../../shared/index';
import { StaticApi } from '../static-api';
import { MulticallUtils } from '../../utils';

export class ERC20TokenApi extends StaticApi {
  public static async getBalances(address: Address, tokens: TokenData[]) {
    const provider = ERC20TokenApi.getProvider();

    const multicall = MulticallUtils.createMulltical(provider as Provider);

    const response = await multicall.call(
      tokens.map((token: TokenData) => ({
        reference: `balances-${token.symbol}`,
        contractAddress: token.token,
        abi: ERC20_TOKEN_ABI,
        // @ts-ignore
        calls: [{ reference: token.symbol, methodName: 'balanceOf', methodParameters: [address] }],
      }))
    );

    let result: any = {};

    for (let item of Object.values(response.results)) {
      const callResult = item.callsReturnContext[0];
      result[callResult.reference] = {
        symbol: callResult.reference,
        value: ethers.utils.formatEther(callResult.returnValues[0]),
      };
    }

    return result;
  }
}
