export const poolDataQuery = `{
  lendingPools {
    id
    aTokenAddress
    availableLiquidity
    averageStableRate
    borrowingEnabled
    baseLTVasCollateral
    decimals
    interestRateStrategyAddress
    isActive
    lastUpdateTimestamp
    isFrozen
    liquidityIndex
    liquidityRate
    priceInMarketReferenceCurrency
    name
    reserveFactor
    reserveLiquidationBonus
    reserveLiquidationThreshold
    stableBorrowRate
    stableBorrowRateEnabled
    stableDebtLastUpdateTimestamp
    stableDebtTokenAddress
    stableRateSlope1
    stableRateSlope2
    symbol
    totalPrincipalStableDebt
    totalScaledVariableDebt
    usageAsCollateralEnabled
    variableBorrowIndex
    variableBorrowRate
    variableDebtTokenAddress
    variableRateSlope1
    variableRateSlope2
  }
}`;

export const poolCurrencyQuery = `{
  poolCurrency(id: "pool-currency") {
    id
    marketReferenceCurrencyDecimals
    marketReferenceCurrencyPriceInUsd
    networkBaseTokenPriceInUsd
    networkBaseTokenPriceDecimals
  }
}`;
