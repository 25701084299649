import { BigNumber } from 'ethers';

const RAY = BigNumber.from('1000000000000000000000000000');

export class BorrowRateHelper {
  public static rayDiv(a: BigNumber, b: BigNumber) {
    return a.mul(RAY).div(b);
  }

  public static rayMul(a: BigNumber, b: BigNumber) {
    return a.mul(b).div(RAY);
  }

  public static calculateVariableBorrowRate(
    utilizationRate: BigNumber,
    baseVariableBorrowRate: BigNumber,
    variableRateSlope1: BigNumber,
    variableRateSlope2: BigNumber,
    OPTIMAL_UTILIZATION_RATE: BigNumber,
    EXCESS_UTILIZATION_RATE: BigNumber
  ) {
    let currentVariableBorrowRate;

    if (utilizationRate.gt(OPTIMAL_UTILIZATION_RATE)) {
      const excessUtilizationRateRatio = BorrowRateHelper.rayDiv(
        utilizationRate.sub(OPTIMAL_UTILIZATION_RATE),
        EXCESS_UTILIZATION_RATE
      );
      currentVariableBorrowRate = baseVariableBorrowRate
        .add(variableRateSlope1)
        .add(BorrowRateHelper.rayMul(variableRateSlope2, excessUtilizationRateRatio));
    } else {
      currentVariableBorrowRate = baseVariableBorrowRate.add(
        BorrowRateHelper.rayDiv(
          BorrowRateHelper.rayMul(utilizationRate, variableRateSlope1),
          OPTIMAL_UTILIZATION_RATE
        )
      );
    }

    return currentVariableBorrowRate;
  }
}
