import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .reserve-widget {
    display: flex;
    flex-direction: column;
    min-width: 58%;
    max-widget: 58%;

    @include respond-to(md) {
      display: block;
      margin: 24px 0;
    }

    &__box {
      flex: none;
    }

    &__box-header {
      margin: 0;
      @include respond-to(sm) {
        display: block;
      }
    }

    &__body {
      margin-top: 12px;
      flex-direction: column;
      @include respond-to(md) {
        height: auto;
        display: block;
      }
    }

    &__tabs {
      margin-left: auto;
    }

    &__title {
      margin-top: 6px;
      font-size: 20px;
    }

    &__text {
      color: rgba(0, 0, 0, 0.4);
      margin: 12px 0 0;
      font-size: 16px;
    }

    &__range {
      margin: 32px 0 0;
    }

    &__list {
      margin: 32px 0 0;
    }

    &__list-item {
      display: flex;
      justify-content: space-between;
      padding: 11px 0 10px;

      span {
        font-weight: 600;
      }
    }

    &__button {
      margin-top: 32px;
      display: block;
    }
  }

  // .vest-widget {
  //   &__field {
  //     margin-bottom: auto;
  //   }
  // }
`;

export default staticStyles;
