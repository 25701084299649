import BigNumber from 'bignumber.js';
import { ComputedReserveData, UserSummary } from '../../libs/pool-data-provider';
import { calculateHealthFactorFromBalancesBigUnits, valueToBigNumber } from '@aave/protocol-js';

export class DepositWidgetHelper {
  public static calculateMaxDepositAmount(
    user: UserSummary,
    poolReserve: ComputedReserveData
  ): { maxUserAmountToBorrow: BigNumber; maxAmountToBorrow: BigNumber } {
    const maxUserAmountToBorrow = valueToBigNumber(
      user.availableBorrowsMarketReferenceCurrency || 0
    ).div(poolReserve.priceInMarketReferenceCurrency);

    let maxAmountToBorrow = BigNumber.max(
      BigNumber.min(poolReserve.availableLiquidity, maxUserAmountToBorrow),
      0
    );

    if (
      maxAmountToBorrow.gt(0) &&
      user.totalBorrowsMarketReferenceCurrency !== '0' &&
      maxUserAmountToBorrow.lt(
        valueToBigNumber(poolReserve.availableLiquidity).multipliedBy('1.01')
      )
    ) {
      maxAmountToBorrow = maxAmountToBorrow.multipliedBy('0.99');
    }

    return { maxUserAmountToBorrow, maxAmountToBorrow };
  }

  public static calculateMHealthFactor(
    user: UserSummary,
    poolReserve: ComputedReserveData,
    amountToDeposit: BigNumber,
    marketRefPriceInUsd: string
  ): { amountInUsd: BigNumber; healthFactorAfterDeposit: BigNumber } {
    const amountIntEth = amountToDeposit.multipliedBy(poolReserve.priceInMarketReferenceCurrency);
    const amountInUsd = amountIntEth.multipliedBy(marketRefPriceInUsd);
    const totalCollateralMarketReferenceCurrencyAfter = valueToBigNumber(
      user.totalCollateralMarketReferenceCurrency
    ).plus(amountIntEth);

    const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralMarketReferenceCurrency)
      .multipliedBy(user.currentLiquidationThreshold)
      .plus(amountIntEth.multipliedBy(poolReserve.reserveLiquidationThreshold))
      .dividedBy(totalCollateralMarketReferenceCurrencyAfter);

    const healthFactorAfterDeposit = calculateHealthFactorFromBalancesBigUnits(
      totalCollateralMarketReferenceCurrencyAfter,
      valueToBigNumber(user.totalBorrowsMarketReferenceCurrency),
      liquidationThresholdAfter
    );

    return { healthFactorAfterDeposit, amountInUsd };
  }

  public static getWalletBalance(
    walletData: Record<string, string>,
    poolReserve: ComputedReserveData
  ): BigNumber {
    return walletData[poolReserve.underlyingAsset] === '0'
      ? valueToBigNumber('0')
      : valueToBigNumber(walletData[poolReserve.underlyingAsset] || '0').dividedBy(
          valueToBigNumber('10').pow(poolReserve.decimals)
        );
  }
}
