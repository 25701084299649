import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ActionsWrapper {
    width: 100%;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    transition: $transition;
    padding: 16px;

    &__buttons {
      width: 100%;
      display: flex;
      color: white;
      gap: 18px;

      & + .TxBottomStatusLine {
        margin-top: 12px;
      }

      &__button-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        &:last-of-type {
          .ActionsWrapper__button:after {
            content: none;
          }
        }
      }
      &__button-title {
        display: flex;
        flex-direction: row;
        padding: 6px 10px;
        font-size: 14px;
        color: #000;
        justify-content: center;
        min-height: 46px;
        align-items: center;

        &--active {
          color: #ffb21e;
        }
        p {
          margin-left: 10px;
        }
      }
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: $small;
      flex: 1;
      font-weight: 400;
      transition: $transition;
      cursor: default;
      min-height: 12px;
      border-radius: 8px;
      &:last-of-type {
        border-right: none !important;
      }
      span,
      p {
        opacity: 0.8;
      }
      span {
        font-weight: 600;
        margin-right: 5px;
      }
    }

    &__buttonActive,
    &__buttonSubmitted,
    &__buttonConfirmed,
    &__buttonError {
      position: relative;
      span,
      p {
        opacity: 1;
      }

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background-color: inherit;
        display: block;
        position: absolute;
        left: 100%;
        margin-left: 3px;
      }
    }
  }
`;

export default staticStyles;
