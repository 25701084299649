import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { staticStyles } from './styles';

interface SelectDropdownProps {
  onOptionClick?: (selectedId: number) => void;
  children: ReactElement[];
  selectedId?: number;
  isOpened?: boolean;
  className?: string;
}

export function SelectDropdown({
  onOptionClick,
  children,
  selectedId,
  className,
}: SelectDropdownProps) {
  return (
    <>
      <div className={classNames('basic-select__dropdown', className)}>
        {React.Children.map(children, (child: ReactElement, index: number) =>
          React.cloneElement(child, {
            className: classNames(child.props.className, index === selectedId ? 'active' : ''),
            selectedId: selectedId,
            onOptionClick: () => onOptionClick!(index),
          })
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
