import { createSelectors } from '../createSelectors';
import { create } from '../../libs/zustand';
import { StoreTokenSaleModel } from './token-sale.model';
import { ERC20TokenApi } from '../../apis';

import { Address } from 'viem';
import { NEW_TOKENS, TokensSymbolEnum } from '../../shared';
import { useCallback, useEffect } from 'react';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { useProviderContext } from '../../libs/provider/WalletProvider';
import { useWeb3DataContext } from '../../libs/web3-data-provider';
import { ethers } from 'ethers';

export const useTokenSaleStore = createSelectors(
  create<StoreTokenSaleModel>((set, get) => ({
    userBalances: {
      isLoading: false,
      isLoaded: false,
      isError: false,
      data: {},
    },
    refetchData: {
      isLoading: false,
      isLoaded: false,
      isError: false,
    },
    tokenPrices: {},
    allowances: {},
    totalSupplies: {},
    tokenSale: {
      isLoaded: false,
      isLoading: false,
      isError: false,
      data: {
        totalPurchased: null,
        totalSaleSupply: null,
        userBalance: null,
        endTime: null,
        saleTokenRate: null,
        vestingPeriod: null,
        isSold: false,
        isEnded: false,
      },
    },
    onLoadUserBalances: async (userAddress, tokens, provider) => {
      let userBalances = get().userBalances;

      set((state) => ({
        ...state,
        userBalances: {
          ...userBalances,
          isLoading: true,
          isLoaded: false,
          isError: false,
        },
      }));

      try {
        ERC20TokenApi.setProvider(provider);
        const balances = await ERC20TokenApi.getBalances(userAddress, tokens);
        const gasTokenBalance = await provider.getBalance(userAddress);

        userBalances = {
          data: {
            ...userBalances.data,
            ...balances,
            [TokensSymbolEnum.GAS]: {
              symbol: TokensSymbolEnum.GAS,
              value: ethers.utils.formatEther(gasTokenBalance),
            },
          },
          isLoading: false,
          isError: false,
          isLoaded: true,
        };
      } catch (error) {
        userBalances = {
          ...userBalances,
          isLoading: false,
          isLoaded: false,
          isError: true,
        };
      }

      set((state) => ({
        ...state,
        userBalances: userBalances,
      }));

      return true;
    },
  }))
);

export function useTokensBalanceData() {
  const { user } = useDynamicPoolDataContext();
  const { provider } = useProviderContext();
  const { currentAccount: address } = useWeb3DataContext();
  const [userBalances, onLoadUserBalances] = [
    useTokenSaleStore.use.userBalances(),
    useTokenSaleStore.use.onLoadUserBalances(),
  ];

  const refetchTokensBalanceData = useCallback(async () => {
    if (user) {
      onLoadUserBalances(user.id as Address, NEW_TOKENS, provider);
    }
  }, [address, provider, user]);

  useEffect(() => {
    if (user) {
      onLoadUserBalances(user.id as Address, NEW_TOKENS, provider);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(refetchTokensBalanceData, 30 * 1000, address, provider);

    return () => clearInterval(intervalId);
  }, []);

  return {
    refetchTokensBalanceData,
    isLoaded: userBalances.isLoaded,
    userBalances,
  };
}
