import { ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';

import animationCircle from '../../../images/animationCircle.svg';

interface CaptionProps {
  title: string;
  description?: string | ReactNode;
  color?: 'primary' | 'secondary' | 'dark';
  className?: string;
  withAnimationCircle?: boolean;
  onWhiteBackground?: boolean;
}

export default function Caption({
  title,
  description,
  color = 'primary',
  className,
  withAnimationCircle,
}: CaptionProps) {
  return (
    <div className={classNames('Caption', `Caption__${color}`, className)}>
      <h2 className={classNames({ Caption__titleWithCircle: withAnimationCircle }, 'ff-lg')}>
        {title} {withAnimationCircle && <img src={animationCircle} alt="" />}
      </h2>

      <div className="Caption__description">{description}</div>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
