import classNames from 'classnames';

import goToTop from '../../../helpers/goToTop';
import Link from '../../basic/Link';

import staticStyles from './style';
import { ReactNode } from 'react';

interface MenuLinkProps {
  title: string;
  isActive: boolean;
  to?: string;
  isDropdown?: boolean;
  children?: ReactNode;
  className?: string;
  noBorder?: boolean;
  disabled?: boolean;
}

export default function MenuLink({
  to,
  title,
  isActive,
  isDropdown,
  children,
  className,
  noBorder,
  disabled,
}: MenuLinkProps) {
  return (
    <>
      {!isDropdown && to !== undefined ? (
        <Link
          to={to}
          className={classNames('MenuLink ButtonLink', className, {
            MenuLink__active: isActive,
          })}
          disabled={disabled}
          onClick={() => goToTop()}
        >
          <div className="MenuLink__title">
            <p>{title}</p>
            {!noBorder && <i />}
          </div>
        </Link>
      ) : (
        <>
          <div
            className={classNames('MenuLink ButtonLink', {
              MenuLink__active: isActive,
            })}
          >
            <div className="MenuLink__title">
              <p>{title}</p>
              <i />
            </div>
          </div>
          {children}
        </>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
