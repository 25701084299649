import { providers } from 'ethers';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import { DEFAULT_APPROVE_AMOUNT, valueToWei } from '@aave/contract-helpers/dist/esm/commons/utils';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { MultiFeeDistribution } from './typechain/contracts';
import { MultiFeeDistribution__factory } from './typechain/factories';

import getNumberFromEtherBigNumber from '../getNumberFromEtherBigNumber';
import { MulticallUtils, Web3Utils } from '../../../utils';
import { LockDurationIndex } from '../../../modules/manage/components/ManageLock/ManageLock';
import { RDNTBalanceDataModel } from '../../wallet-balance-provider/BalanceProvider';
import { Address } from 'viem';
import { _abi } from './typechain/factories/MultiFeeDistribution__factory';
import { RewardDataModel } from '../../../graphs';

export class MultiFeeDistributionService extends BaseService<MultiFeeDistribution> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;
  rdntTokenAddress: string;

  constructor(
    provider: providers.Provider,
    rdntTokenAddress: string,
    multiFeeDistribution: string
  ) {
    super(provider, MultiFeeDistribution__factory);

    this.contractAddress = multiFeeDistribution;
    this.rdntTokenAddress = rdntTokenAddress;
    this.erc20Service = new ERC20Service(provider);
  }

  // @StakingValidator
  public async getBalances(
    // @isEthAddress() user: tEthereumAddress,
    // @isPositiveAmount() amount: string,
    // @isEthAddress() onBehalfOf?: tEthereumAddress,
    user: tEthereumAddress
  ): Promise<RDNTBalanceDataModel> {
    const multicall = MulticallUtils.createMulltical(this.provider);
    const {
      results: { balancesInfo },
    } = await multicall.call([
      {
        reference: `balancesInfo`,
        contractAddress: this.contractAddress,
        abi: _abi,
        // @ts-ignore
        calls: [
          {
            reference: 'withdrawableBalance',
            methodName: 'withdrawableBalance',
            methodParameters: [user],
          },
          { reference: 'earnedBalances', methodName: 'earnedBalances', methodParameters: [user] },
          { reference: 'lockedBalances', methodName: 'lockedBalances', methodParameters: [user] },
        ],
      },
    ]);

    const { withdrawableBalance, earnedBalances, lockedBalances } =
      MulticallUtils.convertData(balancesInfo);

    const [, penaltyAmount, amountWithoutPenalty] = withdrawableBalance;
    const [totalVesting, unlockedVesting, earningsData] = earnedBalances;
    const [totalLocked, unlockable, locked, lockedWithMultiplier, lockData] = lockedBalances;

    const penalty = valueToBigNumber(Web3Utils.formatValue(penaltyAmount.hex));

    const unlockableBalance = valueToBigNumber(Web3Utils.formatValue(unlockable.hex));

    return {
      userTotalLockedBalanceWithMultiplier: lockData.reduce((accumulator: any, lock: any) => {
        const stakedWithMultiplier = valueToBigNumber(Web3Utils.formatValue(lock[0].hex)).times(
          lock[2].hex
        );

        return stakedWithMultiplier.plus(accumulator);
      }, valueToBigNumber(0)),
      userTotalLockedBalance: valueToBigNumber(Web3Utils.formatValue(totalLocked)),
      lockData:
        lockData?.map((data: any) => ({
          amount: valueToBigNumber(Web3Utils.formatValue(data[0].hex)),
          unlockTime: data[1].hex.toString(),
          multiplier: Number(data[2].hex),
          duration: valueToBigNumber(Web3Utils.formatValue(data[3].hex)),
        })) || [],
      earningsData:
        earningsData?.map((data: any) => ({
          amount: valueToBigNumber(Web3Utils.formatValue(data[0].hex)),
          unlockTime: data[1].hex.toString(),
          penalty: valueToBigNumber(Web3Utils.formatValue(data[2].hex)),
        })) || [],
      penalty,
      unlockedVesting: valueToBigNumber(Web3Utils.formatValue(unlockedVesting.hex)),
      unlockable: unlockableBalance,
      userLockedBalance: valueToBigNumber(Web3Utils.formatValue(locked.hex)),
      totalVesting: valueToBigNumber(Web3Utils.formatValue(totalVesting.hex)),
      amountWithPenalty: valueToBigNumber(Web3Utils.formatValue(amountWithoutPenalty.hex)),
      lockedWithMultiplier: valueToBigNumber(Web3Utils.formatValue(lockedWithMultiplier.hex)),
    };
  }

  public async stakingToken(): Promise<string> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return await multiFeeDistributionContract.stakingToken();
  }

  public async getLockedSupply() {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );
    return await multiFeeDistributionContract.lockedSupply();
  }

  public async getDefaultLockIndex(address: Address) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );
    return await multiFeeDistributionContract.defaultLockIndex(address);
  }

  public async getLockDurations(): Promise<string[]> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const lockDurations = await multiFeeDistributionContract.getLockDurations();

    return lockDurations.map((duration) => duration.toString());
  }

  public async getLockInfo(user?: Address): Promise<[string[], string[], number, boolean]> {
    const multicall = MulticallUtils.createMulltical(this.provider);
    let calls: any[] = [
      {
        reference: 'multipliers',
        methodName: 'getLockMultipliers',
        methodParameters: [],
      },
      { reference: 'lockDurations', methodName: 'getLockDurations', methodParameters: [] },
    ];

    calls = user
      ? [
          ...calls,
          {
            reference: 'defaultLockIndex',
            methodName: 'defaultLockIndex',
            methodParameters: [user],
          },
          {
            reference: 'autoRelockDisabled',
            methodName: 'autoRelockDisabled',
            methodParameters: [user],
          },
        ]
      : calls;

    const {
      results: { lockInfo },
    } = await multicall.call([
      {
        reference: `lockInfo`,
        contractAddress: this.contractAddress,
        abi: _abi,
        // @ts-ignore
        calls: calls,
      },
    ]);

    const parsedData = MulticallUtils.convertData(lockInfo);

    return [
      parsedData.multipliers.map((multiplier: any) => Web3Utils.formatValue(multiplier, 0)),
      parsedData.lockDurations.map((duration: any) => Web3Utils.formatValue(duration, 0)),
      parsedData.defaultLockIndex
        ? Number(Web3Utils.formatValue(parsedData.defaultLockIndex, 0))
        : 0,
      Boolean(parsedData.autoRelockDisabled),
    ];
  }

  public async setDefaultRelockTypeIndex(index: number, user: Address) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () =>
        multiFeeDistributionContract.populateTransaction.setDefaultRelockTypeIndex(
          index.toString()
        ),
      from: user,
    });
  }

  public async getLockMultipliers(): Promise<string[]> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const lockMultipliers = await multiFeeDistributionContract.getLockMultipliers();

    return lockMultipliers.map((multiplier) => multiplier.toString());
  }

  public async getLockedBalances(
    user: tEthereumAddress
  ): Promise<{ amount: string; expiryDate: Date }[]> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const lockedBalances = await multiFeeDistributionContract.callStatic.lockedBalances(user);

    return lockedBalances.lockData.map(({ amount, unlockTime }) => {
      return {
        // todo:pavlik check what should be second argument (precision) of getNumberFromEtherBigNumber
        amount: amount.toString(),
        expiryDate: new Date(+unlockTime.mul(1000).toString()),
      };
    });
  }

  public async getAutocompoundEnabled(user: tEthereumAddress): Promise<boolean> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return await multiFeeDistributionContract.callStatic.autocompoundEnabled(user);
  }

  public async getAutoRelockDisabled(user: tEthereumAddress): Promise<boolean> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return await multiFeeDistributionContract.callStatic.autoRelockDisabled(user);
  }

  public async getEarnedBalances(
    user: tEthereumAddress
  ): Promise<{ amount: string; expiryDate: Date }[]> {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );
    const earnedBalances = await multiFeeDistributionContract.callStatic.earnedBalances(user);
    return earnedBalances.earningsData.map(({ amount, unlockTime }) => {
      return {
        // todo:pavlik check what should be second argument (precision) of getNumberFromEtherBigNumber
        amount: getNumberFromEtherBigNumber(amount).toString(),
        expiryDate: new Date(+unlockTime.mul(1000).toString()),
      };
    });
  }

  public async withdraw(user: tEthereumAddress, amount: BigNumber) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const convertedAmount: string = valueToWei(amount.toString(), 18);

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.withdraw(convertedAmount),
      from: user,
    });
  }

  public async exit(user: tEthereumAddress) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.exit(false),
      from: user,
    });
  }

  public async individualEarlyExit(user: tEthereumAddress, unlockTime: string, isClaim = false) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () =>
        multiFeeDistributionContract.populateTransaction.individualEarlyExit(isClaim, unlockTime),
      from: user,
    });
  }

  public async relockLP(user: tEthereumAddress) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.relock(),
      from: user,
    });
  }

  public async withdrawExpiredLocks(user: tEthereumAddress) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () =>
        multiFeeDistributionContract.populateTransaction.withdrawExpiredLocksForWithOptions(
          user,
          '0',
          false
        ),
      from: user,
    });
  }

  public async toggleAutocompound(user: tEthereumAddress) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.toggleAutocompound(),
      from: user,
    });
  }

  public async toggleAutoRelock(user: tEthereumAddress, isAutoRelock: boolean) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.setRelock(isAutoRelock),
      from: user,
    });
  }

  // @StakingValidator
  public async stake(
    // @isEthAddress() user: tEthereumAddress,
    // @isPositiveAmount() amount: string,
    // @isEthAddress() onBehalfOf?: tEthereumAddress,
    user: tEthereumAddress,
    amount: string,
    lockDurationIndex: LockDurationIndex
  ): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const { isApproved, approve, decimalsOf } = this.erc20Service;

    const [approved, decimals] = await Promise.all([
      isApproved({
        token: this.rdntTokenAddress,
        user,
        spender: this.contractAddress,
        amount,
      }),
      decimalsOf(this.rdntTokenAddress),
    ]);

    if (!approved) {
      const approveTx = approve({
        user,
        token: this.rdntTokenAddress,
        spender: this.contractAddress,
        amount: DEFAULT_APPROVE_AMOUNT,
      });
      txs.push(approveTx);
    }

    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const convertedAmount: string = valueToWei(amount, decimals);

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        multiFeeDistributionContract.populateTransaction.stake(
          convertedAmount,
          user,
          lockDurationIndex.toString()
        ),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  }

  public async claimableRewards(user: tEthereumAddress, rewardDatas: RewardDataModel[]) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const claimable = await multiFeeDistributionContract.callStatic.claimableRewards(user);

    return claimable.map(({ token, amount }, i) => ({
      token,
      amount: Web3Utils.formatValue(
        amount,
        rewardDatas.find(({ id }) => id.toLowerCase() === token.toLowerCase())?.decimals ?? 18
      ),
    }));
  }

  public async getUserSlippage(user: tEthereumAddress) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const slippage = await multiFeeDistributionContract.callStatic.userSlippage(user);

    return valueToBigNumber(Web3Utils.formatValue(slippage));
  }

  public async getReward(user: tEthereumAddress, tokens: string[]) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.getReward(tokens),
      from: user,
    });
  }

  public async getAllRewards(user: tEthereumAddress) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    return this.generateTxCallback({
      rawTxMethod: () => multiFeeDistributionContract.populateTransaction.getAllRewards(),
      from: user,
    });
  }

  public async delegateExit(user: tEthereumAddress, delegatee: string) {
    const multiFeeDistributionContract: MultiFeeDistribution = this.getContractInstance(
      this.contractAddress
    );

    const exitDelegatee = await multiFeeDistributionContract.callStatic.exitDelegatee(user);
    if (exitDelegatee.toLowerCase() === delegatee.toLowerCase()) {
      return null;
    }

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: async () =>
        multiFeeDistributionContract.populateTransaction.delegateExit(delegatee),
      from: user,
    });

    return {
      tx: txCallback,
      txType: eEthereumTxType.ERC20_APPROVAL,
      gas: this.generateTxPriceEstimation([], txCallback),
    };
  }
}
