import { useEffect, useState } from 'react';
import { useFeeDistributionStore } from './fee-distribution.store';
import { RewardDataModel } from '../../graphs';
import { DistributionUtils } from '../../utils';
import { FeeDistributionHelper } from './fee-distribution.helper';
import { DistributionData, RewardLockAprsModel } from './fee-distribution.model';
import useRewardTokenPrices from '../tokens-price/tokens-price.hooks';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { APRByTokenType } from '../../modules/markets/types';
import { MarketsHelper } from '../../modules/markets/helpers';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { useLockData } from '../lock-data/useLockData';
import { MathUtils } from '../../utils/math.utils';

export function useRewardsData() {
  const [rewardsData, fetchRewardsData] = [
    useFeeDistributionStore.use.rewardsData(),
    useFeeDistributionStore.use.fetchRewardsData(),
  ];
  const isLoaded = useFeeDistributionStore.use.isFeeLoaded();
  const isLoading = useFeeDistributionStore.use.isFeeLoading();

  useEffect(() => {
    if (!isLoaded) {
      fetchRewardsData();
    }
  }, []);

  return { rewardsData, isLoaded, isLoading };
}

export function useFeeDistribution() {
  const [distributionData, setDistributionData] = useState<DistributionData>({
    dailyPlatformUsdPerToken: [],
    dailyPlatformFees: valueToBigNumber(-1),
  });

  const { rewardsData, isLoaded } = useRewardsData();

  useEffect(() => {
    let isMounted = true;

    if (isLoaded) {
      (async () => {
        const dailyPlatformUsdPerToken: BigNumber[] = rewardsData.map((reward: RewardDataModel) =>
          DistributionUtils.getDailyRewardUsd(reward)
        );

        const dailyPlatformFees: BigNumber =
          FeeDistributionHelper.getArraySum(dailyPlatformUsdPerToken);

        if (isMounted)
          setDistributionData({
            dailyPlatformUsdPerToken,
            dailyPlatformFees,
          });
      })();
    }

    return () => {
      isMounted = false;
    };
  }, [rewardsData, isLoaded]);

  return distributionData;
}

export function useRewardsApr() {
  const { totalLockedSupplyWithMultiplier } = useDynamicPoolDataContext();
  const { rewardsData } = useRewardsData();
  const { prices } = useRewardTokenPrices();
  const [aprByTokens, setAPRByTokens] = useState<RewardLockAprsModel>({
    isLoaded: false,
    data: {},
  });

  const { lockMultipliers } = useLockData();

  useEffect(() => {
    if (rewardsData.length > 0 && totalLockedSupplyWithMultiplier && lockMultipliers.length) {
      const tokensAPR: APRByTokenType = {};
      const totalLockedSupplyInUSD = totalLockedSupplyWithMultiplier.times(prices.lpTokenPriceUsd);

      rewardsData.forEach((reward, index) => {
        const tokenApr: [string, BigNumber] = MarketsHelper.getAprByToken(
          reward,
          totalLockedSupplyInUSD,
          lockMultipliers[lockMultipliers.length - 1]
        );

        tokensAPR[tokenApr[0]] = Number(MathUtils.formatNumber(tokenApr[1], 2));
      });

      setAPRByTokens({
        isLoaded: true,
        data: tokensAPR,
      });
    }
  }, [rewardsData, totalLockedSupplyWithMultiplier, prices, lockMultipliers]);

  return aprByTokens;
}

export function useFeeData() {
  const [totalPlatformFees, dailyData, fetchDailyData] = [
    useFeeDistributionStore.use.totalPlatformFees(),
    useFeeDistributionStore.use.dailyData(),
    useFeeDistributionStore.use.fetchDailyData(),
  ];
  const isLoaded = useFeeDistributionStore.use.isDailyLoaded();
  const isLoading = useFeeDistributionStore.use.isDailyLoading();

  useEffect(() => {
    fetchDailyData();
  }, []);

  return { totalPlatformFees, dailyFeeData: dailyData, isLoaded, isLoading };
}
