import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .MenuLink {
    font-weight: 300;
    text-transform: uppercase;
    color: inherit;
    font-size: $regular;
    padding: 15px 0;
    @include respond-to(xl) {
      font-size: $medium;
    }
    @include respond-to(lg) {
      font-size: $small;
    }
    &:hover {
      .MenuLink__title {
        opacity: 1;
      }
    }

    .MenuLink__title {
      position: relative;
      backface-visibility: hidden;
      transform: translateZ(0);
      display: flex;
      align-items: center;
      p {
        transition: $transition;
        position: relative;
        display: inline-block;
        letter-spacing: 0.25px;
        b {
          opacity: 1;
          font-weight: 300;
          transition: $transition;
        }
      }
      strong {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: $transition;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: 0.25px;
      }
      i {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 5px);
        width: 0;
        height: 2px;
        transition: opacity 0.2s, width 0.3s;
        opacity: 0;
        background: #000;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: inherit;
          transition: $transition;
        }
      }
    }

    &__active {
      color: #000;
      font-weight: 600;
      .MenuLink__title {
        opacity: 1;
        p {
          b {
            opacity: 0;
          }
        }
        i {
          width: 30%;
          opacity: 1;
        }
      }
    }

    &__hidden {
      display: none;
    }
  }
`;

export default staticStyles;
