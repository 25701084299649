import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .risk-range {
    &__value {
      color: #50f4cd;
      margin-left: 4px;
    }
  }
`;

export default staticStyles;
