import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_screen-size';

  .m-vesting {
    width: 100%;
    color: #000;
    position: relative;
    margin: auto;

    &__title {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    &__subtitle {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      margin: 12px 0;

      @include respond-to(xs) {
        font-size: 14px;
      }
    }

    &__box {
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      padding: 16px;
      margin-top: 12px;

      @include respond-to(xs) {
        padding: 16px 12px;
      }
    }

    &__box-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;

      @include respond-to(xs) {
        margin-bottom: 12px;
      }
    }

    &__btn {
      min-width: 130px;

      @include respond-to(xs) {
        min-width: 110px;
      }
    }

    &__curently-row {
      margin-top: 7px;
      align-items: flex-end;

      @include respond-to(xs) {
        align-items: flex-start;
      }
    }

    &__badge {
      margin: 5px 0;

      @include respond-to(xs) {
        font-size: 12px;
      }
    }

    &__badge-label {
      opacity: 0.6;
      min-width: 72px;
      display: inline-block;

      @include respond-to(xs) {
        min-width: auto;
      }
    }

    &__item-cell {
      flex-basis: 33%;

      @include respond-to(xs) {
        font-size: 12px;
      }

      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
        justify-content: right;
      }
    }

    &__logout-btn {
      transition: 0.3s;
      outline: none;

      &:hover {
        cursor: pointer;
        color: #ffb21e;
      }

      &--disabled:hover {
        color: #000;
        cursor: default;
      }
    }

    &__logout {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
    }
  }
`;

export default staticStyles;
