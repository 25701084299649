import { createSelectors } from '../createSelectors';
import { create } from '../../libs/zustand';
import { StoreLockData } from './lock-data.model';
import { LockDurationIndex } from '../../modules/manage/components/ManageLock/ManageLock';

export const useLockDataStore = createSelectors(
  create<StoreLockData>((set, get) => ({
    isLockDataLoaded: false,
    data: {
      lockDurations: [],
      lockMultipliers: [],
      lockIndex: 0,
      autoRelockDisabled: true,
    },
    setLockData: (lockMultipliers, lockDurations, lockIndex, autoRelockDisabled) => {
      set((state) => ({
        ...state,
        data: {
          lockMultipliers,
          lockDurations,
          lockIndex: lockIndex as LockDurationIndex,
          autoRelockDisabled,
        },
        isLockDataLoaded: true,
      }));
    },
    setLockIndex: (lockIndex) => {
      set((state) => ({
        ...state,
        data: {
          ...state.data,
          lockIndex: lockIndex,
        },
      }));
    },
    setAutoRelockDisabled: (disabled) => {
      set((state) => ({
        ...state,
        data: {
          ...state.data,
          autoRelockDisabled: disabled,
        },
      }));
    },
  }))
);
