import { ChangeEvent, ReactNode } from 'react';
import { BasicAmountField } from '../../../../components/BasicAmountField/BasicAmountField';
import DefaultButton, { DefaultButtonProps } from '../../../../components/basic/DefaultButton';
import InputBar, { InputBarCallback } from '../../../../components/basic/InputBar';
import staticStyles from './style';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import classNames from 'classnames';

export function ToolsForm({
  children,
  handleSubmit,
}: {
  children: ReactNode;
  handleSubmit: (event: any) => void;
}) {
  return (
    <BasicBox variant="secondary" className="tools__inner-box">
      <form onSubmit={handleSubmit} className="tools-form">
        {children}
      </form>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicBox>
  );
}

ToolsForm.Field = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <label>
      <p className="tools-form__text">{title}</p>
      {children}
    </label>
  );
};

ToolsForm.Button = ({ children, className, ...props }: DefaultButtonProps) => {
  return (
    <DefaultButton
      {...{ ...props }}
      size="medium"
      color="primary"
      type="submit"
      className={classNames('tools-form__btn', className)}
    >
      {children}
    </DefaultButton>
  );
};

ToolsForm.Range = ({
  min,
  max,
  value,
  children,
}: {
  min: number;
  max: number;
  value: number;
  children: ({ rate, min, max }: InputBarCallback) => ReactNode;
}) => {
  return (
    <InputBar minAmount={min} maxAmount={max} value={value} className="tools-form__range">
      {children}
    </InputBar>
  );
};
