import { TheGraphResponse } from '../../apis/the-graph';
import { TheGraphApi, GRAPH_CLIENT } from '../../apis/the-graph/the-graph.api';

import { FeeDistributionGraphHelper } from './fee-distribution-graph.helper';
import { DailyDataModel, RewardDataModel, TotalFeeDataModel } from './fee-distribution-graph.model';
import {
  dayDataQuery,
  rewardsDataQuery,
  totalFeeDataQuery,
} from './fee-distribution-graph.queries';

export class FeeDistributionGraphApi {
  public static getRewardsData(): Promise<RewardDataModel[]> {
    return TheGraphApi.getGraphData(GRAPH_CLIENT, rewardsDataQuery).then(
      (response: TheGraphResponse<{ rewardDatas: RewardDataModel[] }>) =>
        FeeDistributionGraphHelper.convertRewardData(response.data.rewardDatas)
    );
  }

  public static getDailyRewardsPerDay(): Promise<DailyDataModel[]> {
    return TheGraphApi.getGraphData(GRAPH_CLIENT, dayDataQuery).then(
      (response: TheGraphResponse<{ dailyDatas: DailyDataModel[] }>) =>
        FeeDistributionGraphHelper.convertDailyData(response.data.dailyDatas)
    );
  }

  public static getTotalFeeData(): Promise<TotalFeeDataModel> {
    return TheGraphApi.getGraphData(GRAPH_CLIENT, totalFeeDataQuery).then(
      (response: TheGraphResponse<{ totalFeeData: TotalFeeDataModel }>) =>
        response.data.totalFeeData
    );
  }
}
