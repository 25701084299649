import DefaultButton from '../../../../components/basic/DefaultButton';
import { BasicModal } from '../../../../libs/aave-ui-kit';
import staticStyles from './style';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { BasicSwitcher } from '../../../../components/BasicSwitcher/BasicSwitcher';
import { BasicAmountField } from '../../../../components/BasicAmountField/BasicAmountField';
import BigNumber from 'bignumber.js';
import { valueToBigNumber } from '@aave/protocol-js';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import { DateUtils } from '../../../../utils';
import { MathUtils } from '../../../../utils/math.utils';
import {
  iota,
  MULTI_FEE_DISTRIBUTION_ADDRESS,
  REWARD_ADDRESS,
  TokensSymbolEnum,
  wIOTA_ADDRESS,
} from '../../../../shared';
import { UserSummary } from '../../../../libs/pool-data-provider';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import LockConfirmation from '../LockConfirmation';
import { Address } from 'viem';
import { MultiFeeDistributionService } from '../../../../libs/aave-protocol-js/MulteFeeDistributionContract';
import { sendEthTransaction } from '../../../../helpers/send-ethereum-tx';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import { useLockData } from '../../../../hooks';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { ReactComponent as LinkIcon } from '../../../../images/icons/link-icon.svg';

interface ManageLockProps {
  setStatsRerender: Dispatch<SetStateAction<number>>;
  totalLockedSupply: BigNumber;
  user: UserSummary;
}

export type LockDurationIndex = 0 | 1 | 2 | 3;

export function ManageLock({ user, setStatsRerender }: ManageLockProps) {
  const [lockValue, setLockValue] = useState<string>('');
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const [buttonsState, setButtonsState] = useState<Record<string, boolean>>({
    withdraw: false,
    relock: false,
    autoRelock: false,
    lockIndex: false,
  });

  const {
    lockDurations,
    lockMultipliers,
    lockApr,
    lockIndex,
    setLockIndex,
    setAutoRelockDisabled,
    autoRelockDisabled,
  } = useLockData();
  const { provider } = useProviderContext();
  const { prices } = useRewardTokenPrices();

  const { lockData, unlockable, LPTokenInfo } = useBalanceContext();

  const lockDurationsHumanized = useMemo(() => {
    return lockDurations.map((duration) => DateUtils.humanizedTime(Number(duration)));
  }, [lockDurations]);

  const multiFeeDistributionService = useMemo(
    () => new MultiFeeDistributionService(provider, REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS),
    [provider]
  );

  const handleState = (name: string, disabled: boolean) => {
    setButtonsState((state) => ({
      ...state,
      [name]: disabled,
    }));
  };

  const handleAutoRelock = useCallback(async () => {
    setAutoRelockDisabled(!autoRelockDisabled);
    const actionTx = await multiFeeDistributionService.toggleAutoRelock(
      user.id,
      autoRelockDisabled
    );

    await sendEthTransaction(
      actionTx,
      provider,
      () => {
        handleState('autoRelock', true);
      },
      null,
      {
        onConfirmation: () => {
          setAutoRelockDisabled(!autoRelockDisabled);
        },
        onError: () => {
          console.log('Error');

          setAutoRelockDisabled(autoRelockDisabled);
        },
      }
    );

    handleState('autoRelock', false);
  }, [user, provider, autoRelockDisabled]);

  const handleRelockLP = useCallback(async () => {
    const actionTx = await multiFeeDistributionService.relockLP(user.id);

    await sendEthTransaction(
      actionTx,
      provider,
      () => {
        handleState('relock', true);
      },
      null
    );

    setStatsRerender(Math.random());
    handleState('relock', false);
  }, [user, provider]);

  const handleFieldChange = (value: string) => {
    setLockValue(value);
  };

  const handleWithdrawEarnedLP = useCallback(async () => {
    const actionTx = await multiFeeDistributionService.withdrawExpiredLocks(user.id as string);

    await sendEthTransaction(
      actionTx,
      provider,
      () => {
        handleState('withdraw', true);
      },
      null
    );

    setStatsRerender(Math.random());
    handleState('withdraw', false);
  }, [user, provider]);

  const handleLockDurationChange = useCallback(
    async (index: LockDurationIndex) => {
      const actionTx = await multiFeeDistributionService.setDefaultRelockTypeIndex(
        index,
        user.id as Address
      );

      setLockIndex(index);

      await sendEthTransaction(
        actionTx,
        provider,
        () => {
          handleState('lockIndex', true);
        },
        null,
        {
          onError: () => {
            setLockIndex(lockIndex);
          },
        }
      );

      setStatsRerender(Math.random());
      handleState('lockIndex', false);
    },
    [provider, user]
  );

  return (
    <>
      <div className="m-lock">
        <div className="m-lock__title ff-lg">
          Lock {TokensSymbolEnum.REWARD}-{TokensSymbolEnum.wIOTA} LP
        </div>

        <div className="m-lock__get-lp">
          <DefaultButton
            className=""
            type="link"
            color="neutral"
            size="small"
            href={`https://app.wagmi.com/liquidity/strategies/${wIOTA_ADDRESS}/${REWARD_ADDRESS}?chain=${iota.id}`}
          >
            Get LP <LinkIcon />
          </DefaultButton>
        </div>

        <BasicAmountField
          className="m-lock__field"
          maxAmount={valueToBigNumber(LPTokenInfo.walletBalance)}
        >
          {(max) => (
            <>
              <BasicAmountField.InputBox>
                <BasicAmountField.Input
                  className="m-lock__input"
                  handleChange={handleFieldChange}
                  max={max}
                  type="number"
                  value={lockValue}
                />
                <BasicAmountField.USDValue>
                  {valueToBigNumber(prices.lpTokenPriceUsd)
                    .times(lockValue || 0)
                    .toString() || '0'}
                </BasicAmountField.USDValue>
              </BasicAmountField.InputBox>
              <BasicAmountField.TokenBox>
                {LPTokenInfo.currencySymbol ? (
                  <BasicAmountField.Asset symbol={LPTokenInfo.currencySymbol} />
                ) : null}
                <BasicAmountField.Balance
                  onClick={() => handleFieldChange(LPTokenInfo.walletBalance)}
                >
                  {LPTokenInfo.walletBalance}
                </BasicAmountField.Balance>
              </BasicAmountField.TokenBox>
            </>
          )}
        </BasicAmountField>

        <DefaultButton
          color="primary"
          size="medium"
          title="Lock"
          disabled={
            !Number(lockValue) ||
            buttonsState.lock ||
            valueToBigNumber(LPTokenInfo.walletBalance).lt(lockValue)
          }
          className="m-lock__lock-btn"
          onClick={() => setIsModalShow(true)}
        />

        <div className="m-lock__default manage__content-box">
          <p className="manage__content-title">Default lock length</p>

          {lockDurationsHumanized.length && lockMultipliers.length && (
            <div className="m-lock__default-tabs">
              <ul className="m-lock__default-tabs-list">
                {lockMultipliers.map((multiplier, index) => (
                  <li className="m-lock__default-tabs-item" key={multiplier}>
                    <DefaultButton
                      className="m-lock__default-tabs-btn"
                      size="small"
                      color={index === lockIndex ? 'neutral' : 'tab'}
                      title={lockDurationsHumanized[index]}
                      onClick={() => handleLockDurationChange(index as LockDurationIndex)}
                    />
                    <div className="m-lock__apr" key={index}>
                      <span
                        className={classNames(
                          'm-lock__apr-label',
                          lockIndex === index && 'm-lock__apr-label--highlight'
                        )}
                        key={index}
                      >
                        APR {MathUtils.truncateNumber(lockApr.times(multiplier), 2)} %
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="m-lock__relock manage__content-box">
          <p className="manage__content-title">
            Auto relock
            <BasicSwitcher
              isOn={!autoRelockDisabled}
              disabled={buttonsState.autoRelock}
              handleToggle={handleAutoRelock}
              className="m-lock__relock-switcher"
            />
          </p>

          <p className="manage__content-text">
            Automatically relock expired wLP for your default lock length of <span>12 months</span>
          </p>
        </div>

        {!unlockable.isNegative() ? (
          <>
            <div className="m-lock__relock manage__content-box">
              <p className="manage__content-title">Redeemable locks</p>

              <p className="manage__content-text">
                Relock your wLP to continue receiving platform fees
              </p>
            </div>
            <div className="manage__group">
              <div className="manage__token">
                {LPTokenInfo.currencySymbol ? (
                  <BasicToken tokenSymbol={LPTokenInfo.currencySymbol}>
                    {(asset, asset2) => (
                      <BasicToken.Image
                        className="manage__token-img"
                        icon={asset.icon}
                        icon2={asset2?.icon}
                        alt={LPTokenInfo.currencySymbol}
                        size="medium"
                      />
                    )}
                  </BasicToken>
                ) : null}

                <div className="manage__token-right">
                  <p className="manage__token-balance ff-lg">
                    {MathUtils.toLocaleNumber(unlockable, 2)}
                  </p>
                  <span className="manage__token-usd">
                    $ {MathUtils.toLocaleNumber(unlockable.multipliedBy(prices.lpTokenPriceUsd), 2)}
                  </span>
                </div>
              </div>

              <div className="m-lock__relock-btns">
                <DefaultButton
                  color="secondary"
                  size="small"
                  title="Withdraw"
                  className="m-lock__relock-btn"
                  disabled={unlockable.eq(0) || buttonsState.withdraw}
                  loading={buttonsState.withdraw}
                  onClick={handleWithdrawEarnedLP}
                />
                <DefaultButton
                  color="primary"
                  size="small"
                  title="Relock"
                  className="m-lock__relock-btn"
                  onClick={handleRelockLP}
                  disabled={unlockable.eq(0) || buttonsState.relock}
                  loading={buttonsState.relock}
                />
              </div>
            </div>
          </>
        ) : null}

        {lockData.length > 0 ? (
          <BasicBox className="manage__content-box m-lock__unlock">
            {lockData.map(({ amount, multiplier, unlockTime }, index) => {
              const timeLeft = DateUtils.calculateTimeLeft(unlockTime as string);

              return (
                <div className="m-lock__unlock-row" key={index}>
                  <div className="m-lock__unlock-token">
                    <p className="m-lock__unlock-sub">{multiplier.toString()}x</p>
                    <p className="m-lock__unlock-value">{MathUtils.toLocaleNumber(amount, 3)}</p>

                    {LPTokenInfo.currencySymbol ? (
                      <BasicToken tokenSymbol={LPTokenInfo.currencySymbol}>
                        {(asset, asset2) => (
                          <BasicToken.Image
                            className="m-lock__unlock-img"
                            icon={asset.icon}
                            icon2={asset2?.icon}
                            alt={LPTokenInfo.currencySymbol}
                            size="small"
                          />
                        )}
                      </BasicToken>
                    ) : null}
                  </div>
                  <p className="m-lock__unlock-sub">
                    {Number(timeLeft) ? <>{timeLeft} days</> : <>{timeLeft}</>}
                  </p>
                </div>
              );
            })}
          </BasicBox>
        ) : null}
      </div>

      <BasicModal onBackdropPress={() => setIsModalShow(false)} isVisible={isModalShow}>
        <BasicModal.Close onClose={() => setIsModalShow(false)} />
        <BasicModal.Header>Lock IOL-wIOTA LP</BasicModal.Header>
        <BasicModal.Content>
          <LockConfirmation
            userId={user.id as Address}
            amount={valueToBigNumber(lockValue)}
            maxAmount={valueToBigNumber(LPTokenInfo.walletBalance)}
            lockDuration={lockIndex}
            onMainTxConfirmed={() => {
              setStatsRerender(Math.random());
            }}
            onAfterSuccessClick={() => {
              setLockValue('');
              setIsModalShow(false);
            }}
          />
        </BasicModal.Content>
      </BasicModal>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
