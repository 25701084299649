import { useCallback, useEffect, useMemo, useState } from 'react';
import { MathUtils } from '../../../../utils/math.utils';
import { ToolsForm } from '../ToolsForm/ToolsForm';
import { useToolsStore } from '../../../../store';
import { BasicAmountField } from '../../../../components/BasicAmountField/BasicAmountField';
import { valueToBigNumber } from '@aave/math-utils';
import { ToolsHelper } from '../../tools.helper';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import { LEVEERAGE_CONTRACT_ADDRESS } from '../../../../libs/ToolsContract/typechain/factories/Tools__factory';
import { ToolsContract } from '../../../../libs/ToolsContract';
import { BasicModal, getAssetInfo } from '../../../../libs/aave-ui-kit';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import { UserSummary } from '../../../../libs/pool-data-provider';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import { Address } from 'viem';
import { ReserveWithBalance } from '../../hooks';
import { SwapDataType } from '../../tools.types';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

export function DeleverageForm({
  selectedA,
  selectedB,
  user,
}: {
  selectedA: ReserveWithBalance | null;
  selectedB: ReserveWithBalance | null;
  user: UserSummary;
}) {
  const { provider } = useProviderContext();
  const repayReceiverAddress = useToolsStore.use.repayReceiver();
  const [amountA, setAmountA] = [useToolsStore.use.amountA(), useToolsStore.use.setAmountA()];
  const [amountB, setAmountB] = [useToolsStore.use.amountB(), useToolsStore.use.setAmountB()];
  const [step, setStep] = useState<Steps>(Steps.amount);
  const [swapData, setSwapData] = useState<SwapDataType>({
    outAmountWithFee: '',
    data: { to: '', data: '' },
  });

  const isASelected = selectedA !== null;
  const isBSelected = selectedB !== null;
  const isSelected = isASelected && isBSelected;

  const toolsContract = new ToolsContract(provider, LEVEERAGE_CONTRACT_ADDRESS);

  const isDisabled = useMemo(() => {
    const bigIntA = valueToBigNumber(amountA);
    const bigIntB = valueToBigNumber(amountB);

    if (isSelected) {
      return !bigIntA.gt(0) || bigIntA.gt(selectedA.balance) || bigIntB.gt(selectedB.balance);
    } else {
      return true;
    }
  }, [isSelected, selectedA, selectedB, amountA, amountB]);

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      if (isSelected) {
        const borrowAmountWithFee = ToolsHelper.getFinalRepayAmount(amountA);

        const swapData = await ToolsHelper.getSwapData({
          account: repayReceiverAddress,
          tokenA: selectedA,
          tokenB: selectedB,
          repaymentAmount: borrowAmountWithFee,
        });

        setSwapData(swapData);
        setStep(Steps.confirmation);
      }
    },
    [provider, selectedA, amountA, amountB, selectedB]
  );

  const handleGetTransactions = useCallback(() => {
    return toolsContract.flashRepay({
      borrow: selectedA as ReserveWithBalance,
      borrowAmount: amountA,
      repayWith: selectedB as ReserveWithBalance,
      user: user.id as Address,
      repayReceiverAddress,
      swapData,
    });
  }, [amountA, amountB, swapData, provider, user, repayReceiverAddress]);

  useEffect(() => {
    if (selectedA && selectedB) {
      const repayAmount = ToolsHelper.calculateRepayAmount({
        selectedA: selectedA,
        selectedB: selectedB,
        value: amountA,
      });

      setAmountB(!repayAmount.isNaN() ? repayAmount.toString() : '');
    }
  }, [selectedB, selectedA, amountA]);

  return (
    <>
      <ToolsForm {...{ handleSubmit }}>
        <ToolsForm.Field title="Repaying">
          <BasicAmountField className="tools-form__field" size="small">
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                className="tools-form__input"
                handleChange={setAmountA}
                value={amountA}
              />
              <BasicAmountField.USDValue>
                {isSelected
                  ? MathUtils.toLocaleNumber(
                      valueToBigNumber(amountA).times(selectedA?.priceInMarketReferenceCurrency),
                      2
                    )
                  : 0}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              {isASelected && (
                <>
                  <BasicAmountField.Asset symbol={selectedA?.symbol} />
                  <BasicAmountField.Balance
                    className="tools-form__balance"
                    onClick={() => setAmountA(selectedA.balance)}
                  >
                    {selectedA?.balance}
                  </BasicAmountField.Balance>
                </>
              )}
            </BasicAmountField.TokenBox>
          </BasicAmountField>
        </ToolsForm.Field>
        <ToolsForm.Field title="Repaying with">
          <BasicAmountField className="tools-form__field" size="small">
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                className="tools-form__input"
                handleChange={() => {}}
                value={amountB}
              />
              <BasicAmountField.USDValue>
                {MathUtils.toLocaleNumber(
                  valueToBigNumber(amountB).times(selectedB?.priceInMarketReferenceCurrency || 0),
                  2
                )}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              {isBSelected && (
                <>
                  <BasicAmountField.Asset symbol={selectedB?.symbol} />
                  <BasicAmountField.Balance className="tools-form__balance" onClick={() => {}}>
                    {selectedB.balance}
                  </BasicAmountField.Balance>
                </>
              )}
            </BasicAmountField.TokenBox>
          </BasicAmountField>
        </ToolsForm.Field>

        <ToolsForm.Button disabled={isDisabled} className="tools-form__btn--deleverage">
          Deleverage
        </ToolsForm.Button>
      </ToolsForm>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        {isSelected ? (
          <PoolTxConfirmationView
            mainTxName={'Deleverage'}
            caption={'Deleverage overview'}
            boxTitle={'Deleverage'}
            boxDescription={'Please submit to Deleverage'}
            approveDescription={'Please approve before Deleverage'}
            getTransactionsData={handleGetTransactions}
            blockingError=""
            aTokenData={getAtokenInfo({
              address: selectedB.underlyingAsset,
              symbol: getAssetInfo(selectedB.symbol).symbol,
              decimals: selectedB.decimals,
            })}
          />
        ) : null}
      </BasicModal>
    </>
  );
}
