import staticStyles from './style';
import { ethers } from 'ethers';
import network from '../../libs/aave-ui-kit/helpers/assets-list/icons/iota-network.svg';
import { iota } from '../../shared';
import { useProviderContext } from '../../libs/provider/WalletProvider';

export default function IncorrectNetworkAlert() {
  const neededChain = iota;
  const { chainId, Web3Provider } = useProviderContext();

  return chainId && chainId !== neededChain.id ? (
    <div className="IncorrectNetworkAlert">
      <div className="IncorrectNetworkAlert__content">
        <div className="IncorrectNetworkAlert__content__titles">
          <div className="IncorrectNetworkAlert__content__titles__title">
            Connected to wrong network
          </div>
          <div className="IncorrectNetworkAlert__content__titles__sub-title">
            Switch to IOTA network
          </div>
        </div>
        <div className="IncorrectNetworkAlert__content__button">
          <button
            onClick={async () => {
              if (Web3Provider) {
                try {
                  await Web3Provider.send('wallet_switchEthereumChain', [
                    { chainId: ethers.utils.hexValue(neededChain.id) },
                  ]);

                  // window.location.reload();
                } catch (switchError) {
                  await Web3Provider.send('wallet_addEthereumChain', [
                    {
                      chainId: ethers.utils.hexValue(neededChain.id),
                      chainName: neededChain.name,
                      rpcUrls: [neededChain.rpcUrls.default.http],
                      nativeCurrency: neededChain.nativeCurrency,
                    },
                  ]);
                }
              }
            }}
          >
            <>
              <img src={network} alt="metis icon" /> Switch to IOTA
            </>
          </button>
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  ) : (
    <></>
  );
}
