import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .CircleCompositionBar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 184px;
    height: 184px;
    border-radius: 50%;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
    }

    &__inner {
      position: absolute;
      padding: 20px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #000;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }

    &__value {
      font-size: 24px;
      font-weight: 500;
      display: block;

      &:before {
        content: '$';
      }
    }
  }
`;

export default staticStyles;
