export type WagmiResponse<T> =
  | {
      error: Error;
      result?: undefined;
      status: 'failure';
    }
  | {
      error?: undefined;
      result: T;
      status: 'success';
    };

export const iota = {
  id: 8822,
  name: 'IOTA',
  network: 'iota-evm',
  nativeCurrency: {
    decimals: 18,
    name: 'IOTA',
    symbol: 'IOTA',
  },
  rpcUrls: {
    default: {
      http: ['https://json-rpc.evm.iotaledger.net', 'https://iota-mainnet-evm.public.blastapi.io'],
    },
    public: {
      http: ['https://json-rpc.evm.iotaledger.net', 'https://iota-mainnet-evm.public.blastapi.io'],
    },
  },
  blockExplorers: {
    default: { name: 'Iota Explorer', url: 'https://explorer.evm.iota.org/' },
  },
};

export const kava = {
  id: 2222,
  name: 'Kava',
  network: 'kava-evm',
  nativeCurrency: {
    decimals: 18,
    name: 'KAVA',
    symbol: 'KAVA',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/kava_evm'],
    },
    public: {
      http: ['https://rpc.ankr.com/kava_evm'],
    },
  },
  blockExplorers: {
    default: { name: 'Kava Explorer', url: 'https://explorer.kava.io' },
  },
};
