import { useEffect, useState } from 'react';
import { ComputedReserveData } from '../../../../libs/pool-data-provider';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import { BigNumber } from 'ethers';
import { interestRateStrategyAbi } from './interestRateStrategyAbi';
import { MulticallUtils, Web3Utils } from '../../../../utils';
import { BorrowRateHelper } from './borrowRate.helper';
import { MathUtils } from '../../../../utils/math.utils';

export function useBorrowRates(poolReserve: ComputedReserveData) {
  const { provider } = useProviderContext();
  const [borrowRates, setBorrowRates] = useState<number[]>([]);

  useEffect(() => {
    if (provider) {
      (async () => {
        const multicall = MulticallUtils.createMulltical(provider);

        const {
          results: { data },
        } = await multicall.call([
          {
            reference: 'data',
            contractAddress: poolReserve.interestRateStrategyAddress,
            abi: interestRateStrategyAbi,
            calls: [
              {
                reference: 'excessUtilizationRate',
                methodName: 'EXCESS_UTILIZATION_RATE',
                methodParameters: [],
              },
              {
                reference: 'optimalUtilizationRate',
                methodName: 'OPTIMAL_UTILIZATION_RATE',
                methodParameters: [],
              },
              {
                reference: 'baseVariableBorrowRate',
                methodName: 'baseVariableBorrowRate',
                methodParameters: [],
              },
            ],
          },
        ]);

        const parsedData = MulticallUtils.convertData(data);

        const result = [];

        for (let i = 0; i <= 1; i += 0.1) {
          const calcResult = Number(
            Web3Utils.formatValue(
              BorrowRateHelper.calculateVariableBorrowRate(
                BigNumber.from(Web3Utils.toWei(i, 27)),
                BigNumber.from(parsedData.baseVariableBorrowRate),
                BigNumber.from(poolReserve.variableRateSlope1),
                BigNumber.from(poolReserve.variableRateSlope2),
                BigNumber.from(parsedData.optimalUtilizationRate),
                BigNumber.from(parsedData.excessUtilizationRate)
              ),
              27
            )
          );

          result.push(Number(MathUtils.formatNumber(calcResult * 100, 2)));
        }

        setBorrowRates(result);
      })();
    }
  }, [provider]);

  return borrowRates;
}
