import CircleCompositionBar, {
  CircleCompositionBarItem,
} from '../../../../components/compositionBars/CircleCompositionBar';
import { getAssetColor, getAssetInfo } from '../../../../helpers/config/assets-config';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { valueToBigNumber, BigNumber } from '@aave/protocol-js';
import staticStyles from './style';
import { useIntl } from 'react-intl';
import { ComputedUserReserve } from '@aave/math-utils';
import { useMemo } from 'react';

export function DashboardBorrow() {
  const { user, reserves } = useDynamicPoolDataContext();
  const intl = useIntl();
  const maxBorrowAmount = valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').plus(
    user?.availableBorrowsMarketReferenceCurrency || '0'
  );
  const borrowCompositionData: CircleCompositionBarItem[] = [];

  const borrowList = useMemo(() => {
    const userReservesData = [];

    if (user) {
      for (let i = 0; i < user.userReservesData.length; i++) {
        const _userReserve = user.userReservesData[i];
        const value = valueToBigNumber(_userReserve.totalBorrowsMarketReferenceCurrency)
          .dividedBy(maxBorrowAmount)
          .multipliedBy(100)
          .toNumber();

        if (value > 0) {
          userReservesData.push(_userReserve);
        }
      }

      return userReservesData
        .filter(
          (_userReserve: ComputedUserReserve) =>
            Number(_userReserve.totalBorrowsMarketReferenceCurrency) > 0
        )
        .map((_userReserve: ComputedUserReserve) => {
          const asset = getAssetInfo(_userReserve.reserve.symbol);
          const label: string = `${intl.formatNumber(
            valueToBigNumber(_userReserve.totalBorrowsMarketReferenceCurrency)
              .dividedBy(maxBorrowAmount)
              .multipliedBy(100)
              .toNumber(),
            { maximumFractionDigits: 2 }
          )}%`;

          const value: number = valueToBigNumber(_userReserve.totalBorrowsMarketReferenceCurrency)
            .dividedBy(maxBorrowAmount)
            .multipliedBy(100)
            .toNumber();

          return {
            symbol: asset.symbol,
            label: label,
            disabled: value === 0,
            color: getAssetColor(_userReserve.reserve.symbol),
          };
        });
    }
  }, [user, reserves, maxBorrowAmount]);

  user?.userReservesData.forEach((userReserve) => {
    const poolReserve = reserves.find((res) => {
      return (
        res.symbol === (userReserve.reserve.symbol === 'ETH' ? 'WETH' : userReserve.reserve.symbol)
      );
    });

    if (!poolReserve) {
      throw new Error('data is inconsistent pool reserve is not available');
    }
    if (userReserve.totalBorrows !== '0') {
      borrowCompositionData.push({
        label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
          valueToBigNumber(userReserve.totalBorrowsMarketReferenceCurrency)
            .dividedBy(maxBorrowAmount)
            .multipliedBy(100)
            .toNumber(),
          { maximumFractionDigits: 2 }
        )}%`,
        value: Number(userReserve.totalBorrowsMarketReferenceCurrency),
        color: getAssetColor(userReserve.reserve.symbol),
      });

      const availableBorrowPower = borrowCompositionData
        .reduce((acc, slice) => acc.minus(slice.value), maxBorrowAmount)
        .toNumber();
      const usedBorrowPower = borrowCompositionData
        .reduce((acc, slice) => acc.plus(slice.value), new BigNumber(0))
        .toNumber();

      borrowCompositionData.push({
        value: availableBorrowPower,
        label: `Borrowing power available: ${intl.formatNumber(
          new BigNumber(1)
            .minus(valueToBigNumber(usedBorrowPower).dividedBy(maxBorrowAmount))
            .multipliedBy(100)
            .toNumber(),
          {
            maximumFractionDigits: 2,
          }
        )}%`,
        color: '#C0C0C0',
      });
    }
  });

  return (
    <div className="DashboardBorrow">
      <div className="DashboardBorrow__left">
        <CircleCompositionBar
          title="Borrow"
          totalValue={Number(user?.totalBorrowsMarketReferenceCurrency || 0)}
          data={borrowCompositionData}
        ></CircleCompositionBar>
      </div>
      <div className="DashboardBorrow__body">
        <p className="DashboardBorrow__title">Borrow composition</p>

        <ul className="DashboardBorrow__tokens">
          {borrowList?.map((item) => (
            <li className="DashboardBorrow__token" key={item.symbol}>
              <p>{item.symbol}</p>
              <span>{item?.label}</span>
            </li>
          ))}
        </ul>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
