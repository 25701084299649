import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Value {
    text-align: center;
    &__symbol {
      font-weight: 400;
      margin-left: 5px;
      white-space: nowrap;
    }

    &__withSmallDecimals {
      .Value__symbol {
        font-size: $regular;
        font-weight: 400;
        margin-left: 3px;
        @include respond-to(xl) {
          font-size: $small;
        }
      }
    }

    .Value__tooltip {
      // margin-left: -47px;
      padding: 5px 10px;
      font-size: $medium;
      font-weight: 600;
      @include respond-to(xl) {
        font-size: $small;
      }
      white-space: nowrap;
    }
  }
`;

export default staticStyles;
