import React, { PropsWithChildren, useContext } from 'react';
import { LendingPool, FaucetService } from '@aave/contract-helpers';

import { useProtocolDataContext } from '../protocol-data-provider';
import { useProviderContext } from '../provider/WalletProvider';
import { ZAP_CONTRACT_ADDRESS } from '../aave-protocol-js/ZapContract/typechain/factories/Zap__factory';
import { ZapService } from '../aave-protocol-js/ZapContract';

export interface TxBuilderContextInterface {
  lendingPool: LendingPool;
  Zap: ZapService;
  faucetService: FaucetService;
}

const TxBuilderContext = React.createContext({} as TxBuilderContextInterface);

export function TxBuilderProvider({ children }: PropsWithChildren<{}>) {
  const { currentMarketData } = useProtocolDataContext();
  const { provider } = useProviderContext();

  const lendingPool = new LendingPool(provider, {
    LENDING_POOL: currentMarketData.addresses.LENDING_POOL,
    REPAY_WITH_COLLATERAL_ADAPTER: currentMarketData.addresses.REPAY_WITH_COLLATERAL_ADAPTER,
    SWAP_COLLATERAL_ADAPTER: currentMarketData.addresses.SWAP_COLLATERAL_ADAPTER,
    WETH_GATEWAY: currentMarketData.addresses.WETH_GATEWAY,
  });

  const Zap = new ZapService(provider, ZAP_CONTRACT_ADDRESS);

  const faucetService = new FaucetService(provider, currentMarketData.addresses.FAUCET);

  return (
    <TxBuilderContext.Provider value={{ lendingPool, Zap, faucetService }}>
      {children}
    </TxBuilderContext.Provider>
  );
}

export const useTxBuilderContext = () => useContext(TxBuilderContext);
