import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .reserve-deposits {
    margin-top: 12px;

    @include respond-to(md) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 8px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      padding: 5px 0;

      @include respond-to(md) {
        background-color: rgba(0, 0, 0, 0.01);
        border: 1px solid #d9d9d9;
        padding: 8px;
        border-radius: 4px;
      }
    }

    &__balance {
      text-align: right;

      p {
        font-size: 16px;
        font-weight: 500;
      }

      span {
        display: block;
        margin-top: 6px;
        font-size: 12px;
        opacity: 0.6;
      }
    }
  }
`;

export default staticStyles;
