import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .tools-form {
    flex: 1;

    &__field {
      margin: 4px 0;
    }

    &__token-box {
      justify-content: center;
      padding: 0;
    }

    &__text {
      font-size: 20px;
      font-weight: 600;
      margin: 12px 0 8px;
    }

    &__btn {
      width: 100%;
      margin-top: 35px;

      &--deleverage {
        margin-top: 90px;
      }
    }

    &__range {
      margin-top: 32px;
    }
  }
`;

export default staticStyles;
