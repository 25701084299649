import { ReactNode } from 'react';
import classNames from 'classnames';
import { useThemeContext } from '../../../libs/aave-ui-kit';
import PreloaderOver from '../PreloaderOver';

export interface DefaultButtonProps {
  onClick?: (event: any) => void;
  title?: string;
  type?: 'button' | 'submit' | 'link';
  color?: 'primary' | 'secondary' | 'neutral' | 'tab';
  size?: 'large' | 'medium' | 'small';
  loading?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  className?: string;
  onDarkBackground?: boolean;
  children?: ReactNode;
  fill?: boolean;
  href?: string;
}

export default function DefaultButton({
  onClick,
  title,
  type = 'button',
  color = 'primary',
  size = 'medium',
  rounded,
  disabled,
  loading,
  className,
  children,
  fill,
  href,
}: DefaultButtonProps) {
  const { currentTheme } = useThemeContext();
  const classes = classNames(
    `DefaultButton`,
    `DefaultButton__${color}`,
    `DefaultButton__${size}`,
    {
      DefaultButton__fill: fill,
      DefaultButton__rounded: rounded,
      DefaultButton__loading: loading,
    },
    className
  );

  return (
    <>
      {type === 'link' ? (
        <a
          className={classes}
          title={title}
          onClick={onClick}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {title || children}
        </a>
      ) : (
        <button className={classes} title={title} disabled={disabled} onClick={onClick} type={type}>
          {title || children}

          <PreloaderOver isShown={Boolean(loading)} />
        </button>
      )}

      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .DefaultButton {
          color: rgba(0, 0, 0, 0.8);
          border: 1px solid;
          border-radius: 4px;
          font-size: 14px;
          white-space: nowrap;
          font-weight: 600;
          min-width: 88px;
          transition-property: color, background;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          @include respond-to(xs) {
            font-size: 13px;
          }

          &__fill {
            width: 100%;
          }

          &__loading {
            color: transparent !important;
            position: relative;

            .preloader-over {
              scale: 0.5;
              backdrop-filter: none;
            }

            .Preloader__dot {
              filter: grayscale(1);
              &:last-of-type {
                display: none;
              }
            }
          }

          &__primary {
            background-color: #52679d;
            color: #fff;
            border-color: transparent;

            &:hover {
              background-color: #6981c1;
            }

            &:active {
              background-color: #566a9b;
            }

            &:disabled,
            &:disabled:hover {
              opacity: 0.6;
              background-color: #52679d;
            }
          }

          &__secondary {
            background-color: #fffdf6;
            border-color: rgba(0, 0, 0, 0.8);

            &:hover {
              background-color: rgba(221, 221, 221, 0.2);
            }

            &:active {
              background-color: rgba(221, 221, 221, 0.4);
            }

            &:disabled,
            &:disabled:hover {
              color: #000;
              background: none;
              opacity: 0.5;
            }
          }

          &__neutral {
            color: #020180;
            border-color: transparent;
            border-bottom: 1px solid;
            border-radius: 0;

            &:hover {
              color: #000;
            }

            &:disabled,
            &:disabled:hover {
              border-bottom-color: rgba(0, 0, 0, 0.6);
              opacity: 0.5;
            }
          }

          &__tab {
            color: rgba(0, 0, 0, 0.6);
            border-color: transparent;
            border-radius: 0;

            &:hover {
              border-bottom-color: rgba(0, 0, 0, 0.6);
            }

            &:disabled,
            &:disabled:hover {
              opacity: 0.6;
            }
          }

          &__rounded {
            border-radius: 50px;
          }

          &__small {
            padding: 8px 16px;
          }

          &__medium {
            padding: 11px 16px 12px;
          }

          &__large {
            padding: 16px;
          }

          &:disabled {
            cursor: default;
          }
        }
      `}</style>
    </>
  );
}
