import TableButtonColumn from './TableButtonColumn';
import { ReactElement, useState } from 'react';
import TableAprCol from './TableAprCol';
import { ReactComponent as ArrowDown } from '../../../../images/icons/arrow-down.svg';
import classNames from 'classnames';
import { BasicSwitcher } from '../../../../components/BasicSwitcher/BasicSwitcher';
import BasicTable from '../../../../components/BasicTable';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { Asset } from '../../../../libs/aave-ui-kit/helpers/assets-list';
import TableValueCol from './TableValueCol';
import staticStyles from './style';
import { DepositTableItem } from '../../../../shared';

export default function DepositItem(props: DepositTableItem): ReactElement {
  const {
    reserve,
    usageAsCollateralEnabledOnUser,
    usageAsCollateralEnabledOnThePool,
    underlyingBalance,
    underlyingBalanceUSD,
    onToggleSwitch,
    isActive,
    isFrozen,
    aincentivesAPR,
    onDeposit,
    onWithdraw,
    depositAPY,
  } = props;
  const { symbol } = reserve;
  const [isShown, setIsShown] = useState(false);

  const apr = Number(aincentivesAPR).toFixed(6);

  return (
    <>
      <BasicTable.Row
        className={classNames('dashboard-table__row', isShown && 'active')}
        variant="secondary"
      >
        <div className="dashboard-table__top">
          <BasicTable.Item>
            <BasicToken tokenSymbol={symbol}>
              {(asset: Asset) => (
                <>
                  <BasicToken.Image icon={asset.icon} size="medium" />
                  <BasicToken.Name name={asset.symbol} />
                </>
              )}
            </BasicToken>
          </BasicTable.Item>
          <BasicTable.Item>
            <TableValueCol
              value={Number(underlyingBalance)}
              subValue={Number(underlyingBalanceUSD)}
            />
          </BasicTable.Item>

          <BasicTable.Item>
            <TableAprCol
              type="deposit"
              value={depositAPY || 0}
              liquidityMiningValue={apr}
              symbol={symbol}
            />
          </BasicTable.Item>
          <BasicTable.Item>
            <BasicSwitcher
              className="dashboard-table__row-switcher"
              isOn={usageAsCollateralEnabledOnUser}
              handleToggle={onToggleSwitch}
              disabled={!usageAsCollateralEnabledOnThePool}
            />
            <span className="cell-label">Collateral</span>
          </BasicTable.Item>
          <button
            className="dashboard-table__row-button"
            onClick={() => setIsShown((isShown) => !isShown)}
          >
            <ArrowDown className="dashboard-table__row-arrow" />
          </button>
        </div>

        <div className="dashboard-table__row-footer">
          <TableButtonColumn
            className="TableButtonCol__withdraw"
            disabled={!isActive}
            title="Withdraw"
            color="secondary"
            onClick={onWithdraw}
          />
          <TableButtonColumn
            disabled={!isActive || isFrozen}
            title="Deposit"
            onClick={onDeposit}
            color="primary"
          />
        </div>
        <style jsx={true}>{staticStyles}</style>
      </BasicTable.Row>
    </>
  );
}
