import { useState } from 'react';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { UserSummary, ComputedReserveData } from '../../libs/pool-data-provider';
import { ComputedUserReserve } from '@aave/math-utils';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import { useWithdrawData } from './useWithdrawData';
import DefaultButton from '../basic/DefaultButton';
import WithdrawConfirmationModal from '../modals/WithdrawModal/confirmation';
import { MathUtils } from '../../utils/math.utils';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

interface BorrowAmountProps {
  poolReserve: ComputedReserveData;
  symbol: string;
  user: UserSummary;
  userReserve: ComputedUserReserve;
}

export function WithdrawWidget({ userReserve, poolReserve, user, symbol }: BorrowAmountProps) {
  const [amountToWithdraw, setAmountToWithdraw] = useState<BigNumber>(BigNumber(0));
  const [step, setStep] = useState<string>(Steps.amount);
  const [fieldValue, setFieldValue] = useState<string>('');

  const { maxUserAmountToWithdraw } = useWithdrawData({
    user,
    poolReserve,
    userReserve,
  });

  const handleAmountChange = (value: string) => {
    setFieldValue(value);
    setAmountToWithdraw(BigNumber(value || 0));
  };

  const handleSetAmountSubmit = (amount: string) => {
    setStep(Steps.confirmation);
  };

  return (
    <>
      <BasicAmountField
        className="reserve-widget__field vest-widget__field"
        maxAmount={maxUserAmountToWithdraw}
      >
        {(maxAmount) => (
          <>
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                handleChange={handleAmountChange}
                value={fieldValue}
                max={maxAmount}
              />
              <BasicAmountField.USDValue>
                {MathUtils.truncateNumber(
                  valueToBigNumber(fieldValue).times(poolReserve.priceInMarketReferenceCurrency),
                  10
                )}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              <BasicAmountField.Asset symbol={symbol} />
              <BasicAmountField.Balance onClick={() => handleAmountChange(maxAmount.toString())}>
                {maxAmount.toString()}
              </BasicAmountField.Balance>
            </BasicAmountField.TokenBox>
          </>
        )}
      </BasicAmountField>

      <DefaultButton
        fill
        className="reserve-widget__button"
        onClick={handleSetAmountSubmit}
        disabled={amountToWithdraw.toNumber() <= 0}
      >
        Continue
      </DefaultButton>

      <WithdrawConfirmationModal
        currencySymbol={symbol}
        userReserve={userReserve}
        poolReserve={poolReserve}
        amount={amountToWithdraw}
        user={user}
        showModal={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      />
    </>
  );
}
