import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .LiquidityMiningAPYLine {
    position: relative;
    padding: 5px 8px 4px;
    margin-top: 3px;
    border-radius: 15px;

    background: rgba(0, 0, 0, 0.08);

    display: flex;
    align-items: center;
    justify-content: center;
    &.noBorder {
      border: none;
      box-shadow: none;
    }

    &__withTooltip {
      cursor: pointer;
    }

    &__tribe {
      display: flex;
      align-items: center;
      justify-content: center;
      strong {
        margin: 0 3px;
        @include respond-to(lg) {
          position: relative;
          top: 1px;
        }
        @include respond-to(md) {
          top: 0;
        }
      }
      img {
        width: 12px;
        height: 12px;
        position: relative;
        @include respond-to(xl) {
          width: 10px;
          height: 10px;
        }
      }
    }

    .ValuePercent,
    &__title {
      font-size: 14px;
      color: #000;
    }

    &__title {
      opacity: 0.6;
      font-weight: 300;
      margin-left: 4px;
    }

    .ValuePercent {
      margin: 0 3px;
      font-weight: 500;
    }

    .TokenIcon {
      &__image {
        margin-right: 0;
      }
    }

    .LiquidityMiningAPYLine__tooltip {
      max-width: 550px;
      display: block;
      padding: 7px 10px;
      border-radius: 8px;
      box-shadow: $boxShadow;
      background-color: #16151b;
      box-shadow: 8px 8px 13px rgba(0, 0, 0, 0.3), -2px -2px 16px rgba(255, 255, 255, 0.06);
      @include respond-to(xl) {
        max-width: 380px;
      }
    }

    &__tooltip--content {
      font-size: $medium;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
    }
  }
`;

export default staticStyles;
