import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .Lock_OLDAndEarn {
    color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
    margin: auto;
    flex-basis: 100%;
    border-radius: 16px;
    position: relative;
    margin-top: 23px;
    align-items: center;

    @include respond-to(md) {
      margin: 16px 0 0;
      padding: 0;
    }

    @include respond-to(xs) {
      grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
    }

    &__item {
      display: flex;
      flex-direction: row;
      width: 160px;
      font-style: normal;
      font-size: 12px;
      padding: 5px;
      border: 1px solid rgba(217, 217, 217, 1);
      gap: 8px;
      border-radius: 50px;
      line-height: 1.2;
      @include respond-to(xs) {
        width: auto;
      }

      &__info__symbol {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
      }

      span {
        font-weight: 500;
      }
    }
  }
`;

export default staticStyles;
