import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { valueToBigNumber, BigNumber } from '@aave/protocol-js';

import staticStyles from './style';

interface HealthFactorProps {
  value: string;
  title?: string;
  helpIconSize?: number;
  updateCondition?: boolean;
  className?: string;
  withoutModal?: boolean;
  withoutTitle?: boolean;
  withTextShadow?: boolean;
  titleColor?: 'dark' | 'white';
  titleLightWeight?: boolean;
  isColumn?: boolean;
  onWhiteBackground?: boolean;
  withHALLink?: boolean;
  valueColor?: string;
}

export enum HealthFactorEnum {
  Danger = 1.2,
  Normal = 2,
  Max = 5,
}

export default function HealthFactor({ value, updateCondition }: HealthFactorProps) {
  const [newValue, setNewValue] = useState(value);
  const updateValue = updateCondition ? undefined : value;

  useEffect(() => {
    setNewValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateValue]);

  const formattedHealthFactor = Number(valueToBigNumber(newValue).toFixed(2, BigNumber.ROUND_DOWN));

  const healthFactorType = useMemo(() => {
    if (valueToBigNumber(newValue).lte(0)) {
      return '';
    } else if (valueToBigNumber(newValue).lte(HealthFactorEnum.Danger)) {
      return 'danger';
    } else if (valueToBigNumber(newValue).lte(HealthFactorEnum.Normal)) {
      return 'normal';
    } else {
      return 'safe';
    }
  }, [newValue]);

  return (
    <span className={classNames('HealthFactor', `HealthFactor__${healthFactorType}`)}>
      {formattedHealthFactor <= 0 ? '-' : formattedHealthFactor}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </span>
  );
}
