import { ContractTransaction, ethers, providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import { tEthereumAddress } from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { GeistToken__factory, _abi } from './typechain/GeistToken__factory';
import { GeistToken } from './typechain/GeistToken';
import { MulticallUtils, Web3Utils } from '../../../utils';

export class GeistTokenContract extends BaseService<GeistToken> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;

  constructor(provider: providers.Provider, contractAddress: string) {
    super(provider, GeistToken__factory);

    this.contractAddress = contractAddress;
    this.erc20Service = new ERC20Service(provider);
  }

  public async getInfo(
    user: tEthereumAddress | undefined
  ): Promise<{ walletBalance: string; currencySymbol: string; totalSupply: string }> {
    const { decimalsOf } = this.erc20Service;

    const decimals = await decimalsOf(this.contractAddress);
    const multicall = MulticallUtils.createMulltical(this.provider);
    const {
      results: { info },
    } = await multicall.call([
      {
        reference: `info`,
        contractAddress: this.contractAddress,
        abi: _abi,
        // @ts-ignore
        calls: [
          { reference: 'symbol', methodName: 'symbol', methodParameters: [] },
          {
            reference: 'balanceOf',
            methodName: 'balanceOf',
            methodParameters: [user ? user : ethers.constants.AddressZero],
          },
          { reference: 'totalSupply', methodName: 'totalSupply', methodParameters: [] },
        ],
      },
    ]);

    const { symbol, balanceOf, totalSupply } = MulticallUtils.convertData(info);

    const walletBalance = Web3Utils.formatValue(balanceOf.hex, decimals);
    const total = Web3Utils.formatValue(totalSupply.hex, decimals);

    return {
      walletBalance: Number(walletBalance) === 0 ? '0' : walletBalance,
      currencySymbol: symbol,
      totalSupply: total,
    };
  }

  public async totalSupply() {
    const geistTokenContract: GeistToken = this.getContractInstance(this.contractAddress);

    return await geistTokenContract.totalSupply();
  }

  public async balanceOf(address: string) {
    const geistTokenContract: GeistToken = this.getContractInstance(this.contractAddress);

    return await geistTokenContract.balanceOf(address);
  }

  public async mint(user: tEthereumAddress): Promise<ContractTransaction> {
    const geistTokenContract: GeistToken = this.getContractInstance(this.contractAddress);

    return geistTokenContract.mint(user, 100);
  }
}
