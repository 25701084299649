import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import BigNumber from 'bignumber.js';
import { valueToBigNumber } from '@aave/protocol-js';

import { useProtocolDataContext } from '../protocol-data-provider';
import { useStaticPoolDataContext } from '../pool-data-provider';
import { GeistTokenContract } from '../aave-protocol-js/GeistTokenContract';
import { MultiFeeDistributionService } from '../aave-protocol-js/MulteFeeDistributionContract';
import { useProviderContext } from '../provider/WalletProvider';
import {
  STAKE_ADDRESS,
  REWARD_ADDRESS,
  TokensSymbolEnum,
  CHEF_INCENTIVES_CONTROLLER,
  MULTI_FEE_DISTRIBUTION_ADDRESS,
} from '../../shared';

import { ChefIncentivesService } from '../aave-protocol-js/ChefIncentivesContract';
import { useFeeDistribution, useRewardsData } from '../../store';
import { TokenUtils } from '../../utils';
import { Address } from 'viem';
import { ManageHelper } from '../../modules/manage/helpers';
import { ethers } from 'ethers';
import useTotalData from '../aave-protocol-js/hooks/use-total-data';

export interface ClaimableReward {
  [x: string]: any;
  tokSymbol?: string;
  token: string;
  amount: BigNumber;
  usdVal: BigNumber;
}

export type LPRTTokenInfo = {
  walletBalance: string;
  totalSupply: string;
  currencySymbol?: string;
};

export interface RDNTLockData {
  amount: BigNumber;
  multiplier: number;
  duration: BigNumber;
  unlockTime: string;
}

export interface RDNTEarningsData {
  unlockTime: string;
  amount: BigNumber;
  penalty: BigNumber;
}
export type RDNTBalanceDataModel = {
  userTotalLockedBalanceWithMultiplier: BigNumber;
  lockData: RDNTLockData[];
  earningsData: RDNTEarningsData[];
  unlockable: BigNumber;
  userLockedBalance: BigNumber;
  penalty: BigNumber;
  totalVesting: BigNumber;
  amountWithPenalty: BigNumber;
  lockedWithMultiplier: BigNumber;
  unlockedVesting: BigNumber;
  userTotalLockedBalance: BigNumber;
};

type BalanceProviderContext = {
  LPTokenInfo: LPRTTokenInfo;
  loading: boolean;
  ready: boolean;
  allPendingRewards: BigNumber;
  RTTokenInfo: LPRTTokenInfo;
  claimableRewards: ClaimableReward[];
  totalClaimableRewardsInUsd: BigNumber;
  userShare: BigNumber;
  userRevenuePerDay: BigNumber;
  totalClaimableRewards: BigNumber;
  refetch: () => void;
  fetchVestable: () => void;
  setAllPendingRewards: Dispatch<SetStateAction<BigNumber>>;
  setRdntData: Dispatch<SetStateAction<RDNTBalanceDataModel>>;
} & RDNTBalanceDataModel;

const Context = React.createContext<BalanceProviderContext>({} as BalanceProviderContext);

const FETCH_VEST_INTERVAL = 60000;

export const BalanceProvider: React.FC = ({ children }) => {
  const { provider } = useProviderContext();
  const { chainId } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { totalLockedSupplyWithMultiplier } = useTotalData();
  const [loading, setLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [userShare, setUserShares] = useState<BigNumber>(valueToBigNumber(-1));
  const { dailyPlatformFees } = useFeeDistribution();
  const [userRevenuePerDay, setUserRevenuePerDay] = useState<BigNumber>(valueToBigNumber(-1));
  const [RTTokenInfo, setRTTokenInfo] = useState<LPRTTokenInfo>({
    walletBalance: '',
    currencySymbol: TokensSymbolEnum.REWARD,
    totalSupply: '',
  });
  const [LPTokenInfo, setLPTokenInfo] = useState<LPRTTokenInfo>({
    walletBalance: '0',
    currencySymbol: undefined,
    totalSupply: '0',
  });

  const [allPendingRewards, setAllPendingRewards] = useState<BigNumber>(valueToBigNumber(-1));

  const [rdntData, setRdntData] = useState<RDNTBalanceDataModel>({
    userTotalLockedBalanceWithMultiplier: valueToBigNumber(-1),
    lockData: [],
    earningsData: [],
    unlockable: valueToBigNumber(-1),
    userLockedBalance: valueToBigNumber(-1),
    penalty: valueToBigNumber(-1),
    totalVesting: valueToBigNumber(-1),
    amountWithPenalty: valueToBigNumber(-1),
    unlockedVesting: valueToBigNumber(-1),
    lockedWithMultiplier: valueToBigNumber(-1),
    userTotalLockedBalance: valueToBigNumber(-1),
  });

  const [claimableRewards, setClaimableRewards] = useState<ClaimableReward[]>([]);
  const [totalClaimableRewardsInUsd, setTotalClaimableRewardsInUsdInUsd] = useState<BigNumber>(
    valueToBigNumber(-1)
  );
  const [totalClaimableRewards, setTotalClaimableRewards] = useState<BigNumber>(
    valueToBigNumber(-1)
  );
  const { rewardsData } = useRewardsData();

  const multiFeeDistributionService = new MultiFeeDistributionService(
    provider,
    REWARD_ADDRESS,
    MULTI_FEE_DISTRIBUTION_ADDRESS
  );

  const fetch = useCallback(async () => {
    const userAddress = userId ? userId : ethers.constants.AddressZero;

    const rdntContract = new GeistTokenContract(provider, REWARD_ADDRESS);
    const lpContract = new GeistTokenContract(provider, STAKE_ADDRESS);
    const promises = [
      rdntContract.getInfo(userId),
      lpContract.getInfo(userId),
      multiFeeDistributionService.getBalances(userAddress),
    ];

    Promise.all(promises)
      .then(([rdntInfo, lpInfo, rdntDta]: any) => {
        setRTTokenInfo(rdntInfo as LPRTTokenInfo);
        setLPTokenInfo(lpInfo as LPRTTokenInfo);
        setRdntData(rdntDta as RDNTBalanceDataModel);
      })
      .catch((error) => {
        console.log(error);
      });

    if (rewardsData.length > 0) {
      let totalRewardAmountInUsd = BigNumber(0);
      let totalRewards = BigNumber(0);
      const _rewards = await multiFeeDistributionService.claimableRewards(userAddress, rewardsData);

      const rewards: ClaimableReward[] = [];

      _rewards.forEach(({ token, amount }, i) => {
        const reserveToken = rewardsData.find(
          (data) => data.id.toLowerCase() === token.toLowerCase()
        );

        let tokSymbol = TokenUtils.getTokenSymbolByAddress(token as Address);

        if (reserveToken) {
          let usdVal = BigNumber(amount).times(reserveToken.price);

          rewards.push({
            tokSymbol,
            token,
            amount: BigNumber(amount),
            usdVal: usdVal,
          });

          usdVal = usdVal.lt(0.00001) ? BigNumber(0) : usdVal;

          totalRewardAmountInUsd = totalRewardAmountInUsd.plus(usdVal);
          totalRewards = totalRewards.plus(amount);
        }
      });

      const chefChefIncentivesService = new ChefIncentivesService(
        provider,
        CHEF_INCENTIVES_CONTROLLER
      );

      const allPendingRewards = await chefChefIncentivesService.allPendingRewards(userAddress);

      setAllPendingRewards(allPendingRewards);

      setClaimableRewards(rewards);
      setTotalClaimableRewards(totalRewards);
      setTotalClaimableRewardsInUsdInUsd(totalRewardAmountInUsd);
    }

    setReady(true);
  }, [userId, provider, rewardsData]);

  const refetch = useCallback(async () => {
    setLoading(true);
    try {
      await fetch();
    } catch (e) {
      console.log('error fetching RDNT balance', e);
    }
    setLoading(false);
  }, [setLoading, fetch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const fetchVestable = useCallback(async () => {
    // console.log(`fetching vestable1`);
  }, [chainId, userId]);

  useEffect(() => {
    fetchVestable();
    const interval = setInterval(fetchVestable, FETCH_VEST_INTERVAL);
    return () => clearInterval(interval);
  }, [fetchVestable]);

  useEffect(() => {
    if (totalLockedSupplyWithMultiplier) {
      const avarageMultiplier = rdntData.userTotalLockedBalanceWithMultiplier.div(
        rdntData.userTotalLockedBalance
      );
      const stakingShare = rdntData.userTotalLockedBalance
        .times(avarageMultiplier)
        .div(totalLockedSupplyWithMultiplier);

      setUserShares(stakingShare.isPositive() ? stakingShare.times(100) : valueToBigNumber(0));
    }
  }, [rdntData, totalLockedSupplyWithMultiplier]);

  useEffect(() => {
    if (userShare.isPositive() && dailyPlatformFees.isPositive()) {
      const userRevPerDay: BigNumber = ManageHelper.getUserRevenuePerDay({
        userShare: userShare,
        dailyPlatformFees,
      });

      setUserRevenuePerDay(userRevPerDay);
    }
  }, [dailyPlatformFees, userShare]);

  return (
    <Context.Provider
      value={{
        LPTokenInfo,
        ...rdntData,
        loading,
        ready,
        refetch,
        RTTokenInfo,
        allPendingRewards,
        totalClaimableRewardsInUsd,
        claimableRewards,
        userRevenuePerDay,
        userShare,

        fetchVestable,

        setAllPendingRewards,
        setRdntData,
        totalClaimableRewards,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useBalanceContext = () => useContext(Context);
