import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import { ReactComponent as WalletIcon } from '../../images/icons/wallet-icon.svg';
import { ReactComponent as ContractIcon } from '../../images/icons/contract-icon.svg';
import { staticStyles } from './style';
import classNames from 'classnames';
import BasicToken from '../BasicToken/BasicToken';
import { MathUtils } from '../../utils/math.utils';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';

interface BasicAmountFieldProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'max'> {
  handleChange: (value: string) => void;
  children?: ReactNode;
  className?: string;
  max?: BigNumber;
  decimals?: number;
}

interface BasicFieldProps {
  children?: ReactNode;
  className?: string;
}

interface BasicFieldBalanceProps extends BasicFieldProps {
  children: ReactNode;
  label?: string;
  onClick?: () => void;
  contract?: boolean;
}

interface BasicFieldAssetProps extends BasicFieldProps {
  symbol: string;
}

interface BasicFormWrapperProps extends BasicFieldProps {
  children: ReactNode | ((maxAmount: BigNumber) => ReactNode);
  size?: 'small';
  maxAmount?: BigNumber;
}

export function BasicAmountField({ children, className, size, maxAmount }: BasicFormWrapperProps) {
  return (
    <>
      <div
        className={classNames('basic-amount-field', className, size && 'basic-amount-field--small')}
      >
        {typeof children === 'function' ? children(maxAmount || valueToBigNumber(0)) : children}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

BasicAmountField.InputBox = ({ className, children }: BasicFieldProps) => (
  <div className={classNames('basic-amount-field__input-box', className)}>{children}</div>
);

BasicAmountField.TokenBox = ({ className, children }: BasicFieldProps) => (
  <div className={classNames('basic-amount-field__token-box', className)}>{children}</div>
);

BasicAmountField.Input = ({
  className,
  handleChange,
  pattern,
  value,
  disabled = false,
  type,
  decimals = 18,
}: BasicAmountFieldProps) => {
  const splited = value?.toString().split('.');
  const integerPart = (splited?.[0] || '').replace(/^0[0-9]/, '0');
  const decimalPart = (splited?.[1] || '').substring(0, decimals);
  let formattedValue: any = '';

  if (type === 'number') {
    formattedValue = decimalPart
      ? `${integerPart}.${decimalPart}`
      : splited!.length > 1
      ? integerPart + '.'
      : integerPart;
  } else {
    formattedValue = value;
  }

  const trimLeadingZero = (value: string) => {
    if (value.startsWith('0')) {
      if (value.charAt(1) !== '.' && value.charAt(1) !== '') {
        return value.substring(1);
      }
    }
    return value;
  };

  return (
    <input
      className={classNames('basic-amount-field__input ff-lg', className)}
      placeholder="0.00001"
      value={
        valueToBigNumber(formattedValue).gt(0)
          ? valueToBigNumber(Number(formattedValue)).toFixed()
          : formattedValue
      }
      disabled={disabled}
      pattern={pattern}
      onKeyDown={(event) => {
        const key = event.key;
        const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;

        if (
          !((key >= '0' && key <= '9') || key === '.' || key === 'Backspace' || isCtrlOrCmdPressed)
        ) {
          event.preventDefault();
        }
      }}
      onChange={(event) => {
        if (valueToBigNumber(event.target.value).isNegative()) {
          handleChange('');
        } else {
          handleChange(!event.target.value.length ? '' : trimLeadingZero(event.target.value));
        }
      }}
    />
  );
};

BasicAmountField.Asset = ({ className, symbol }: BasicFieldAssetProps) => (
  <BasicToken className={classNames('basic-amount-field__asset', className)} tokenSymbol={symbol}>
    {(asset, asset2) => (
      <>
        <BasicToken.Image
          size="small"
          className="basic-amount-field__asset-img"
          icon={asset.icon}
          icon2={asset2?.icon}
          alt={asset.name}
        />

        <BasicToken.Name
          name={symbol.split(' ')[0]}
          className="ReserveInformation-top__token-symbol"
        />
      </>
    )}
  </BasicToken>
);

BasicAmountField.USDValue = ({ children, className }: BasicFieldProps) => {
  const isRelevantAmount = Number(children) >= 0.01 || Number(children) === 0;

  return (
    <span className={classNames('basic-amount-field__usd-value', className)}>
      $ {isRelevantAmount ? MathUtils.toLocaleNumber(children as string, 2) : '< 0.01'}
    </span>
  );
};

BasicAmountField.Balance = ({
  children,
  className,
  contract = false,
  label,
  onClick,
}: BasicFieldBalanceProps) => (
  <span
    className={classNames('basic-amount-field__balance', className)}
    onClick={onClick && onClick}
  >
    {contract ? (
      <ContractIcon className="basic-amount-field__balance-icon" />
    ) : !label ? (
      <WalletIcon className="basic-amount-field__balance-icon" />
    ) : (
      <span className="basic-amount-field__balance-label">{label}</span>
    )}
    <span className="basic-amount-field__balance-value">
      {typeof children === 'string' ? MathUtils.truncateNumber(children, 2) : children}
    </span>
  </span>
);
