import { useCallback, useEffect } from 'react';

import { useTokensPriceStore } from '..';

import { useProviderContext } from '../../libs/provider/WalletProvider';
import { useInterval } from '../../hooks';

const useRewardTokenPrices = () => {
  const { provider } = useProviderContext();
  const [prices, fetchRewardTokenPrices] = [
    useTokensPriceStore.use.data(),
    useTokensPriceStore.use.fetchRewardTokenPrices(),
  ];
  const isLoaded = useTokensPriceStore.use.isLoaded();
  const isLoading = useTokensPriceStore.use.isLoading();

  const refetchPrices = useCallback(() => fetchRewardTokenPrices(provider), [provider]);

  useEffect(() => {
    if (provider !== undefined) {
      fetchRewardTokenPrices(provider);
    }
  }, [provider]);

  useInterval(() => {
    if (provider !== undefined) {
      fetchRewardTokenPrices(provider);
    }
  }, 30 * 1000);

  return {
    prices,
    isLoaded,
    isLoading,
    refetchPrices,
  };
};

export default useRewardTokenPrices;
