import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  .switch {
    position: relative;
    width: 36px;
    padding: 3px;
    border-radius: 50px;
    transition: 0.3s;
    display: block;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4);

    &.checked {
      background-color: #52679d;
      border-color: transparent;
      box-shadow: none;
    }

    &__input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    &__slider {
      display: block;
      cursor: pointer;
      transition: 0.4s;
      height: 14px;
      width: 14px;
      background-color: white;
      transition: 0.3s;
      margin-left: 0;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.4);
    }

    &__input:checked + &__slider {
      margin-left: 100%;
      transform: translateX(-100%);
      background-color: white;
    }

    &__input:disabled + &__slider {
      cursor: default;
    }
  }
`;

export default staticStyles;
