import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .basic-token {
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 16px;

    @include respond-to(xs) {
      font-size: 14px;
      gap: 12px;
    }

    &__image-group {
      display: flex;
      position: relative;
      width: auto;

      &.small {
        padding-right: 14px;
      }

      &.medium {
        padding-right: 16px;
      }

      &.large {
        padding-right: 25px;
      }

      .basic-token__img {
        &:first-of-type {
          z-index: 1;
          position: relative;
        }

        &:last-of-type {
          position: absolute;
          left: 35%;
          top: 0;
        }
      }
    }

    &__img {
      &--small {
        width: 24px;
        height: 24px;
      }
      &--medium {
        width: 32px;
        height: 32px;
      }
      &--large {
        width: 44px;
        height: 44px;
      }
      &--extra-large {
        width: 52px;
        height: 52px;
      }
    }
  }
`;

export default staticStyles;
