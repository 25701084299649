import css from 'styled-jsx/css';

/*language=SCSS*/
export const staticStyles = css.global`
  .basic-select__dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99;
    min-width: 100%;
    padding: 12px 2px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s cubic-bezier(0.44, 1.53, 1, 1);
    transition-property: margin;
    margin-top: -15px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .is-opened & {
      visibility: visible;
      opacity: 1;
      margin-top: 5px;
    }
  }
`;
