import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/_vars';
  @import 'src/_mixins/_screen-size';

  .m-top {
    color: #000;
    flex-basis: 47%;

    @include respond-to(md) {
      display: block;
    }

    &__column {
      display: flex;
      gap: 24px;
      flex: 1 1 auto;

      @include respond-to(md) {
        display: block;
      }
    }

    &__inner {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      padding: 24px 16px;
      position: relative;
      margin: 24px 0 0;
      overflow: hidden;
      gap: 12px;
      flex-wrap: wrap;

      @include respond-to(xs) {
        margin: 16px 0 0;
      }
    }

    &__item {
      font-weight: 400;
      font-size: 16px;

      @include respond-to(xs) {
        font-size: 12px;
        flex-basis: 44%;
        text-align: center;
      }
    }

    &__item-value {
      font-weight: 500;
      font-size: 28px;
      margin: 2px 0;

      @include respond-to(xs) {
        font-size: 20px;
      }

      &--highlight {
        color: #ffb21e;
      }
    }

    &__item-subtext {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);

      @include respond-to(xs) {
        font-size: 12px;
      }
    }
  }
`;

export default staticStyles;
