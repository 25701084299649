import { valueToBigNumber } from '@aave/math-utils';
import { DailyDataModel } from '../../graphs';
import { DateUtils } from '../../utils';
import BigNumber from 'bignumber.js';

export class FeeDistributionHelper {
  public static getArraySum(rewardsRates: BigNumber[]): BigNumber {
    return rewardsRates.reduce(
      (accumulator, currentValue) => accumulator.plus(currentValue),
      valueToBigNumber(0)
    );
  }

  public static getTotalByDailyRewards(dailyData: DailyDataModel[]): number {
    let totalSum = 0;

    for (const daily of dailyData) {
      totalSum += daily.dataPerToken.reduce(
        (prev, cur) => prev + Number(cur.price) * (Number(cur.rate) * DateUtils.getDaySeconds()),
        0
      );
    }

    return totalSum;
  }
}
