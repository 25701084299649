import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DashboardBorrow {
    display: flex;

    @include respond-to(sm) {
      flex-direction: column;
      gap: 12px;
    }

    &__left {
      margin-right: 38px;

      @include respond-to(sm) {
        margin: 0;
        .CircleCompositionBar {
          margin: auto;
        }
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 600;

      @include respond-to(sm) {
        font-size: 18px;
      }
    }

    &__tokens {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 12px;
    }

    &__token {
      font-size: 14px;
      border-radius: 50px;
      width: 104px;
      padding: 5px 12px 4px;
      background-color: #fffdf9;
      border: 1px solid #d9d9d9;

      span {
        display: block;
        font-weight: 500;
      }
    }
  }
`;

export default staticStyles;
