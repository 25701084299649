import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';

import staticStyles from './style';
import { PieChart } from '../PieChart';
import Value from '../../basic/Value';

export interface CircleCompositionBarItem {
  color: string;
  value: number;
  label: string | number;
}

interface CircleCompositionBarProps {
  title: string;
  totalValue: number;
  data: CircleCompositionBarItem[];
}

export default function CircleCompositionBar({
  title,
  totalValue,
  data,
}: CircleCompositionBarProps) {
  return (
    <div className="CircleCompositionBar">
      <ParentSize>
        {() => <PieChart width={184} height={184} slices={data} disableBackground />}
      </ParentSize>

      <div className="CircleCompositionBar__inner">
        <span>{title}</span>

        <Value
          value={totalValue}
          compact={true}
          maximumValueDecimals={2}
          className="CircleCompositionBar__value ff-lg"
        />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
