import { useIntl } from 'react-intl';
import { useDynamicPoolDataContext } from '../libs/pool-data-provider';
import { valueToBigNumber } from '@aave/protocol-js';
import { getAssetColor, getAssetInfo } from '../libs/aave-ui-kit';
import { useEffect, useState } from 'react';

export function useUserDepositedComp() {
  const { user } = useDynamicPoolDataContext();
  const intl = useIntl();

  const [depositList, setDepositList] = useState<any>();

  useEffect(() => {
    if (user) {
      const userReservesData = [];
      for (let i = 0; i < user.userReservesData.length; i++) {
        const _userReserve = user.userReservesData[i];
        const value = valueToBigNumber(_userReserve.underlyingBalanceMarketReferenceCurrency)
          .dividedBy(user.totalCollateralMarketReferenceCurrency)
          .multipliedBy(100)
          .toNumber()
          .toFixed(2);
        const asset = getAssetInfo(_userReserve.reserve.symbol);

        if (Number(value) !== 0) {
          userReservesData.push({
            ..._userReserve,
            symbol: asset.symbol,
            disabled: Number(value) === 0,
            label: `${intl.formatNumber(
              valueToBigNumber(_userReserve.underlyingBalanceMarketReferenceCurrency)
                .dividedBy(user.totalCollateralMarketReferenceCurrency)
                .multipliedBy(100)
                .toNumber(),
              { maximumFractionDigits: 2 }
            )}%`,
            color: getAssetColor(asset.symbol),
          });
        }
      }

      setDepositList(userReservesData);
    }
  }, [user]);

  return depositList;
}
