import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import { DropdownWrapper, textCenterEllipsis } from '../../../libs/aave-ui-kit';

import { useWeb3DataContext } from '../../../libs/web3-data-provider';
import { useMenuContext } from '../../../libs/menu';
import ConnectButton from '../../ConnectButton';

import staticStyles from './style';
import messages from './messages';
import { getNetworkConfig } from '../../../helpers/config/markets-and-network-config';
import useGetEnsName from '../../../libs/hooks/use-get-ens-name';
import DefaultButton from '../../basic/DefaultButton';
import { BasicBox } from '../../BasicBox/BasicBox';

import { ReactComponent as CopyIcon } from '../../../images/icons/copy-icon.svg';
import { ReactComponent as LinkIcon } from '../../../images/icons/link-address-icon.svg';
import { ReactComponent as WalletIcon } from '../../../images/icons/wallet-icon.svg';
import { ReactComponent as CheckIcon } from '../../../images/icons/check-icon.svg';
import { Select } from '../../Select/Select';
import { iota } from '../../../shared';

export default function AddressInfo() {
  const intl = useIntl();
  const { chainId } = useWeb3React();
  const { currentAccount, disconnectWallet, availableAccounts, handleSetCurrentAccount } =
    useWeb3DataContext();
  const { ensName } = useGetEnsName(currentAccount);
  const ensNameAbbreviated = ensName
    ? ensName.length > 18
      ? textCenterEllipsis(ensName, 12, 3)
      : ensName
    : undefined;
  const { closeMobileMenu } = useMenuContext();

  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number>(
    availableAccounts.indexOf(currentAccount)
  );
  const [clipboard, setClipboard] = useState<string>('');

  const config = chainId ? getNetworkConfig(chainId) : undefined;
  const networkName = config && config.name;
  let longName = networkName;
  let networkColor = '';
  if (config?.isFork) {
    networkColor = '#ff4a8d';
    longName += ' fork';
  } else if (config?.isTestnet) {
    networkColor = '#7157ff';
    longName += ' test';
  } else {
    networkColor = '#65c970';
  }

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(currentAccount);
    setClipboard(currentAccount);
  }, [currentAccount]);

  const handleSelectChange = (selectedId: number) => {
    setSelectedItem(selectedId);

    handleSetCurrentAccount(availableAccounts[selectedId]);
  };

  return (
    <>
      <div className="AddressInfo">
        {currentAccount ? (
          <DropdownWrapper
            visible={visible}
            setVisible={setVisible}
            horizontalPosition="right"
            verticalPosition="bottom"
            className="AddressInfo__dropdownWrapper"
            buttonComponent={
              <DefaultButton
                className={classNames('AddressInfo__button', {
                  AddressInfo__buttonActive: visible,
                })}
                onClick={() => setVisible(!visible)}
                type="button"
                color="secondary"
              >
                <WalletIcon width={16} />
                {ensNameAbbreviated ? ensNameAbbreviated : textCenterEllipsis(currentAccount, 4, 4)}
              </DefaultButton>
            }
          >
            <BasicBox variant="primary" className="AddressInfo__content">
              <div className="AddressInfo__header">
                <p className="AddressInfo__title">{ensName ? ensName : 'Account'}</p>

                <div className="AddressInfo__group-btns">
                  <button className="AddressInfo__icon-btn" onClick={handleCopy}>
                    {clipboard === currentAccount ? (
                      <CheckIcon width={14} className="AddressInfo__icon--checked" />
                    ) : (
                      <CopyIcon className="AddressInfo__icon" />
                    )}
                  </button>
                  <a
                    href={`${iota.blockExplorers.default.url}address/${currentAccount}`}
                    target="_blank"
                    className="AddressInfo__icon-btn"
                    rel="noreferrer"
                  >
                    <LinkIcon className="AddressInfo__icon" />
                  </a>
                </div>
              </div>

              {availableAccounts.length > 1 ? (
                <Select
                  selectedId={selectedItem}
                  onOptionChange={handleSelectChange}
                  className="AddressInfo__select"
                >
                  <Select.Header className="AddressInfo__select-head">
                    <div className="AddressInfo__address">
                      {textCenterEllipsis(availableAccounts[selectedItem], 4, 4)}
                    </div>
                  </Select.Header>
                  <Select.Dropdown className="AddressInfo__selec-dropdown">
                    {availableAccounts.map((address: string) => (
                      <Select.Item key={address} className="AddressInfo__select-option">
                        <div className="AddressInfo__address">
                          {textCenterEllipsis(address, 4, 4)}
                        </div>
                      </Select.Item>
                    ))}
                  </Select.Dropdown>
                </Select>
              ) : (
                <div className="AddressInfo__address AddressInfo__address--single">
                  {textCenterEllipsis(currentAccount, 4, 4)}
                </div>
              )}
              {/* {(availableAccounts.length > 1) && (
              <button
                className="AddressInfo__contentButton"
                type="button"
                onClick={() => displaySwitchAccountModal(true)}
              >
                <span>{intl.formatMessage(messages.changeAddress)}</span>
              </button>
            )} */}

              <DefaultButton
                className="AddressInfo__contentButton"
                type="button"
                size="small"
                onClick={() => {
                  disconnectWallet();
                  closeMobileMenu();
                }}
              >
                <span>{intl.formatMessage(messages.disconnect)}</span>
              </DefaultButton>

              <div className="AddressInfo__row">
                <span className="AddressInfo__row-label">Network:</span>
                <p className="AddressInfo__content-network">
                  <i />
                  <span>{intl.formatMessage(messages.networkShortName, { name: longName })}</span>
                </p>
              </div>
            </BasicBox>
          </DropdownWrapper>
        ) : (
          <ConnectButton size="small" />
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DropdownWrapper__right .DropdownWrapper__content {
          p {
          }
        }
        .AddressInfo {
          &__button {
            width: 158px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            height: 46px;

            img {
              margin-right: 8px;
            }
          }
          &__content-network {
            i {
              background: ${networkColor};
            }
          }
        }
      `}</style>
    </>
  );
}
