import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { InterestRate } from '@aave/protocol-js';
import classNames from 'classnames';

import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import NoDataPanel from '../../../../components/NoDataPanel';
import MainDashboardTable from '../../components/MainDashboardTable';
import DashboardNoData from '../../components/DashboardNoData';

import CollateralModal from '../../../../components/modals/CollateralModal';
import DepositModal from '../../../../components/modals/DepositModal';
import WithdrawModal from '../../../../components/modals/WithdrawModal';

import staticStyles from './style';
import PageHeader from '../../../../components/PageHeader';
import BorrowModal from '../../../../components/modals/BorrowModal';
import RepayModal from '../../../../components/modals/RepayModal';
import ButtonTabs from '../../../../components/basic/ButtonTabs';
import { DashboardDeposit } from '../../components/DashboardDeposit/DashboardDeposit';
import { DashboardBorrow } from '../../components/DashboardBorrow/DashboardBorrow';
import { DashboardHealth } from '../../components/DashboardHealth/DashboardHealth';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import {
  BorrowModalData,
  CollateralModalData,
  DepositModalData,
  RepayModalData,
  WithdrawModalData,
} from '../../../../shared';
import { DashboarRewards } from '../../components/DashboardRewards/DashboardRewards';
import { useUserPositions } from '../../../../hooks';
import { EmissionsRow } from '../../../../components';

const DASHBOARD_TABS: string[] = ['Deposits', 'Borrows', 'Health factor'];

export default function Dashboard() {
  const history = useHistory();
  const location = useLocation();
  const { user, reserves } = useDynamicPoolDataContext();

  const [collateralModal, setCollateralModal] = useState<CollateralModalData | undefined>();
  const [depositModal, setDepositModal] = useState<DepositModalData | undefined>();
  const [withdrawModal, setWithdrawModal] = useState<WithdrawModalData | undefined>();
  const [borrowModal, setBorrowModal] = useState<BorrowModalData | undefined>();
  const [repayModal, setRepayModal] = useState<RepayModalData | undefined>();
  const [currentTab, setCurrentTab] = useState<string>(DASHBOARD_TABS[0]);

  const { depositedPositions, borrowedPositions } = useUserPositions();

  const [depositedList, borroedList] = useMemo(
    () => [
      depositedPositions.map((userReserve) => {
        const poolReserve = reserves.find((res: any) => res.symbol === userReserve.reserve.symbol);

        if (!poolReserve) {
          throw new Error('data is inconsistent pool reserve is not available');
        }

        return {
          ...userReserve,
          collateralModalData: {
            history,
            id: poolReserve.id,
            usageAsCollateralEnabledOnUser: !userReserve.usageAsCollateralEnabledOnUser,
            underlyingAsset: poolReserve.underlyingAsset,
            showModal: false,
            user: user,
            userReserve: userReserve,
            poolReserve: poolReserve,
            asCollateral: true,
          },
          depositModalData: {
            showModal: false,
            currencySymbol: poolReserve.symbol,
            poolReserve: poolReserve,
            history,
          },
          withdrawModalData: {
            showModal: false,
            currencySymbol: poolReserve.symbol,
            poolReserve: poolReserve,
            history,
            userReserve,
            user,
          },
          onToggleSwitch: () => {
            setCollateralModal({
              history,
              id: poolReserve.id,
              usageAsCollateralEnabledOnUser: !userReserve.usageAsCollateralEnabledOnUser,
              underlyingAsset: poolReserve.underlyingAsset,
              showModal: true,
              user: user,
              userReserve: userReserve,
              poolReserve: poolReserve,
              asCollateral: !userReserve.usageAsCollateralEnabledOnUser,
            });
          },
          onDeposit: () => {
            setDepositModal({
              showModal: true,
              currencySymbol: poolReserve.symbol,
              poolReserve: poolReserve,
              history,
            });
          },
          onWithdraw: () => {
            setWithdrawModal({
              showModal: true,
              currencySymbol: poolReserve.symbol,
              poolReserve: poolReserve,
              history,
              userReserve,
              user,
            });
          },
        };
      }),
      borrowedPositions.map((userReserve) => {
        const poolReserve = reserves.find((res: any) => res.symbol === userReserve.reserve.symbol);

        if (!poolReserve) {
          throw new Error('data is inconsistent pool reserve is not available');
        }

        if (userReserve.borrowRateMode === InterestRate.Variable) {
          return {
            ...userReserve,
            onBorrowClick: () => {
              setBorrowModal({
                showModal: true,
                currencySymbol: poolReserve.symbol,
                poolReserve: poolReserve,
                history,
                user,
                userReserve,
              });
            },
            onRepayClick: () => {
              setRepayModal({
                showModal: true,
                currencySymbol: poolReserve.symbol,
                poolReserve: poolReserve,
                history,
                user,
                userReserve,
                location,
              });
            },
          };
        } else {
          return {
            ...userReserve,
            onBorrowClick: () => {
              setBorrowModal({
                showModal: true,
                currencySymbol: poolReserve.symbol,
                poolReserve: poolReserve,
                history,
                user,
                userReserve,
              });
            },
            onRepayClick: () => {
              setRepayModal({
                showModal: true,
                currencySymbol: poolReserve.symbol,
                poolReserve: poolReserve,
                history,
                user,
                userReserve,
                location,
              });
            },
          };
        }
      }),
    ],
    [depositedPositions, borrowedPositions]
  );

  return (
    <div className="Dashboard">
      <EmissionsRow />

      <PageHeader text="Dashboard" />
      <p className="Dashboard__subtitle">Available to deposit</p>

      {user ? (
        <>
          <div className="Dashboard__section">
            <BasicBox className="Dashboard__box">
              <BasicBox.Header className="Dashboard__box-header">
                <BasicBox.Title className="Dashboard__box-title">Lending</BasicBox.Title>
                <ButtonTabs
                  disabled={Number(user?.totalLiquidityMarketReferenceCurrency || 0) === 0}
                  tabs={DASHBOARD_TABS}
                  selectedTab={currentTab}
                  setSelectedTab={setCurrentTab}
                />
              </BasicBox.Header>
              <BasicBox.Body className="Dashboard__body">
                <div
                  className={classNames(
                    'Dashboard__tab',
                    currentTab === DASHBOARD_TABS[0] && 'active'
                  )}
                >
                  <DashboardDeposit />
                </div>
                <div
                  className={classNames(
                    'Dashboard__tab',
                    currentTab === DASHBOARD_TABS[1] && 'active'
                  )}
                >
                  <DashboardBorrow />
                </div>
                <div
                  className={classNames(
                    'Dashboard__tab',
                    currentTab === DASHBOARD_TABS[2] && 'active'
                  )}
                >
                  <DashboardHealth />
                </div>
              </BasicBox.Body>
            </BasicBox>
            <BasicBox className="Dashboard__box Dashboard__box-rewards">
              <BasicBox.Header className="Dashboard__box-header">
                <BasicBox.Title className="Dashboard__box-title">Rewards</BasicBox.Title>
              </BasicBox.Header>
              <BasicBox.Body className="Dashboard__body">
                <DashboarRewards />
              </BasicBox.Body>
            </BasicBox>
          </div>
          {!!depositedList.length ? (
            <MainDashboardTable
              borrowedPositions={borroedList}
              depositedPositions={depositedList}
              isBorrow={false}
            />
          ) : (
            <DashboardNoData />
          )}
        </>
      ) : (
        <NoDataPanel
          title="Please connect your wallet"
          description={
            'We couldn’t detect a wallet. Please connect a wallet to view your personal information here'
          }
          withConnectButton={true}
        />
      )}

      {collateralModal && (
        <CollateralModal
          showModal={collateralModal.showModal}
          onBackdropPress={() => setCollateralModal({ ...collateralModal, showModal: false })}
          history={history}
          id={collateralModal.id}
          usageAsCollateralEnabledOnUser={collateralModal.usageAsCollateralEnabledOnUser}
          underlyingAsset={collateralModal.underlyingAsset}
          user={user}
          userReserve={collateralModal.userReserve}
          poolReserve={collateralModal.poolReserve}
          asCollateral={collateralModal.asCollateral}
        ></CollateralModal>
      )}

      {depositModal && (
        <DepositModal
          showModal={depositModal.showModal}
          onBackdropPress={() => setDepositModal({ ...depositModal, showModal: false })}
          currencySymbol={depositModal.currencySymbol}
          poolReserve={depositModal.poolReserve}
          history={depositModal.history}
        />
      )}

      {withdrawModal && (
        <WithdrawModal
          showModal={withdrawModal.showModal}
          onBackdropPress={() => setWithdrawModal({ ...withdrawModal, showModal: false })}
          currencySymbol={withdrawModal.currencySymbol}
          poolReserve={withdrawModal.poolReserve}
          history={withdrawModal.history}
          userReserve={withdrawModal.userReserve}
          user={withdrawModal.user}
        ></WithdrawModal>
      )}

      {borrowModal && (
        <BorrowModal
          showModal={borrowModal.showModal}
          onBackdropPress={() => setBorrowModal({ ...borrowModal, showModal: false })}
          currencySymbol={borrowModal.currencySymbol}
          poolReserve={borrowModal.poolReserve}
          history={borrowModal.history}
          userReserve={borrowModal.userReserve}
          user={borrowModal.user}
        />
      )}

      {repayModal && (
        <RepayModal
          showModal={repayModal.showModal}
          onBackdropPress={() => setRepayModal({ ...repayModal, showModal: false })}
          currencySymbol={repayModal.currencySymbol}
          poolReserve={repayModal.poolReserve}
          userReserve={repayModal.userReserve}
          user={repayModal.user}
          history={repayModal.history}
          location={location}
        />
      )}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
