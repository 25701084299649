import { Provider } from '@ethersproject/providers';
import { createSelectors } from '../../store/createSelectors';
import { create } from '../../libs/zustand';

import { StoreTokensPriceTypes } from './tokens-price.model';
import { PriceProviderApi } from '../../apis';
import { valueToBigNumber } from '@aave/protocol-js';

export const useTokensPriceStore = createSelectors(
  create<StoreTokensPriceTypes>((set, get) => ({
    isLoading: false,
    isLoaded: false,
    data: {
      totalLockedSupply: valueToBigNumber(-1),
      totalLockedSupplyWithMultiplier: null,
      wethPriceUsd: '-1',
      tokenPrice: '-1',
      lpTokenPrice: '-1',
      tokenPriceUsd: '-1',
      lpTokenPriceUsd: '-1',
    },
    fetchRewardTokenPrices: async (provider: Provider) => {
      if (get().isLoading) {
        return;
      }

      set((state) => ({
        ...state,
        isLoaded: false,
        isLoading: true,
      }));

      PriceProviderApi.setProvider(provider);

      try {
        const prices = await PriceProviderApi.getTokenPrices();

        prices.wethPriceUsd = valueToBigNumber(prices.tokenPriceUsd)
          .div(prices.tokenPrice)
          .toFixed();

        set((state) => ({
          ...state,
          data: prices,
          isLoading: false,
          isLoaded: true,
        }));
      } catch (error) {
        console.log(error);

        set((state) => ({
          ...state,
          isLoading: false,
          isLoaded: true,
        }));
      }
    },
  }))
);
