import { useEffect, useMemo, useState } from 'react';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import BigNumber from 'bignumber.js';
import { valueToBigNumber } from '@aave/math-utils';
import { MINIMUM_ELIGIBILITY_PERCENT } from './EligibilityProgress';

export function useEligibility() {
  const { userTotalLockedBalance } = useBalanceContext();
  const {
    prices: { lpTokenPriceUsd },
  } = useRewardTokenPrices();
  const { user } = useDynamicPoolDataContext();
  const [eligibilityPercent, setEligibilityPercent] = useState<BigNumber>(valueToBigNumber(-1));
  const [isEligible, setIsEligible] = useState<boolean>(false);

  const lockedInUsd = useMemo(
    () => userTotalLockedBalance.times(lpTokenPriceUsd),
    [userTotalLockedBalance, lpTokenPriceUsd]
  );

  useEffect(() => {
    if (user) {
      if (lockedInUsd.lte(0) || Number(user.totalLiquidityUSD) === 0) {
        setIsEligible(false);
        setEligibilityPercent(valueToBigNumber(0));

        return;
      }

      const eligibility = lockedInUsd.div(user.totalLiquidityUSD).multipliedBy(100);

      setEligibilityPercent(eligibility.isNaN() ? valueToBigNumber(0) : eligibility);

      setIsEligible(eligibility.gte(MINIMUM_ELIGIBILITY_PERCENT));
    }
  }, [user, lockedInUsd]);

  return {
    eligibilityPercent,
    isEligible,
  };
}
