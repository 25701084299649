import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .dashboard-table {
    &__header {
      &.basic-table__header {
        padding-right: 44px;

        .basic-table__item:first-of-type {
          text-align: left;
        }

        @include respond-to(sm) {
          padding-right: 16px;
          .basic-table__item:last-of-type {
            display: none;
          }
        }
      }
    }

    &__row {
      padding: 10px;
      color: #000;
      display: block !important;
      margin: 4px 0;
      @include respond-to(sm) {
        padding: 10px 0;
      }

      .basic-table__item:last-of-type {
        @include respond-to(sm) {
          flex-basis: 100%;
          display: flex;
          gap: 12px;
          margin: 24px 12px 12px;
          align-items: center;

          .dashboard-table__row-switcher {
            margin: auto 0;
          }
        }

        .cell-label {
          display: none;
          @include respond-to(sm) {
            display: block;
          }
        }
      }
    }

    &__top {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      min-height: 68px;
      padding-right: 30px;

      @include respond-to(sm) {
        padding: 0;
        flex-wrap: wrap;

        flex-basis: 100%;
      }
    }

    &__row-switcher {
      margin: auto;
    }

    &__row-button {
      position: absolute;
      top: 14px;
      right: 18px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      color: #000;
      transform: translateY(1px);

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      @include respond-to(sm) {
        top: auto;
        bottom: 2px;
        &--borrow {
          left: 50%;
          top: auto;
          margin-left: -20px;
          bottom: -4px;
          right: auto;
        }
      }
    }

    &__row-arrow {
      cursor: pointer;
      margin: auto;
      width: 20px;

      .active & {
        transform: rotate(-180deg);
      }
    }

    &__row-footer {
      display: none;
      gap: 12px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 6px;

      @include respond-to(sm) {
        justify-content: space-around;
      }

      .active & {
        display: flex;
      }
    }
  }
`;

export default staticStyles;
