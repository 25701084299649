import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import staticStyles from './style';
import { valueToBigNumber } from '@aave/protocol-js';
import { ReactComponent as HealthImage } from '../../../../images/dashboard/health-factor.svg';
import { ReactComponent as InfiniteIcon } from '../../../../images/dashboard/infinite-icon.svg';
import { ReactComponent as HealthArrow } from '../../../../images/dashboard/factor-arrow.svg';
import ValuePercent from '../../../../components/basic/ValuePercent';
import HealthFactor, { HealthFactorEnum } from '../../../../components/HealthFactor';

export function DashboardHealth() {
  const { user } = useDynamicPoolDataContext();

  const maxBorrowAmount = valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').plus(
    user?.availableBorrowsMarketReferenceCurrency || '0'
  );
  const collateralUsageRate = maxBorrowAmount.eq(0)
    ? valueToBigNumber(1)
    : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').div(maxBorrowAmount);

  const loanToValue =
    user?.totalCollateralMarketReferenceCurrency === '0'
      ? valueToBigNumber(0)
      : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').dividedBy(
          user?.totalCollateralMarketReferenceCurrency || '1'
        );

  let graphicDegree = (Number(user?.healthFactor) / HealthFactorEnum.Max) * 180;

  if (Number(user?.healthFactor) < 0) {
    graphicDegree = 0;
  } else if (Number(user?.healthFactor) >= HealthFactorEnum.Max) {
    graphicDegree = 180;
  }

  return (
    <div className="DashboardHealth">
      <div className="DashboardHealth__left">
        <div className="DashboardHealth__image">
          <HealthImage className="DashboardHealth__bar" />
          <HealthArrow
            className="DashboardHealth__arrow"
            style={{ transform: `rotate(${graphicDegree - 90}deg)` }}
          />
        </div>

        <div className="DashboardHealth__footer">
          <span>1</span>

          <InfiniteIcon />
        </div>
      </div>

      <div className="DashboardHealth__body">
        <div className="DashboardHealth__box">
          <p className="DashboardHealth__box-text">Health factor</p>
          <span className="DashboardHealth__box-value">
            {user ? <HealthFactor value={user.healthFactor} /> : null}
          </span>
        </div>

        <div className="DashboardHealth__item">
          <span className="DashboardHealth__item-label">Borrowing Power Used: </span>
          <ValuePercent
            value={collateralUsageRate.times(100)}
            percentSymbol
            className="DashboardHealth__item-value"
          />
        </div>
        <div className="DashboardHealth__item">
          <span className="DashboardHealth__item-label">Current LTV: </span>
          <ValuePercent
            value={loanToValue.times(100)}
            percentSymbol
            className="DashboardHealth__item-value"
          />
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
