import DefaultButton from '../../../../../components/basic/DefaultButton';
import TableCol from '../TableCol';

import staticStyles from './style';

type TableButtonColumnProps = {
  title: string;
  className?: string;
  color: any;
  disabled: boolean;
  onClick: () => void;
};

export default function TableButtonColumn({
  title,
  color,
  className,
  disabled,
  onClick,
}: TableButtonColumnProps) {
  return (
    <>
      <DefaultButton
        className={['TableButtonCol__button', className ?? ''].join(' ')}
        color={color}
        size="small"
        title={title}
        disabled={disabled}
        onClick={onClick}
      />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
