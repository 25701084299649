import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { BigNumberValue } from '@aave/protocol-js';

import staticStyles from './style';

interface ValuePercentProps {
  value: BigNumberValue;
  percentSymbol?: boolean;
  maximumDecimals?: number;
  minimumDecimals?: number;
  updateCondition?: boolean;
  percentColor?: string;
  className?: string;
  valueColor?: string;
  onWhiteBackground?: boolean;
}

export default function ValuePercent({
  value,
  percentSymbol = true,
  maximumDecimals,
  minimumDecimals,
  updateCondition,
  className,
}: ValuePercentProps) {
  const intl = useIntl();

  const [newValue, setNewValue] = useState(value);
  const [suffixFormat, setSuffixFormat] = useState('');
  const updateValue = updateCondition ? undefined : value;

  useEffect(() => {
    setNewValue(Number(value));
    // setNewValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateValue]);

  return (
    <div className={classNames('ValuePercent', className)}>
      {isNaN(Number(newValue))
        ? 0
        : intl.formatNumber(percentSymbol ? Number(newValue) : Number(newValue), {
            maximumFractionDigits: maximumDecimals || 2,
            minimumFractionDigits: minimumDecimals ? minimumDecimals : undefined,
          })}
      {percentSymbol && suffixFormat}
      {percentSymbol && '%'}

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
