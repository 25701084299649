import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { RewardDataModel } from '../../../graphs';

import { TokenUtils, DistributionUtils } from '../../../utils';
import { DAYS_IN_YEAR } from '../../../shared';

export class MarketsHelper {
  public static isValidTotalApr(apr: BigNumber): boolean {
    const value: string = apr.toFixed(2).toString();

    return !apr.isNaN() && value !== 'Infinity' && value !== '0.00';
  }

  public static calculateTotalAprByTokens(tokensApr: any): string {
    let result: number = 0;

    for (const tokenKey in tokensApr) {
      result += Number(tokensApr[tokenKey]);
    }

    return result.toFixed(2);
  }

  public static getAprByToken(
    reward: RewardDataModel,
    totalLockedSupplyInUSD: BigNumber,
    multiplier: string
  ): [string, BigNumber] {
    const yearRewardsInUsd = DistributionUtils.getDailyRewardUsd(reward).times(DAYS_IN_YEAR);
    const symbol = TokenUtils.getTokenSymbolByAddress(reward.id);

    const apr = yearRewardsInUsd.div(totalLockedSupplyInUSD).times(100).times(multiplier);

    return [symbol, !apr.isNaN() ? apr : valueToBigNumber(0)];
  }

  public static getRelevantApr(tokenApr: BigNumber, number: number): number {
    const getApr = (tokenApr: BigNumber, count: number) =>
      valueToBigNumber(tokenApr.toFixed(count));
    let apr = getApr(tokenApr, number);

    let count = number;

    while (apr.eq(0) && count < 5) {
      count++;
      apr = getApr(tokenApr, count);
    }

    return apr.toNumber();
  }
}
