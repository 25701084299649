import staticStyles from './style';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { ReactNode, useEffect, useState } from 'react';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { getAssetInfo } from '../../../../libs/aave-ui-kit';
import { ToolsSelectedItemType, usePoolsInfoData, useToolsStore } from '../../../../store';
import { valueToBigNumber } from '@aave/math-utils';
import { PoolUtils } from '../../../../utils';
import {
  ComputedReserveData,
  useDynamicPoolDataContext,
} from '../../../../libs/pool-data-provider';
import { usePoolData } from '../../../../libs/pool-data-provider/hooks/use-pool-data';
import { EstimateLoopingAndAprs } from '../../../../utils/pool.utils';
import { MathUtils } from '../../../../utils/math.utils';
import HealthFactor from '../../../../components/HealthFactor';
import BigNumber from 'bignumber.js';
import { TokensSymbolEnum } from '../../../../shared';

export function ToolsInfo({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="tools-info">{children}</div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

ToolsInfo.Position = ({
  item,
  title,
  amount,
}: {
  item: ToolsSelectedItemType | null;
  amount: string;
  title: string;
}) => {
  const tokenAmount = Number(amount) > 0 ? amount : '0';

  return (
    <BasicBox variant="secondary" className="tools__inner-box tools-info__position">
      <p className="tools-info__text">{title}</p>

      <div className="tools-info__token">
        {item ? (
          <BasicToken tokenSymbol={getAssetInfo(item.symbol).symbol}>
            {(asset) => (
              <>
                <BasicToken.Image
                  icon={asset.icon}
                  size="medium"
                  className="tools-info__token-img"
                />
                <span className="tools-info__token-name">{asset.symbol}</span>
              </>
            )}
          </BasicToken>
        ) : (
          '-'
        )}
        <div className="tools-info__token-right">
          <p className="tools-info__token-balance">{MathUtils.toLocaleNumber(tokenAmount, 2)}</p>
          <span className="tools-info__token-usd">
            ${' '}
            {item
              ? MathUtils.toLocaleNumber(
                  valueToBigNumber(tokenAmount).times(item.priceInMarketReferenceCurrency),
                  2
                )
              : '0'}
          </span>
        </div>
      </div>
    </BasicBox>
  );
};

ToolsInfo.Group = ({ children }: { children: ReactNode }) => {
  return <div className="tools-info__group">{children}</div>;
};

export function ToolsInfoList({
  reserve,
  amount,
}: {
  reserve: ComputedReserveData | null;
  amount: string;
}) {
  const { poolsInfoData } = usePoolsInfoData();
  const { user } = useDynamicPoolDataContext();
  const leverage = useToolsStore.use.leverage();
  const [loopingData, setLoopingData] = useState<EstimateLoopingAndAprs>();

  useEffect(() => {
    if (user && poolsInfoData.length && reserve) {
      const loopingData = PoolUtils.getPoolLooping({
        reserve: reserve,
        poolsInfoData,
        amount: valueToBigNumber(amount),
        maxLeverage: valueToBigNumber(leverage),
        userSummary: user,
      });

      setLoopingData(loopingData);
    }
  }, [poolsInfoData, user, leverage, amount]);

  return (
    <BasicBox variant="secondary" className="tools__inner-box tools-info__list">
      {loopingData && reserve && amount ? (
        <>
          <div className="tools-info__list-item">
            <p>{TokensSymbolEnum.REWARD} APR</p>{' '}
            <span>{MathUtils.toLocaleNumber(loopingData.rewardAPR || 0, 2)}%</span>
          </div>
          <div className="tools-info__list-item">
            <p>{getAssetInfo(reserve.symbol).symbol} APY</p>{' '}
            <span>
              {MathUtils.toLocaleNumber(
                valueToBigNumber(loopingData.depositAPY.minus(loopingData.borrowAPY) ?? 0),
                2
              )}
              %
            </span>
          </div>
          <div className="tools-info__list-item">
            <p>Net APY</p> <span>{MathUtils.toLocaleNumber(loopingData?.netAPY, 2)}%</span>
          </div>
          <div className="tools-info__list-item">
            <p>Deposit APY</p> <span>{MathUtils.toLocaleNumber(loopingData?.depositAPY, 2)}%</span>
          </div>
          <div className="tools-info__list-item">
            <p>Borrow APY</p>{' '}
            <span>{MathUtils.toLocaleNumber(loopingData?.borrowAPY.negated(), 2)}%</span>
          </div>
          <div className="tools-info__list-item">
            <p>Health Factor:</p>{' '}
            <HealthFactor value={MathUtils.formatNumber(loopingData.healthFactor, 2)} />
          </div>
        </>
      ) : (
        <>
          <div className="tools-info__list-item">
            <p>{TokensSymbolEnum.REWARD} APR</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>{reserve ? getAssetInfo(reserve.symbol).symbol : ''} APY</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>Net APY</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>Deposit APY</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>Borrow APY</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>Health Factor:</p> <span>-</span>
          </div>
        </>
      )}
    </BasicBox>
  );
}
