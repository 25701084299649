import BigNumber from 'bignumber.js';
import { ComputedReserveData, UserSummary } from '../../libs/pool-data-provider';
import { valueToBigNumber } from '@aave/protocol-js';
import { ComputedUserReserve } from '@aave/math-utils';

export interface BorrowWidgetData {
  maxAmountToBorrow: BigNumber;
  maxUserAmountToBorrow: BigNumber;
  userAvailableAmountToBorrow: BigNumber;
  currentStableBorrowRate: string;
}

interface UseBorrowDataProps {
  user: UserSummary;
  poolReserve: ComputedReserveData;
  userReserve: ComputedUserReserve;
}

export class BorrowWidgetHelper {
  public static calculateMaxBorrowAmount(
    user: UserSummary,
    poolReserve: ComputedReserveData
  ): { maxUserAmountToBorrow: BigNumber; maxAmountToBorrow: BigNumber } {
    const maxUserAmountToBorrow = valueToBigNumber(
      user.availableBorrowsMarketReferenceCurrency || 0
    ).div(poolReserve.priceInMarketReferenceCurrency);

    let maxAmountToBorrow = BigNumber.max(
      BigNumber.min(poolReserve.availableLiquidity, maxUserAmountToBorrow),
      0
    );

    if (
      maxAmountToBorrow.gt(0) &&
      user.totalBorrowsMarketReferenceCurrency !== '0' &&
      maxUserAmountToBorrow.lt(
        valueToBigNumber(poolReserve.availableLiquidity).multipliedBy('1.01')
      )
    ) {
      maxAmountToBorrow = maxAmountToBorrow.multipliedBy('0.99');
    }

    return { maxUserAmountToBorrow, maxAmountToBorrow };
  }

  public static calculateUserAvailableBorrowAmount(
    user: UserSummary,
    poolReserve: ComputedReserveData
  ): BigNumber {
    let userAvailableAmountToBorrow = valueToBigNumber(
      user.availableBorrowsMarketReferenceCurrency
    ).div(poolReserve.priceInMarketReferenceCurrency);

    if (
      userAvailableAmountToBorrow.gt(0) &&
      user?.totalBorrowsMarketReferenceCurrency !== '0' &&
      userAvailableAmountToBorrow.lt(
        valueToBigNumber(poolReserve.availableLiquidity).multipliedBy('1.01')
      )
    ) {
      userAvailableAmountToBorrow = userAvailableAmountToBorrow.multipliedBy('0.995');
    }

    return userAvailableAmountToBorrow;
  }
  public static getBorrowData({ user, poolReserve, userReserve }: UseBorrowDataProps) {
    const { maxUserAmountToBorrow, maxAmountToBorrow } =
      BorrowWidgetHelper.calculateMaxBorrowAmount(user, poolReserve);

    const userAvailableAmountToBorrow = BorrowWidgetHelper.calculateUserAvailableBorrowAmount(
      user,
      poolReserve
    );

    const currentStableBorrowRate =
      (userReserve?.stableBorrows &&
        Number(userReserve?.stableBorrows) > 0 &&
        poolReserve.stableBorrowAPY) ||
      '-1';

    return {
      maxUserAmountToBorrow,
      maxAmountToBorrow,
      userAvailableAmountToBorrow,
      currentStableBorrowRate: currentStableBorrowRate,
    };
  }
}
