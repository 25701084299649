import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import Value from '../../../../components/basic/Value';
import ValuePercent from '../../../../components/basic/ValuePercent';
import staticStyles from './styles';
import { MathUtils } from '../../../../utils/math.utils';
import APYLine from '../APYLine';
import { Select } from '../../../../components/Select/Select';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { useHistory } from 'react-router-dom';
import { valueToBigNumber } from '@aave/math-utils';

export function ReserveMainInfo({ symbol, dollarPrefix, poolReserve, reserveOverviewData }: any) {
  const history = useHistory();
  const { reserves } = useDynamicPoolDataContext();

  const handleClick = ({ id, underlyingAsset }: any) => {
    const scrollY = window.scrollY;

    history.push(`/reserve-overview/${underlyingAsset}-${id}`, { scrollY });
  };

  return (
    <>
      <BasicBox className="ReserveInformation-top" variant="secondary">
        <Select
          selectedId={-1}
          onOptionChange={(id) =>
            handleClick({ underlyingAsset: reserves[id].underlyingAsset, id: reserves[id].id })
          }
          className="ReserveInformation-top__select"
        >
          <Select.Header className="ReserveInformation-top__select-header">
            <div className="ReserveInformation-top__token">
              <div className="ReserveInformation-top__token-icon">
                <BasicToken tokenSymbol={symbol}>
                  {(asset, asset2) => (
                    <>
                      <BasicToken.Image
                        icon={asset.icon}
                        icon2={asset2?.icon}
                        alt={symbol}
                        size="extra-large"
                      />

                      <div className="ReserveInformation-top__body">
                        <BasicToken.Name
                          name={asset.symbol}
                          className="ReserveInformation-top__token-symbol"
                        />
                      </div>
                    </>
                  )}
                </BasicToken>
              </div>
            </div>
          </Select.Header>

          <Select.Dropdown className="ReserveInformation-top__select-dropdown">
            {reserves.map((reserve) => (
              <Select.Item
                key={reserve.symbol}
                className="ReserveInformation-top__select-item"
                disabled={reserve.aTokenAddress === poolReserve.aTokenAddress}
              >
                <BasicToken tokenSymbol={reserve.symbol}>
                  {(asset, asset2) => (
                    <>
                      <BasicToken.Image
                        icon={asset.icon}
                        icon2={asset2?.icon}
                        alt={reserve.symbol}
                        size="medium"
                      />
                      <BasicToken.Name name={asset.symbol} />
                    </>
                  )}
                </BasicToken>
              </Select.Item>
            ))}
          </Select.Dropdown>
        </Select>

        <ul className="ReserveInformation-top__list">
          <li className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Asset price</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {!!dollarPrefix && '$'}
              <Value
                value={poolReserve.priceInMarketReferenceCurrency}
                maximumValueDecimals={2}
                tokenIcon={false}
              />
            </div>
          </li>
          <li className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Borrow APY</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              <APYLine
                value={reserveOverviewData.variableAPY}
                condition={reserveOverviewData.borrowingEnabled}
              />
            </div>
          </li>
          <li className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Reserve size</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {MathUtils.formatNumber(poolReserve.totalLiquidity, 2)}
            </div>
          </li>

          <li className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Total borrowed</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {MathUtils.truncateNumber(
                valueToBigNumber(reserveOverviewData.totalBorrows)
                  .div(
                    valueToBigNumber(reserveOverviewData.totalBorrows).plus(
                      reserveOverviewData.availableLiquidity
                    )
                  )
                  .times(100),
                2
              )}
              %
            </div>
          </li>
          <li className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Available liquidity</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              {MathUtils.formatNumber(reserveOverviewData.availableLiquidity, 2)}
            </div>
          </li>
          <li className="ReserveOverview__badge">
            <p className="ReserveOverview__badge-title">Utilization rate</p>
            <div className="ReserveOverview__badge-txt ff-lg">
              <ValuePercent
                value={
                  reserveOverviewData.borrowingEnabled
                    ? reserveOverviewData.utilizationRate * 100
                    : 0
                }
                minimumDecimals={2}
                maximumDecimals={2}
                className="PercentBlock__value"
              />
            </div>
          </li>
        </ul>
      </BasicBox>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
