import { BigNumber as EthersBigNumber, providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { ChefIncentivesController } from './typechain/ChefIncentivesController';
import {
  _abi,
  ChefIncentivesController__factory,
} from './typechain/ChefIncentivesController__factory';
import getNumberFromEtherBigNumber from '../getNumberFromEtherBigNumber';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { MulticallUtils, Web3Utils } from '../../../utils';
import { ERC20_TOKEN_ABI } from '../../../apis/erc20-token/erc20-token.abi';

export class ChefIncentivesService extends BaseService<ChefIncentivesController> {
  readonly erc20Service: IERC20ServiceInterface;
  chefIncentivesControllerAddr: string;
  version: string;

  constructor(provider: providers.Provider, chefIncentivesControllerAddr: string) {
    super(provider, ChefIncentivesController__factory);
    this.chefIncentivesControllerAddr = chefIncentivesControllerAddr;
    this.erc20Service = new ERC20Service(provider);
    this.version = window.localStorage.getItem('version') ?? 'v2';
  }

  public _claim(user: tEthereumAddress, tokens: string[]): () => Promise<transactionType> {
    const ChefIncentivesContract: ChefIncentivesController = this.getContractInstance(
      this.chefIncentivesControllerAddr
    );

    return this.generateTxCallback({
      rawTxMethod: () => ChefIncentivesContract.populateTransaction.claim(user, tokens),
      from: user,
    });
  }

  public claimAll(user: tEthereumAddress): () => Promise<transactionType> {
    const ChefIncentivesContract: ChefIncentivesController = this.getContractInstance(
      this.chefIncentivesControllerAddr
    );

    return this.generateTxCallback({
      rawTxMethod: () => ChefIncentivesContract.populateTransaction.claimAll(user),
      from: user,
    });
  }

  public async claim(
    user: tEthereumAddress,
    tokens: string[]
  ): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];

    const ChefIncentivesContract: ChefIncentivesController = this.getContractInstance(
      this.chefIncentivesControllerAddr
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () => ChefIncentivesContract.populateTransaction.claim(user, tokens),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.REWARD_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  }

  public async userBaseClaimable(user: tEthereumAddress): Promise<number> {
    const chefIncentivesContract: ChefIncentivesController = this.getContractInstance(
      this.chefIncentivesControllerAddr
    );

    const _userBaseClaimable = await chefIncentivesContract.callStatic.userBaseClaimable(user);
    // TODO: investigate - assuming 18 here?
    return getNumberFromEtherBigNumber(_userBaseClaimable, 18);
  }

  public async allPendingRewards(user: tEthereumAddress): Promise<BigNumber> {
    const chefIncentivesContract: ChefIncentivesController = this.getContractInstance(
      this.chefIncentivesControllerAddr
    );

    const allPendingRewards = await chefIncentivesContract.callStatic.allPendingRewards(user);

    // TODO: investigate - assuming 18 here?
    return valueToBigNumber(getNumberFromEtherBigNumber(allPendingRewards, 18));
  }

  public async getTotalSupplies(
    addresses: tEthereumAddress[]
  ): Promise<Record<string, EthersBigNumber>> {
    const multicall = MulticallUtils.createMulltical(this.provider);

    const results = await multicall.call(
      addresses.map((address) => ({
        reference: `poolInfo-${address}`,
        contractAddress: address,
        abi: ERC20_TOKEN_ABI,
        // @ts-ignore
        calls: [
          {
            reference: `pool-${address}`,
            methodName: 'totalSupply',
            methodParameters: [],
          },
        ],
      }))
    );

    let data = {} as any;

    Object.entries(results.results).forEach(([key, value]) => {
      data = { ...data, ...MulticallUtils.convertData(value) };
    });

    // console.log(data);

    return data;
  }
}
