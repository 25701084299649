import { useCallback } from 'react';
import staticStyles from './style';
import { RewardLockAprsModel } from '../../../../store';
import BasicToken from '../../../../components/BasicToken/BasicToken';

export default function MetLockAndEarn({ aprByTokens }: { aprByTokens: RewardLockAprsModel }) {
  const renderApr = (apr: number): string => {
    return `${apr === 0 ? '< 0.01' : apr}%`;
  };

  const renderColumns = useCallback(() => {
    return Object.keys(aprByTokens.data).map((key, index) => {
      if (typeof aprByTokens.data[key] !== 'undefined') {
        return (
          <BasicToken tokenSymbol={key} className="Lock_OLDAndEarn__item" key={`item-${index}`}>
            {(asset, asset2) => (
              <>
                <BasicToken.Image
                  icon={asset.icon}
                  icon2={asset2?.icon}
                  alt={key}
                  size="medium"
                  className="MarketTableItem__token"
                />
                <div className="Lock_OLDAndEarn__item__inf">
                  <BasicToken.Name
                    className="Lock_OLDAndEarn__item__info__symbol"
                    name={!asset2 ? asset.symbol : `${asset.symbol} / ${asset2.symbol}`}
                  />
                  <span>
                    {typeof aprByTokens.data[key] !== 'undefined'
                      ? renderApr(aprByTokens.data[key])
                      : 'Fetch...'}
                  </span>
                </div>
              </>
            )}
          </BasicToken>
        );
      } else {
        return null;
      }
    });
  }, [aprByTokens, aprByTokens.isLoaded]);

  return (
    <>
      <div className="Lock_OLDAndEarn">
        {aprByTokens.isLoaded ? renderColumns() : <span>Fetching...</span>}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
