import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import massages from './messages';

import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import NoDataPanel from '../../../../components/NoDataPanel';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import staticStyles from './style';

import PageHeader from '../../../../components/PageHeader';
import { ManageTopSectoin } from '../../components/ManageTopSectoin/ManageTopSectoin';
import { ManageRevenueSectoin } from '../../components/ManageRevenueSectoin/ManageRevenueSectoin';
import { ManageLock } from '../../components/ManageLock/ManageLock';
import { ManageVesting } from '../../components/ManageVesting/ManageVesting';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { EmissionsRow } from '../../../../components';

export interface EarnedProps {
  amount: string;
  expiryDate: Date;
}

export function ManageMain() {
  const intl = useIntl();
  const { user, totalLockedSupply } = useDynamicPoolDataContext();
  const { refetch } = useBalanceContext();

  const [statsRerender, setStatsRerender] = useState<number>(0);

  useEffect(() => {
    refetch();
  }, [statsRerender]);

  return (
    <>
      <ScreenWrapper className="manage">
        <EmissionsRow />

        <PageHeader text="LIQUIDITY MINING" />
        <p className="manage__txt">
          Explore more real yield from revenue share! Earn platform fees by locking IOL - IOTA LPs.
        </p>

        <div className="manage__top">
          <ManageTopSectoin {...{ user, totalLockedSupply }} />
          {user ? <ManageRevenueSectoin {...{ user, setStatsRerender }} /> : null}
        </div>

        {user ? (
          <>
            <div className="manage__contant">
              <BasicBox className="manage__basic-box">
                <ManageLock
                  {...{
                    totalLockedSupply,
                    setStatsRerender,
                    user,
                  }}
                />
              </BasicBox>
              <BasicBox className="manage__basic-box">
                <ManageVesting {...{ setStatsRerender, user }} />
              </BasicBox>
            </div>
          </>
        ) : (
          <NoDataPanel
            title={intl.formatMessage(massages.connectWallet)}
            description={intl.formatMessage(massages.connectWalletDescription)}
            withConnectButton={true}
          />
        )}
      </ScreenWrapper>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

export default ManageMain;
