import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { BasicModal } from '../../libs/aave-ui-kit';
import {
  BigNumber,
  valueToBigNumber,
  InterestRate,
  calculateHealthFactorFromBalancesBigUnits,
} from '@aave/protocol-js';
import {
  UserSummary,
  ComputedReserveData,
  useStaticPoolDataContext,
} from '../../libs/pool-data-provider';
import { ComputedUserReserve } from '@aave/math-utils';
import { useTxBuilderContext } from '../../libs/tx-provider';
import messages from './messages';
import PoolTxConfirmationView from '../PoolTxConfirmationView';
import Row from '../basic/Row';
import Value from '../basic/Value';
import ValuePercent from '../basic/ValuePercent';
import HealthFactor from '../HealthFactor';
import { getReferralCode } from '../../libs/referral-handler';
import { getAtokenInfo } from '../../helpers/get-atoken-info';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import RiskBar from '../basic/RiskBar';
import DefaultButton from '../basic/DefaultButton';
import { MathUtils } from '../../utils/math.utils';
import { BorrowWidgetHelper } from './borrow-widget.helper';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

interface BorrowAmountProps {
  poolReserve: ComputedReserveData;
  currencySymbol: string;
  user: UserSummary;
  userReserve: ComputedUserReserve;
}

export function BorrowWidget({
  userReserve,
  poolReserve,
  user,
  currencySymbol,
}: BorrowAmountProps) {
  const [amountToBorrow, setAmountToBorrow] = useState<BigNumber>(BigNumber(0));
  const intl = useIntl();
  const { lendingPool } = useTxBuilderContext();
  const [step, setStep] = useState<string>(Steps.amount);
  const [fieldValue, setFieldValue] = useState<string>('');

  const { marketRefPriceInUsd } = useStaticPoolDataContext();

  const { currentStableBorrowRate, maxAmountToBorrow, userAvailableAmountToBorrow } = useMemo(
    () =>
      BorrowWidgetHelper.getBorrowData({
        user,
        poolReserve,
        userReserve,
      }),

    [user, poolReserve, userReserve]
  );

  const handleAmountChange = (value: string) => {
    setFieldValue(value);
    setAmountToBorrow(BigNumber(value || 0));
  };

  const handleSetAmountSubmit = (amount: string) => {
    setStep(Steps.confirmation);
  };

  let blockingError = '';

  const aTokenData = getAtokenInfo({
    address: poolReserve.underlyingAsset,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
    withFormattedSymbol: true,
  });

  // lock values to not update them after tx was executed
  const [isTxExecuted, setIsTxExecuted] = useState(false);

  const interestRateMode = InterestRate.Variable;

  if (amountToBorrow.gt(poolReserve.availableLiquidity)) {
    blockingError = intl.formatMessage(messages.errorNotEnoughLiquidity, {
      currencySymbol,
    });
  }

  if (userAvailableAmountToBorrow.lt(amountToBorrow)) {
    blockingError = intl.formatMessage(messages.errorNotEnoughCollateral);
  }

  if (!poolReserve.borrowingEnabled) {
    blockingError = intl.formatMessage(messages.errorBorrowingNotAvailable);
  }

  const { amountToBorrowInUsd, newHealthFactor } = useMemo(() => {
    const amountToBorrowInUsd = amountToBorrow
      .times(poolReserve.priceInMarketReferenceCurrency)
      .times(marketRefPriceInUsd);

    const newHealthFactor = calculateHealthFactorFromBalancesBigUnits(
      user.totalCollateralUSD,
      valueToBigNumber(user.totalBorrowsUSD).plus(amountToBorrowInUsd),
      user.currentLiquidationThreshold
    );

    return { amountToBorrowInUsd, newHealthFactor };
  }, [poolReserve, amountToBorrow, marketRefPriceInUsd, user, fieldValue]);

  const handleGetTransactions = async () => {
    const referralCode = getReferralCode() || undefined;
    return await lendingPool.borrow({
      interestRateMode,
      referralCode,
      user: user.id,
      amount: amountToBorrow.toString(),
      reserve: poolReserve.underlyingAsset,
      debtTokenAddress:
        interestRateMode === InterestRate.Variable
          ? poolReserve.variableDebtTokenAddress
          : poolReserve.stableDebtTokenAddress,
    });
  };

  const handleMainTxExecuted = () => setIsTxExecuted(true);

  return (
    <>
      <BasicAmountField className="reserve-widget__field" maxAmount={maxAmountToBorrow}>
        {(maxAmount) => (
          <>
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                type="number"
                handleChange={handleAmountChange}
                value={fieldValue}
                max={maxAmount}
              />
              <BasicAmountField.USDValue>
                {Number(fieldValue)
                  ? MathUtils.truncateNumber(
                      valueToBigNumber(fieldValue).times(
                        poolReserve.priceInMarketReferenceCurrency
                      ),
                      10
                    )
                  : 0}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              <BasicAmountField.Asset symbol={currencySymbol} />
              <BasicAmountField.Balance
                onClick={() => handleAmountChange(maxAmount.toString())}
                label="available to borrow:"
              >
                {maxAmount.toString()}
              </BasicAmountField.Balance>
            </BasicAmountField.TokenBox>
          </>
        )}
      </BasicAmountField>

      <RiskBar
        onChange={() => {}}
        disabled={true}
        maxAmount={'10'}
        amountToBorrowInUsd={amountToBorrowInUsd}
        newHealthFactor={newHealthFactor.lt(1) ? valueToBigNumber(1) : newHealthFactor}
        className="reserve-widget__range"
      />

      <DefaultButton
        fill
        className="reserve-widget__button"
        onClick={handleSetAmountSubmit}
        disabled={!Number(fieldValue)}
      >
        Continue
      </DefaultButton>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        <PoolTxConfirmationView
          mainTxName={'Borrow'}
          caption={'Borrow overview'}
          boxTitle={'Borrow'}
          boxDescription={'Please submit to borrow'}
          approveDescription={'Please approve before borrowing'}
          getTransactionsData={handleGetTransactions}
          onMainTxExecuted={handleMainTxExecuted}
          blockingError={blockingError}
          className="BorrowConfirmation"
          aTokenData={aTokenData}
          goToAfterSuccess="/dashboard"
        >
          <Row title={'Amount'} withMargin={true}>
            <Value
              symbol={currencySymbol}
              value={amountToBorrow.toString()}
              tokenIcon={true}
              subValue={amountToBorrowInUsd.toString()}
              subSymbol="USD"
              tooltipId={currencySymbol}
            />
          </Row>

          {Number(currentStableBorrowRate) >= 0 && (
            <Row
              title={intl.formatMessage(messages.currentBorrowRateTitle, {
                borrowRateMode: 'Stable'.toLowerCase(),
              })}
              withMargin={true}
            >
              <ValuePercent value={currentStableBorrowRate} updateCondition={isTxExecuted} />
            </Row>
          )}

          <Row title={intl.formatMessage(messages.APYRowTitle)} withMargin={true}>
            <ValuePercent value={poolReserve.variableBorrowAPY} updateCondition={isTxExecuted} />
          </Row>

          <Row title={intl.formatMessage(messages.rateTypeRowTitle)} withMargin={true}>
            <strong className="BorrowRateMode">
              {interestRateMode === InterestRate.Variable
                ? intl.formatMessage(messages.variable)
                : intl.formatMessage(messages.stable)}
            </strong>
          </Row>

          <Row title={intl.formatMessage(messages.healthFactorRowTitle)}>
            <HealthFactor value={newHealthFactor.toString()} />
          </Row>
        </PoolTxConfirmationView>
      </BasicModal>
    </>
  );
}
