import classNames from 'classnames';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLanguageContext } from '../../../libs/language-provider';
import { useHeaderTitle, useWithDesktopTitle } from '../ScreensWrapper';

import staticStyles from './style';

// Pages where the banners should be displayed
export const DISPLAY_BRIDGE_BANNER_PAGES = ['/deposit', '/repay'];

interface ScreenWrapperProps {
  pageTitle?: string;
  isTitleOnDesktop?: boolean;
  isTopLineSmall?: boolean;
  className?: string;
  children: ReactNode;
}

export default function ScreenWrapper({
  pageTitle,
  isTitleOnDesktop,
  isTopLineSmall,
  className,
  children,
}: ScreenWrapperProps) {
  const { currentLangSlug } = useLanguageContext();
  const location = useLocation();
  const { setTitle } = useHeaderTitle();
  const { setTopPanelSmall } = useWithDesktopTitle();

  useEffect(() => {
    pageTitle && setTitle(pageTitle);
    // eslint-disable-next-line no-lone-blocks
    {
      if (isTitleOnDesktop || isTopLineSmall) {
        setTopPanelSmall(true);
        localStorage.setItem('isTopPanelSmall', 'true');
      } else {
        setTopPanelSmall(false);
        localStorage.setItem('isTopPanelSmall', 'false');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLangSlug, location.pathname]);

  return (
    <section
      className={classNames('ScreenWrapper', className, {
        ScreenWrapper__withDesktopTitle: isTitleOnDesktop,
      })}
    >
      {children}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </section>
  );
}
