import { useEffect, useState } from 'react';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';

import {
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
} from '../../../../libs/pool-data-provider';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import MarketTable from '../../components/MarketTable';

import staticStyles from './style';
import { BN_ONE, BN_ZERO } from '../../../../helpers/leverage';
import { isAssetStable } from '../../../../helpers/config/assets-config';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';

import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import PageHeader from '../../../../components/PageHeader';
import { useFeeData, usePoolsInfoData } from '../../../../store';
import { MathUtils } from '../../../../utils/math.utils';
import { PoolUtils } from '../../../../utils';
import { EmissionsRow } from '../../../../components';
import { MarketsAprs } from '../../components/MarketsAprs/MarketsAprs';
import { REWARD_ADDRESS, TokensSymbolEnum, wIOTA_ADDRESS } from '../../../../shared';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export default function Markets() {
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves, totalLockedSupply } = useDynamicPoolDataContext();

  const { prices } = useRewardTokenPrices();

  const { totalPlatformFees } = useFeeData();

  const [totalLiquidyInUsd, setTotalLiquidyInUsd] = useState(valueToBigNumber('-1'));
  const [sortedData, setSortedData] = useState<any>([]);

  const { poolsInfoData } = usePoolsInfoData();

  const [sortOptions, setSortOptions] = useState({
    desc: false,
  });

  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    if (reserves?.length && poolsInfoData.length) {
      let totalLiquidyInUsd = valueToBigNumber('0');
      let sortedData = reserves
        .filter((res) => res.isActive && !res.isFrozen)
        .map((reserve) => {
          totalLiquidyInUsd = totalLiquidyInUsd.plus(
            valueToBigNumber(reserve.totalLiquidity)
              .multipliedBy(reserve.priceInMarketReferenceCurrency)
              .multipliedBy(marketRefPriceInUsd)
          );

          const totalLiquidity = Number(reserve.totalLiquidity);
          const totalLiquidityInUSD = valueToBigNumber(reserve.totalLiquidity)
            .multipliedBy(reserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
            .toNumber();

          const totalBorrows = Number(reserve.totalDebt);
          const totalBorrowsInUSD = valueToBigNumber(reserve.totalDebt)
            .multipliedBy(reserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
            .toNumber();

          const isStable = isAssetStable(reserve.symbol);

          const maxLeverage = BN_ONE.div(
            BN_ONE.minus(valueToBigNumber(reserve.baseLTVasCollateral))
          ).decimalPlaces(2, BigNumber.ROUND_FLOOR);

          const { rewardsDepositApr, rewardsBorrowApr, netAPY } = PoolUtils.getPoolLooping({
            reserve,
            poolsInfoData,
            amount: valueToBigNumber(1),
            maxLeverage,
          });

          return {
            totalLiquidity,
            totalLiquidityInUSD,
            totalBorrows: reserve.borrowingEnabled ? totalBorrows : -1,
            totalBorrowsInUSD: reserve.borrowingEnabled ? totalBorrowsInUSD : -1,
            id: reserve.id,
            underlyingAsset: reserve.underlyingAsset,
            currencySymbol: reserve.symbol,
            borrowAPY: reserve.variableBorrowAPY,
            depositAPY: reserve.borrowingEnabled ? Number(reserve.supplyAPY) : -1,
            avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
            stableBorrowRate:
              reserve.stableBorrowRateEnabled && reserve.borrowingEnabled
                ? Number(reserve.stableBorrowAPY)
                : -1,
            variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
            avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
            borrowingEnabled: reserve.borrowingEnabled,
            stableBorrowRateEnabled: reserve.stableBorrowRateEnabled,
            isFreezed: reserve.isFrozen,
            rewardsDepositApr,
            rewardsBorrowApr,
            isStable,
            loopAPY: Number(netAPY) > 0 ? netAPY : BN_ZERO,
            maxCap: 0,
          };
        });

      setSortedData(sortedData);
      setTotalLiquidyInUsd(totalLiquidyInUsd);
    }
  }, [reserves, poolsInfoData]);

  const renderValue = (isLoaded: boolean, value: string) => {
    return isLoaded ? value : 'Fetching...';
  };

  return (
    <ScreenWrapper className="Markets">
      <EmissionsRow />

      <PageHeader text="MARKETS" />

      <div className="markets-inner">
        <MarketsAprs />

        <BasicBox className="markets-totals markets-basic-box">
          <div className="markets-totals__inner">
            <div className="markets-total__item">
              <p className="markets-total__item-name">Total market size</p>
              <p className="markets-total__item-value ff-lg">
                {renderValue(
                  !totalLiquidyInUsd.lt(0),
                  `$ ${MathUtils.toLocaleNumber(totalLiquidyInUsd, 2)}`
                )}
              </p>
            </div>
            <div className="markets-total__item">
              <p className="markets-total__item-name">wLP Locked</p>
              <p className="markets-total__item-value ff-lg">
                {renderValue(
                  !totalLockedSupply.lt(0),
                  MathUtils.toLocaleNumber(totalLockedSupply, 2)
                )}
              </p>
              <span className="markets-total__item-sub">
                {totalLockedSupply.isPositive() ? (
                  <>
                    $ {MathUtils.toLocaleNumber(totalLockedSupply.times(prices.lpTokenPriceUsd), 2)}
                  </>
                ) : (
                  ''
                )}
              </span>
            </div>
            {queryParams['total-fees'] === null && (
              <div className="markets-total__item">
                <p className="markets-total__item-name">Total platform fees</p>
                <p className="markets-total__item-value ff-lg">
                  {renderValue(
                    totalPlatformFees >= 0,
                    MathUtils.toLocaleNumber(totalPlatformFees, 2)
                  )}
                </p>
                <span className="markets-total__item-sub">
                  {' '}
                  {Number(prices.tokenPrice) && totalPlatformFees >= 0 ? (
                    <>
                      ${' '}
                      {MathUtils.toLocaleNumber(
                        totalPlatformFees * Number(prices.tokenPriceUsd),
                        2
                      )}
                    </>
                  ) : null}
                </span>
              </div>
            )}
            <div className="markets-total__item">
              <p className="markets-total__item-name">{TokensSymbolEnum.REWARD} Price</p>
              <p className="markets-total__item-value ff-lg">
                {renderValue(
                  Number(prices.tokenPriceUsd) > 0,
                  `$ ${Number(prices.tokenPriceUsd).toFixed(2)}`
                )}
              </p>
              <span className="markets-total__item-sub">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="markets-total__item-link"
                  href={`https://app.wagmi.com/trade/swap#/trade/swap?inputCurrency=${wIOTA_ADDRESS}&outputCurrency=${REWARD_ADDRESS}`}
                >
                  Buy
                </a>
              </span>
            </div>
          </div>
        </BasicBox>
      </div>

      <MarketTable data={sortedData} {...{ sortOptions, setSortOptions }} />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </ScreenWrapper>
  );
}
