import { ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';

export interface RowProps {
  children?: ReactNode;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  className?: string;
  isColumn?: boolean;
  withMargin?: boolean;
  color?: 'dark' | 'lightBlue' | 'white';
  weight?: 'normal' | 'light';
}

export default function Row({ children, title, subTitle, className }: RowProps) {
  return (
    <div className={classNames('Row', className)}>
      {title && (
        <div className="Row__title-inner">
          <div className="Row__title">{title}</div>
          {subTitle && <span className="Row__subtitle">{subTitle}</span>}
        </div>
      )}

      {children && <div className="Row__content">{children}</div>}

      <style jsx={true} global>
        {staticStyles}
      </style>
    </div>
  );
}
