import classNames from 'classnames';
import staticStyles from './style';

interface BasicSwitcherProps {
  isOn: boolean;
  handleToggle: () => void;
  disabled?: boolean;
  className?: string;
}

export function BasicSwitcher({ isOn, handleToggle, className, disabled }: BasicSwitcherProps) {
  return (
    <>
      <label className={classNames('switch', className, isOn && 'checked', disabled && 'disabled')}>
        <input
          className="switch__input"
          type="checkbox"
          checked={isOn}
          onChange={handleToggle}
          disabled={disabled}
        />
        <span className="switch__slider"></span>
      </label>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
