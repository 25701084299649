import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;

    @include respond-to(md) {
      font-size: $medium;
    }
    @include respond-to(sm) {
      font-size: $regular;
    }

    &__column {
      display: block;
      text-align: center;
      .Row__title-inner {
        margin-bottom: 3px;
      }
      .Row__title {
        padding-right: 0;
      }
      .Row__content {
        display: block;
      }
    }

    &__title-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      text-align: right;

      & .Value {
        text-align: right;
      }
    }

    &__subtitle {
      font-size: 12px;
    }
  }
`;

export default staticStyles;
