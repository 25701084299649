import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Dashboard {
    color: #000;
    &__subtitle {
      font-size: 18px;
      margin-top: 10px;

      @include respond-to(sm) {
        font-size: 16px;
      }
    }

    &__switcher-inner {
      margin: 20px 0;
      display: none;
      @include respond-to(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .Dashboard__switcher {
      @include respond-to(sm) {
        margin: 0 auto;
      }
    }

    &__top--line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px 0 15px;
      @include respond-to(xl) {
        margin: 35px 0 10px;
      }
      @include respond-to(sm) {
        display: none;
      }

      .Dashboard__topLine--button {
        width: unset;
        min-width: 140px;
        min-height: 36px;
        font-size: $medium;
        @include respond-to(xl) {
          min-width: 100px;
          min-height: 26px;
          font-size: $extraSmall;
        }
      }
    }

    &__mobileMigrate--inner {
      display: none;
      @include respond-to(sm) {
        display: block;
        .Link {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
          padding: 15px;
          font-size: $regular;
        }
      }
    }
    &__mobileMigrateWithoutContent {
      height: 30px;
    }

    .ValuePercent {
      @include respond-to(sm) {
        justify-content: end;
        &__value {
          font-size: 14px !important;
        }
      }
    }
    .Row.Row__column {
      .Row__title-inner {
        text-align: left;
      }
      @include respond-to(sm) {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .Row__content,
      .Value {
        align-items: flex-start;
        justify-content: flex-start;
      }
      .Row__content {
        text-align: left;
      }
    }
    .HealthFactor__column {
      text-align: left;
      @include respond-to(sm) {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .HealthFactor__percent {
        justify-content: flex-start;
      }
      .HealthFactor__no-value {
        text-align: left;
        justify-content: flex-start;
      }
    }

    .CollateralCompositionBar {
      @include respond-to(sm) {
        flex-direction: column;
        &.Row {
          align-items: flex-start;
        }
        .Row__title {
          padding-right: 0;
          margin-bottom: 5px;
        }
        .Row__content {
          width: 100%;
        }
      }
    }

    .Row.Dashboard__mobileRow-center {
      align-items: center;
    }
    &__mobileRow-content {
      display: flex;
      align-items: center;
    }
    .Dashboard__mobileButton {
      width: 80px;
      min-height: 32px;
      font-size: $small;
      margin-left: 10px;
    }

    &__section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 18px;
      margin: 24px 0 24px;

      @include respond-to(md) {
        display: block;
      }
    }

    &__box {
      position: relative;
      @include respond-to(md) {
        margin: 20px 0;
      }
    }

    &__box-header {
      @include respond-to(sm) {
        display: block;

        .ButtonTabs {
          justify-content: flex-start;
          margin-top: 13px;
        }
      }
    }

    &__tab {
      display: none;

      &.active {
        display: block;
      }
    }
    &__rewards {
      display: flex;
      flex-wrap: wrap;
      gap: 28px 0;
    }

    &__reward {
      flex-basis: 50%;
      font-size: 16px;

      @include respond-to(xs) {
        font-size: 14px;
      }
    }

    &__reward-value {
      font-weight: 500;
      font-size: 27px;
      margin: 2px 0;

      @include respond-to(xs) {
        font-size: 22px;
      }
    }

    &__reward-subtext {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);

      @include respond-to(xs) {
        font-size: 12px;
      }
    }
  }
`;

export default staticStyles;
