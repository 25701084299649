import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import DefaultButton from '../../../../components/basic/DefaultButton';
import { getAssetInfo } from '../../../../libs/aave-ui-kit';
import staticStyles from './style';
import { UserSummary } from '../../../../libs/pool-data-provider';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import {
  REWARD_ADDRESS,
  TokensSymbolEnum,
  MULTI_FEE_DISTRIBUTION_ADDRESS,
  CHEF_INCENTIVES_CONTROLLER,
} from '../../../../shared';
import { MathUtils } from '../../../../utils/math.utils';
import { MultiFeeDistributionService } from '../../../../libs/aave-protocol-js/MulteFeeDistributionContract';
import { sendEthTransaction } from '../../../../helpers/send-ethereum-tx';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import { ChefIncentivesService } from '../../../../libs/aave-protocol-js/ChefIncentivesContract';
import { DateUtils } from '../../../../utils';
import { ReactComponent as LogOutIcon } from '../../../../images/icons/logout-icon.svg';
import { ZapModal } from '../../../../components';
import classNames from 'classnames';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { valueToBigNumber } from '@aave/math-utils';

interface ManageVestProps {
  setStatsRerender: Dispatch<SetStateAction<number>>;
  user: UserSummary;
}

export function ManageVesting({ setStatsRerender, user }: ManageVestProps) {
  const { prices } = useRewardTokenPrices();
  const { provider } = useProviderContext();
  const {
    totalVesting,
    penalty,
    earningsData,
    allPendingRewards,
    unlockedVesting,
    setAllPendingRewards,
    setRdntData,
  } = useBalanceContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonsState, setButtonsState] = useState<Record<string, boolean>>({
    vest: false,
    exit: false,
    withdraw: false,
    penalty: false,
  });

  const handleState = (name: string, disabled: boolean) => {
    setButtonsState((state) => ({
      ...state,
      [name]: disabled,
    }));
  };

  const asset = getAssetInfo(TokensSymbolEnum.REWARD);

  const chefIncentivesService = new ChefIncentivesService(provider, CHEF_INCENTIVES_CONTROLLER);

  const multiFeeDistributionService = new MultiFeeDistributionService(
    provider,
    REWARD_ADDRESS,
    MULTI_FEE_DISTRIBUTION_ADDRESS
  );

  const vestButtonHandler = useCallback(async () => {
    const actionTx = await chefIncentivesService.claimAll(user.id as string);

    await sendEthTransaction(
      actionTx,
      provider,
      () => {
        handleState('vest', true);
      },
      null,
      { onConfirmation: () => setAllPendingRewards(valueToBigNumber(0)) }
    );

    setStatsRerender(Math.random());
    handleState('vest', false);
  }, []);

  const withdrawVestedHandler = useCallback(async () => {
    const actionTx = await multiFeeDistributionService.withdraw(user.id as string, unlockedVesting);

    await sendEthTransaction(
      actionTx,
      provider,
      () => {
        handleState('withdraw', true);
      },
      null
    );

    setStatsRerender(Math.random());
    handleState('withdraw', false);
  }, [user, provider, unlockedVesting]);

  const individualEarlyExit = useCallback(
    async (unlockTime) => {
      const actionTx = await multiFeeDistributionService.individualEarlyExit(user.id, unlockTime);

      await sendEthTransaction(
        actionTx,
        provider,
        () => {
          handleState('penalty', true);
        },
        null
      );

      setStatsRerender(Math.random());
      handleState('penalty', false);
    },
    [user, provider]
  );

  const exitVestHandler = useCallback(async () => {
    const actionTx = await multiFeeDistributionService.exit(user.id);

    await sendEthTransaction(
      actionTx,
      provider,
      () => {
        handleState('exit', true);
      },
      null,
      {
        onConfirmation: () =>
          setRdntData((data) => {
            data.totalVesting = valueToBigNumber(0);
            return data;
          }),
      }
    );

    setStatsRerender(Math.random());
    handleState('exit', false);
  }, [user, provider]);

  const onHandleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="m-vesting">
        <div className="m-vesting__title ff-lg">Vesting</div>

        <p className="m-vesting__subtitle">
          {TokensSymbolEnum.REWARD} earned from lending and borrowing must vest for 90 days.
        </p>

        <div className="m-vesting__relock manage__content-box">
          <p className="manage__content-title">Ready to vest</p>

          <div className="m-vesting__box">
            <p className="m-vesting__box-title">Vestable balance</p>

            <div className="manage__group">
              <div className="manage__token">
                <img className="manage__token-img" src={asset.icon} alt={asset.name} />

                <div className="manage__token-right">
                  <p className="manage__token-balance ff-lg">
                    {MathUtils.toLocaleNumber(allPendingRewards, 2)}
                  </p>
                  <span className="manage__token-usd">
                    ${' '}
                    {MathUtils.toLocaleNumber(
                      allPendingRewards.multipliedBy(prices.tokenPriceUsd),
                      2
                    )}
                  </span>
                </div>
              </div>

              <DefaultButton
                color="primary"
                size="small"
                title="Start Vesting"
                className="m-vesting__relock-btn"
                disabled={allPendingRewards.eq(0) || buttonsState.vest}
                loading={buttonsState.vest}
                onClick={vestButtonHandler}
              />
            </div>
          </div>
        </div>

        <div className="m-vesting__relock manage__content-box">
          <p className="manage__content-title">Currently vesting</p>

          <div className="m-vesting__box">
            <p className="m-vesting__box-title">Vesting balance</p>

            <div className="manage__group">
              <div className="manage__token">
                <img className="manage__token-img" src={asset.icon} alt={asset.name} />

                <div className="manage__token-right">
                  <p className="manage__token-balance ff-lg">
                    {MathUtils.toLocaleNumber(totalVesting, 2)}
                  </p>
                  <span className="manage__token-usd">
                    $ {MathUtils.toLocaleNumber(totalVesting.multipliedBy(prices.tokenPriceUsd), 2)}
                  </span>
                </div>
              </div>

              <DefaultButton
                color="primary"
                size="small"
                title="Zap into"
                className="m-vesting__btn"
                disabled={totalVesting.isNegative() || totalVesting.eq(0)}
                onClick={() => setIsOpen(true)}
              />
            </div>

            <div className="manage__group m-vesting__curently-row">
              <div>
                <div className="m-vesting__badge">
                  <span className="m-vesting__badge-label">Penalty:</span>{' '}
                  {MathUtils.toLocaleNumber(penalty, 2)} {asset.symbol}
                </div>
              </div>

              <DefaultButton
                color="secondary"
                size="small"
                title="Exit early"
                onClick={exitVestHandler}
                disabled={totalVesting.isNegative() || totalVesting.eq(0) || buttonsState.exit}
                loading={buttonsState.exit}
                className="m-vesting__btn"
              />
            </div>
          </div>
        </div>
        <p className="manage__content-text">
          Early withdrawal from a vest will incur a penalty between 25-90% based on a linear
          schedule of elapsed time.
        </p>
        <div className="m-vesting__relock manage__content-box">
          <p className="manage__content-title">Vested</p>

          <div className="m-vesting__box">
            <p className="m-vesting__box-title">Vested balance</p>

            <div className="manage__group">
              <div className="manage__token">
                <img className="manage__token-img" src={asset.icon} alt={asset.name} />

                <div className="manage__token-right">
                  <p className="manage__token-balance ff-lg">
                    {MathUtils.toLocaleNumber(unlockedVesting, 2)}
                  </p>
                  <span className="manage__token-usd">
                    ${' '}
                    {MathUtils.toLocaleNumber(
                      unlockedVesting.multipliedBy(prices.tokenPriceUsd),
                      2
                    )}
                  </span>
                </div>
              </div>

              <DefaultButton
                disabled={
                  unlockedVesting.isNegative() || unlockedVesting.eq(0) || buttonsState.withdraw
                }
                loading={buttonsState.withdraw}
                color="secondary"
                size="small"
                title="Withdraw"
                className="m-vesting__btn"
                onClick={withdrawVestedHandler}
              />
            </div>
          </div>
        </div>

        <p className="manage__content-text">
          {TokensSymbolEnum.REWARD} that has completed the 90 day vesting period.
        </p>

        {earningsData.length > 0 ? (
          <BasicBox className="manage__content-box m-lock__unlock">
            <div className="m-lock__unlock-row m-lock__unlock-header">
              <p className="m-vesting__item-cell">Amount</p>
              <p className="m-vesting__item-cell m-vesting__item-cell--center">Unlockable in</p>
              <p className="m-vesting__item-cell m-vesting__item-cell--right">penalty</p>
            </div>

            {earningsData.map(({ amount, penalty, unlockTime }, index) => {
              const timeLeft = DateUtils.calculateTimeLeft(unlockTime);

              return (
                <div className="m-lock__unlock-row" key={index}>
                  <div className="m-vesting__item-cell">
                    <div className="m-lock__unlock-token">
                      <p className="m-lock__unlock-value">{MathUtils.toLocaleNumber(amount, 2)}</p>
                      <img className="m-lock__unlock-img" src={asset.icon} alt={asset.name} />
                    </div>
                  </div>
                  <p className="m-lock__unlock-sub m-vesting__item-cell--center m-vesting__item-cell">
                    {timeLeft}
                  </p>

                  <p className="m-vesting__logout m-vesting__item-cell m-vesting__item-cell--right">
                    <span>{MathUtils.truncateNumber(penalty, 2)}</span>
                    <LogOutIcon
                      width={20}
                      className={classNames(
                        'm-vesting__logout-btn',
                        buttonsState.penalty && `m-vesting__logout-btn--disabled`
                      )}
                      onClick={() => !buttonsState.penalty && individualEarlyExit(unlockTime)}
                    />
                  </p>
                </div>
              );
            })}
          </BasicBox>
        ) : null}
      </div>

      <ZapModal {...{ isOpen, onHandleClose }} onlyVestingMode />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
