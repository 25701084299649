import LiquidityMiningCard from '../../../../../components/liquidityMining/LiquidityMiningCard';

import staticStyles from './style';

interface TableAprColProps {
  value: number;
  thirtyDaysAverage?: string;
  liquidityMiningValue: string | number;
  condition?: boolean;
  symbol?: string;
  type?: string;
}

export default function TableAprCol({
  value,
  thirtyDaysAverage,
  liquidityMiningValue,
  type,
  symbol,
}: TableAprColProps) {
  return (
    <>
      <LiquidityMiningCard
        value={value}
        thirtyDaysValue={thirtyDaysAverage}
        liquidityMiningValue={liquidityMiningValue}
        symbol={symbol}
        type={type}
      />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
