import classNames from 'classnames';

import staticStyles from './style';

interface NoDataProps {
  color?: 'dark' | 'white' | 'lightBlue';
  className?: string;
  onWhiteBackground?: boolean;
}

export default function NoData({ color = 'white', className }: NoDataProps) {
  return (
    <span className={classNames('NoData', `NoData__${color}`, className)}>
      —<style jsx={true}>{staticStyles}</style>
    </span>
  );
}
