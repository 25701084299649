import css from 'styled-jsx/css';

export const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .basic-amount-field {
    display: flex;
    padding: 22px;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid #d9d9d9;

    @include respond-to(xs) {
      padding: 12px;
    }

    &__input-box {
      flex: 1 1 auto;
      margin-right: 16px;
    }

    &__token-box {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__input {
      font-size: 24px;
      font-weight: 400;
      background: none;
      border: none;
      color: #000;
      padding: 15px 0;
      margin: -16px 0 -13px;
      line-height: 34px;
      display: block;
      width: 100%;

      @include respond-to(xs) {
        font-size: 20px;
      }
    }

    &__asset {
      display: flex;
      font-weight: 500;
      gap: 8px;
      align-items: center;
    }

    &__asset-img {
      &--second {
        margin-left: -16px;
      }
    }

    &__usd-value {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      display: block;

      @include respond-to(xs) {
        font-size: 12px;
      }
    }

    &__balance {
      color: #52679d;
      font-size: 14px;
      display: flex;
      gap: 6px;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;

      @include respond-to(xs) {
        font-size: 12px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__balance-label {
      color: #000;
    }

    &__balance-icon {
      width: 16px;
      height: 14px;
    }

    &--small {
      padding: 13px 12px;

      .basic-amount-field {
        &__input {
          padding: 6px 0;
          margin: -4px 0 -3px;
          line-height: 28px;
        }

        &__balance {
          margin-top: 4px;
        }
      }
    }
  }
`;
