import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MainDashboardTable {
    display: flex;
    gap: 18px;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;

    @include respond-to(md) {
      display: block;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    &__inner {
      flex: 1;

      &--no-data {
        flex-basis: calc(50% - 6px);

        .NoDataPanel__wrapper {
          margin: 0;
          @include respond-to(md) {
            margin-top: 24px;
          }
        }
      }

      @include respond-to(md) {
        margin-top: 40px;
      }
    }

    &__noBorrows {
      max-width: none;
      margin: 0;

      .NoDataPanel__logo {
        display: none;
      }
    }
  }
`;

export default staticStyles;
