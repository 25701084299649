import css from 'styled-jsx/css';

/*language=SCSS*/
export const staticStyles = css.global`
  .basic-select__header {
    display: flex;
    gap: 9px;
    position: relative;
    align-items: center;
    transition: background 0.3s;
    cursor: pointer;
    border-radius: 25px;
    padding: 8px 10px 8px 8px;

    .enabled &:hover,
    .is-opened & {
      background-color: rgba(14, 13, 19, 0.1);
    }

    .disabled & {
      cursor: default;
    }
  }

  .basic-select__header-arrow {
    width: 12px;
    height: 7px;
    transition: transform 0.3s;

    svg {
      width: 100%;
      height: auto;
    }

    .is-opened & {
      transform: rotate(-180deg);
    }
  }
`;
