import {
  ComputedReserveData,
  UserSummary,
  useStaticPoolDataContext,
} from '../../libs/pool-data-provider';
import { useEffect, useState } from 'react';
import { ComputedUserReserve } from '@aave/math-utils';
import { useWalletBalanceProviderContext } from '../../libs/wallet-balance-provider/WalletBalanceProvider';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { DepositWidgetHelper } from './deposit-widget.helper';

export interface DepositWidgetData {
  walletBalance: BigNumber;
  maxAmountToDeposit: BigNumber;
  amountInUsd: BigNumber;
  healthFactorAfterDeposit: BigNumber;
  notShowHealthFactor: boolean;
  usageAsCollateralEnabledOnDeposit: boolean;
}

interface useDepositDataProps {
  user: UserSummary;
  poolReserve: ComputedReserveData;
  amountToDeposit: BigNumber;
  userReserve: ComputedUserReserve;
}

export function useDepositData({
  user,
  poolReserve,
  amountToDeposit,
  userReserve,
}: useDepositDataProps) {
  const { walletData } = useWalletBalanceProviderContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { networkConfig } = useProtocolDataContext();
  const [depositData, setDepositData] = useState<DepositWidgetData>({
    walletBalance: valueToBigNumber(0),
    maxAmountToDeposit: valueToBigNumber(0),
    amountInUsd: valueToBigNumber(0),
    healthFactorAfterDeposit: valueToBigNumber(0),
    notShowHealthFactor: false,
    usageAsCollateralEnabledOnDeposit: false,
  });

  useEffect(() => {
    if (poolReserve && user && walletData) {
      const { healthFactorAfterDeposit, amountInUsd } = DepositWidgetHelper.calculateMHealthFactor(
        user,
        poolReserve,
        amountToDeposit.isNaN() ? valueToBigNumber(0) : amountToDeposit,
        marketRefPriceInUsd
      );

      const walletBalance = DepositWidgetHelper.getWalletBalance(walletData, poolReserve);

      let maxAmountToDeposit = valueToBigNumber(walletBalance);
      if (
        maxAmountToDeposit.gt(0) &&
        poolReserve.symbol.toUpperCase() === networkConfig.baseAsset
      ) {
        // keep it for tx gas cost
        maxAmountToDeposit = maxAmountToDeposit.minus('0.001');
      }
      if (maxAmountToDeposit.lte(0)) {
        maxAmountToDeposit = valueToBigNumber('0');
      }

      const notShowHealthFactor =
        user.totalBorrowsMarketReferenceCurrency !== '0' && poolReserve.usageAsCollateralEnabled;

      const usageAsCollateralEnabledOnDeposit =
        poolReserve.usageAsCollateralEnabled &&
        (!userReserve?.underlyingBalance ||
          userReserve.underlyingBalance === '0' ||
          userReserve.usageAsCollateralEnabledOnUser);

      setDepositData({
        walletBalance,
        maxAmountToDeposit,
        amountInUsd,
        healthFactorAfterDeposit,
        notShowHealthFactor,
        usageAsCollateralEnabledOnDeposit,
      });
    }
  }, [user, poolReserve, walletData, marketRefPriceInUsd, networkConfig, amountToDeposit]);

  return depositData;
}
