import staticStyles from './style';
import InputBar from '../../../basic/InputBar';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { useMemo } from 'react';
import { MathUtils } from '../../../../utils/math.utils';
import classNames from 'classnames';
import { useEligibility } from './useEligibility';

export const MINIMUM_ELIGIBILITY_PERCENT = 5;

export function EligibilityProgress({ className }: { className?: string }) {
  const { userTotalLockedBalance } = useBalanceContext();
  const {
    prices: { lpTokenPriceUsd },
  } = useRewardTokenPrices();
  const { eligibilityPercent } = useEligibility();
  const { user } = useDynamicPoolDataContext();

  const lockedInUsd = useMemo(
    () => userTotalLockedBalance.multipliedBy(lpTokenPriceUsd),
    [userTotalLockedBalance, lpTokenPriceUsd]
  );

  return (
    <>
      <div className={classNames('eligibility', className)}>
        <div className="eligibility-progress">
          <InputBar
            minAmount={0}
            maxAmount={MINIMUM_ELIGIBILITY_PERCENT}
            value={eligibilityPercent.toNumber()}
            className="eligibility-progress__line eligibility-progress__line--small"
          >
            {({ rate, min, max }) => (
              <>
                <InputBar.Range onChange={() => {}} minAmount={min} maxAmount={max} value={3}>
                  <InputBar.Progress rate={rate > 1 ? 1 : rate} />
                </InputBar.Range>
              </>
            )}
          </InputBar>
          <InputBar
            minAmount={0}
            maxAmount={100 - MINIMUM_ELIGIBILITY_PERCENT}
            value={eligibilityPercent.minus(MINIMUM_ELIGIBILITY_PERCENT).toNumber()}
            className="eligibility-progress__line eligibility-progress__line--big"
          >
            {({ rate, min, max }) => (
              <>
                <InputBar.Range onChange={() => {}} minAmount={min} maxAmount={max} value={3}>
                  <InputBar.Progress rate={rate > 1 ? 1 : rate} />
                </InputBar.Range>
              </>
            )}
          </InputBar>
        </div>
        <div className="eligibility__footer">
          <span>
            <span className="eligibility__footer-label">Locked LP:</span>
            <span className="eligibility__footer-amount">
              $ {MathUtils.formatNumber(lockedInUsd, 2)} (
              {MathUtils.truncateNumber(eligibilityPercent, 2) || 0}%)
            </span>
          </span>
          <span>$ {MathUtils.formatNumber(Number(user?.totalLiquidityUSD) || 0, 2)}</span>
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
