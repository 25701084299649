import { useState, useEffect } from 'react';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import useRewardTokenPrices from '../../../store/tokens-price/tokens-price.hooks';

const useTotalData = () => {
  const [totalData, setTotalData] = useState<{
    totalLockedSupply: BigNumber;
    // readyToVest?: string;
    rewardTokenPrice: BigNumber;
    marketCap: BigNumber;
    totalLockedSupplyWithMultiplier: BigNumber | null;
    totalPlatformFees: BigNumber;
  }>({
    totalLockedSupply: valueToBigNumber(-1),
    totalLockedSupplyWithMultiplier: null,
    rewardTokenPrice: valueToBigNumber(0),
    marketCap: valueToBigNumber(0),
    totalPlatformFees: valueToBigNumber(0),
  });
  const { prices } = useRewardTokenPrices();

  useEffect(() => {
    const getData = async () => {
      try {
        setTotalData({
          totalLockedSupply: prices.totalLockedSupply,
          // readyToVest: prices.readyToVest || '0',
          rewardTokenPrice: valueToBigNumber(prices.tokenPriceUsd || 0),
          marketCap: valueToBigNumber(0),
          totalPlatformFees: valueToBigNumber(0),
          totalLockedSupplyWithMultiplier: prices.totalLockedSupplyWithMultiplier,
        });
      } catch (error) {
        console.log('useTotalData: Error => ', error);
      }
    };

    if (prices) {
      getData();

      const intervalId = setInterval(getData, 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [prices]);

  return { ...totalData };
};

export default useTotalData;
