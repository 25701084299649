import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .input-bar {
    &--primary &__range {
      background-color: rgba(170, 170, 170, 0.4);
    }

    &--primary &__progress {
      background-color: #aaaaaa;
    }

    &--secondary &__range {
      background: linear-gradient(
        -90deg,
        rgba(64, 235, 207, 0.4) 0%,
        rgba(255, 199, 105, 0.4) 50%,
        rgba(255, 115, 171, 0.4) 100%
      );
    }

    &--secondary &__progress {
      background: linear-gradient(90deg, #ff73ab 0%, #ff9b8c 24.5%);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      margin-bottom: 9px;
    }

    &__thumb {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      box-shadow: 0px 0px 8.8px 2px rgba(0, 0, 0, 0.25);
      outline: none !important;
      background-color: #fff;
    }

    &__range {
      position: relative;
      width: 100%;
      height: 8px;
      border-radius: 8px;
    }

    &__progress {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 10px 0 0 10px;
    }

    &__footer {
      margin-top: 7px;
      font-weight: 600;
      display: flex;
      justify-connect: flex-start;
    }

    &__dragged {
      position: relative;
      transform: translateX(-50%);
    }
  }
`;

export default staticStyles;
