import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';

  .reserve-loop {
    &__tabs {
      margin: auto;
    }
  }
`;

export default staticStyles;
